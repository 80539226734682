import { getMantisIdIfAllowedForVendor } from "./mantisId.js";
import { getFeatureFlag } from "../../helpers/configUtilities";
import { vendor } from "./consent.js"
/**
 * Sets the value for the publisher-provided ID for google Ad manager
 * @function setPublisherProvidedId
 * @returns {string} mantisid
 */
export function setPublisherProvidedId(gt = (window.googletag = window.googletag || { cmd: [] })) {
  return new Promise((resolve) => {
    getMantisIdIfAllowedForVendor(vendor.google).then((mantisId) => {
      const ppidEnabled = getFeatureFlag('ppidEnabled');
      if (ppidEnabled && mantisId) {
        gt.cmd.push(function () {
          gt.pubads().setPublisherProvidedId(mantisId);
          resolve();
        });
      } else {
        resolve();
      }
    });
  });
}


