import React, { useState, useMemo, useEffect, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { locations, locationsKey } from '../../config/locations';
import { getCategoryByName, getCategoryMap, getCategorys } from '../../config/categories';
import { getAllPublicationNames, getPublications } from '../../config/publications';
import { updateUserPreferences } from '../../services/api';
import { setUserIdFromLocalStorage } from '../../helpers/localConfigUtils';
import {
  getLocalConfig,
  getInterestKeys,
  getUniqueLocations,
  getInterestPropertiesByValue,
  setLocalConfig,
  updateInterests,
} from '../../helpers/localConfigUtils';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import InterestTreeMenu from '../InterestTreeMenu/InterestTreeMenu';

const categoriesDataMap = getCategoryMap();
const domainsNames = getAllPublicationNames();
const domainsData = getPublications();

const categoriesRawData = getCategorys();

const Interest = forwardRef((props, ref) => {
  const { isLogged, isProfile, personalisedNews, setPersonalisedNews } = props;
  let domainsArray = [];
  let locationsArray = [];
  let exclusionsArray = [];
  let expandedArray = [];

  const [selectedExclusions, setSelectedExclusions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedExpandedSearch, setSelectedExpandedSearch] = useState([]);
  const [isLimitExist, setLimitExist] = useState(false);
  const [isMinim, setIsMinim] = useState(false);
  const [isLocation, setIsLocation] = useState(false);
  const [searchBrand, setSearchBrand] = useState('');
  const [searchExpanded, setSearchExpanded] = useState('');
  const [searchLocation, setSearchLocation] = useState([]);
  const [activeKeys, setActiveKeys] = useState([]);
  const [openNodes, setOpenNodes] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleDelete = (chipToDelete) => () => {
    const categoryConfig = getCategoryByName(chipToDelete);
    const renderKey = categoryConfig.categoryType + '/' + chipToDelete;

    setSelectedCategories((categories) => {
      if (categories.includes(chipToDelete)) {
        return categories.filter((activeCategory) => activeCategory !== chipToDelete);
      }
      return categories;
    });

    setActiveKeys((keys) => {
      if (keys.includes(renderKey)) {
        return keys.filter((activeKey) => activeKey !== renderKey);
      }
      return [...keys, renderKey];
    });
  };

  const toggleItem = (event) => {
    if (event.level === 0) {
      if (openNodes.find(key => key  === event.key)) {
        setOpenNodes([]);
        return;
      }
      setOpenNodes([event.key]);
      return;
    } else {
      const categoryKey = event.categoryKey;
      const categoryConfig = getCategoryByName(categoryKey);
      const renderKey = `${categoryConfig.categoryType}/${categoryKey}`;

      if (selectedCategories.length < 50) {
        setSelectedCategories((categories) => {
          if (categories.includes(categoryKey)) {
            return categories.filter((activeCategory) => activeCategory !== categoryKey);
          }
          return [...categories, categoryKey];
        });
      }

      let isNewCategorySelected = true;

      if (activeKeys.length >= 50) { 
        setSelectedCategories((categories) => {
          if (categories.includes(categoryKey)) {
            isNewCategorySelected = false;
            return categories.filter((activeCategory) => activeCategory !== categoryKey);
          }
          return categories;
        });

        if (isNewCategorySelected) {
          setLimitExist(true); 
          return;
        }
      }

      setActiveKeys((keys) => {
        if (keys.includes(renderKey)) {
          return keys.filter((activeKey) => activeKey !== renderKey);
        }
        return [...keys, renderKey];
      });
    }
  };

  let navigate = useNavigate();

  const containsText = (text, searchCategory) => text.toLowerCase().indexOf(searchCategory) > -1;

  const getStoredExpandedSearch = () => {
    const storedExpandedSearch = getInterestPropertiesByValue('expandedSearch', true);
    let expandSearchArray = [];
    if (storedExpandedSearch) {
      categoriesDataMap.map((categoriesData) => {
        storedExpandedSearch.map((categoryKey) => {
          if (categoriesData[categoryKey]) {
            expandSearchArray.push(categoriesData[categoryKey].displayName);
          }
        });
      });
    }
    return expandSearchArray;
  };


  const displayedBrands = useMemo(() => domainsNames.filter((option) => containsText(option, searchBrand)), [searchBrand]);

  const displayedLocations = useMemo(() => locationsKey.filter((option) => containsText(option, searchLocation)), [searchLocation]);

  const expandedSearch = getStoredExpandedSearch()
  const displayedExpandedSearch = useMemo(() => expandedSearch.filter((option) => containsText(option, searchExpanded)), [searchExpanded]);


  const handleLocationChange = (event) => {
    setPersonalisedNews(true);
    const value = event.target.value;
    setSelectedLocation(value);
  };

  const handleExclusionsChange = (event) => {
    setPersonalisedNews(true);
    const value = event.target.value;
    if (value.length > 0) {
      setSelectedExclusions(value);
    } else {
      setSelectedExclusions([]);
    }
  };

  const handleExpandedSearchChange = (event) => {
    const value = event.target.value;
    setSelectedExpandedSearch(value);
  };

  const handleConfirm = (domainsData, categoriesDataMap, locations) => {
    if (activeKeys.length < 1 && personalisedNews) {
      setIsMinim(true);
      return;
    }
    if (selectedLocation.length === 0 && personalisedNews) {
      setIsLocation(true);
      return;
    }

    let localConfig = getLocalConfig();
    localConfig.consents.personalisedExperience = personalisedNews;

    if (personalisedNews) {
      if (selectedExpandedSearch.length) {
        let data = categoriesRawData;
        selectedExpandedSearch.forEach((expandedSearch) => {
          Object.entries(data).forEach(([key, value]) => {
            if (value.displayName === expandedSearch) {
              expandedArray.push(key);
            }
          });
        });
      }

      if (selectedExclusions.length || selectedExclusions.length === 0) {
        selectedExclusions.forEach((domainkey) => {
          for (const domain in domainsData) {
            if (domainsData[domain].Name === domainkey) {
              exclusionsArray.push(domainsData[domain].mantisID);
            }
          }
        });
      }

      if (selectedLocation.length) {
        selectedLocation.forEach((locationkey) => {
          locations.forEach((location) => {
            if (location[locationkey]) {
              if (locationkey !== 'US' && locationkey !== 'UK' && locationkey !== 'International' && !domainsArray.includes(locations[1]['UK'])) {
                domainsArray.push(location[locationkey], locations[1]['UK']);
              } else {
                domainsArray.push(location[locationkey]);
              }
              let locationsSelection = locationkey.startsWith('- ') ? locationkey.slice(2) : locationkey;
              locationsArray.push(locationsSelection);
            }
          });
        });
        localConfig.exclusions = exclusionsArray;
        localConfig = updateInterests(localConfig, selectedCategories, locationsArray, expandedArray);
      }
    }

    setLocalConfig(localConfig);

    if (localStorage.getItem('auth-user')) {
      updateUserPreferences();
    }

    navigate('/');
  };

  const handleInfoClose = () => {
    setLimitExist(false);
    setIsMinim(false);
    setIsLocation(false);
  };

  const handleAuthUILogin = (e) => {
    if (e.detail.isAuthenticated && !isProfile && isLogged) {
      setUserIdFromLocalStorage();
      isLogged(true);
    }
  };

  const handlePersonalizedFields = (checked) => {
    const localConfig = getLocalConfig();
    const storedCategories = getInterestKeys();
    const personalisedExperienceEnabled = checked ?? (localConfig?.consents.personalisedExperience ?? false);
    const storedExclusions = localConfig?.exclusions ?? [];
    const storedLocation = getUniqueLocations(localConfig);
    const storedExpandedSearch = getInterestPropertiesByValue('expandedSearch', true, localConfig);
  
    let brandArray = [];
    let locationsArray = [];
    let categoryArray = [];
    let categoryKeysArray = [];
    let expandSearchArray = [];
  
    window.addEventListener('auth-ui.authentication', handleAuthUILogin);
  
    const hasStoredExclusions = storedExclusions.length > 0;
    const hasStoredLocation = storedLocation.length > 0;
    const hasStoredCategories = storedCategories.length > 0;
  
    if (personalisedExperienceEnabled) {
      if (hasStoredExclusions) {
        storedExclusions.forEach((domainValue) => {
          for (const domain in domainsData) {
            if (domainsData[domain].mantisID === domainValue) {
              brandArray.push(domainsData[domain].Name);
            }
          }
        });
        setSelectedExclusions(brandArray);
      }
  
      if (hasStoredLocation) {
        storedLocation.forEach((locationValue) => {
          locations.forEach((location) => {
            if (Object.keys(location)[0] === locationValue) {
              locationsArray.push(locationValue);
            } else if (Object.keys(location)[0] === '- ' + locationValue) {
              locationsArray.push('- ' + locationValue);
            }
          });
        });
        setSelectedLocation(locationsArray);
      }
  
      if (hasStoredCategories) {
        storedCategories.forEach((categoryKey) => {
          const categoryConfig = getCategoryByName(categoryKey);
          const renderKey = `${categoryConfig?.categoryType}/${categoryKey}`;
          categoryArray.push(categoryKey);
          categoryKeysArray.push(renderKey);
        });
        setSelectedCategories(categoryArray);
        setActiveKeys(categoryKeysArray);
      }
  
      if (storedExpandedSearch.length > 0) {
        storedExpandedSearch.forEach((categoryKey) => {
          expandSearchArray.push(categoryKey);
        });
        setSelectedExpandedSearch(expandSearchArray);
      }
      setSelectedExpandedSearch(getStoredExpandedSearch());
    }
  };  

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('auth-ui.authentication', (e) => {
      handleAuthUILogin(e);
    });
    handlePersonalizedFields();
  }, []);

  useEffect(() => {
    if (!personalisedNews) {
      setSelectedExclusions([]);
      setActiveKeys([]);
      setSelectedLocation([]);
      setSelectedCategories([]);
      setSelectedExpandedSearch([]);
    } else {
      handlePersonalizedFields(personalisedNews);
    }
  }, [personalisedNews])

  return (
    <>
      <div>
        <Box sx={{  width: '100%', my: 3 }}>
          <DropdownMenu
            inputLabel='Location'
            selectedItems={selectedLocation}
            handleSelectionChange={handleLocationChange}
            handleSearchChange={setSearchLocation}
            displayedItems={displayedLocations}
          />
          <DropdownMenu
            inputLabel='Brand Exclusions'
            selectedItems={selectedExclusions}
            handleSelectionChange={handleExclusionsChange}
            handleSearchChange={setSearchBrand}
            displayedItems={displayedBrands}
          />
          { getInterestPropertiesByValue('expandedSearch', true).length > 0 && (
            <DropdownMenu
              inputLabel='Expanded search'
              selectedItems={selectedExpandedSearch}
              handleSelectionChange={handleExpandedSearchChange}
              handleSearchChange={setSearchExpanded}
              displayedItems={displayedExpandedSearch}
            />
          )}
        </Box>
        <InterestTreeMenu
          activeKeys={activeKeys}
          openNodes={openNodes}
          selectedCategories={selectedCategories}
          toggleItem={toggleItem}
          handleDelete={handleDelete}
        />
      </div>

      <Button
        onClick={() => {
          handleConfirm(domainsData, categoriesDataMap, locations);
        }}
        className='btn'
        color='primary'
        variant='contained'
        sx={{ my: 4 }}
      >
        Save
      </Button>

      {
        <Dialog open={isLimitExist} aria-labelledby="responsive-dialog-title">
          <DialogContent>
            <Typography id='responsive-dialog-title' variant='h3' sx={{ mx: 2, mt: 4, mb: 2 }}>Info</Typography>
            <Typography variant='h6' sx={{ mx: 2 }}>50 Categories can be selected at a time</Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              autoFocus
              onClick={handleInfoClose}
              color='primary'
              variant='contained'
              sx={{ width: '100%', mx: 4, mb: 4 }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      }
      {
        <Dialog open={isMinim} aria-labelledby="responsive-dialog-title">
          <DialogContent>
            <Typography id='responsive-dialog-title' variant='h3' sx={{ mx: 2, mt: 4, mb: 2 }}>Select a category</Typography>
            <Typography variant='h6' sx={{ mx: 2 }}>Please select a category</Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              autoFocus
              onClick={handleInfoClose}
              color='primary'
              variant='contained'
              sx={{ width: '100%', mx: 4, mb: 4 }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      }
      {
        <Dialog open={isLocation} aria-labelledby="responsive-dialog-title">
          <DialogContent>
            <Typography id='responsive-dialog-title' variant='h3' sx={{ mx: 2, mt: 4, mb: 2 }}>Select a Location</Typography>
            <Typography variant='h6' sx={{ mx: 2 }}>Please select a Location</Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              autoFocus
              onClick={handleInfoClose}
              color='primary'
              variant='contained'
              sx={{ width: '100%', mx: 4, mb: 4 }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
});

Interest.displayName = 'Interest';
export default Interest;
