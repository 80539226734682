// React import
import React from 'react';
import { useState, useEffect } from 'react'; // Importing useState and useEffect from 'react'

// Material-UI imports
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

// Local component imports
import ListItem from './categoryTeaser';
import './categoryWise.scss';
import CategoryDesktopPlaceholder from './gridDesktopPlaceholder';
import CategoryMobilePlaceholder from './gridMobilePlaceholder';
import Overlay from '../shared/Overlay';
import CategoryNotFound from '../homepage/categoryNotFound';
import CustomPopup from '../CustomPopup/CustomPopup';
import MissingPersonalisationPopup from '../shared/MissingPersonalisationPopup';
import PausedPersonalisationPopup from '../shared/PausedPersonalisationPopup';
import Signup from '../CustomPopup/Signup/Signup';
import EditableCategoryName from '../editableCategoryName/EditableCategoryName';
import Trending from '../breadcrumbsAndSocialSharing/Trending/Trending';

// API and routing imports
import { fetchDataFromAPI, getUserPreferences, updateUserPreferences } from '../../services/api';
import { useNavigate } from 'react-router-dom';

// Configuration imports
import { AdvertisingSlot } from 'react-advertising';
import categoryMobileSlots from '../../config/adConfig/categoryPageConfig/categorySlotsMobile';
import desktopSlots from '../../config/adConfig/categoryPageConfig/categorySlotsDesktop';

// Helper function imports
import {
  __log,
  isPersonalisedUser,
  isLoginRadiusUser,
  removeExcludedDomains,
  retrieveExcludedDomains,
  retrieveDomainsFromLocation,
  getScrollPercent,
  constructPath,
} from '../../helpers/utils';
import { getFeatureValue } from '../../helpers/configUtilities';
import { getSuggestedCategories } from '../../config/categories';

// Helmet for head tag management
import { Helmet } from 'react-helmet-async';

// Local storage utility imports
import { getLocalConfig, setLocalConfig, getInterestProperty, updateInterests, updateInterest, isPersonalisedExperience } from '../../helpers/localConfigUtils';

let isEndOfThePage = true;

export default function List({ sectionSlug, subSectionSlug, categorySlug, config, circularLoading }) {
  const initialDisplayName = () => {
    let localConfig = getLocalConfig();
    return localConfig?.interests?.[config.key]?.displayName ?? config.displayName ?? '';
  };
  const [lists, setLists] = useState({ mobile: [], desktop: [] });
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);
  const [pageStart, setPageStart] = useState(0);
  const [pageLimit, setPageLimit] = useState(30);
  const [isLoading, setIsLoading] = useState(true);
  const [paginateCount, setPaginateCount] = useState(1);
  const [isPaginate, setIsPaginate] = useState(false);
  const [updateDomains, setUpdateDomains] = useState(false);
  const [isSavedCategory, setIsSavedCategory] = useState(false);
  const [isEnhancedSignInPopupOpen, setIsEnhancedSignInPopupOpen] = useState(false);
  const [isPausedPopupOpen, setIsPausedPopupOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMissingPersonalisationPopup, setIsMissingPersonalisationPopup] = useState(false);
  const [displayName, setDisplayName] = useState(initialDisplayName);

  const navigate = useNavigate();
  const theme = useTheme();
  let categoryMobileArticles = [];
  let categoryDesktopArticles = [];
  const siteRoot = getFeatureValue('siteRoot');

  const handleLogin = () => {
    setIsEnhancedSignInPopupOpen(false);

    const event = new CustomEvent('auth-ui.authenticate', {
      detail: { origin: window.location.pathname },
    });
    window.dispatchEvent(event);
  };

  const enhancedSignInPopupActions = [
    {
      onClick: () => handleLogin(),
      label: 'sign in',
    },
    {
      onClick: () => navigate('/signup/interests'),
      label: 'signup',
    },
  ];

  const handleClosePausedPopup = () => {
    setIsPausedPopupOpen(false);
  };

  const handleClosePersonalisationPopup = () => {
    setIsMissingPersonalisationPopup(false);
  };

  let localConfig = getLocalConfig();
  const isLoggedIn = isLoginRadiusUser();
  let personalisedExperience = isPersonalisedExperience();
  const expectedURLPath = constructPath(config?.sectionSlug, config?.subSectionSlug, config?.categorySlug);
  const actualURLPath = constructPath(sectionSlug, subSectionSlug, categorySlug);
  const isExpectedCategoryMatch = expectedURLPath === actualURLPath;
  const canonicalURL = `${siteRoot}/${expectedURLPath}`;
  const overlayCategory = subSectionSlug === 'cost-of-living';
  const limitPerLoad = 30;
  const suggestedCategories = getSuggestedCategories(sectionSlug, subSectionSlug, config.key);

  const injectsAds = (data) => {
    const dataCopy = [...data];

    categoryMobileSlots.forEach((slot) => {
      if (dataCopy.length >= slot.position) {
        dataCopy.splice(slot.position, 0, {
          id: slot.id,
          isAd: true,
        });
      }
    });
    return dataCopy;
  };

  const updateMedia = () => {
    setIsDesktopView(window.innerWidth > 768);
  };

  const handleLoadMore = () => {
    setIsLoading(true);
    setIsPaginate(true);
    setPageStart(pageStart + limitPerLoad);
    setPageLimit(pageLimit);
    setPaginateCount(paginateCount + 1);
    setIsLoading(false);
    if (lists.mobile.length && lists.desktop.length) {
      circularLoading(true);
    }
  };

  const handleSavedCategory = () => {
    personalisedExperience = isPersonalisedExperience();
    if (!localConfig || !localConfig?.interests) {
      if (localStorage.getItem('auth-user')) {
        setIsMissingPersonalisationPopup(true);
        return null;
      }
      setIsEnhancedSignInPopupOpen(true);
      return null;
    } else if (!personalisedExperience) {
      setIsPausedPopupOpen(true);
      return null;
    }
    // To create/update a new interest
    const newInterestName = config.key;

    const isSavedCategory = updateInterest(newInterestName);
    setIsSavedCategory(isSavedCategory);

    if (localStorage.getItem('auth-user')) {
      updateUserPreferences();
    }

    navigate('/' + expectedURLPath);
  };

  const handleAuthAndSavedCategory = async (event) => {
    if (localConfig) return;

    const data = event.data || event.detail || {};
    if (!data.isAuthenticated) return;
    setIsAuthenticated(true);

    const userProfile = await getUserPreferences();

    if (data.isAuthenticated && !userProfile) {
      setIsMissingPersonalisationPopup(true);
      return null;
    }
    setLocalConfig(userProfile?.profile_info);
    const newInterestName = config.key;
    const isSavedCategory = updateInterest(newInterestName);
    setIsSavedCategory(isSavedCategory);
    updateUserPreferences();
    navigate('/' + expectedURLPath);
  };

  const handleCategoryNameChange = (e) => {
    if (!localConfig || !Object.prototype.hasOwnProperty.call(localConfig.interests, config.key)) return;

    const newDisplayName = e.target.value.trim();
    localConfig.interests[config.key].displayName = newDisplayName;
    setDisplayName(newDisplayName);
    setLocalConfig(localConfig);
    localStorage.getItem('auth-user') && updateUserPreferences();
    navigate('/' + expectedURLPath);
  };

  useEffect(() => {
    //decides the url
    if (config && config.isEnabled !== false) {
      if (!isExpectedCategoryMatch) {
        navigate('/' + expectedURLPath);
      }
    } else {
      navigate('/error/404');
    }

    if (localStorage.getItem('lrLogged') && localStorage.getItem('lrLogged') === 'true') {
      setIsAuthenticated(true);
    }

    // Check if the current searchQuery matches any saved search
    if (localConfig) {
      const savedCategory = localConfig?.interests || {};
      const key = config.key;
      setIsSavedCategory(key && key !== '' && savedCategory?.[key] ? true : false);
    }

    personalisedExperience = isPersonalisedExperience();
    const expandedSearchEnabled = getInterestProperty('expandedSearch', config.key);
    let interestedDomains = removeExcludedDomains(retrieveDomainsFromLocation(config.key));
    let storedExclusions = retrieveExcludedDomains();

    const getRandomLists = () => {
      if (!isPaginate) {
        setIsLoading(true);
        setLists({ mobile: [], desktop: [] });
        setPageStart(0);
      }
      if (expandedSearchEnabled || !personalisedExperience) {
        interestedDomains = '';
      } else if (!isPaginate) {
        setLists({ mobile: [], desktop: [] });
      }
      fetchDataFromAPI({
        age: config.age,
        concepts: config.concepts ?? '',
        domains: interestedDomains,
        entities: config.entities ?? '',
        exclude_concepts: config.exclude_concepts ?? '',
        exclude_entities: config.exclude_entities ?? '',
        exclude_mantis_categories: config.excludeMantisCategories ?? '',
        exclude_tags: config.excludeTags ?? '',
        excludedDomains: storedExclusions,
        mantisCategories: config.mantisCategories,
        pageLimit: pageLimit,
        pageStart: isPaginate ? pageStart : 0,
        tags: config.tags,
      })
        .then((response) => {
          const data = response.articleData;
          if (data && data.length > 0) {
            let sortedData = data.sort((articleOne, articleTwo) => {
              return new Date(articleTwo.lastModified) - new Date(articleOne.lastModified); //sorting articles in decending order to display latest articles first
            });
            sortedData.forEach((article) => {
              article.title = article.title.replace(/(<([^>]+)>)/gi, ''); //escape html tag
            });
            let articleJson = sortedData.map(JSON.stringify);
            let uniqueArticle = new Set(articleJson);
            let uniqueArray = Array.from(uniqueArticle).map(JSON.parse);
            //slots array is injected in the data array
            const dataWithAds = injectsAds(uniqueArray);
            //data arrays are differentiated depending of the viewport
            if (isPaginate) {
              categoryMobileArticles = [...lists.mobile, ...dataWithAds];
              categoryDesktopArticles = [...lists.desktop, ...uniqueArray];
            } else {
              categoryMobileArticles = dataWithAds;
              categoryDesktopArticles = uniqueArray;
              window.scrollTo(0, 0);
            }
            setLists({ mobile: categoryMobileArticles, desktop: categoryDesktopArticles });
            isEndOfThePage = true;
          }
          setIsPaginate(false);
          setIsLoading(false);
          circularLoading(false);
          window.dispatchEvent(new CustomEvent('singleCategoryLoaded'));
          __log('singleCategoryLoaded event dispatched, category loaded case', 1);
        })
        .catch((error) => {
          setIsLoading(false);
          circularLoading(false);
          __log('fetchDataFromAPI error', error);
        });
    };
    getRandomLists();

    setDisplayName(localConfig?.interests?.[config.key]?.displayName ?? config.displayName);

    window.addEventListener('auth-ui.authentication', handleAuthAndSavedCategory);
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateMedia);
    return () => {
      window.removeEventListener('resize', updateMedia);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('auth-ui.authentication', handleAuthAndSavedCategory);
    };
  }, [isDesktopView, sectionSlug, subSectionSlug, categorySlug, paginateCount, updateDomains, isAuthenticated]);

  const handleScroll = (e) => {
    let scrollPercent = getScrollPercent().toFixed();
    if (scrollPercent > 90 && isEndOfThePage) {
      isEndOfThePage = false;
      handleLoadMore();
    }
  };

  const updateExcludedDomains = () => {
    setUpdateDomains(true);
    window.location.reload();
  };

  if (lists.length === 0) {
    return <div></div>;
  }

  const FollowButton = (
    <Button
      data-testid="save-button"
      variant="contained"
      className="follow-btn"
      onClick={handleSavedCategory}
      aria-label={isSavedCategory ? 'Unfollow' : 'Follow'}
      endIcon={isSavedCategory && personalisedExperience ? <FavoriteIcon color="primary" /> : <FavoriteBorderIcon color="primary" />}
    >
      <Typography variant="label1" sx={{ color: theme.palette.common.black }}>
        {isSavedCategory && personalisedExperience ? 'Unfollow' : 'Follow'}
      </Typography>
    </Button>
  );

  return (
    <>
      <Helmet>
        <title>{config.metaTitle ? config.metaTitle + ' | My News Assistant' : config.displayName + ' | My News Assistant'}</title>
        <link rel="canonical" href={canonicalURL} />
        <meta name="description" content={config.metaDescription ? config.metaDescription : config.displayName + ' Latest News'} />
        <meta property="og:title" content={config.metaTitle ? config.metaTitle + ' | My News Assistant' : config.displayName + ' | My News Assistant'} />
        <meta property="og:description" content={config.metaDescription ? config.metaDescription : config.displayName + ' Latest News'} />
        <meta property="og:image" content={config.metaImage ? config.metaImage : `${siteRoot}/static/assets/images/mna_twitter_image.jpg`} />
        <meta property="og:url" content={canonicalURL} />

        <meta name="twitter:title" content={config.metaTitle ? config.metaTitle + ' | My News Assistant' : config.displayName + ' | My News Assistant'} />
        <meta name="twitter:description" content={config.metaDescription ? config.metaDescription : config.displayName + ' Latest News'} />
        <meta name="twitter:image" content={config.metaImage ? config.metaImage : `${siteRoot}/static/assets/images/mna_twitter_image.jpg`} />
        <meta name="twitter:card" content={`${siteRoot}/static/assets/images/mna_twitter_card.jpg`} />
      </Helmet>
      {isDesktopView ? (
        <div className="categoryList">
          {isLoading ? (
            <CategoryDesktopPlaceholder />
          ) : (
            <div className="category-wrapper">
              <div className="category-header">
                {overlayCategory && !personalisedExperience && !isLoggedIn && <Overlay />}
                <div className="title-container">
                  <EditableCategoryName
                    displayName={displayName}
                    isEditable={isLoggedIn && personalisedExperience && isSavedCategory}
                    handleCategoryNameChange={handleCategoryNameChange}
                  />
                  {FollowButton}
                </div>
                {Object.keys(suggestedCategories).length !== 0 ? <Trending trendingKeywords={suggestedCategories} text /> : null}
              </div>
              <Grid container columnSpacing={1} sx={{ justifyContent: 'space-between', minHeight: '100vh' }}>
                {lists.desktop.length > 0 ? (
                  lists.desktop.map((item, i) => (
                    <Grid item key={i} index={i} sx={{ width: '330px', marginBottom: '25px' }}>
                      <ListItem item={item} updateExcludedDomains={updateExcludedDomains} />
                    </Grid>
                  ))
                ) : (
                  <CategoryNotFound categoryName={categorySlug} />
                )}
              </Grid>
            </div>
          )}
          <div className="ad-container">
            <AdvertisingSlot id={desktopSlots[0].id} key={desktopSlots[0].id} className="ad-placeholder-category" />
          </div>
        </div>
      ) : (
        <>
          {isLoading ? (
            <CategoryMobilePlaceholder />
          ) : (
            <div className="category-wrapper">
              <div className="category-header">
                {overlayCategory && !personalisedExperience && !isLoggedIn && <Overlay />}
                <EditableCategoryName
                  displayName={displayName}
                  isEditable={isLoggedIn && personalisedExperience && isSavedCategory}
                  handleCategoryNameChange={handleCategoryNameChange}
                />
                {FollowButton}
              </div>
              <Container maxWidth="xl" sx={{ padding: 'unset' }}>
                {lists.mobile.length > 0 ? (
                  <Grid container columnSpacing={1} sx={{ justifyContent: 'space-between' }}>
                    {lists.mobile.map((item, i) =>
                      item.isAd ? (
                        <Grid container>
                          <AdvertisingSlot id={item.id} key={i} className="ad-placeholder-category-mobile" />
                        </Grid>
                      ) : (
                        <Grid
                          item={item}
                          key={i}
                          index={i}
                          xs={12}
                          sm={6}
                          sx={{ width: window.innerWidth >= 690 && window.innerWidth <= 768 ? '330px' : '100%', marginBottom: '15px' }}
                        >
                          <ListItem item={item} updateExcludedDomains={updateExcludedDomains} />
                        </Grid>
                      )
                    )}
                  </Grid>
                ) : (
                  <CategoryNotFound categoryName={categorySlug} />
                )}
              </Container>
            </div>
          )}
        </>
      )}
      {isEnhancedSignInPopupOpen && (
        <CustomPopup
          isOpen={isEnhancedSignInPopupOpen}
          onClose={() => setIsEnhancedSignInPopupOpen(false)}
          logo={require(`../../assets/mna_alt_logo.png`)}
          divider
          title="Sign in for an enhanced My News Assistant experience"
          actionButtons={enhancedSignInPopupActions}
        >
          <Signup />
        </CustomPopup>
      )}
      {isPausedPopupOpen && <PausedPersonalisationPopup show={isPausedPopupOpen} handleClosePausedPopup={handleClosePausedPopup} />}

      {isMissingPersonalisationPopup && (
        <MissingPersonalisationPopup show={isMissingPersonalisationPopup} handleClosePersonalisationPopup={handleClosePersonalisationPopup} />
      )}
    </>
  );
}
