import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { AdvertisingProvider, AdvertisingSlot } from 'react-advertising';
import List from '../../components/homepage/category';
import mobileSlots from '../../config/adConfig/homePageConfig/slotsMobile';
import { setPublisherProvidedId } from '../../components/tracking/gam';
import { init as mantisIdInit } from '../../components/tracking/mantisId';
import { getAdConfig } from '../../config/adConfig/getAdConfig';
import { getCategoryBySection, getCategoryByName } from '../../config/categories.js';
import { getRouteByName } from '../../config/routeConfig';
import { __log, constructPath } from '../../helpers/utils';
import { getFeatureValue } from '../../helpers/configUtilities';
import './sectionPage.scss';

const mantisIdReady = new Promise(resolve => {
  mantisIdInit().then(setPublisherProvidedId).then(resolve);
  if (location.hostname === 'localhost') {
    __log('localhost env detected', 'skipping cmp load');
    resolve();
  }
});

/**
 * The Section page component.
 * @returns {ReactElement} The Section page component.
 */
export default function SectionPage({ sectionSlug, subSectionSlug, categorySlug, config }) {
  const [adConfiguration, setAdConfiguration] = useState();
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);
  const [pinnedCategories, setPinnedCategories] = useState([]);
  const siteRoot = getFeatureValue('siteRoot');
  const categoriesConfig = getCategoryBySection(sectionSlug, subSectionSlug);
  const categoriesMap = new Map(Object.entries(categoriesConfig));
  const numCategories = Object.keys(categoriesConfig).length;
  const canonicalURLPath = constructPath(sectionSlug, subSectionSlug, categorySlug);
  const canonicalURL = `${siteRoot}/${canonicalURLPath}`;

  /**
   * Update the pinned categories state by toggling the given category key.
   * @param {string} categoryKey - The key of the category to toggle.
   */
  const updatePinnedCategories = categoryKey => {
    setPinnedCategories(prevState => {
      if (prevState.includes(categoryKey)) {
        return prevState.filter(activeCategory => activeCategory !== categoryKey);
      }
      return [...prevState, categoryKey];
    });
    __log('state changing in the section page with the clicked categ', categoryKey);
  };

  /**
   * Update the media state (desktop or mobile) and reinitialize ads.
   */
  const updateMedia = () => {
    initAds();
    setIsDesktopView(window.innerWidth > 768);
  };

  /**
   * Initialize ads after the Mantis ID is ready.
   */
  const initAds = async () => {
    await mantisIdReady;
    setAdConfiguration(getAdConfig('sectionpage'));
    __log('Ads initiated', 'for section page');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('allCategoriesLoaded', initAds);
    window.addEventListener('resize', updateMedia);
    return () => {
      window.removeEventListener('resize', updateMedia);
      window.removeEventListener('allCategoriesLoaded', initAds);
    };
  }, [pinnedCategories]);

  /**
   * Render a category and its corresponding ad slot.
   * @param {number} index - The index of the category.
   * @param {string} categoryName - The name of the category.
   * @returns {ReactElement|null} The rendered category and ad slot, or null if the category is not enabled.
   */
  const renderCategory = (index, categoryName) => {
    const isDesktop = window.innerWidth > 768;
    const categoryConfig = getCategoryByName(categoryName);

    // Skip rendering if the category is not enabled
    if (!categoryConfig || categoryConfig.isEnabled === false) {
      return null;
    }
    const mobileAdId =
      !isDesktop && mobileSlots.find((slot) => slot.position === index)?.id;

    return (
      <div key={index}>
        <List
          key={categoryConfig.mantisCategories}
          categorySlug={categoryConfig.categorySlug}
          categoryName={categoryName}
          displayName={categoryConfig.displayName}
          sectionSlug={categoryConfig.sectionSlug}
          subSectionSlug={categoryConfig.subSectionSlug}
          domains=""
          age={categoryConfig.age}
          rowIndex={index}
          numCategories={numCategories}
          desktopOrMobile={isDesktopView}
          pageStart={0}
          pageLimit={categoryConfig.limit}
          mantisCategories={categoryConfig.mantisCategories}
          tags={categoryConfig.tags}
          exclude_tags={categoryConfig?.excludeTags}
          exclude_mantis_categories={categoryConfig?.excludeMantisCategories}
          concepts={categoryConfig?.concepts}
          entities={categoryConfig?.entities}
          exclude_concepts={categoryConfig?.exclude_concepts}
          exclude_entities={categoryConfig?.exclude_entities}
          categoryType={true}
          updatePinnedCategories={updatePinnedCategories}
        />
        {mobileAdId && <AdvertisingSlot id={mobileAdId} key={mobileAdId} className="ad-placeholder" />}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{config.metaTitle ? config.metaTitle + ' | My News Assistant' : config.displayName + ' | My News Assistant'}</title>
        <link rel="canonical" href={canonicalURL} />
        <meta name="description" content={config.metaDescription ? config.metaDescription : config.displayName + ' Latest News'} />
        <meta property="og:title" content={config.metaTitle ? config.metaTitle + ' | My News Assistant' : config.displayName + ' | My News Assistant'} />
        <meta property="og:description" content={config.metaDescription ? config.metaDescription : config.displayName + ' Latest News'} />
        <meta property="og:image" content={config.metaImage ? config.metaImage : `${siteRoot}/static/assets/images/mna_twitter_image.jpg`} />
        <meta property="og:url" content={canonicalURL} />

        <meta name="twitter:title" content={config.metaTitle ? config.metaTitle + ' | My News Assistant' : config.displayName + ' | My News Assistant'} />
        <meta name="twitter:description" content={config.metaDescription ? config.metaDescription : config.displayName + ' Latest News'} />
        <meta name="twitter:image" content={config.metaImage ? config.metaImage : `${siteRoot}/static/assets/images/mna_twitter_image.jpg`} />
        <meta name="twitter:card" content={`${siteRoot}/static/assets/images/mna_twitter_card.jpg`} />
      </Helmet>
      <div className="standard-layout section-page">
        <main className={window.innerWidth > 768 ? 'desktop' : 'mobile'}>
          <AdvertisingProvider config={adConfiguration}>
            {Array.from(categoriesMap.keys()).map((categoryName, index) => {
              if (categoriesMap.get(categoryName).subSectionSlug === subSectionSlug || categoriesMap.get(categoryName).sectionSlug === sectionSlug) {
                return renderCategory(index, categoryName);
              }
              return null;
            })}
          </AdvertisingProvider>
        </main>
      </div>
    </>
  );
}
