import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import List from '../../../components/homepage/category';
import { getCategorys } from '../../../config/categories.js';
import './pageNotFound.scss';

export default function NotFound() {
  const config = getCategorys();
  const defaultCategoryName = 'news';
  const categoryConfig = config[defaultCategoryName];
  const [pinnedCateg, setPinnedCateg] = useState([]);

  const updatePinnedCategories = (categoryKey) => {
    setPinnedCateg((prevState) => {
      if (prevState.includes(categoryKey)) {
        return prevState.filter((activeCategory) => activeCategory !== categoryKey);
      }
      return [...prevState, categoryKey];
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pinnedCateg]);

  return (
    <div className="bordered-container page-not-found">
      <Typography variant="h1">Oops</Typography>
      <Typography variant="label4" sx={{ display: 'block', my: 2 }}>
        We cant seem to find the page you are looking for.
      </Typography>
      <div className='content-text'>
      <Typography variant='body1'>
        It looks like you've stumbled upon a page that doesn't exist... or maybe it used to exist, but now it's just a figment of your imagination. Either way,
        you won't find any breaking news here. But don't worry, there's still plenty of fake news out there for you to discover. Just kidding, we don't do fake
        news here. Or do we? wink
      </Typography>
      <Typography variant='body1'>
        If you'd like to return to our homepage, just click{' '}
        <Link to="/" className='link'>here</Link>
        . Or, if you're feeling adventurous, why not check out some of the content our users can't get enough of: 👇
      </Typography>
      <Typography variant='body1' color='primary'>Error Code: 404</Typography>
      </div>
      <div className="carousel-div">
        <List
          key={defaultCategoryName}
          categorySlug={categoryConfig.categorySlug}
          categoryName={defaultCategoryName}
          displayName={categoryConfig.displayName}
          sectionSlug={categoryConfig.sectionSlug}
          subSectionSlug={categoryConfig.subSectionSlug}
          domains=""
          age={categoryConfig.age}
          rowIndex={1}
          pageStart={0}
          pageLimit={categoryConfig.limit}
          isPageNotFound={true}
          mantisCategories={categoryConfig.mantisCategories}
          tags={categoryConfig.tags}
          exclude_tags={categoryConfig?.excludeTags}
          exclude_mantis_categories={categoryConfig?.excludeMantisCategories}
          concepts={categoryConfig?.concepts}
          entities={categoryConfig?.entities}
          exclude_concepts={categoryConfig?.exclude_concepts}
          exclude_entities={categoryConfig?.exclude_entities}
          categoryType={categoryConfig.categoryType}
          updatePinnedCategories={updatePinnedCategories}
        />
      </div>
    </div>
  );
}
