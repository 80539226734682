import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

// Local Components
import Breadcrumbs from './breadcrumbs/breadcrumbs';
import ShareButton from './ShareButton/ShareButton';
import TrendingComponent from './Trending/Trending';

// Helper Functions and Constants
import { getCategoryByName } from '../../config/categories';
import { getRouteByName } from '../../config/routeConfig';
import { constructPath } from '../../helpers/utils';
import { getFeatureFlag, getFeatureValue } from '../../helpers/configUtilities';

// Styling
import './breadcrumbsAndSocialSharing.scss';

const BreadcrumbsAndSocialSharingContainer = () => {
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth >= 768);
  const { sectionSlug, subSectionSlug, categorySlug } = useParams();
  const location = useLocation();

  // Retrieve values from location if they are undefined
  const updatedSectionSlug = sectionSlug || location.pathname.split('/')[1];
  const updatedSubSectionSlug = subSectionSlug || location.pathname.split('/')[2];
  const updatedCategorySlug = categorySlug || location.pathname.split('/')[3];

  // Check if trending component is enabled and get its value
  const trendingKeywords = getFeatureFlag('TrendingComponentEnabled') ? getFeatureValue('trendingKeywords') : '';

  const shouldRenderTrending = location.pathname === '/' || location.pathname === '/feed/';

  useEffect(() => {
    const handleResize = () => setIsDesktopView(window.innerWidth >= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="breadcrumbs-and-sharing-container">
      {isDesktopView ? (
        <>
          {shouldRenderTrending ? (
            <div className="trending-container" data-testid="trending-component">
              <TrendingComponent trendingKeywords={trendingKeywords} icon />
            </div>
          ) : (
            <div className="breadcrumbs-container">
              <Breadcrumbs sectionSlug={updatedSectionSlug} subSectionSlug={updatedSubSectionSlug} categorySlug={updatedCategorySlug} />
            </div>
          )}
          <div className="sharing-container">
            <ShareButton />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default BreadcrumbsAndSocialSharingContainer;
