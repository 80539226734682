import { getFeatureFlag } from '../../helpers/configUtilities';
import TeaserIframe from '../shared/TeaserIframe';
import Teaser from '../shared/Teaser';

export default function ListItem({ index, item, updateExcludedDomains }) {
  return (
    <div>
      {getFeatureFlag('iframeCategory') ? (
        <TeaserIframe item={item} updateExcludedDomains={updateExcludedDomains} />
      ) : (
        <Teaser item={item} updateExcludedDomains={updateExcludedDomains} />
      )}
    </div>
  );
}
