import { createTheme } from '@mui/material/styles';

export const navbarTheme = (theme) =>
  createTheme({
    ...theme,
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: '0px 7px 24px 0px rgba(0, 0, 0, 0.15)',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            justifyContent: 'space-between',
            minHeight: '64px',
            maxHeight: '64px',
            [theme.breakpoints.up('tablet')]: {
              minHeight: '77px',
              maxHeight: '77px',
            },
            '& .MuiToolbar-gutters': {
              paddingLeft: '24px',
              paddingRight: '24px',
              backgroundColor: 'red',
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgb(0, 0, 0, 0.5)',
            '& .MuiPaper-root': {
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.timberGreen.main,
            },
            '& .MuiMenuItem-root': {
              fontFamily: theme.typography.fontFamily,
              fontSize: theme.typography.size20,
              '&:hover': {
                backgroundColor: theme.palette.common.black,
              },
              '&:focus-visible': {
                backgroundColor: theme.palette.common.black,
              },
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: '40px',
            height: '40px',
            '& .MuiTab-root': {
              [theme.breakpoints.up('tablet')]: {
                minWidth: '146px',
              },
            },
            '& .MuiTabs-flexContainer': {
              height: '40px',
            },
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.common.black,
              display: 'none',
              [theme.breakpoints.up('tablet')]: {
                display: 'unset',
              },
            },
            '& .MuiSvgIcon-fontSizeSmall ': {
              fontSize: '2rem',
            },
            '& .Mui-selected': {
              color: `${theme.palette.primary.main} !important`,
              [theme.breakpoints.up('tablet')]: {
                color: `${theme.palette.common.black} !important`,
              },
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minHeight: '40px',
            textTransform: 'initial',
            maxWidth: 'none',
          },
        },
      },
    },
  });