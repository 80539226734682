import Iframe from 'react-iframe';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackableURL } from '../../helpers/utils';
import './articlePage.scss';

export default function ArticlePage(props) {
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);

  const { state } = useLocation();
  const updateMedia = () => {
    setIsDesktopView(window.innerWidth > 768);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <div className='article-page'>
      <main className="iframebody">
        <Iframe url={trackableURL(state.url, 'teaser_description')} position="absolute" width="100%" id="myId" className="myClassname" height="100%" />
      </main>
    </div>
  );
}
