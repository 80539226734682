// React and hooks
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdvertisingSlot } from 'react-advertising';
import { Helmet } from 'react-helmet-async';

// Material UI Components and Icons
import '../categorypage/categoryWise.scss';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

// Local Components
import ListItem from '../categorypage/categoryTeaser';
import { fetchTermData, updateUserPreferences, getUserPreferences } from '../../services/api';
import categoryMobileSlots from '../../config/adConfig/categoryPageConfig/categorySlotsMobile';
import desktopSlots from '../../config/adConfig/categoryPageConfig/categorySlotsDesktop';
import CategoryDesktopPlaceholder from '../categorypage/gridDesktopPlaceholder';
import CategoryMobilePlaceholder from '../categorypage/gridMobilePlaceholder';
import CategoryNotFound from '../homepage/categoryNotFound';
import CustomPopup from '../CustomPopup/CustomPopup';
import MissingPersonalisationPopup from '../shared/MissingPersonalisationPopup';
import PausedPersonalisationPopup from '../shared/PausedPersonalisationPopup';
import Signup from '../CustomPopup/Signup/Signup';
import EditableCategoryName from '../editableCategoryName/EditableCategoryName';
import Trending from '../breadcrumbsAndSocialSharing/Trending/Trending';

// Helper Functions and Constants
import { __log, retrieveExcludedDomains, getScrollPercent, createTimestamp, isLoginRadiusUser } from '../../helpers/utils';
import { getLocalConfig, setLocalConfig, isPersonalisedExperience } from '../../helpers/localConfigUtils';
import { getSavedSearchConfigOrDefault, getSavedSearchProperty, updateSavedSearchCategory, generateSearchQuery } from '../../helpers/savedSearchUtils';
import { getFeatureFlag, getFeatureValue } from '../../helpers/configUtilities';
import { getSuggestedCategories } from '../../config/categories';

export default function List({ savedSearchSlug, circularLoading }) {
  const initialDisplayName = savedSearchSlug ? savedSearchSlug.replace(/\b\w/g, (char) => char.toUpperCase()) : '';

  const [lists, setLists] = useState({ mobile: [], desktop: [] });
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);
  const [pageStart, setPageStart] = useState(0);
  const [pageLimit, setPageLimit] = useState(30);
  const [isLoading, setIsLoading] = useState(true);
  const [paginateCount, setPaginateCount] = useState(1);
  const [isPaginate, setIsPaginate] = useState(false);
  const [updateDomains, setUpdateDomains] = useState(false);
  const [isSavedSearch, setIsSavedSearch] = useState(false);
  const [isEnhancedSignInPopupOpen, setIsEnhancedSignInPopupOpen] = useState(false);
  const [isPausedPopupOpen, setIsPausedPopupOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMissingPersonalisationPopup, setIsMissingPersonalisationPopup] = useState(false);
  const [displayName, setDisplayName] = useState(initialDisplayName);

  const navigate = useNavigate();
  const theme = useTheme();
  const UserDefinedCategoriesEnabled = getFeatureFlag('UserDefinedCategoriesEnabled');
  const siteRoot = getFeatureValue('siteRoot');

  const handleLogin = () => {
    setIsEnhancedSignInPopupOpen(false);

    const event = new CustomEvent('auth-ui.authenticate', {
      detail: { origin: window.location.pathname },
    });
    window.dispatchEvent(event);
  };

  const enhancedSignInPopupActions = [
    {
      onClick: () => handleLogin(),
      label: 'sign in',
    },
    {
      onClick: () => navigate('/signup/interests'),
      label: 'signup',
    },
  ];

  const handleClosePausedPopup = () => {
    setIsPausedPopupOpen(false);
  };

  const handleClosePersonalisationPopup = () => {
    setIsMissingPersonalisationPopup(false);
  };

  let localConfig = getLocalConfig();
  const isLoggedIn = isLoginRadiusUser();
  let personalisedExperience = isPersonalisedExperience();
  const key = savedSearchSlug ? savedSearchSlug.toLowerCase().replace(/\s+/g, '-') : '';
  const savedSearch = getSavedSearchConfigOrDefault(key, localConfig);
  const isLocalSavedConfig = savedSearch?.searchQuery || savedSearch?.orSearchQuery ? true : false;

  const searchConfig = isLocalSavedConfig
    ? savedSearch
    : {
        ...savedSearch,
        categorySlug: key,
        category: key,
        createdDate: createTimestamp(),
        displayName: initialDisplayName.replace(/-/g, ' '),
        name: key,
        searchQuery: savedSearchSlug.replace(/-/g, ' '),
        sortOrder: 'date',
      };
  const canonicalURL = `${siteRoot}/${savedSearchSlug}`;
  const limitPerLoad = 30;
  let isEndOfThePage = true;
  const suggestedCategories = getSuggestedCategories(searchConfig.sectionSlug, searchConfig.subSectionSlug, searchConfig.name);

  const injectsAds = (data) => {
    const dataCopy = [...data];

    categoryMobileSlots.forEach((slot) => {
      if (dataCopy.length >= slot.position) {
        dataCopy.splice(slot.position, 0, {
          id: slot.id,
          isAd: true,
        });
      }
    });
    return dataCopy;
  };

  const updateMedia = () => {
    setIsDesktopView(window.innerWidth > 768);
  };

  const handleLoadMore = () => {
    setIsLoading(true);
    setIsPaginate(true);
    setPageStart(pageStart + limitPerLoad);
    setPageLimit(pageLimit);
    setPaginateCount((prevCount) => prevCount + 1);
    setIsLoading(false);
    if (lists.mobile.length && lists.desktop.length) {
      circularLoading(true);
    }
  };

  const updateSavedSearches = () => {
    const { searchQuery, key } = generateSearchQuery(searchConfig);
    const isSavedCategory = updateSavedSearchCategory(searchQuery, searchConfig);
    setIsSavedSearch(isSavedCategory);
    updateUserPreferences();
    navigate('/feed/' + key);
  };

  const handleAuthAndSavedCategory = async (event) => {
    if (localConfig) return;

    const data = event.data || event.detail || {};
    if (!data.isAuthenticated) return;

    setIsAuthenticated(true);
    const userProfile = await getUserPreferences();

    if (data.isAuthenticated && !userProfile) {
      setIsMissingPersonalisationPopup(true);
      return null;
    }
    setLocalConfig(userProfile?.profile_info);
    updateSavedSearches();
  };

  const handleSavedSearch = () => {
    personalisedExperience = isPersonalisedExperience();
    if (!localConfig || !localConfig?.savedSearches) {
      if (localStorage.getItem('auth-user')) {
        setIsMissingPersonalisationPopup(true);
        return null;
      }
      setIsEnhancedSignInPopupOpen(true);
      return null;
    } else if (!personalisedExperience) {
      setIsPausedPopupOpen(true);
      return null;
    }
    updateSavedSearches();
  };

  const handleCategoryNameChange = (e) => {
    if (!localConfig || !Object.prototype.hasOwnProperty.call(localConfig.savedSearches, searchConfig.categorySlug)) return;

    const newDisplayName = e.target.value.trim();
    localConfig.savedSearches[searchConfig.categorySlug].displayName = newDisplayName;
    setDisplayName(newDisplayName);
    setLocalConfig(localConfig);
    localStorage.getItem('auth-user') && updateUserPreferences();
    navigate('/feed/' + key);
  };

  useEffect(() => {
    if (!searchConfig) {
      navigate('/error/404');
      return;
    }

    if (localStorage.getItem('lrLogged') && localStorage.getItem('lrLogged') === 'true') {
      setIsAuthenticated(true);
    }

    // Check if the current searchQuery matches any saved search
    if (localConfig) {
      const savedSearches = localConfig?.savedSearches || {};
      const { key } = generateSearchQuery(searchConfig);
      setIsSavedSearch(key && key !== '' && savedSearches?.[key] ? true : false);
    }

    const expandedSearchEnabled = getSavedSearchProperty('expandedSearch', savedSearchSlug);

    // Fetch random lists based on saved search parameter
    const getRandomLists = () => {
      if (!isPaginate) {
        setIsLoading(true);
        setLists({ mobile: [], desktop: [] });
        setPageStart(0);
      }

      personalisedExperience = isPersonalisedExperience();
      let interestedDomains = searchConfig.domains; // Declare and initialize interestedDomains variable
      let storedExclusions = retrieveExcludedDomains();

      if (expandedSearchEnabled || !personalisedExperience) {
        interestedDomains = '';
      } else if (!isPaginate) {
        setLists({ mobile: [], desktop: [] });
      }
      fetchTermData(
        searchConfig.searchQuery,
        searchConfig.orSearchQuery ? searchConfig.orSearchQuery : '',
        searchConfig.excludeQuery,
        searchConfig.categoryQuery,
        searchConfig?.tags ?? '',
        interestedDomains,
        isPaginate ? pageStart : 0,
        pageLimit,
        searchConfig.sortOrder ? searchConfig.sortOrder : 'relevance',
        storedExclusions,
        searchConfig.author ?? ''
      )
        .then((response) => {
          const data = response.articleData;
          if (data && data.length > 0) {
            let sortedData = data.sort((articleOne, articleTwo) => {
              return new Date(articleTwo.lastModified) - new Date(articleOne.lastModified); //sorting articles in descending order to display latest articles first
            });
            sortedData.forEach((article) => {
              article.title = article.title.replace(/(<([^>]+)>)/gi, ''); //escape html tags
            });
            let articleJson = sortedData.map(JSON.stringify);
            let uniqueArticle = new Set(articleJson);
            let uniqueArray = Array.from(uniqueArticle).map(JSON.parse);
            // slots array is injected in the data array
            const dataWithAds = injectsAds(uniqueArray);
            // data arrays are differentiated depending on the viewport
            if (isPaginate) {
              const newMobileArticles = [...lists.mobile, ...dataWithAds];
              const newDesktopArticles = [...lists.desktop, ...uniqueArray];
              setLists({ mobile: newMobileArticles, desktop: newDesktopArticles });
            } else {
              setLists({ mobile: dataWithAds, desktop: uniqueArray });
              window.scrollTo(0, 0);
            }
            isEndOfThePage = true;
          }
          setIsPaginate(false);
          setIsLoading(false);
          circularLoading(false);
          window.dispatchEvent(new CustomEvent('singleCategoryLoaded'));
          __log('singleCategoryLoaded event dispatched, category loaded case', 1);
        })
        .catch((error) => {
          setIsLoading(false);
          circularLoading(false);
          __log('fetchDataFromAPI error', error);
        });
    };
    getRandomLists();

    setDisplayName(localConfig?.savedSearches?.[searchConfig.categorySlug]?.displayName ?? searchConfig.displayName);

    window.addEventListener('auth-ui.authentication', handleAuthAndSavedCategory);
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateMedia);
    return () => {
      window.removeEventListener('resize', updateMedia);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('auth-ui.authentication', handleAuthAndSavedCategory);
    };
  }, [isDesktopView, paginateCount, savedSearchSlug, updateDomains, isSavedSearch]);

  const handleScroll = () => {
    let scrollPercent = getScrollPercent().toFixed();
    if (scrollPercent > 90 && isEndOfThePage) {
      isEndOfThePage = false;
      handleLoadMore();
    }
  };

  const updateExcludedDomains = () => {
    setUpdateDomains(true);
    window.location.reload();
  };

  if (lists.length === 0) {
    return <div></div>;
  }

  const FollowButton = (
    <Button
      data-testid="save-button"
      variant="contained"
      className="follow-btn"
      onClick={handleSavedSearch}
      aria-label={isSavedSearch ? 'Unfollow' : 'Follow'}
      endIcon={isSavedSearch && personalisedExperience ? <FavoriteIcon color="primary" /> : <FavoriteBorderIcon color="primary" />}
    >
      <Typography variant="label1" sx={{ color: theme.palette.common.black }}>
        {isSavedSearch && personalisedExperience ? 'Unfollow' : 'Follow'}
      </Typography>
    </Button>
  );

  return (
    <>
      <Helmet>
        <title>{searchConfig.displayName + ' | My News Assistant'}</title>
        <link rel="canonical" href={canonicalURL} />
        <meta name="description" content={searchConfig.displayName + ' Latest News'} />
        <meta property="og:title" content={searchConfig.displayName + ' | My News Assistant'} />
        <meta property="og:description" content={searchConfig.displayName + ' Latest News'} />
        <meta property="og:image" content={`${siteRoot}/static/assets/images/mna_twitter_image.jpg`} />
        <meta property="og:url" content={canonicalURL} />

        <meta name="twitter:title" content={searchConfig.displayName + ' | My News Assistant'} />
        <meta name="twitter:description" content={searchConfig.displayName + ' Latest News'} />
        <meta name="twitter:image" content={`${siteRoot}/static/assets/images/mna_twitter_image.jpg`} />
        <meta name="twitter:card" content={`${siteRoot}/static/assets/images/mna_twitter_card.jpg`} />
      </Helmet>
      {isDesktopView ? (
        <div className="categoryList">
          {isLoading ? (
            <CategoryDesktopPlaceholder />
          ) : (
            <div className="category-wrapper">
              <div className="category-header">
                <div className="title-container">
                  <EditableCategoryName
                    displayName={displayName}
                    isEditable={isLoggedIn && personalisedExperience && isSavedSearch}
                    handleCategoryNameChange={handleCategoryNameChange}
                  />
                  {UserDefinedCategoriesEnabled && FollowButton}
                </div>
                {Object.keys(suggestedCategories).length !== 0 ? <Trending trendingKeywords={suggestedCategories} text /> : null}
              </div>
              <Grid container columnSpacing={1} sx={{ justifyContent: 'space-between', minHeight: '100vh' }}>
                {lists.desktop.length > 0 ? (
                  lists.desktop.map((item, i) => (
                    <Grid item key={i} index={i} sx={{ width: '330px', marginBottom: '25px' }}>
                      <ListItem item={item} updateExcludedDomains={updateExcludedDomains} />
                    </Grid>
                  ))
                ) : (
                  <CategoryNotFound categoryName={searchConfig} />
                )}
              </Grid>
            </div>
          )}
          <div className="ad-container">
            <AdvertisingSlot id={desktopSlots[0].id} key={desktopSlots[0].id} className="ad-placeholder-category" />
          </div>
        </div>
      ) : (
        <>
          {isLoading ? (
            <CategoryMobilePlaceholder />
          ) : (
            <div className="category-wrapper">
              <div className="category-header">
                <EditableCategoryName
                  displayName={displayName}
                  isEditable={isLoggedIn && personalisedExperience && isSavedSearch}
                  handleCategoryNameChange={handleCategoryNameChange}
                />
                {UserDefinedCategoriesEnabled && FollowButton}
              </div>
              <Container maxWidth="xl" sx={{ padding: 'unset' }}>
                {lists.mobile.length > 0 ? (
                  <Grid container spacing={1} sx={{ justifyContent: 'space-between' }}>
                    {lists.mobile.map((item, i) =>
                      item.isAd ? (
                        <Grid container>
                          <AdvertisingSlot id={item.id} key={i} className="ad-placeholder-category-mobile" />
                        </Grid>
                      ) : (
                        <Grid
                          item={item}
                          key={i}
                          index={i}
                          xs={12}
                          sm={6}
                          sx={{ width: window.innerWidth >= 690 && window.innerWidth <= 768 ? '330px' : '100%', marginBottom: '15px' }}
                        >
                          <ListItem item={item} updateExcludedDomains={updateExcludedDomains} />
                        </Grid>
                      )
                    )}
                  </Grid>
                ) : (
                  <CategoryNotFound categoryName={searchConfig} />
                )}
              </Container>
            </div>
          )}
        </>
      )}
      {isEnhancedSignInPopupOpen && (
        <CustomPopup
          isOpen={isEnhancedSignInPopupOpen}
          onClose={() => setIsEnhancedSignInPopupOpen(false)}
          logo={require(`../../assets/mna_alt_logo.png`)}
          divider
          title="Sign in for an enhanced My News Assistant experience"
          actionButtons={enhancedSignInPopupActions}
        >
          <Signup />
        </CustomPopup>
      )}
      {isPausedPopupOpen && <PausedPersonalisationPopup show={isPausedPopupOpen} handleClosePausedPopup={handleClosePausedPopup} />}
      {isMissingPersonalisationPopup && (
        <MissingPersonalisationPopup show={isMissingPersonalisationPopup} handleClosePersonalisationPopup={handleClosePersonalisationPopup} />
      )}
    </>
  );
}
