import React from 'react';

const Signup = () => {
  return (
    <ul>
      <li><strong>Follow and stay informed</strong> on news, topics, and local updates.</li>
      <li><strong>Personalise</strong> your news experience with a tailored feed based on your interests.</li>
      <li><strong>Access</strong> a diverse range of trusted news articles.</li>
      <li><strong>Save time</strong> with <strong>customisable</strong> news preferences.</li>
    </ul>
  );
};

export default Signup;
