import { getDomain } from './pageUtilities';
const viaFouraData = require('../config/viafoura');

function getArticleIDFromUrl(url) {
  let articleID = '';
  if (url.split('.')[1] !== 'express') {
    articleID = url.split('-')[url.split('-').length - 1];
  } else {
    articleID = url.split('/')[url.split('/').length - 2];
  }
  return articleID;
}

function getPayLoad(payload, url) {
  const articleId = getArticleIDFromUrl(url);
  const payloadList = [];
  payloadList.push(payload.replace(/#id/g, articleId));
  return payloadList;
}

function formatViafouraData(url) {
  const newData = [];
  Object.entries(viaFouraData).forEach((item) =>
    newData.push({
      domain: `www.${item[1].domain}`,
      viafouraId: item[1].viafouraId,
      viafouraPayload: getPayLoad(item[1].viafouraPayload, url),
    })
  );
  return newData;
}

const getViafouraData = (url) => {
  const domain = getDomain(url);
  const vfDataFormatted = formatViafouraData(url);
  return vfDataFormatted?.find(({ domain: itemDomain }) => itemDomain === domain) || {};
};

export { getArticleIDFromUrl, getPayLoad, formatViafouraData, getViafouraData };