import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateUserPreferences } from '../../services/api';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import altlogoimage from '../../assets/mna_alt_logo.png';
import './loader.scss';

export default function Loader(props) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem('mna-init-signup-or-signin')) {
      localStorage.removeItem('mna-init-signup-or-signin');
      updateUserPreferences()
        .then((result) => {
          if (result) {
            navigate('/signup/loginsuccess');
          }
        })
        .catch(() => {
          navigate('/');
        });

      gtag('event', 'lr_registration_complete', {
        event: 'password_supplied',
        source: ' step_2_LoginRadius_pop_up',
      });
    } else {
      navigate('/');
    }
  }, []);

  return (
    <div id='container' className='bordered-container'>
      <div className='loading-logo'>
        <img src={altlogoimage} alt='my news assistant logo' />
      </div>
      <Box sx={{ width: '100%' }}>
        <LinearProgress sx={{ height: '20px', my: 2 }} />
      </Box>
      <div className='content-text'>
        <Typography variant='body1' align='center'>
          Loading you personalised news feed, this will take a few seconds
        </Typography>
      </div>
    </div>
  );
}
