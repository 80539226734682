import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import './Overlay.scss';
import { avoid } from '../../helpers/utils';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export default function Overlay() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  // GA4 tracking via gtag.js
  const gtag = window.gtag || avoid;

  return (
    <div className="overlay--container">
      <div
        className={location.pathname.includes('/cost-of-living') ? 'categorypage-overlay' : 'overlay'}
        onClick={(event) => {
          gtag('event', 'personalisation_banner_cta', {
            event: 'clicked',
            source: 'homepage_overlay',
            link_url: event.target.href,
          });
          navigate('/signup/interests');
        }}
      >
        <Typography variant="h1bold" className="teaser">
          Want to see more?
        </Typography>
        <Typography variant="h6bold" className="text">
          We have hundreds of categories for you to create a customised newsfeed of unique content for you.
        </Typography>
        <button className="overlay--btn" to="/signup/interests">
          Get started <ChevronRightIcon />
        </button>
      </div>
    </div>
  );
}
