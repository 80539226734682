import { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import CustomPopup from '../../components/CustomPopup/CustomPopup';

import { IconButton, Paper, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';

import { getCategoryByName, getAllDisplayNames, getMantisValuesByDisplayName } from '../../config/categories';
import { constructPath, sortAlphabetically, removeDuplicates } from '../../helpers/utils';
import { locationsKey } from '../../config/locations';
import { getAllPublicationNames, getPublications, mapLocationToDomain, filterDomainsByKeys, mantisIDsToNames } from '../../config/publications';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};
const TextFieldLabelProps = {
  style: {
    left: '12px',
    top: '6px',
  },
};
const SelectIconProps = (props) => <KeyboardArrowDownIcon sx={{ marginRight: '6px' }} {...props} />;
const inputPropsStyle = { paddingLeft: '8px' };
const selectPropsStyle = { paddingLeft: '8px' };

function SearchPopup({ show, handleClose }) {
  const [exactSearchQuery, setExactSearchQuery] = useState('');
  const [orSearchQuery, setOrSearchQuery] = useState('');
  const [excludeQuery, setExcludeQuery] = useState('');
  const [region, setRegion] = useState([]);
  const [domains, setDomains] = useState([]);
  const [category, setCategory] = useState('');
  const [author, setAuthor] = useState('');
  const [visibleAdvancedOptions, setVisibleAdvancedOptions] = useState(false);
  const [error, setError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const location = useLocation();
  let domainsNames = getAllPublicationNames();
  let categoriesKeys = getAllDisplayNames();
  let sortedCategoriesKeys = sortAlphabetically(categoriesKeys);
  const exactSearchRef = useRef();

  useEffect(() => {
    location.state?.exactSearchQuery
      ? setExactSearchQuery(location.state?.exactSearchQuery)
      : setExactSearchQuery(searchParams.get('q') ? searchParams.get('q') : '');

    location.state?.orSearchQuery
      ? setOrSearchQuery(location.state?.orSearchQuery)
      : setOrSearchQuery(searchParams.get('as_oq') ? searchParams.get('as_oq') : '');

    location.state?.excludeQuery
      ? setExcludeQuery(location.state.excludeQuery)
      : setExcludeQuery(searchParams.get('exclude_q') ? searchParams.get('exclude_q') : '');

    let domainsValues = location.state?.domains ?? searchParams.get('domains');
    if (domainsValues) {
      const domainNames = mantisIDsToNames(domainsValues);
      setDomains((domains) => [...domains, ...domainNames]);
    } else {
      setDomains([]);
    }

    setAuthor(location.state?.author ?? searchParams.get('author') ?? '');
  }, []);

  const handleSearch = () => {
    const key = exactSearchQuery?.trim().toLowerCase().replace(/\s+/g, '-');
    const mantisConfig = getCategoryByName(key);
    if (mantisConfig) {
      const mantisCategoryPath = constructPath(mantisConfig?.sectionSlug, mantisConfig?.subSectionSlug, mantisConfig?.categorySlug);
      navigate('/' + mantisCategoryPath);
      handleClose(true);
      return;
    }

    let shouldTerm = '';
    let mustTerm = '';
    let excludeTerm = '';
    let domainsArray = [];
    let categories = '';

    if (!exactSearchQuery.trim() && !orSearchQuery.trim() && !author.trim()) {
      setError(true);
      return;
    }

    if (exactSearchQuery && exactSearchQuery.length !== 0) {
      mustTerm = exactSearchQuery.trim();
    }

    if (orSearchQuery && orSearchQuery.length > 0) {
      shouldTerm = orSearchQuery.trim();
    }

    if (excludeQuery && excludeQuery.length > 0) {
      excludeTerm = excludeQuery.trim();
    }

    if (region.length) {
      domainsArray = mapLocationToDomain(region);
    }

    if (domains.length) {
      domainsArray = filterDomainsByKeys(domains, domainsArray);
    }

    if (category.length) {
      categories = getMantisValuesByDisplayName(category);
    }

    handleClose(true);
    navigate(`/search?q=${mustTerm}&as_oq=${shouldTerm}&exclude_q=${excludeTerm}&domains=${removeDuplicates(domainsArray)}&author=${author.trim()}&sort=date`, {
      state: {
        exactSearchQuery: mustTerm,
        orSearchQuery: shouldTerm,
        excludeQuery: excludeTerm,
        domains: removeDuplicates(domainsArray),
        category: categories,
        author: author.trim(),
      },
    });
  };

  const searchButton = [
    {
      onClick: handleSearch,
      label: visibleAdvancedOptions ? 'Advanced search' : 'Search',
    },
  ];

  const toggleAdvancedOptions = () => {
    setVisibleAdvancedOptions((prevState) => !prevState);
    setExactSearchQuery(exactSearchRef.current.value);
  };

  return (
    <CustomPopup isOpen={show} onClose={handleClose} actionButtons={searchButton}>
      <Paper
        margin="dense"
        style={{
          backgroundColor: visibleAdvancedOptions && '#F8F8F8',
          boxShadow: 'none',
        }}
      >
        {visibleAdvancedOptions && (
          <Typography variant="label3" style={{ marginLeft: '5px' }}>
            Find article matching
          </Typography>
        )}
        <TextField
          defaultValue={exactSearchQuery}
          inputRef={exactSearchRef}
          type="text"
          onChange={(e) => {
            setExactSearchQuery(e.target.value);
          }}
          fullWidth
          margin="dense"
          variant={visibleAdvancedOptions ? 'standard' : 'outlined'}
          label={visibleAdvancedOptions ? 'Exact phrase' : 'Search'}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !!exactSearchQuery?.trim()) {
              e.preventDefault();
              handleSearch();
            }
          }}
          InputProps={{
            endAdornment: (
              <IconButton data-testid="expandIcon" onClick={toggleAdvancedOptions}>
                {visibleAdvancedOptions ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            ),
            style: inputPropsStyle,
          }}
          InputLabelProps={visibleAdvancedOptions ? TextFieldLabelProps : null}
          inputProps={{ 'data-testid': 'must-text-field-popup' }}
        />
        {error && (
          <div>
            <Typography variant="body1" color="error.main" data-testid="must-error">
              {visibleAdvancedOptions ? 'At least one of the fields is required.' : 'This field is required.'}
            </Typography>
          </div>
        )}
        {visibleAdvancedOptions && (
          <>
            <Paper sx={{ boxShadow: 'none', backgroundColor: '#F8F8F8' }}>
              <TextField
                value={orSearchQuery}
                variant="standard"
                label={'Any of these words'}
                margin="dense"
                fullWidth
                onChange={(e) => {
                  setOrSearchQuery(e.target.value);
                }}
                inputProps={{ 'data-testid': 'should-text-field-popup' }}
                InputProps={{
                  endAdornment: orSearchQuery ? (
                    <IconButton
                      onClick={() => {
                        setOrSearchQuery('');
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  ),
                  style: inputPropsStyle,
                }}
                InputLabelProps={TextFieldLabelProps}
              />
              {error && (
                <div>
                  <Typography variant="body1" color="error.main" data-testid="should-error">
                    At least one of the fields is required.
                  </Typography>
                </div>
              )}
              <TextField
                value={excludeQuery}
                label={'Exclude these words'}
                fullWidth
                margin="dense"
                variant="standard"
                required={false}
                onChange={(e) => {
                  setExcludeQuery(e.target.value);
                }}
                InputProps={{
                  endAdornment: excludeQuery ? (
                    <IconButton
                      onClick={() => {
                        setExcludeQuery('');
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  ),
                  style: inputPropsStyle,
                }}
                InputLabelProps={TextFieldLabelProps}
                inputProps={{ 'data-testid': 'exclude-text-field-popup' }}
              />
            </Paper>

            <Paper sx={{ marginTop: '15px', boxShadow: 'none', backgroundColor: '#F8F8F8' }}>
              <Typography variant="label3" style={{ marginTop: '8px', marginLeft: '5px' }}>
                Narrow your result by
              </Typography>
              <FormControl fullWidth margin="dense" data-testid="regionFormControl">
                <InputLabel id="region">Region</InputLabel>
                <Select
                  labelId="region"
                  label="Region"
                  id="select"
                  value={region}
                  variant="standard"
                  fullWidth
                  multiple
                  onChange={(e) => setRegion(e.target.value)}
                  renderValue={(region) => region.join(', ')}
                  IconComponent={SelectIconProps}
                  MenuProps={MenuProps}
                  inputProps={{ 'data-testid': 'search-region-popup' }}
                  SelectDisplayProps={{ style: selectPropsStyle }}
                >
                  {locationsKey.map((locationKey) => (
                    <MenuItem value={locationKey} key={locationKey}>
                      {locationKey}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense" data-testid="domainFormControl">
                <InputLabel id="brand">Brand</InputLabel>
                <Select
                  label="Brand"
                  labelId="brand"
                  id="select"
                  value={domains}
                  onChange={(e) => {
                    setDomains(e.target.value);
                  }}
                  multiple
                  displayEmpty
                  renderValue={(domains) => domains.join(', ')}
                  variant="standard"
                  IconComponent={SelectIconProps}
                  MenuProps={MenuProps}
                  inputProps={{ 'data-testid': 'search-domain-popup' }}
                  SelectDisplayProps={{ style: selectPropsStyle }}
                >
                  {domainsNames.map((domain) => (
                    <MenuItem value={domain} key={domain}>
                      {domain}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth margin="dense">
                <InputLabel id="category">Category</InputLabel>
                <Select
                  label="Category"
                  labelId="category"
                  id="select"
                  value={category}
                  variant="standard"
                  onChange={(e) => setCategory(e.target.value)}
                  displayEmpty
                  renderValue={(category) => category}
                  IconComponent={SelectIconProps}
                  MenuProps={MenuProps}
                  inputProps={{ 'data-testid': 'search-category-popup' }}
                  SelectDisplayProps={{ style: selectPropsStyle }}
                >
                  {sortedCategoriesKeys.map((category) => (
                    <MenuItem value={category} key={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                value={author}
                label={'Author'}
                fullWidth
                margin="dense"
                variant="standard"
                required={false}
                onChange={(e) => setAuthor(e.target.value)}
                InputProps={{
                  endAdornment: author ? (
                    <IconButton onClick={() => setAuthor('')}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  ),
                  style: inputPropsStyle,
                }}
                InputLabelProps={TextFieldLabelProps}
                inputProps={{ 'data-testid': 'author-text-field-popup' }}
              />
              {error && (
                <div>
                  <Typography variant="body1" color="error.main" data-testid="should-error">
                    At least one of the fields is required.
                  </Typography>
                </div>
              )}
            </Paper>
          </>
        )}
      </Paper>
    </CustomPopup>
  );
}

export default SearchPopup;
