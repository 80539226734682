import './searchPage.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton, Paper, TextField, Select, MenuItem, InputLabel, FormControl, Button, Pagination } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { __log, disableScrollBar } from '../../helpers/utils';
import { removeDuplicates, sortAlphabetically } from '../../helpers/utils';
import { fetchTermData } from '../../services/api';
import SearchResults from './SearchResults';
import { locationsKey } from '../../config/locations';
import { getAllPublicationNames, mapLocationToDomain, filterDomainsByKeys, mantisIDsToNames } from '../../config/publications';
import { getAllDisplayNames, getMantisValuesByDisplayName } from '../../config/categories';

function EmbedSearchPage() {
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);
  const [searchData, setSearchData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [exactSearchQuery, setExactSearchQuery] = useState('');
  const [exactSearchInput, setExactSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isDataAvailable, setIsDataAvailable] = useState(undefined);
  const [articleNumber, setArticleNumber] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(30);
  const [paginateCount, setPaginateCount] = useState(null);
  const [selectValue, setSelectValue] = useState('date');
  const [domains, setDomains] = useState([]);
  const [domainsQuery, setDomainsQuery] = useState([]);
  const [region, setRegion] = useState([]);
  const [category, setCategory] = useState('');
  const [orSearchQuery, setOrSearchQuery] = useState('');
  const [orSearchInput, setOrSearchInput] = useState('');
  const [excludeQuery, setExcludeQuery] = useState('');
  const [excludeInput, setExcludeInput] = useState('');
  const [authorQuery, setAuthorQuery] = useState('');
  const [authorInput, setAuthorInput] = useState('');
  const [visibleAdvancedOptions, setVisibleAdvancedOptions] = useState(false);
  const [error, setError] = useState(false);

  const theme = useTheme();
  const limitPerLoad = 30;
  let expectedPageNr = Math.ceil(articleNumber / 30);
  const exactSearchRef = useRef();
  let domainsNames = getAllPublicationNames();
  let categoriesKeys = getAllDisplayNames();
  let sortedCategoriesKeys = sortAlphabetically(categoriesKeys);

  const toggleAdvancedOptions = () => {
    setVisibleAdvancedOptions((prevState) => !prevState);
    setExactSearchQuery(exactSearchRef.current.value);
    setRegion([]);
    setCategory('');
  };

  //Disable the scrollbar when queryparam(disable-scrollbar) passed
  const isScrollbarDisabled = searchParams.get('disable-scrollbar');
  if (isScrollbarDisabled === 'true') {
    disableScrollBar();
  }

  useEffect(() => {
    const handleResize = () => {
      setIsDesktopView(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isDesktopView]);

  useEffect(() => {
    let exactQueryParam = searchParams.get('q');
    setExactSearchQuery(exactQueryParam ? exactQueryParam : '');
    setExactSearchInput(exactQueryParam ? exactQueryParam : '');

    let domainsQueryParam = searchParams.get('domains');
    setDomainsQuery(domainsQueryParam);
    setDomains(mantisIDsToNames(domainsQueryParam));

    let excludeParam = searchParams.get('exclude_q');
    setExcludeQuery(excludeParam ? excludeParam : '');
    setExcludeInput(excludeParam ? excludeParam : '');

    let orSearchQueryParam = searchParams.get('as_oq');
    setOrSearchQuery(orSearchQueryParam ? orSearchQueryParam : '');
    setOrSearchInput(orSearchQueryParam ? orSearchQueryParam : '');

    setAuthorInput(searchParams.get('author') ?? '');
    setAuthorQuery(searchParams.get('author') ?? '');

    let sortParam = searchParams.get('sort');
    setSelectValue(sortParam ? sortParam : selectValue);

    setSearchData([]);
    setPaginateCount(0);
    setPageLimit(30);
    setArticleNumber(0);
    setError(false);

    window.scrollTo(0, 0);
  }, [searchParams]);

  useEffect(() => {
    document.title = (exactSearchQuery || orSearchQuery || 'No results found') + ' Search | My News Assistant';

    if (exactSearchQuery.length > 0 || orSearchQuery.length > 0 || !!authorQuery) {
      getData();
    }
  }, [
    exactSearchQuery,
    orSearchQuery,
    excludeQuery,
    region,
    domains,
    domainsQuery,
    category,
    paginateCount,
    page,
    isDataAvailable,
    selectValue,
    expectedPageNr,
    authorQuery,
  ]);

  const getData = () => {
    if (!exactSearchQuery && !orSearchQuery && !authorQuery) {
      setError(true);
      return;
    }
    setIsLoading(true);
    fetchTermData(
      exactSearchQuery.length ? exactSearchQuery : '',
      orSearchQuery.length ? orSearchQuery : '',
      excludeQuery.length ? excludeQuery : '',
      category.length ? category : '',
      '', //Empty tags string
      domainsQuery?.length ? domainsQuery : '',
      paginateCount ? paginateCount : 0,
      pageLimit,
      selectValue,
      '',
      authorQuery
    )
      .then((response) => {
        const data = response.articleData;
        if (data && data.length > 0) {
          setIsDataAvailable(true);
          let total = response.totalNumberofArticlesMatched;
          setArticleNumber(total);

          data.forEach((article) => {
            article.title = article.title.replace(/(<([^>]+)>)/gi, ''); //escape html tag
          });
          setSearchData(data);
          setIsLoading(false);
        } else {
          setIsDataAvailable(false);
          setIsLoading(false);
          setArticleNumber(0);
        }
        sendSearchPageHeight();
      })
      .catch((error) => {
        __log('getSearchTextData error', error);
      });
  };

  const sendSearchPageHeight = () => {
    const searchFormWrapper = document.getElementsByClassName('search--form');
    const searchInfoWrapper = document.getElementsByClassName('search--info');
    const resultsWrapper = document.getElementsByClassName('results--wrapper');
    const paginationWrapper = document.getElementsByClassName('pagination');

    let heightSent =
      searchFormWrapper[0].offsetHeight +
      searchInfoWrapper[0].offsetHeight +
      resultsWrapper[0].offsetHeight +
      paginationWrapper[0].offsetHeight +
      searchFormWrapper[0].offsetHeight;

    const scrollToTop = true;

    const data = { 'mna-search-result-height': heightSent, 'scroll-to-top': scrollToTop };

    window.parent.postMessage(data, '*');
  };

  const handleLoadMore = (e, page) => {
    setPage(page);
    setSearchData([]);
    setPageLimit(pageLimit);
    setPaginateCount((page - 1) * limitPerLoad);
    window.scrollTo(0, 0);
  };

  const handleChange = (e) => {
    setSelectValue(e.target.value.toLowerCase());
    setSearchParams({
      q: exactSearchQuery,
      as_oq: orSearchQuery,
      exclude_q: excludeQuery,
      domains: domainsQuery,
      author: authorQuery,
      sort: e.target.value.toLowerCase(),
    });
    setPage(1);
  };

  const handleSearch = () => {
    let shouldTerm = '';
    let mustTerm = '';
    let excludeTerm = '';
    let domainsArray = [];
    let mantisCategory = '';

    if (!exactSearchInput.trim() && !orSearchInput.trim() && !authorInput.trim()) {
      setError(true);
      return;
    }

    if (exactSearchInput && exactSearchInput.length !== 0) {
      mustTerm = exactSearchInput.trim();
      setExactSearchQuery(mustTerm);
    }

    if ((orSearchInput && orSearchInput.length > 0) || (orSearchQuery && orSearchQuery.length > 0)) {
      shouldTerm = orSearchInput.trim() ?? orSearchQuery.trim();
      setOrSearchQuery(shouldTerm);
    }

    if (excludeInput && excludeInput.length > 0) {
      excludeTerm = excludeInput.trim();
      setExcludeQuery(excludeTerm);
    }

    if (region.length > 0) {
      domainsArray = mapLocationToDomain(region);
    }

    if (domains.length > 0) {
      domainsArray = filterDomainsByKeys(domains, domainsArray);
    }

    if (category.length) {
      mantisCategory = getMantisValuesByDisplayName(category);
      setCategory(mantisCategory);
    }

    setAuthorQuery(authorInput.trim());

    let domainsString = removeDuplicates(domainsArray)?.toString();
    setSearchParams({
      q: mustTerm,
      as_oq: shouldTerm,
      exclude_q: excludeTerm,
      domains: domainsString,
      author: authorInput.trim(),
      sort: selectValue,
    });
    setVisibleAdvancedOptions(false);
    setError(false);
    setPage(1);
  };

  const SelectIconProps = (props) => <KeyboardArrowDownIcon sx={{ marginLeft: '6px' }} {...props} />;
  const inputPropsStyle = { paddingLeft: '8px' };
  const selectPropsStyle = { paddingLeft: '8px' };
  const TextFieldLabelProps = {
    style: {
      left: '12px',
      top: '6px',
    },
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };
  const dropdownFormControlStyle = { marginTop: '14px' };

  return (
    <div className={'embed-layout search-page'}>
      <main className={isDesktopView ? 'desktop' : 'mobile'}>
        <div className="search--layout">
          <div className="search--wrapper">
            <div className="search--form">
              <FormControl
                sx={{
                  backgroundColor: visibleAdvancedOptions ? theme.palette.common.white : 'none',
                  zIndex: visibleAdvancedOptions ? 1000 : 'unset',
                  height: 'auto',
                  minHeight: '635px',
                  padding: visibleAdvancedOptions ? (isDesktopView ? '27px' : '20px') : 'none',
                  borderRadius: '0px 0px 10px 10px',
                  boxShadow: visibleAdvancedOptions ? '20px 0px 44px 0px rgba(0, 0, 0, 0.25)' : 'unset',
                }}
              >
                <TextField
                  value={exactSearchInput}
                  inputRef={exactSearchRef}
                  type="text"
                  onChange={(e) => {
                    setExactSearchInput(e.target.value);
                  }}
                  fullWidth
                  margin="dense"
                  variant={visibleAdvancedOptions ? 'standard' : 'outlined'}
                  label={visibleAdvancedOptions ? 'Exact phrase' : 'Search'}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleSearch();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton data-testid="expandIcon" onClick={toggleAdvancedOptions}>
                        {visibleAdvancedOptions ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    ),
                    style: {
                      paddingRight: '0px',
                      backgroundColor: visibleAdvancedOptions ? theme.palette.lightGray.main : theme.palette.common.white,
                      paddingLeft: visibleAdvancedOptions ? '8px' : '0px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      left: visibleAdvancedOptions ? '12px' : '-8px',
                      top: visibleAdvancedOptions ? '6px' : '0px',
                      zIndex: 10,
                    },
                  }}
                  inputProps={{ 'data-testid': 'must-text-field-popup' }}
                  sx={{
                    marginBottom: '0px',
                    backgroundColor: visibleAdvancedOptions ? theme.palette.lightGray.main : theme.palette.common.white,
                  }}
                />
                {error && (
                  <div>
                    <Typography
                      variant="body1"
                      color="error.main"
                      data-testid="must-error"
                      style={{ backgroundColor: visibleAdvancedOptions ? theme.palette.lightGray.main : theme.palette.common.white }}
                    >
                      {visibleAdvancedOptions ? 'At least one of the fields is required.' : 'This field is required.'}
                    </Typography>
                  </div>
                )}
                {visibleAdvancedOptions && (
                  <>
                    <Paper sx={{ boxShadow: 'none', backgroundColor: '#F8F8F8', borderRadius: '0px 0px 10px 10px' }}>
                      <TextField
                        value={orSearchInput}
                        variant="standard"
                        label={'Any of these words'}
                        margin="dense"
                        fullWidth
                        onChange={(e) => {
                          e.preventDefault();
                          setOrSearchInput(e.target.value);
                        }}
                        inputProps={{ 'data-testid': 'should-text-field-popup' }}
                        InputProps={{
                          endAdornment: orSearchInput ? (
                            <IconButton
                              onClick={() => {
                                setOrSearchInput('');
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          ) : (
                            <IconButton aria-label="search">
                              <SearchIcon />
                            </IconButton>
                          ),
                          style: inputPropsStyle,
                        }}
                        InputLabelProps={TextFieldLabelProps}
                      />
                      {error && (
                        <div>
                          <Typography variant="body1" color="error.main" data-testid="should-error">
                            At least one of the fields is required.
                          </Typography>
                        </div>
                      )}
                      <TextField
                        value={excludeInput}
                        label={'Exclude these words'}
                        fullWidth
                        margin="dense"
                        variant="standard"
                        required={false}
                        onChange={(e) => {
                          setExcludeInput(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: excludeInput ? (
                            <IconButton
                              onClick={() => {
                                setExcludeInput('');
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          ) : (
                            <IconButton aria-label="search">
                              <SearchIcon />
                            </IconButton>
                          ),
                          style: inputPropsStyle,
                        }}
                        InputLabelProps={TextFieldLabelProps}
                        inputProps={{ 'data-testid': 'exclude-text-field-popup' }}
                      />
                      <Typography variant="label3" style={{ marginLeft: '5px' }}>
                        Narrow your result by
                      </Typography>
                      <FormControl fullWidth margin="dense" data-testid="regionFormControl" sx={dropdownFormControlStyle}>
                        <InputLabel id="region">Region</InputLabel>
                        <Select
                          labelId="region"
                          label="Region"
                          id="select"
                          value={region}
                          variant="standard"
                          fullWidth
                          displayEmpty
                          multiple
                          onChange={(e) => setRegion(e.target.value)}
                          renderValue={(region) => region.join(', ')}
                          IconComponent={SelectIconProps}
                          MenuProps={MenuProps}
                          inputProps={{ 'data-testid': 'search-region-popup' }}
                          SelectDisplayProps={{ style: selectPropsStyle }}
                        >
                          {locationsKey.map((locationKey) => (
                            <MenuItem value={locationKey} key={locationKey}>
                              {locationKey}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth margin="dense" data-testid="domainFormControl" sx={dropdownFormControlStyle}>
                        <InputLabel id="brand">Brand</InputLabel>
                        <Select
                          labelId="brand"
                          label="Brand"
                          id="select"
                          fullWidth
                          displayEmpty
                          value={domains}
                          onChange={(e) => {
                            setDomains(e.target.value);
                          }}
                          multiple
                          renderValue={(domains) => domains.join(', ')}
                          variant="standard"
                          IconComponent={SelectIconProps}
                          MenuProps={MenuProps}
                          inputProps={{ 'data-testid': 'search-domain-popup' }}
                          SelectDisplayProps={{ style: selectPropsStyle }}
                        >
                          {domainsNames.map((domain) => (
                            <MenuItem value={domain} key={domain}>
                              {domain}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth margin="dense" sx={dropdownFormControlStyle}>
                        <InputLabel id="category">Category</InputLabel>
                        <Select
                          label="Category"
                          labelId="category"
                          id="select"
                          value={category}
                          variant="standard"
                          onChange={(e) => setCategory(e.target.value)}
                          displayEmpty
                          renderValue={(category) => category}
                          IconComponent={SelectIconProps}
                          MenuProps={MenuProps}
                          inputProps={{ 'data-testid': 'search-category-popup' }}
                          SelectDisplayProps={{ style: selectPropsStyle }}
                        >
                          {sortedCategoriesKeys.map((category) => (
                            <MenuItem value={category} key={category}>
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        value={authorInput}
                        variant="standard"
                        label={'Author'}
                        margin="dense"
                        fullWidth
                        onChange={(e) => {
                          e.preventDefault();
                          setAuthorInput(e.target.value);
                        }}
                        inputProps={{ 'data-testid': 'author-text-field-popup' }}
                        InputProps={{
                          endAdornment: authorInput ? (
                            <IconButton onClick={() => setAuthorInput('')}>
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          ) : (
                            <IconButton aria-label="search">
                              <SearchIcon />
                            </IconButton>
                          ),
                          style: inputPropsStyle,
                        }}
                        InputLabelProps={TextFieldLabelProps}
                      />
                      {error && (
                        <div>
                          <Typography variant="body1" color="error.main" data-testid="should-error">
                            At least one of the fields is required.
                          </Typography>
                        </div>
                      )}
                    </Paper>
                    <Button data-testid="confirm-button" className="search--button" onClick={handleSearch}>
                      Advanced Search
                    </Button>
                  </>
                )}
              </FormControl>
            </div>
            <div className="search--info">
              <Typography variant="label1Bold">About {articleNumber} results</Typography>
              <div className="dropdown">
                <Typography variant="label1Bold" sx={{ minWidth: 'fit-content' }}>
                  Sort by{' '}
                </Typography>
                <Select
                  sx={{
                    zIndex: visibleAdvancedOptions ? 900 : 1001,
                    width: isDesktopView ? 'fit-content' : '100%',
                    color: theme.palette.osloGrey.main,
                    ':hover': {
                      bgcolor: 'transparent',
                      color: 'unset',
                    },
                  }}
                  SelectDisplayProps={{ style: { color: theme.palette.osloGrey.main, paddingLeft: '8px' } }}
                  IconComponent={SelectIconProps}
                  value={selectValue}
                  onChange={handleChange}
                  data-testid="select"
                >
                  <MenuItem
                    value={'relevance'}
                    sx={{
                      color: theme.palette.osloGrey.main,
                    }}
                  >
                    Relevance
                  </MenuItem>
                  <MenuItem
                    value={'date'}
                    sx={{
                      color: theme.palette.osloGrey.main,
                    }}
                  >
                    Date
                  </MenuItem>
                </Select>
              </div>
            </div>
            <SearchResults
              isLoading={isLoading}
              isDataAvailable={isDataAvailable}
              searchData={searchData}
              exactSearchQuery={exactSearchQuery}
              orSearchQuery={orSearchQuery}
            />
            {searchData && searchData.length > 0 ? (
              <div className="pagination">
                <Pagination size={isDesktopView ? 'medium' : 'small'} count={expectedPageNr} onChange={handleLoadMore} page={page} />
              </div>
            ) : null}
          </div>
        </div>
      </main>
    </div>
  );
}

export default EmbedSearchPage;
