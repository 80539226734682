module.exports = {
  bristolpost: {
    domain: 'bristolpost.co.uk',
    viafouraId: '00000000-0000-4000-8000-10ecbaa8ad7e',
    viafouraPayload: 'bristolpost-prod-#id',
  },
  gloucestershirelive: {
    domain: 'gloucestershirelive.co.uk',
    viafouraId: '00000000-0000-4000-8000-c608644907c4',
    viafouraPayload: 'gloucestershirelive-prod-#id',
  },
  somersetlive: {
    domain: 'somersetlive.co.uk',
    viafouraId: '00000000-0000-4000-8000-93e912a1d173',
    viafouraPayload: 'somersetlive-prod-#id',
  },
  hulldailymail: {
    domain: 'hulldailymail.co.uk',
    viafouraId: '0000000-0000-4000-8000-d91d2fe21258',
    viafouraPayload: 'hulldailymail-prod-#id',
  },
  grimsbytelegraph: {
    domain: 'grimsbytelegraph.co.uk',
    viafouraId: '00000000-0000-4000-8000-287fbf16dab3',
    viafouraPayload: 'grimsbytelegraph-prod-#id',
  },
  lincolnshirelive: {
    domain: 'lincolnshirelive.co.uk',
    viafouraId: '00000000-0000-4000-8000-6fd37ba7c0ec',
    viafouraPayload: 'lincolnshirelive-prod-#id',
  },
  leicestermercury: {
    domain: 'leicestermercury.co.uk',
    viafouraId: '00000000-0000-4000-8000-e40deda4938b',
    viafouraPayload: 'leicestermercury-prod-#id',
  },
  nottinghampost: {
    domain: 'nottinghampost.com',
    viafouraId: '00000000-0000-4000-8000-0cd0ec02c1a6',
    viafouraPayload: 'nottinghampost-prod-#id',
  },
  derbytelegraph: {
    domain: 'derbytelegraph.co.uk',
    viafouraId: '00000000-0000-4000-8000-b4eca7c9e374',
    viafouraPayload: 'derbytelegraph-prod-#id',
  },
  burtonmail: {
    domain: 'staffordshire-live.co.uk',
    viafouraId: '00000000-0000-4000-8000-db69eaa6a028',
    viafouraPayload: 'burtonmail-prod-#id',
  },
  devonlive: {
    domain: 'devonlive.com',
    viafouraId: '00000000-0000-4000-8000-eae513ad15d4',
    viafouraPayload: 'devonlive-prod-#id',
  },
  plymouthherald: {
    domain: 'plymouthherald.co.uk',
    viafouraId: '00000000-0000-4000-8000-ff6c3c62fcf5',
    viafouraPayload: 'plymouthherald-prod-#id',
  },
  cornwalllive: {
    domain: 'cornwalllive.com',
    viafouraId: '00000000-0000-4000-8000-4e5e3c00eb2c',
    viafouraPayload: 'cornwalllive-prod-#id',
  },
  stokesentinel: {
    domain: 'stokesentinel.co.uk',
    viafouraId: '00000000-0000-4000-8000-2e030ad480fc',
    viafouraPayload: 'stokesentinel-prod-#id',
  },
  essexlive: {
    domain: 'essexlive.news',
    viafouraId: '00000000-0000-4000-8000-b9c39f2a8585',
    viafouraPayload: 'essexlive-prod-#id',
  },
  kentlive: {
    domain: 'kentlive.news',
    viafouraId: '00000000-0000-4000-8000-0d6613e2c239',
    viafouraPayload: 'kentlive-prod-#id',
  },
  hertfordshiremercury: {
    domain: 'hertfordshiremercury.co.uk',
    viafouraId: '00000000-0000-4000-8000-299f0a8ff53a',
    viafouraPayload: 'hertfordshiremercury-prod-#id',
  },
  hampshirelive: {
    domain: 'hampshirelive.news',
    viafouraId: '00000000-0000-4000-8000-60de860f9c07',
    viafouraPayload: 'hampshirelive-prod-#id',
  },
  sussexlive: {
    domain: 'sussexlive.co.uk',
    viafouraId: '00000000-0000-4000-8000-08c316bd0c91',
    viafouraPayload: 'sussexlive-prod-#id',
  },
  northantslive: {
    domain: 'northantslive.news',
    viafouraId: '00000000-0000-4000-8000-a8c6f988c90c',
    viafouraPayload: 'northantslive-prod-#id',
  },
  buckinghamshirelive: {
    domain: 'buckinghamshirelive.com',
    viafouraId: '00000000-0000-4000-8000-50529cebbf95',
    viafouraPayload: 'buckinghamshirelive-prod-#id',
  },
  bedfordshirelive: {
    domain: 'bedfordshirelive.co.uk',
    viafouraId: '00000000-0000-4000-8000-95dac70077d2',
    viafouraPayload: 'bedfordshirelive-prod-#id',
  },
  oxfordshirelive: {
    domain: 'oxfordshirelive.co.uk',
    viafouraId: '00000000-0000-4000-8000-7520c1ccc451',
    viafouraPayload: 'oxfordshirelive-prod-#id',
  },
  norfolklive: {
    domain: 'norfolklive.co.uk',
    viafouraId: '00000000-0000-4000-8000-c46866252f6b',
    viafouraPayload: 'norfolklive-prod-#id',
  },
  suffolklive: {
    domain: 'suffolklive.com',
    viafouraId: '00000000-0000-4000-8000-60b3a34cf2d8',
    viafouraPayload: 'suffolklive-prod-#id',
  },
  dorsetlive: {
    domain: 'dorset.live',
    viafouraId: '00000000-0000-4000-8000-a5d4d74d1373',
    viafouraPayload: 'dorsetlive-prod-#id',
  },
  wiltshirelive: {
    domain: 'wiltshirelive.co.uk',
    viafouraId: '00000000-0000-4000-8000-b7c3e80e5ef3',
    viafouraPayload: 'wiltshirelive-prod-#id',
  },
  galwaybeo: {
    domain: 'galwaybeo.ie',
    viafouraId: '00000000-0000-4000-8000-534847382267',
    viafouraPayload: 'galwaybeo-prod-#id',
  },
  aberdeenlive: {
    domain: 'aberdeenlive.news',
    viafouraId: '00000000-0000-4000-8000-9077f10608bf',
    viafouraPayload: 'aberdeenlive-prod-#id',
  },
  //regionals
  birminghammail: {
    domain: 'birminghammail.co.uk',
    viafouraId: '00000000-0000-4000-8000-289a5c4e7396',
    viafouraPayload: 'birminghammail-prod-#id',
  },
  men: {
    domain: 'manchestereveningnews.co.uk',
    viafouraId: '00000000-0000-4000-8000-14cc93248f99',
    viafouraPayload: 'manchester evening news-prod-#id',
  },
  accrington: {
    domain: 'lancs.live',
    viafouraId: '00000000-0000-4000-8000-791bc79b4efd',
    viafouraPayload: 'accrington-prod-#id',
  },
  nechronicle: {
    domain: 'chroniclelive.co.uk',
    viafouraId: '00000000-0000-4000-8000-ecb1138a6c24',
    viafouraPayload: 'nechronicle-prod-#id',
  },
  walesonline: {
    domain: 'walesonline.co.uk',
    viafouraId: '00000000-0000-4000-8000-530063b48e37',
    viafouraPayload: 'icwales2-prod-#id',
  },
  northwales: {
    domain: 'dailypost.co.uk',
    viafouraId: '00000000-0000-4000-8000-c458cb197a97',
    viafouraPayload: 'dailypost-prod-#id',
  },
  coventrytelegraph: {
    domain: 'coventrytelegraph.net',
    viafouraId: '00000000-0000-4000-8000-cf2a2d576e54',
    viafouraPayload: 'coventrytelegraph-prod-#id',
  },
  liverpoolecho: {
    domain: 'liverpoolecho.co.uk',
    viafouraId: '00000000-0000-4000-8000-b72c1a6f9789',
    viafouraPayload: 'liverpoolecho-prod-#id',
  },
  birminghampost: {
    domain: 'business-live.co.uk',
    viafouraId: '00000000-0000-4000-8000-9693f3d4bcfc',
    viafouraPayload: 'birminghampost-prod-#id',
  },
  gazettelive: {
    domain: 'gazettelive.co.uk',
    viafouraId: '00000000-0000-4000-8000-9dd5aa60dcf4',
    viafouraPayload: 'gazettelive2-prod-#id',
  },
  getreading: {
    domain: 'getreading.co.uk',
    viafouraId: '00000000-0000-4000-8000-014c3665d778',
    viafouraPayload: 'get reading-prod-#id',
  },
  getsurrey: {
    domain: 'getsurrey.co.uk',
    viafouraId: '00000000-0000-4000-8000-837a801e835b',
    viafouraPayload: 'get surrey-prod-#id',
  },
  huddersfieldexaminer: {
    domain: 'examinerlive.co.uk',
    viafouraId: '00000000-0000-4000-8000-d0a628957588',
    viafouraPayload: 'huddexaminer-prod-#id',
  },
  chesterchronicle: {
    domain: 'cheshire-live.co.uk',
    viafouraId: '00000000-0000-4000-8000-e81494fbe527',
    viafouraPayload: 'chesterchronicle-prod-#id',
  },
  getwestlondon: {
    domain: 'mylondon.news',
    viafouraId: '00000000-0000-4000-8000-08dd17b71b01',
    viafouraPayload: 'get west london-prod-#id',
  },
  belfastlive: {
    domain: 'belfastlive.co.uk',
    viafouraId: '00000000-0000-4000-8000-56ba2ecbf887',
    viafouraPayload: 'belfastlive-prod-#id',
  },
  dublinlive: {
    domain: 'dublinlive.ie',
    viafouraId: '00000000-0000-4000-8000-f3720a5df306',
    viafouraPayload: 'dublinlive-prod-#id',
  },
  glasgowlive: {
    domain: 'glasgowlive.co.uk',
    viafouraId: '00000000-0000-4000-8000-d53ee3d50073',
    viafouraPayload: 'glasgowlive-prod-#id',
  },
  cambridgenews: {
    domain: 'cambridge-news.co.uk',
    viafouraId: '00000000-0000-4000-8000-954f9be12a9c',
    viafouraPayload: 'cambridgenews-prod-#id',
  },
  footballlondon: {
    domain: 'football.london',
    viafouraId: '00000000-0000-4000-8000-e023a75cf8cd',
    viafouraPayload: 'footballlondon-prod-#id',
  },
  leedslive: {
    domain: 'leeds-live.co.uk',
    viafouraId: '00000000-0000-4000-8000-e5c81b276e78',
    viafouraPayload: 'leedslive-prod-#id',
  },
  edinburghlive: {
    domain: 'edinburghlive.co.uk',
    viafouraId: '00000000-0000-4000-8000-7594cc3ce58a',
    viafouraPayload: 'edinburghlive-prod-#id',
  },
  footballscotland: {
    domain: 'footballscotland.co.uk',
    viafouraId: '00000000-0000-4000-8000-40af056e634f',
    viafouraPayload: 'footballscotland-prod-#id',
  },
  corklive: {
    domain: 'corkbeo.ie',
    viafouraId: '00000000-0000-4000-8000-21ba04940abe',
    viafouraPayload: 'corklive-prod-#id',
  },
  liverpool: {
    domain: 'liverpool.com',
    viafouraId: '00000000-0000-4000-8000-fe9e49ec9edd',
    viafouraPayload: 'liverpool-prod-#id',
  },
  //nationals
  mirror: {
    domain: 'mirror.co.uk',
    viafouraId: '00000000-0000-4000-8000-67e599051dc3',
    viafouraPayload: 'mirror-prod-#id',
  },
  express: {
    domain: 'express.co.uk',
    viafouraId: '00000000-0000-4000-8000-0ed77b31aaaf',
    viafouraPayload: 'express-prod-#id',
    brandingImgUrl: 'https://cdn.images.express.co.uk/img/logo/dr/logo.png',
  },
  ok: {
    domain: 'ok.co.uk',
    viafouraId: '00000000-0000-4000-8000-eafb9cdd39e5',
    viafouraPayload: 'ok-prod-#id',
  },
  dailystar: {
    domain: 'dailystar.co.uk',
    viafouraId: '00000000-0000-4000-8000-74e153e394bb',
    viafouraPayload: 'dailystar-prod-#id',
  },
  dailyrecord: {
    domain: 'dailyrecord.co.uk',
    viafouraId: '00000000-0000-4000-8000-f5c84e28340e',
    viafouraPayload: 'dailyrecord-prod-#id',
  },
  irishmirror: {
    domain: 'irishmirror.ie',
    viafouraId: '00000000-0000-4000-8000-b916e3dd9dff',
    viafouraPayload: 'irishmirror-prod-#id',
  },
  rsvp: {
    domain: 'rsvplive.ie',
    viafouraId: '00000000-0000-4000-8000-8cef58327b08',
    viafouraPayload: 'rsvplive-prod-#id',
  },
  businessinsider: {
    domain: 'insider.co.uk',
    viafouraId: '00000000-0000-4000-8000-e629277e92f8',
    viafouraPayload: 'businessinsider-prod-#id',
  },
  buzz: {
    domain: 'buzz.ie',
    viafouraId: '00000000-0000-4000-8000-0de010ea809a',
    viafouraPayload: 'buzz-prod-#id',
  },
  scottishdailyexpress: {
    domain: 'scottishdailyexpress.co.uk',
    viafouraId: '00000000-0000-4000-8000-e546ea7bdb5a',
    viafouraPayload: 'scottishdailyexpress-prod-#id',
  },
};
