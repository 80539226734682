const pageConfig = {
  path: '/5293/mynewsassistant.com',
  targeting: {
    templ: 'home',
    configPageType: 'home-default',
    platform: 'web,desktop',
    breakpoint: 'desktop',
    loggedin: 'undefined',
    site: 'niche',
    publication: 'mynewsassistant.com',
    mantis: 'unknown',
    iab_context: 'unknown',
    mantis_context: 'unknown',
    gs_cat: 'none',
  },
};

export default pageConfig;
