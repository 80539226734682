const slots = [
  {
    position: 0,
    id: 'div-gpt-ad-in-feed-mma-1',
    sizes: [[320, 50]],
    targeting: {
      index: 6006,
      hivis: 'n',
    },
  },
  {
    position: 4,
    id: 'div-gpt-ad-in-feed-mma-2',
    sizes: [[320, 50]],
    targeting: {
      index: 6007,
      hivis: 'n',
    },
  },
];

export default slots;