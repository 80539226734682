const slots = [
  {
    position: 4,
    id: 'div-gpt-ad-in-category-mma-1',
    sizes: [[320, 50]],
    targeting: {
      index: 6009,
      hivis: 'n',
    },
  },
  {
    position: 9,
    id: 'div-gpt-ad-in-category-mma-2',
    sizes: [[320, 50]],
    targeting: {
      index: 6010,
      hivis: 'n',
    },
  },
  {
    position: 14,
    id: 'div-gpt-ad-in-category-mma-3',
    sizes: [[320, 50]],
    targeting: {
      index: 6011,
      hivis: 'n',
    },
  },
  {
    position: 19,
    id: 'div-gpt-ad-in-category-mma-4',
    sizes: [[320, 50]],
    targeting: {
      index: 6012,
      hivis: 'n',
    },
  },
  {
    position: 24,
    id: 'div-gpt-ad-in-category-mma-5',
    sizes: [[320, 50]],
    targeting: {
      index: 6013,
      hivis: 'n',
    },
  },
  {
    position: 29,
    id: 'div-gpt-ad-in-category-mma-6',
    sizes: [[320, 50]],
    targeting: {
      index: 6014,
      hivis: 'n',
    },
  },
  {
    position: 34,
    id: 'div-gpt-ad-in-category-mma-7',
    sizes: [[320, 50]],
    targeting: {
      index: 6015,
      hivis: 'n',
    },
  }
];

export default slots;
