import StarIcon from '@mui/icons-material/Star';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import LuggageIcon from '@mui/icons-material/Luggage';
import PetsIcon from '@mui/icons-material/Pets';
import WaterIcon from '@mui/icons-material/Water';
import FlutterDashIcon from '@mui/icons-material/FlutterDash';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import SportsIcon from '@mui/icons-material/Sports';
import TvIcon from '@mui/icons-material/Tv';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ParkIcon from '@mui/icons-material/Park';
import CelebrationIcon from '@mui/icons-material/Celebration';
import EggIcon from '@mui/icons-material/Egg';
import CalculateIcon from '@mui/icons-material/Calculate';
import CampaignIcon from '@mui/icons-material/Campaign';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
// import SleddingIcon from '@mui/icons-material/Sledding';
import {
  Accessible,
  AccountBalance,
  AccountBalanceWallet,
  Apartment,
  Audiotrack,
  Autorenew,
  Book,
  Cake,
  CardGiftcard,
  Code,
  Computer,
  DirectionsBus,
  DirectionsRun,
  EmojiTransportation,
  FitnessCenter,
  Gavel,
  GolfCourse,
  Home,
  Hotel,
  Language,
  LocalBar,
  LocalGroceryStore,
  LocalLaundryService,
  LocalMovies,
  LocalShipping,
  Map,
  OndemandVideo,
  OutdoorGrill,
  People,
  Pool,
  Power,
  PregnantWoman,
  RadioButtonChecked,
  RadioOutlined,
  Restaurant,
  RoomService,
  Rowing,
  School,
  Search,
  Security,
  ShoppingCart,
  Spa,
  SportsBaseball,
  SportsCricket,
  SportsHandball,
  SportsHockey,
  SportsMma,
  SportsMotorsports,
  SportsRugby,
  SportsSoccer,
  SportsTennis,
  SportsVolleyball,
  Storage,
  Toys,
  Tv,
  TwoWheeler,
  VideogameAsset,
  WbSunny,
  Wifi,
  Work,
  WorkOutline,
} from '@material-ui/icons';
import {
  Agriculture,
  AirportShuttle,
  Animation,
  Architecture,
  AutoFixHigh,
  AutoMode,
  AutoStories,
  BedroomBaby,
  BiotechRounded,
  CarRental,
  Checkroom,
  Church,
  Construction,
  DesignServices,
  Diamond,
  DirectionsCarFilled,
  DownhillSkiing,
  Elderly,
  ElectricBolt,
  ElectricCar,
  Engineering,
  Event,
  FaceRetouchingNatural,
  Factory,
  FoodBank,
  HelpCenter,
  Hiking,
  House,
  Kayaking,
  Key,
  Lightbulb,
  Living,
  LocalPolice,
  LunchDining,
  Medication,
  MilitaryTech,
  ModeOfTravel,
  Moped,
  Mosque,
  Newspaper,
  NordicWalking,
  Paragliding,
  PestControl,
  Phishing,
  Roofing,
  Sailing,
  SailingOutlined,
  Savings,
  Science,
  ScubaDiving,
  SettingsSuggest,
  Skateboarding,
  Sledding,
  Snowboarding,
  SocialDistance,
  SportsGymnastics,
  SportsMartialArts,
  Surfing,
  Synagogue,
  TempleBuddhist,
  TheaterComedy,
  Tour,
  Villa,
  Yard,
} from '@mui/icons-material';
import { Radio } from '@mui/material';
import { getFeatureValue } from '../helpers/configUtilities';

const getCategoryByType = (type) => {
  type = type || 'primary'; // Use a default value if type is falsy

  let categoryByType = {};
  Object.keys(categoryData)
    .filter((category) =>
      categoryData[category] &&
      categoryData[category].categoryType === type &&
      categoryData[category].isEnabled !== false
    )
    .sort((a, b) => {
      // Custom sorting logic:
      // 1. 'primary' categories come first
      // 2. Categories with isFeatured come next
      return (
        categoryData[b].categoryType === 'primary' - categoryData[a].categoryType === 'primary' ||
        categoryData[b].isFeatured - categoryData[a].isFeatured
      );
    })
    .forEach((category) => {
      categoryByType[category] = categoryData[category];
    });

  return categoryByType;
};

const getCategoryBySection = (section, subSection) => {
  section = subSection || section || 'entertainment';
  let categoryByType = {};
  Object.keys(categoryData).forEach((category) => {
    if (
      categoryData[category] &&
      ((categoryData[category].subSectionSlug === subSection && subSection) || (categoryData[category].sectionSlug === section && !subSection)) &&
      categoryData[category].isEnabled !== false
    ) {
      categoryByType[category] = categoryData[category];
    }
  });
  return categoryByType;
};

const getCategoryKeys = () => {
  return Object.keys(categoryData);
};

const getCategoryByName = (categoryName) => {
  const categoryConfig = categoryData[categoryName];
  if (categoryConfig) {
    return categoryConfig;
  }
  for (const categoryConfig of Object.values(categoryData)) {
    if (categoryConfig.categorySlug === categoryName) {
      return categoryConfig;
    }
  }
  return undefined;
};

const getCategorys = () => {
  return categoryData;
};

const getCategoryMap = () => {
  let category = [];
  Object.keys(categoryData).map((key) => {
    category.push({ [key]: categoryData[key] });
  });
  return category;
};

let getCategoryTreeMap = (data) => {
  data ? data : (data = categoryData);
  let category = [];
  Object.keys(data).map((key) => {
    category.push({ key: key, label: data[key].displayName, nodes: [], categoryKey: key });
  });
  return category;
};

const getAllDisplayNames = () => {
  let displayNames = [];
  Object.keys(categoryData).map((key) => {
    if (categoryData[key]['isEnabled'] !== false) {
      displayNames.push(categoryData[key]['displayName']);
    }
  });
  return displayNames;
};

const filterCategory = (categoryName, data) => {
  const filteredData = {};
  for (const key in data) {
    if (key !== categoryName) {
      filteredData[key] = data[key];
    }
  }
  return filteredData;
};

function constructSavedSearchConfig(categoryName) {
  // Convert category name to lowercase and replace hyphens with spaces
  const displayName = categoryName.replace(/-/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());

  // Construct the saved search config object
  const savedSearchConfig = {
    categorySlug: categoryName.toLowerCase(),
    categoryQuery: '',
    displayName: displayName,
    domains: '',
    excludeQuery: '',
    name: categoryName.toLowerCase(),
    orSearchQuery: '',
    searchQuery: categoryName.toLowerCase(),
    sectionSlug: 'feed',
    sortOrder: 'date',
    subSectionSlug: '',
  };

  return savedSearchConfig;
}


function getSuggestedCategories(sectionSlug, subSectionSlug, categoryName) {
  // Check if categoryName is defined and retrieve category configuration
  const existingCategory = categoryName ? getCategoryByName(categoryName) : undefined;

  // Check if existingCategory is defined and relatedCategories is not an empty string
  const useExistingCategory = existingCategory?.relatedCategories?.trim()?.length > 0 ?? false;

  // Get category configurations for each related category
  let relatedCategoryConfigurations = [];
  if (useExistingCategory) {
    relatedCategoryConfigurations = existingCategory.relatedCategories
      .split(',')
      .map((relatedCategoryName) => {
        const config = getCategoryByName(relatedCategoryName.trim());
        return config !== undefined ? config : constructSavedSearchConfig(relatedCategoryName.trim());
      });
  }

  // If useExistingCategory is true and relatedCategoryConfigurations is not empty, use them
  const configSuggestedCategories = useExistingCategory && relatedCategoryConfigurations.length > 0
    ? relatedCategoryConfigurations
    : getCategoryBySection(sectionSlug, subSectionSlug);

  // Continue with the existing logic for feature override
  const suggestedCategoriesOverride = getFeatureValue('CustomSuggestedCategories');
  const suggestedCategories = suggestedCategoriesOverride[categoryName]
    ? suggestedCategoriesOverride[categoryName]
    : filterCategory(categoryName, configSuggestedCategories);

  return suggestedCategories;
}

const getMantisValuesByDisplayName = (category) => {
  let mantisCategories = '';

  for (const element in categoryData) {
    if (categoryData[element].displayName === category) {
      mantisCategories = categoryData[element].mantisCategories;
    }
  }
  return mantisCategories;
};

const categoryTypes = [
  { key: 'primary', label: 'Popular' },
  { key: 'recommended', label: 'Recommended' },
  { key: 'annual-holidays', label: 'Annual Holidays & Festivals' },
  { key: 'animals', label: 'Animals' },
  { key: 'business', label: 'Business' },
  { key: 'cars-and-transport', label: 'Cars & Transport' },
  { key: 'creativity-and-culture', label: 'Creativity & Culture' },
  { key: 'entertainment', label: 'Entertainment' },
  { key: 'football', label: 'Football' },
  { key: 'life-and-style', label: 'Life & Style' },
  { key: 'news', label: 'News' },
  { key: 'reality-tv', label: 'Reality TV' },
  { key: 'science-fiction', label: 'Science Fiction TV' },
  { key: 'science-and-education', label: 'Science & Education' },
  { key: 'sports', label: 'Sports' },
  { key: 'tech', label: 'Tech' },
  { key: 'travel-and-tourism', label: 'Travel & Tourism' },
  { key: 'misc', label: 'Miscellaneous' },
];

const categoryData = {
  celebrity: {
    age: 15,
    categorySlug: 'celebrity-news',
    categoryDescription:
      'A well-known individual who has achieved fame and recognition for their accomplishments in various fields, such as entertainment, sports, politics, business, or social media.',
    categoryImage: '',
    categoryType: 'primary',
    displayName: 'Celebs',
    featureLimit: 1,
    icon: '<StarIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'celebrity',
    limit: 20,
    mantisCategories: '',
    metaDescription: "The latest news, pictures, and videos about celebrities' breakups, reconciliations, elopements and shopping trips are right here.",
    metaImage: '',
    metaTitle: 'Keeping you up to date with the latest celebrity gossip and pictures',
    relatedCategories: 'ariana-grande,lady-gaga,kanye-west,taylor-swift,rihanna,harry-styles,justin-bieber,ed-sheeran,beyonce,jennifer-lawrence,dwayne-johnson,brad-pitt,adele,leonardo-dicaprio,kim-kardashian,angelina-jolie',
    sectionSlug: 'entertainment',
    subSectionSlug: 'red-carpet',
    tags: '',
    concepts: 'Celebrity',
  },
  news: {
    age: 15,
    categorySlug: 'latest-news',
    categoryDescription: 'Information about current events and developments that is gathered, verified, analysed, and disseminated.',
    categoryImage: '',
    categoryType: 'primary',
    displayName: 'News',
    featureLimit: 1,
    icon: '<NewspaperIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'news',
    limit: 20,
    mantisCategories:
      'health_fitness,disease,cold_flu,epidemic,law_govt_politics,government,government_contracting_procurement,mens_health,family_parenting,children,business_industrial,business_operations,business_plans,charity,politics_bespoke,health_fitness_bespoke,back_to_school,business_news,financial_news,international_news,local_news,national_news,tech_news',
    metaDescription: "News from the UK and around the world. Explore the day's top stories, opinion, photos, and videos.",
    metaImage: '',
    metaTitle: 'The latest news, exclusives, opinions, and analysis',
    relatedCategories: 'crime,gaming,health,politics,royal-family,tech,ukraine,israel,palestine,weather,viral',
    sectionSlug: 'news',
    subSectionSlug: '',
    tags: '',
    concepts: '',
  },
  'formula-1': {
    age: 15,
    categorySlug: 'formula-1',
    categoryDescription:
      'Get the latest news, race updates, and analysis from the world of Formula 1, including driver interviews, team standings, and the latest gossip from the paddock.',
    categoryImage: '',
    categoryType: 'primary',
    displayName: 'Formula 1',
    featureLimit: 1,
    icon: '<TimeToLeaveIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'formula-1',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Bringing you the latest F1 news, highlights, results and commentary.',
    metaImage: '',
    metaTitle: 'The latest news, opinions, previews, gossip, and results',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
    concepts: 'Formula One',
  },
  football: {
    age: 15,
    categorySlug: 'latest-football-news',
    categoryDescription:
      'Follow all the action from the world of football, with up-to-date news, transfer rumors, match previews and reviews, and live scores.',
    categoryImage: '',
    categoryType: 'primary',
    displayName: 'Football',
    featureLimit: 1,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'football',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Premier League, Championship, European and World Football news, scores, results, fixtures and videos.',
    metaImage: '',
    metaTitle: 'The latest news, opinion, previews, results, and live scores',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
    concepts: 'Football',
  },
  travel: {
    age: 15,
    categorySlug: 'latest-travel-news',
    categoryDescription: 'Discover the best travel destinations, top tips for planning your trip, and inspiring travel stories from around the world.',
    categoryImage: '',
    categoryType: 'primary',
    displayName: 'Travel',
    featureLimit: 1,
    icon: '<LuggageIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'travel',
    limit: 20,
    mantisCategories: 'travel,business_travel,specialty_travel,adventure_travel,immigration,summer_staycation,whats_on',
    metaDescription:
      "Have you been looking for the perfect holiday? You'll find what you're looking for whether you're looking for a two-week blow-out on the other side of the world or a short city break.",
    metaImage: '',
    metaTitle: 'The latest holiday news and deals & bargains',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'travel',
    tags: '',
    concepts: '',
  },
  'whats-on': {
    age: 30,
    categorySlug: 'whats-on',
    categoryDescription: "Find out what's on in your area with our comprehensive guide to events, concerts, theater performances, and more.",
    categoryImage: '',
    categoryType: 'recommended',
    displayName: "What's On",
    featureLimit: 0,
    icon: '<AnnouncementIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'whats-on',
    limit: 20,
    mantisCategories: 'whats_on',
    metaDescription: 'The latest news on music, film, comedy, and theatre across the country.',
    metaImage: '',
    metaTitle: 'News from the worlds of music, film, comedy, and theatre',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    tags: '',
    concepts: '',
  },
  politics: {
    age: 30,
    categorySlug: 'latest-politics-news',
    categoryDescription:
      'Keep up with the latest political news, analysis, and commentary from around the world, including breaking stories, election updates, and expert opinions.',
    categoryImage: '',
    categoryType: 'recommended',
    displayName: 'Politics',
    featureLimit: 0,
    icon: '<HowToVoteIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'politics',
    limit: 20,
    mantisCategories: 'politics',
    metaDescription: 'News, commentary, and analysis on the latest developments in UK and global politics.',
    metaImage: '',
    metaTitle: 'Politics news from the UK & around the world',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: 'politics',
    tags: '',
    concepts: '',
  },
  sport: {
    age: 30,
    categorySlug: 'latest-sports-news',
    categoryDescription: 'Stay up to date with the latest sports news, including live scores, match highlights, and expert analysis from around the world.',
    categoryImage: '',
    categoryType: 'recommended',
    displayName: 'Sport',
    featureLimit: 0,
    icon: '<SportsIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'sport',
    limit: 20,
    mantisCategories: 'sports_news,sports',
    metaDescription: 'Discover the latest sports news, including football, boxing, racing, and MMA.',
    metaImage: '',
    metaTitle: 'The latest sports news, live blogs, and opinions from around the world',
    relatedCategories: "boxing,football,formula-1,rugby,women's-world-cup,ufc",
    sectionSlug: 'sports',
    subSectionSlug: '',
    tags: '',
    concepts: '',
  },
  tv: {
    age: 30,
    categorySlug: 'tv',
    categoryDescription:
      'Get the latest TV news, reviews, and recommendations, as well as exclusive interviews with your favorite stars and behind-the-scenes looks at your favorite shows.',
    categoryImage: '',
    categoryType: 'recommended',
    displayName: 'TV',
    featureLimit: 0,
    icon: '<TvIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'tv',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'We bring you the latest news, sports, celebrity gossip, TV, politics and lifestyle.',
    metaImage: '',
    metaTitle: "The latest news, previews, and reviews from the world's most popular shows",
    relatedCategories: 'coronation-street,eastenders,gogglebox,love-island,netflix,selling-sunset,this-morning',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'Television',
  },
  showbiz: {
    age: 30,
    categorySlug: 'showbiz',
    categoryDescription:
      'From Hollywood to Bollywood, get the latest news and gossip from the world of showbiz, including red carpet events, celebrity interviews, and breaking stories.',
    categoryImage: '',
    categoryType: 'recommended',
    displayName: 'Showbiz',
    featureLimit: 0,
    icon: '<StarIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'showbiz',
    limit: 20,
    mantisCategories:
      'celebrity_bespoke,summer_staycation,back_to_school,whats_onsociety,social_institution,divorce,marriage,style_fashion,beauty,dating,family_parenting,babies_toddlers,baby_clothes,travel,traveling_with_kids',
    metaDescription: "Find out what's happening in the world of show business with the latest celebrity news and gossip.",
    metaImage: '',
    metaTitle: 'The latest celebrity gossip and pictures from the world of showbiz',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'red-carpet',
    tags: '',
    concepts: '',
  },
  'cost-of-living': {
    age: 30,
    categorySlug: 'cost-of-living',
    categoryDescription:
      'Stay informed about the cost of living in your area, including real estate prices, inflation rates, and the cost of everyday goods and services.',
    categoryImage: '',
    categoryType: 'primary',
    displayName: 'Cost of Living',
    featureLimit: 0,
    icon: '<CreditCardIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'cost-of-living',
    limit: 20,
    mantisCategories: '',
    metaDescription:
      'Stay up-to-date with the latest news and trends on the cost of living with My News Assistant. Find articles and insights on managing your finances and improving your financial situation.',
    metaImage: '',
    metaTitle: 'Cost of Living',
    relatedCategories: 'energy costs,inflation,martin-lewis,money-saving-tips,mortgages,pensions,universal-credit',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
    concepts: 'Cost of living',
  },
  'animal-welfare': {
    age: 30,
    categorySlug: 'animal-welfare',
    categoryDescription:
      'Learn about animal welfare issues and how you can help make a difference, including information about animal rescue organizations, animal cruelty laws, and tips for responsible pet ownership.',
    categoryImage: '',
    categoryType: 'animals',
    displayName: 'Animal Welfare',
    featureLimit: 0,
    icon: '<PetsIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'animal-welfare',
    limit: 20,
    mantisCategories: 'animal_welfare',
    metaDescription:
      'Get the latest news and updates on animal welfare with My News Assistant. Stay informed on the latest laws, regulations, and initiatives to protect animals and improve their well-being.',
    metaImage: '',
    metaTitle: 'Animal Welfare News',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: 'society-and-culture',
    tags: '',
    concepts: '',
  },
  aquariums: {
    age: 30,
    categorySlug: 'aquariums',
    categoryDescription:
      'Discover the amazing world of aquariums, with information about marine life, tank setups, and expert advice for keeping fish healthy and happy.',
    categoryImage: '',
    categoryType: 'animals',
    displayName: 'Aquariums',
    featureLimit: 0,
    icon: '<WaterIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'aquariums',
    limit: 20,
    mantisCategories: '',
    metaDescription:
      'Discover the latest news and trends on aquariums with My News Assistant. Get insights on setting up and maintaining your own aquarium, as well as updates on the latest technology and equipment.',
    metaImage: '',
    metaTitle: 'Aquarium News and Trends',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'animals',
    tags: '',
    concepts: 'Aquarium',
  },
  birds: {
    age: 30,
    categorySlug: 'birds',
    categoryDescription:
      'Learn about different bird species, their behaviors and habitats, and get tips for bird watching and attracting birds to your backyard.',
    categoryImage: '',
    categoryType: 'animals',
    displayName: 'Birds',
    featureLimit: 0,
    icon: '<FlutterDashIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'birds',
    limit: 20,
    mantisCategories: 'birds',
    metaDescription:
      'Stay informed on the latest news and trends on birds with My News Assistant. Discover new species, get tips on bird watching, and stay up-to-date on the latest research and conservation efforts.',
    metaImage: '',
    metaTitle: 'Bird News and Trends',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'animals',
    tags: '',
    concepts: '',
  },
  birdwatching: {
    age: 30,
    categorySlug: 'birdwatching',
    categoryDescription:
      'Find the best bird watching spots, learn how to identify different bird species, and get expert advice for a successful bird watching trip.',
    categoryImage: '',
    categoryType: 'animals',
    displayName: 'Bird Watching',
    featureLimit: 0,
    icon: '<FlutterDashIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'birdwatching',
    limit: 20,
    mantisCategories: 'birdwatching',
    metaDescription:
      'Get the latest news and tips on bird watching with My News Assistant. Discover the best spots for bird watching, learn about different species, and get advice on how to get the most out of your bird watching experience.',
    metaImage: '',
    metaTitle: 'Bird Watching News and Tips',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'animals',
    tags: '',
    concepts: '',
  },
  cats: {
    age: 30,
    categorySlug: 'cats',
    categoryDescription:
      'Get expert advice on caring for your feline friends, including health and nutrition tips, behavior advice, and information on popular cat breeds.',
    categoryImage: '',
    categoryType: 'animals',
    displayName: 'Cats',
    featureLimit: 0,
    icon: '<PetsIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'cats',
    limit: 20,
    mantisCategories: 'cats',
    metaDescription:
      'Stay informed on the latest news and trends on cats with My News Assistant. Get tips on caring for your cat, learn about different breeds, and stay up-to-date on the latest research and products for your furry friend.',
    metaImage: '',
    metaTitle: 'Cat News and Trends',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'animals',
    tags: '',
    concepts: '',
  },
  dogs: {
    age: 30,
    categorySlug: 'dogs',
    categoryDescription:
      'Get expert advice on caring for your canine companions, including health and nutrition tips, behavior advice, and information on popular dog breeds.',
    categoryImage: '',
    categoryType: 'animals',
    displayName: 'Dogs',
    featureLimit: 0,
    icon: '<PetsIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'dogs',
    limit: 20,
    mantisCategories: 'dogs',
    metaDescription:
      'Stay up-to-date on the latest news and trends on dogs with My News Assistant. Get tips on caring for your dog, learn about different breeds, and stay informed on the latest research and products for your furry friend.',
    metaImage: '',
    metaTitle: 'Dog News and Trends',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'animals',
    tags: '',
    concepts: '',
  },
  horses: {
    age: 30,
    categorySlug: 'horses',
    categoryDescription:
      'Discover the world of horses, including information on different breeds, riding disciplines, and expert advice for horse care and training.',
    categoryImage: '',
    categoryType: 'animals',
    displayName: 'Horses',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'horses',
    limit: 20,
    mantisCategories: 'horses',
    metaDescription:
      'Stay informed on the latest news and trends on horses with My News Assistant. Discover the best breeds for different activities, get insights on training and care, and stay up-to-date on the latest products and equipment.',
    metaImage: '',
    metaTitle: 'Horse News and Trends',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'animals',
    tags: '',
    concepts: '',
  },
  'large-animals': {
    age: 30,
    categorySlug: 'large-animals',
    categoryDescription:
      'Learn about different large animal species, their behaviors and habitats, and get tips for conservation and responsible wildlife viewing.',
    categoryImage: '',
    categoryType: 'animals',
    displayName: 'Large Animals',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'large-animals',
    limit: 20,
    mantisCategories: 'large_animals',
    metaDescription:
      'Stay informed on the latest news and insights on large animals with My News Assistant. Discover information on animal behavior, habitat, and conservation, and stay up-to-date on the latest research and initiatives.',
    metaImage: '',
    metaTitle: 'News and Insights on Large Animals',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'animals',
    tags: '',
    concepts: '',
  },
  reptiles: {
    age: 30,
    categorySlug: 'reptiles',
    categoryDescription:
      'Discover the fascinating world of reptiles, including information on different species, their care and feeding, and expert advice for keeping reptiles as pets.',
    categoryImage: '',
    categoryType: 'animals',
    displayName: 'Reptiles',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'reptiles',
    limit: 20,
    mantisCategories: 'reptiles',
    metaDescription:
      'Stay informed on the latest news and trends on reptiles with My News Assistant. Discover information on different species, get tips on care and maintenance, and stay up-to-date on the latest research and products.',
    metaImage: '',
    metaTitle: 'Reptile News and Trends',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'animals',
    tags: '',
    concepts: '',
  },
  zoo: {
    age: 30,
    categorySlug: 'zoo-animals',
    categoryDescription: 'Learn about different zoo animals, their behaviors and habitats, and get tips for a successful zoo visit.',
    categoryImage: '',
    categoryType: 'animals',
    displayName: 'Zoo',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'zoo',
    limit: 20,
    mantisCategories: 'zoo',
    metaDescription:
      'Stay informed on the latest news and insights on zoos with My News Assistant. Discover information on different zoos around the world, get insights on animal behavior, habitat, and conservation, and stay up-to-date on the latest research and initiatives.',
    metaImage: '',
    metaTitle: 'Zoo News and Insights',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'animals',
    tags: '',
    concepts: 'Zoo',
  },
  'black-friday': {
    age: 30,
    categorySlug: 'black-friday',
    categoryDescription: 'Get the best deals and discounts on Black Friday, including tips for shopping smart and maximising your savings.',
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Black Friday',
    featureLimit: 0,
    icon: '<LocalOfferIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'black-friday',
    limit: 20,
    mantisCategories: '',
    metaDescription:
      'Stay informed on the latest news and trends on Black Friday with My News Assistant. Discover the best deals and promotions, get insights on shopping strategies, and stay up-to-date on the latest products and trends.',
    metaImage: '',
    metaTitle: 'Black Friday Deals and Trends',
    relatedCategories: 'cyber-monday,deals',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
    concepts: 'Black Friday (shopping)',
    excludeMantisCategories: 'law_govt_politics,law_enforcement,fire_department,society,crime,property_crime',
  },
  'cyber-monday': {
    age: 30,
    categorySlug: 'cyber-monday',
    categoryDescription: 'Find the hottest online deals and discounts on Cyber Monday. Get tips for smart online shopping and maximise your savings on tech, electronics, and more.',
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Cyber Monday',
    featureLimit: 0,
    icon: '<LocalOfferIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'cyber-monday',
    limit: 20,
    mantisCategories: '',
    metaDescription:
      'Stay informed on the latest news and trends on Cyber Monday with My News Assistant. Discover the best online deals and promotions, get insights on online shopping strategies, and stay up-to-date on the latest tech products and trends.',
    metaImage: '',
    metaTitle: 'Cyber Monday Deals and Trends',
    relatedCategories: 'black-friday,deals',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
    concepts: 'Cyber Monday',
    excludeMantisCategories: 'law_govt_politics,law_enforcement,fire_department,society,crime,property_crime',
  },
  christmas: {
    age: 30,
    categorySlug: 'christmas',
    categoryDescription: 'Get into the holiday spirit with our guide to Christmas traditions, gift ideas, and festive events.',
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Christmas',
    featureLimit: 0,
    icon: '<ParkIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'christmas',
    limit: 20,
    mantisCategories: '',
    metaDescription:
      'Stay informed on the latest news and insights on Christmas with My News Assistant. Discover the best holiday recipes and decoration ideas, get insights on shopping and gift-giving strategies, and stay up-to-date on the latest products and trends.',
    metaImage: '',
    metaTitle: 'Christmas News and Insights',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    tags: '',
    concepts: 'Christmas',
    excludeMantisCategories: 'law_govt_politics,law_enforcement,fire_department,crime,property_crime',
  },
  diwali: {
    age: 30,
    categorySlug: 'diwali',
    categoryDescription: 'Learn about the Hindu festival of lights, Diwali, including its history, traditions, and celebrations.',
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Diwali',
    featureLimit: 0,
    icon: '<CelebrationIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'diwali',
    limit: 20,
    mantisCategories: '',
    metaDescription:
      'Discover the latest news and stories about Diwali, the Festival of Lights, with My News Assistant. Stay up-to-date with the latest trends, events, and traditions.',
    metaImage: '',
    metaTitle: 'Celebrate Diwali with My News Assistant',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    tags: '',
    concepts: 'Diwali',
  },
  easter: {
    age: 30,
    categorySlug: 'easter',
    categoryDescription: 'Celebrate Easter with our guide to traditions, recipes, and events.',
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Easter',
    featureLimit: 0,
    icon: '<EggIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'easter',
    limit: 20,
    mantisCategories: '',
    metaDescription:
      'Get the latest news and stories about Easter with My News Assistant. Learn about the history, traditions, and celebrations of this important Christian holiday.',
    metaImage: '',
    metaTitle: 'Easter News and Stories',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    tags: '',
    concepts: 'Easter',
  },
  'eid-al-fitr': {
    age: 30,
    categorySlug: 'eid-al-fitr',
    categoryDescription: 'Learn about the Islamic holiday of Eid al-Fitr, including its history, traditions, and celebrations.',
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Eid al-Adha',
    featureLimit: 0,
    icon: '<CelebrationIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'eid-al-fitr',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Stay up-to-date with the latest news and stories about Eid al-Adha, one of the most important Islamic holidays, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Celebrate Eid al-Adha with My News Assistant',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    tags: '',
    concepts: 'Eid al-Adha',
  },
  'fathers-day': {
    age: 30,
    categorySlug: 'fathers-day',
    categoryDescription: "Celebrate Father's Day with gift ideas, activities, and events to make your dad feel special.",
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Fathers Day',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'fathers-day',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Discover the latest news and stories about Fathers Day, a day to honor and celebrate fathers, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Celebrate Fathers Day with My News Assistant',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    tags: '',
    concepts: "Father's Day",
  },
  halloween: {
    age: 30,
    categorySlug: 'halloween',
    categoryDescription: 'Get into the spooky spirit with our guide to Halloween costumes, decorations, recipes, and events.',
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Halloween',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'halloween',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest news and stories about Halloween, one of the most popular holidays around the world, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Halloween News and Stories',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    tags: '',
    concepts: 'Halloween',
  },
  'mothers-day': {
    age: 30,
    categorySlug: 'mothers-day',
    categoryDescription: "Celebrate Mother's Day with gift ideas, activities, and events to show your appreciation for your mom.",
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Mothers Day',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'mothers-day',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Stay up-to-date with the latest news and stories about Mothers Day, a day to honor and celebrate mothers, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Celebrate Mothers Day with My News Assistant',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    tags: '',
    concepts: "Mother's Day",
  },
  ramadan: {
    age: 30,
    categorySlug: 'ramadan',
    categoryDescription: 'Learn about the Islamic holy month of Ramadan, including its traditions, fasting, and celebrations.',
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Ramadan',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'ramadan',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Stay up-to-date with the latest news and stories about Ramadan, one of the most important Islamic holidays, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Ramadan News and Stories',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    tags: '',
    concepts: 'Ramadan',
  },
  'st-patricks-day': {
    age: 30,
    categorySlug: 'st-patricks-day',
    categoryDescription: "Celebrate St. Patrick's Day with our guide to traditions, recipes, and events.",
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'St Patricks Day',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'st-patricks-day',
    limit: 20,
    mantisCategories: 'st_patricks_day',
    metaDescription:
      "Discover the latest news and stories about St. Patrick's Day, the holiday that honors the patron saint of Ireland, with My News Assistant.",
    metaImage: '',
    metaTitle: "Celebrate St. Patrick's Day with My News Assistant",
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    tags: '',
  },
  'valentines-day': {
    age: 30,
    categorySlug: 'valentines-day',
    categoryDescription: "Celebrate Valentine's Day with gift ideas, romantic activities, and events to show your love.",
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Valentines Day',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'valentines-day',
    limit: 20,
    mantisCategories: 'valentines_day',
    metaDescription: "Stay up-to-date with the latest news and stories about Valentine's Day, the holiday of love and romance, with My News Assistant.",
    metaImage: '',
    metaTitle: "Celebrate Valentine's Day with My News Assistant",
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    tags: '',
  },
  'yom-kippur': {
    age: 30,
    categorySlug: 'yom-kippur',
    categoryDescription: 'Learn about the Jewish holiday of Yom Kippur, including its traditions, fasting, and prayers.',
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Yom Kippur',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'yom-kippur',
    limit: 20,
    mantisCategories: 'yom_kippur',
    metaDescription: 'Discover the latest news and stories about Yom Kippur, the holiest day of the Jewish calendar, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Celebrate Yom Kippur with My News Assistant',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    tags: '',
  },
  'glastonbury-festival': {
    age: 30,
    categorySlug: 'glastonbury-festival',
    categoryDescription: 'Stay updated on the latest news and highlights from the Glastonbury Festival, one of the world\'s largest and most iconic music and arts festivals.',
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Glastonbury Festival',
    featureLimit: 0,
    icon: '<MusicNote />',
    isEnabled: true,
    isFeatured: false,
    key: 'glastonbury-festival',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on Glastonbury Festival performances, artists, and cultural experiences with My News Assistant.',
    metaImage: '',
    metaTitle: 'Glastonbury Festival News - Latest Highlights and Performances',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'festivals',
    tags: '',
    concepts: 'Glastonbury Festival',
  },
  'coachella-music-arts-festival': {
    age: 30,
    categorySlug: 'coachella-music-arts-festival',
    categoryDescription: 'Stay updated on the latest news and highlights from the Coachella Valley Music and Arts Festival, a renowned annual music and arts event showcasing diverse performers and creative expressions.',
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Coachella Festival',
    featureLimit: 0,
    icon: '<MusicNote />',
    isEnabled: true,
    isFeatured: false,
    key: 'coachella-music-arts-festival',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on Coachella Festival performances, art installations, and cultural experiences with My News Assistant.',
    metaImage: '',
    metaTitle: 'Coachella Festival News - Latest Highlights and Performances',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'festivals',
    tags: '',
    concepts: 'Coachella Valley Music and Arts Festival',
  },
  'reading-leeds-festivals': {
    age: 30,
    categorySlug: 'reading-leeds-festivals',
    categoryDescription: 'Stay updated on the latest news and highlights from the Reading and Leeds Festivals, annual music events in the UK featuring a diverse lineup of artists across various genres.',
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Reading and Leeds Festivals',
    featureLimit: 0,
    icon: '<MusicNote />',
    isEnabled: true,
    isFeatured: false,
    key: 'reading-leeds-festivals',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on Reading and Leeds Festival performances, lineup announcements, and festival experiences with My News Assistant.',
    metaImage: '',
    metaTitle: 'Reading and Leeds Festivals News - Latest Highlights and Performances',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'festivals',
    tags: '',
    concepts: 'Reading and Leeds Festivals',
  },
  'tomorrowland-festival': {
    age: 30,
    categorySlug: 'tomorrowland-festival',
    categoryDescription: 'Stay updated on the latest news and highlights from the Tomorrowland Festival, a world-renowned electronic dance music (EDM) festival featuring top DJs and immersive experiences.',
    categoryImage: '',
    categoryType: 'annual-holidays',
    displayName: 'Tomorrowland Festival',
    featureLimit: 0,
    icon: '<MusicNote />',
    isEnabled: true,
    isFeatured: false,
    key: 'tomorrowland-festival',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on Tomorrowland Festival, including DJ lineups, stage designs, and the electrifying atmosphere of one of the largest EDM festivals with My News Assistant.',
    metaImage: '',
    metaTitle: 'Tomorrowland Festival News - Latest Highlights and Performances',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'festivals',
    tags: '',
    concepts: 'Tomorrowland Festival',
  },
  'accounting-auditing': {
    age: 30,
    categorySlug: 'accounting-auditing',
    categoryDescription:
      'Get the latest news, insights, and advice from the world of accounting and auditing, including updates on tax laws, regulations, and financial reporting.',
    categoryImage: '',
    categoryType: 'business',
    displayName: 'Accounting',
    featureLimit: 0,
    icon: '<CalculateIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'accounting-auditing',
    limit: 20,
    mantisCategories: 'accounting_auditing',
    metaDescription:
      'Get the latest news and stories about Accounting and Auditing, including financial statements, tax laws, and auditing practices, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about Accounting and Auditing with My News Assistant',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'business',
    tags: '',
  },
  'advertising-marketing': {
    age: 30,
    categorySlug: 'advertising-marketing',
    categoryDescription:
      'Discover the latest trends and best practices in advertising and marketing, including social media marketing, content marketing, and digital advertising.',
    categoryImage: '',
    categoryType: 'business',
    displayName: 'Advertising & Marketing',
    featureLimit: 0,
    icon: '<CampaignIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'advertising-marketing',
    limit: 20,
    mantisCategories: 'advertising_marketing',
    metaDescription:
      'Get the latest news and trends about advertising and marketing, including social media, content marketing, SEO, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Advertising and Marketing News',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'business',
    tags: '',
  },
  'business-news': {
    age: 30,
    categorySlug: 'business-news',
    categoryDescription: 'Stay informed about the latest business news, including market trends, company news, and expert analysis.',
    categoryImage: '',
    categoryType: 'business',
    displayName: 'Business News',
    featureLimit: 0,
    icon: '<BusinessCenterIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'business-news',
    limit: 20,
    mantisCategories: 'business_news',
    metaDescription: 'Get the latest news and insights about business news, including finance, economy, market trends, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about Business News',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'business',
    tags: '',
  },
  'business-operations': {
    age: 30,
    categorySlug: 'business-operations',
    categoryDescription:
      'Learn about best practices and strategies for business operations, including supply chain management, logistics, and productivity tips.',
    categoryImage: '',
    categoryType: 'business',
    displayName: 'Business Operations',
    featureLimit: 0,
    icon: '<BusinessCenterIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'business-operations',
    limit: 20,
    mantisCategories: 'business_operations',
    metaDescription:
      'Stay up-to-date with the latest news and trends about business operations, including supply chain management, logistics, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Discover the latest Business Operations news',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'business',
    tags: '',
  },
  'business-software': {
    age: 30,
    categorySlug: 'business-software',
    categoryDescription:
      'Discover the latest business software and technology solutions, including cloud computing, artificial intelligence, and automation tools.',
    categoryImage: '',
    categoryType: 'business',
    displayName: 'Business Software',
    featureLimit: 0,
    icon: '<BusinessCenterIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'business-software',
    limit: 20,
    mantisCategories: 'business_software',
    metaDescription:
      'Stay informed about the latest trends and developments in business software, including CRM, ERP, project management, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Get the latest news about Business Software',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'business',
    tags: '',
  },
  'business-travel': {
    age: 30,
    categorySlug: 'business-travel',
    categoryDescription:
      'Get expert advice on business travel, including tips for booking flights and hotels, managing expenses, and staying productive on the road.',
    categoryImage: '',
    categoryType: 'business',
    displayName: 'Business travel',
    featureLimit: 0,
    icon: '<BusinessCenterIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'business-travel',
    limit: 20,
    mantisCategories: 'business_travel',
    metaDescription: 'Get the latest news and tips about business travel, including airline industry, hotel industry, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Business Travel news',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'business',
    tags: '',
  },
  'career-advice': {
    age: 30,
    categorySlug: 'career-advice',
    categoryDescription: 'Get expert career advice, including tips for job searching, networking, and career advancement.',
    categoryImage: '',
    categoryType: 'business',
    displayName: 'Career Advice',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'career-advice',
    limit: 20,
    mantisCategories: 'career_advice',
    metaDescription:
      'Discover the latest career advice and tips to help you achieve your career goals, including resume writing, job interviews, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Get Career Advice and Tips',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'business',
    tags: '',
  },
  'career-planning': {
    age: 30,
    categorySlug: 'career-planning',
    categoryDescription: 'Plan your career with expert advice on career paths, job skills, and professional development.',
    categoryImage: '',
    categoryType: 'business',
    displayName: 'Career Planning',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'career-planning',
    limit: 20,
    mantisCategories: 'career_planning',
    metaDescription:
      'Stay up-to-date with the latest trends and insights about career planning, including skills development, career change, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Plan Your Career with My News Assistant',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'business',
    tags: '',
  },
  company: {
    age: 30,
    categorySlug: 'company',
    categoryDescription: 'Learn about different companies, their products and services, and their impact on the industry and society.',
    categoryImage: '',
    categoryType: 'business',
    displayName: 'Company',
    featureLimit: 0,
    icon: '<BusinessIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'company',
    limit: 20,
    mantisCategories: 'comapany',
    metaDescription:
      'Stay informed about the latest news and insights about companies, including corporate finance, mergers and acquisitions, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Get the Latest News and Insights about Companies',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'business',
    tags: '',
  },
  'financial-news': {
    age: 30,
    categorySlug: 'financial-news',
    categoryDescription: 'Stay informed about the latest financial news, including market updates, economic indicators, and investment opportunities.',
    categoryImage: '',
    categoryType: 'business',
    displayName: 'Financial News',
    featureLimit: 0,
    icon: '<MonetizationOnIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'financial-news',
    limit: 20,
    mantisCategories: 'financial_news',
    metaDescription: 'Get the latest news and insights about finance, including stocks, bonds, currencies, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Financial News',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'finance',
    tags: '',
  },
  investing: {
    age: 30,
    categorySlug: 'investing',
    categoryDescription:
      'Learn about different investment strategies, including stocks, bonds, real estate, and alternative investments, and get expert advice on building a portfolio.',
    categoryImage: '',
    categoryType: 'business',
    displayName: 'Investing',
    featureLimit: 0,
    icon: '<MonetizationOnIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'investing',
    limit: 20,
    mantisCategories: 'investing',
    metaDescription: 'Discover the latest trends and insights about investing, including stocks, real estate, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about Investing',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'finance',
    tags: '',
  },
  'auto-parts': {
    age: 30,
    categorySlug: 'auto-parts',
    categoryDescription: 'Discover the latest automotive parts and accessories, including performance upgrades, replacement parts, and styling enhancements.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Auto Parts',
    featureLimit: 0,
    icon: '<CarRepairIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'auto-parts',
    limit: 20,
    mantisCategories: 'auto_parts',
    metaDescription: 'Get the latest news and insights about auto parts, including industry trends, new products, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Auto Parts News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  'auto-repair': {
    age: 30,
    categorySlug: 'auto-repair',
    categoryDescription:
      'Get expert advice on auto repair and maintenance, including tips for DIY repairs, finding a trustworthy mechanic, and keeping your car running smoothly.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Auto Repair',
    featureLimit: 0,
    icon: '<CarRepairIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'auto-repair',
    limit: 20,
    mantisCategories: 'auto_repair',
    metaDescription: 'Get the latest news and insights about auto repair, including maintenance tips, industry trends, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Auto Repair News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  'bicycles-accessories': {
    age: 30,
    categorySlug: 'bicycles-accessories',
    categoryDescription:
      'Discover the latest bicycles and accessories, including road bikes, mountain bikes, and electric bikes, as well as gear and equipment for cycling enthusiasts.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Bicycles Accessories',
    featureLimit: 0,
    icon: '<DirectionsBikeIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'bicycles-accessories',
    limit: 20,
    mantisCategories: 'bicycles_accessories',
    metaDescription: 'Discover the latest news and trends about bicycles accessories, including helmets, lights, locks, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about Bicycles Accessories',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  'boat-racing': {
    age: 30,
    categorySlug: 'boat-racing',
    categoryDescription: 'Follow the latest boat racing news, including race schedules, results, and analysis.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Boat Racing',
    featureLimit: 0,
    icon: '<DirectionsBoatIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'boat-racing',
    limit: 20,
    mantisCategories: 'boat_racing',
    metaDescription: 'Stay informed about the latest news and insights about boat racing, including events, results, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Get the Latest Boat Racing News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  'boats-watercraft': {
    age: 30,
    categorySlug: 'boats-watercraft',
    categoryDescription:
      'Learn about different boats and watercraft, including yachts, sailboats, and personal watercraft, as well as maintenance and safety tips.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Boats Watercraft',
    featureLimit: 0,
    icon: '<DirectionsBoatIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'boats-watercraft',
    limit: 20,
    mantisCategories: 'boats_watercraft',
    metaDescription: 'Get the latest news and insights about boats and watercraft, including new models, accessories, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Boats and Watercraft News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  bobsled: {
    age: 30,
    categorySlug: 'bobsled',
    categoryDescription: 'Get the latest news and updates on bobsled competitions, including the Olympics and World Cup races.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Bobsled',
    featureLimit: 0,
    icon: '<Sledding />',
    isEnabled: true,
    isFeatured: false,
    key: 'bobsled',
    limit: 20,
    mantisCategories: 'bobsled',
    metaDescription: 'Discover the latest news and insights about bobsled, including events, athletes, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about Bobsled',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  'buying-selling-cars': {
    age: 30,
    categorySlug: 'buying-selling-cars',
    categoryDescription:
      'Get expert advice on buying and selling cars, including tips for negotiating, financing, and getting the best price for your vehicle.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Buying Selling Cars',
    featureLimit: 0,
    icon: '<TimeToLeaveIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'buying-selling-cars',
    limit: 20,
    mantisCategories: 'buying_selling_cars',
    metaDescription: 'Get the latest news and insights about buying and selling cars, including tips, market trends, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Buying and Selling Cars News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  'campers-rvs': {
    age: 30,
    categorySlug: 'campers-rvs',
    categoryDescription: 'Discover the latest campers and RVs, as well as tips for camping and RVing enthusiasts, including maintenance and safety advice.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Campers Rvs',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'campers-rvs',
    limit: 20,
    mantisCategories: 'campers_rvs',
    metaDescription:
      'Stay informed about the latest news and insights about campers and RVs, including new models, accessories, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Discover the Latest Campers and RVs News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  cars: {
    age: 30,
    categorySlug: 'cars',
    categoryDescription: 'Stay up-to-date with the latest automotive news, including new model releases, concept cars, and industry trends.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Cars',
    featureLimit: 0,
    icon: '<TimeToLeaveIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'cars',
    limit: 20,
    mantisCategories: 'cars',
    metaDescription: 'Get the latest news and insights about cars, including new models, industry trends, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about the Latest Cars News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  'commercial-vehicles': {
    age: 30,
    categorySlug: 'commercial-vehicles',
    categoryDescription:
      'Learn about different types of commercial vehicles, including trucks, vans, and buses, as well as tips for buying and maintaining them.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Commercial Vehicles',
    featureLimit: 0,
    icon: '<DirectionsBus />',
    isEnabled: true,
    isFeatured: false,
    key: 'commercial-vehicles',
    limit: 20,
    mantisCategories: 'commercial_vehicles',
    metaDescription: 'Get the latest news and insights about commercial vehicles, including trucks, buses, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Commercial Vehicles News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  'electric-vehicles': {
    age: 30,
    categorySlug: 'electric-vehicles',
    categoryDescription: 'Discover the latest electric vehicles, including cars, bikes, and scooters, as well as infrastructure and charging options.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Electric Vehicles',
    featureLimit: 0,
    icon: '<ElectricCar />',
    isEnabled: true,
    isFeatured: false,
    key: 'electric-vehicles',
    limit: 20,
    mantisCategories: 'electric_vehicles',
    metaDescription:
      'Stay informed about the latest news and insights about electric vehicles, including new models, charging infrastructure, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Discover the Latest Electric Vehicles News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  minivan: {
    age: 30,
    categorySlug: 'minivan',
    categoryDescription: 'Learn about different minivans and their features, including safety ratings, fuel efficiency, and cargo space.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Mininvan',
    featureLimit: 0,
    icon: '<AirportShuttle />',
    isEnabled: true,
    isFeatured: false,
    key: 'minivan',
    limit: 20,
    mantisCategories: 'minivan',
    metaDescription: 'Get the latest news and insights about minivans, including new models, features, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Minivan News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  'motor-shows': {
    age: 30,
    categorySlug: 'motor-shows',
    categoryDescription: 'Follow the latest news and updates from motor shows around the world, including highlights, new models, and concept cars.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Motor Shows',
    featureLimit: 0,
    icon: '<SportsMotorsports />',
    isEnabled: true,
    isFeatured: false,
    key: 'motor-shows',
    limit: 20,
    mantisCategories: 'motor_shows',
    metaDescription: 'Discover the latest news and insights about motor shows, including new models, concept cars, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about the Latest Motor Shows',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  motorcycles: {
    age: 30,
    categorySlug: 'motorcycles',
    categoryDescription:
      'Discover the latest motorcycles, including sport bikes, cruisers, and touring bikes, as well as gear and equipment for motorcycle enthusiasts.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Motorcycles',
    featureLimit: 0,
    icon: '<TwoWheeler />',
    isEnabled: true,
    isFeatured: false,
    key: 'motorcycles',
    limit: 20,
    mantisCategories: 'motorcycles',
    metaDescription: 'Get the latest news and insights about motorcycles, including new models, industry trends, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Motorcycles News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  motorcycling: {
    age: 30,
    categorySlug: 'motorcycling',
    categoryDescription:
      'Get expert advice on motorcycling, including safety tips, maintenance advice, and the best routes and destinations for motorcycle trips.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Motorcycling',
    featureLimit: 0,
    icon: '<TwoWheeler />',
    isEnabled: true,
    isFeatured: false,
    key: 'motorcycling',
    limit: 20,
    mantisCategories: 'motorcycling',
    metaDescription: 'Discover the latest news and insights about motorcycling, including events, gear, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about Motorcycling News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  'offroad-vehicles': {
    age: 30,
    categorySlug: 'offroad-vehicles',
    categoryDescription:
      'Learn about different off-road vehicles, including ATVs, side-by-sides, and dirt bikes, as well as gear and equipment for off-road enthusiasts.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Offroad Vehicles',
    featureLimit: 0,
    icon: '<DirectionsCarFilled />',
    isEnabled: true,
    isFeatured: false,
    key: 'offroad-vehicles',
    limit: 20,
    mantisCategories: 'offroad_vehicles',
    metaDescription: 'Get the latest news and insights about offroad vehicles, including new models, industry trends, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about Offroad Vehicles News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  'roadside-assistance': {
    age: 30,
    categorySlug: 'roadside-assistance',
    categoryDescription:
      'Get expert advice on roadside assistance, including tips for choosing a provider, what to do in case of an emergency, and how to stay safe on the road.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Roadside Assistance',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'roadside-assistance',
    limit: 20,
    mantisCategories: 'roadside_assistance',
    metaDescription: 'Discover the latest news and insights about roadside assistance, including tips, services, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Roadside Assistance News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  'scooters-mopeds': {
    age: 30,
    categorySlug: 'scooters-mopeds',
    categoryDescription: 'Discover the latest scooters and mopeds, including electric models, as well as gear and accessories for scooter enthusiasts.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Scooters & Mopeds',
    featureLimit: 0,
    icon: '<Moped />',
    isEnabled: true,
    isFeatured: false,
    key: 'scooters-mopeds',
    limit: 20,
    mantisCategories: 'scooters_mopeds',
    metaDescription: 'Get the latest news and insights about scooters and mopeds, including new models, industry trends, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Scooters & Mopeds News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  transport: {
    age: 30,
    categorySlug: 'transport',
    categoryDescription:
      'Stay informed about different transportation options, including public transit, ride-sharing, and bike-sharing, as well as transportation policies and infrastructure.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Transport',
    featureLimit: 0,
    icon: '<EmojiTransportation />',
    isEnabled: true,
    isFeatured: false,
    key: 'transport',
    limit: 20,
    mantisCategories: 'transports',
    metaDescription:
      'Discover the latest news and insights about transport, including autonomous vehicles, public transportation, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about the Latest Transport News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  'trucks-suvs': {
    age: 30,
    categorySlug: 'trucks-suvs',
    categoryDescription:
      'Learn about different trucks and SUVs, including pickup trucks, crossovers, and SUVs, as well as safety ratings, towing capacity, and cargo space.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Trucks Suvs',
    featureLimit: 0,
    icon: '<LocalShipping />',
    isEnabled: true,
    isFeatured: false,
    key: 'trucks-suvs',
    limit: 20,
    mantisCategories: 'trucks_suvs',
    metaDescription: 'Discover the latest news and insights about trucks and SUVs, including new models, industry trends, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Trucks and SUVs News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  'vehicle-brands': {
    age: 30,
    categorySlug: 'vehicle-brands',
    categoryDescription: 'Discover different vehicle brands, including their history, models, and reputation, as well as expert reviews and ratings.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Vehicle Brands',
    featureLimit: 0,
    icon: '<TimeToLeaveIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'vehicle-brands',
    limit: 20,
    mantisCategories: 'vehicle_brands',
    metaDescription: 'Get the latest news and insights about vehicle brands, including announcements, partnerships, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about Vehicle Brands News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  'vehicle-rental': {
    age: 30,
    categorySlug: 'vehicle-rental',
    categoryDescription:
      'Get expert advice on renting vehicles, including tips for choosing a rental company, insurance options, and fees and charges to watch out for.',
    categoryImage: '',
    categoryType: 'cars-and-transport',
    displayName: 'Vehicle_Rental',
    featureLimit: 0,
    icon: '<CarRental />',
    isEnabled: true,
    isFeatured: false,
    key: 'vehicle-rental',
    limit: 20,
    mantisCategories: 'vehicle_rental',
    metaDescription: 'Get the latest news and insights about vehicle rental services, including deals, reviews, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Vehicle Rental News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'motoring',
    tags: '',
  },
  'art-design': {
    age: 30,
    categorySlug: 'art-design',
    categoryDescription:
      'Discover the world of art and design, including different art styles and movements, famous artists, and design trends and innovations.',
    categoryImage: '',
    categoryType: 'creativity-and-culture',
    displayName: 'Art Design',
    featureLimit: 0,
    icon: '<DesignServices />',
    isEnabled: true,
    isFeatured: false,
    key: 'art-design',
    limit: 20,
    mantisCategories: 'visual_art_design',
    metaDescription: 'Discover the latest news and insights about art and design, including exhibitions, trends, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about Art and Design News',
    relatedCategories: '',
    sectionSlug: 'art-and-design',
    subSectionSlug: '',
    tags: '',
  },
  'art-history': {
    age: 30,
    categorySlug: 'art-history',
    categoryDescription:
      'Learn about the history of art, including different art periods and movements, major artists and their works, and the cultural and social context of art.',
    categoryImage: '',
    categoryType: 'creativity-and-culture',
    displayName: 'Art History',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'art-history',
    limit: 20,
    mantisCategories: 'art_history',
    metaDescription: 'Get the latest news and insights about art history, including discoveries, research, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Art History News',
    relatedCategories: '',
    sectionSlug: 'art-and-design',
    subSectionSlug: '',
    tags: '',
  },
  'art-technology': {
    age: 30,
    categorySlug: 'art-technology',
    categoryDescription: 'Explore the intersection of art and technology, including new media art, digital art, and interactive installations.',
    categoryImage: '',
    categoryType: 'creativity-and-culture',
    displayName: 'Art Technology',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'art-technology',
    limit: 20,
    mantisCategories: 'art_technology',
    metaDescription: 'Discover the latest news and insights about art and technology, including digital art, AI, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about Art and Technology News',
    relatedCategories: '',
    sectionSlug: 'art-and-design',
    subSectionSlug: '',
    tags: '',
  },
  'arts-crafts': {
    age: 30,
    categorySlug: 'arts-crafts',
    categoryDescription:
      'Get inspiration and advice for arts and crafts projects, including different techniques, materials, and tools, as well as project ideas for all skill levels.',
    categoryImage: '',
    categoryType: 'creativity-and-culture',
    displayName: 'Arts Crafts',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'arts-crafts',
    limit: 20,
    mantisCategories: 'arts_crafts',
    metaDescription: 'Discover the latest news and insights about arts and crafts, including DIY projects, new techniques, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Arts and Crafts News',
    relatedCategories: '',
    sectionSlug: 'art-and-design',
    subSectionSlug: '',
    tags: '',
  },
  'body-art': {
    age: 30,
    categorySlug: 'body-art',
    categoryDescription: 'Learn about different types of body art, including tattoos, piercings, and body painting, as well as safety and aftercare advice.',
    categoryImage: '',
    categoryType: 'creativity-and-culture',
    displayName: 'Body Art',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'body-art',
    limit: 20,
    mantisCategories: 'body_art',
    metaDescription: 'Get the latest news and insights about body art, including new styles, trends, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about Body Art News',
    relatedCategories: '',
    sectionSlug: 'art-and-design',
    subSectionSlug: '',
    tags: '',
  },
  decor: {
    age: 30,
    categorySlug: 'decor',
    categoryDescription:
      'Get inspiration and advice for home decor, including different styles, colors, and trends, as well as DIY projects and decorating tips.',
    categoryImage: '',
    categoryType: 'creativity-and-culture',
    displayName: 'Interior Design & Decorating',
    featureLimit: 0,
    icon: '<Living />',
    isEnabled: true,
    isFeatured: false,
    key: 'decor',
    limit: 20,
    mantisCategories: 'interior_decorating',
    metaDescription: 'Get the latest news and insights about interior design and decorating, including new trends, products, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Interior Design and Decorating News',
    relatedCategories: '',
    sectionSlug: 'art-and-design',
    subSectionSlug: '',
    tags: '',
  },
  home: {
    age: 30,
    categorySlug: 'home',
    categoryDescription:
      'Learn about different aspects of home life, including home improvement, organisation, and cleaning, as well as tips for creating a comfortable and functional living space.',
    categoryImage: '',
    categoryType: 'creativity-and-culture',
    displayName: 'Home Furnishings',
    featureLimit: 0,
    icon: '<House />',
    isEnabled: true,
    isFeatured: false,
    key: 'home',
    limit: 20,
    mantisCategories: 'home_furnishings',
    metaDescription: 'Discover the latest news and insights about home furnishings, including new collections, designs, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about Home Furnishings News',
    relatedCategories: '',
    sectionSlug: 'art-and-design',
    subSectionSlug: '',
    tags: '',
  },
  literature: {
    age: 30,
    categorySlug: 'literature',
    categoryDescription:
      'Discover different types of literature, including fiction, non-fiction, poetry, and drama, as well as classic and contemporary works and authors.',
    categoryImage: '',
    categoryType: 'creativity-and-culture',
    displayName: 'Literature',
    featureLimit: 0,
    icon: '<Book />',
    isEnabled: true,
    isFeatured: false,
    key: 'literature',
    limit: 20,
    mantisCategories: 'books_literature',
    metaDescription: 'Get the latest news and insights about literature, including book releases, author interviews, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay up-to-date with Literature News',
    relatedCategories: '',
    sectionSlug: 'art-and-design',
    subSectionSlug: '',
    tags: '',
  },
  reading: {
    age: 30,
    categorySlug: 'reading',
    categoryDescription: 'Get inspiration and advice for reading, including book reviews, reading lists, and tips for developing a reading habit.',
    categoryImage: '',
    categoryType: 'creativity-and-culture',
    displayName: 'Reading',
    featureLimit: 0,
    icon: '<AutoStories />',
    isEnabled: true,
    isFeatured: false,
    key: 'reading',
    limit: 20,
    mantisCategories: 'reading',
    metaDescription:
      'Discover the latest news and insights about reading, including book recommendations, reading challenges, and more, with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay informed about Reading News',
    relatedCategories: '',
    sectionSlug: 'art-and-design',
    subSectionSlug: '',
    tags: '',
  },
  refurbishment: {
    age: 30,
    categorySlug: 'refurbishment',
    categoryDescription: 'Learn about home refurbishment, including different types of projects, budgeting and financing, and finding the right contractors.',
    categoryImage: '',
    categoryType: 'creativity-and-culture',
    displayName: 'Home Improvement',
    featureLimit: 0,
    icon: '<Construction />',
    isEnabled: true,
    isFeatured: false,
    key: 'refurbishment',
    limit: 20,
    mantisCategories: 'home_improvement_repair',
    metaDescription:
      'Stay up-to-date with the latest refurbishment trends and news with My News Assistant. Read about home improvement ideas and tips on our platform.',
    metaImage: '',
    metaTitle: 'Home Improvement News - Latest Refurbishment Trends',
    relatedCategories: '',
    sectionSlug: 'art-and-design',
    subSectionSlug: '',
    tags: '',
  },
  scrapbooking: {
    age: 30,
    categorySlug: 'scrapbooking',
    categoryDescription:
      'Get inspiration and advice for scrapbooking, including different techniques, materials, and tools, as well as project ideas and tips for preserving memories.',
    categoryImage: '',
    categoryType: 'creativity-and-culture',
    displayName: 'Scrap Booking',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'scrapbooking',
    limit: 20,
    mantisCategories: 'scrapbooking',
    metaDescription:
      'Discover new scrapbooking techniques and creative ideas with My News Assistant. Stay updated with the latest news and trends in scrapbooking.',
    metaImage: '',
    metaTitle: 'Scrap Booking News - Creative Ideas and Inspiration',
    relatedCategories: '',
    sectionSlug: 'art-and-design',
    subSectionSlug: '',
    tags: '',
  },
  'celebrity-gossip': {
    age: 30,
    categorySlug: 'celebrity-gossip',
    categoryDescription: 'Follow the latest celebrity gossip, including rumors, scandals, and updates on the lives of the rich and famous.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'Celebrity Gossip',
    featureLimit: 0,
    icon: '<StarIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'celebrity-gossip',
    limit: 20,
    mantisCategories: 'celebrity_fan_gossip',
    metaDescription:
      'Get the latest celebrity gossip news and updates on your favorite stars with My News Assistant. Stay in the loop with the latest celebrity scandals and rumors.',
    metaImage: '',
    metaTitle: 'Celebrity Gossip News - Latest Updates on Your Favorite Stars',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'red-carpet',
    tags: '',
  },
  'comics-animation': {
    age: 30,
    categorySlug: 'comics-animation',
    categoryDescription: 'Discover different types of comics and animation, including superhero comics, graphic novels, animated films, and cartoons.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'Comics',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'comics-animation',
    limit: 20,
    mantisCategories: 'comics_animation',
    metaDescription:
      'Stay updated with the latest news and updates on your favorite comics and animation with My News Assistant. Read about new releases, reviews, and much more.',
    metaImage: '',
    metaTitle: 'Comics News - Latest Updates on Your Favorite Comics and Animation',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: '',
    tags: '',
  },
  movies: {
    age: 30,
    categorySlug: 'movies',
    categoryDescription:
      'Stay up-to-date with the latest movie news, reviews, and recommendations, including box office updates, new releases, and award shows.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'Movies',
    featureLimit: 0,
    icon: '<LocalMovies />',
    isEnabled: true,
    isFeatured: false,
    key: 'movies',
    limit: 20,
    mantisCategories: 'movies',
    metaDescription:
      'Get the latest movie news and updates on upcoming films and trailers with My News Assistant. Stay in the loop with the latest Hollywood gossip and trends.',
    metaImage: '',
    metaTitle: 'Movie News - Latest Updates on Upcoming Films and Trailers',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'movies',
    tags: '',
  },
  music: {
    age: 30,
    categorySlug: 'music',
    categoryDescription:
      'Discover different genres of music, including rock, pop, hip-hop, and classical, as well as news and updates on your favorite artists, concerts, and festivals.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'Music',
    featureLimit: 0,
    icon: '<Audiotrack />',
    isEnabled: true,
    isFeatured: false,
    key: 'music',
    limit: 20,
    mantisCategories: 'music',
    metaDescription:
      'Stay updated with the latest news and updates on your favorite artists and music genres with My News Assistant. Read about new releases, concerts, and much more.',
    metaImage: '',
    metaTitle: 'Music News - Latest Updates on Your Favorite Artists and Genres',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'music',
    tags: '',
  },
  radio: {
    age: 30,
    categorySlug: 'radio',
    categoryDescription: 'Get the latest news and updates from the world of radio, including new programs, podcasts, and popular radio personalities.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'Radio',
    featureLimit: 0,
    icon: '<RadioOutlined />',
    isEnabled: true,
    isFeatured: false,
    key: 'radio',
    limit: 20,
    mantisCategories: 'radio',
    metaDescription:
      'Stay up-to-date with the latest news and updates on your favorite radio stations and personalities with My News Assistant. Read about new shows, interviews, and much more.',
    metaImage: '',
    metaTitle: 'Radio News - Latest Updates on Your Favorite Stations and Personalities',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'music',
    tags: '',
  },
  'show-business': {
    age: 30,
    categorySlug: 'show-business',
    categoryDescription:
      'Learn about different aspects of show business, including acting, directing, producing, and writing, as well as news and updates on the entertainment industry.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'Show Business',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'show-business',
    limit: 20,
    mantisCategories:
      'celebrity_bespoke,summer_staycation,back_to_school,whats_onsociety,social_institution,divorce,marriage,style_fashion,beauty,dating,family_parenting,babies_toddlers,baby_clothes,travel,traveling_with_kids',
    metaDescription:
      'Stay up-to-date with the latest show business news and updates on celebrities and the entertainment industry with My News Assistant. Read about celebrity gossip, fashion, travel, and much more.',
    metaImage: '',
    metaTitle: 'Show Business News - Latest Updates on Celebrities and Entertainment Industry',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'red-carpet',
    tags: '',
  },
  'shows-events': {
    age: 30,
    categorySlug: 'shows-events',
    categoryDescription:
      'Stay up-to-date with the latest shows and events, including concerts, theater performances, and art exhibitions, as well as tips for finding and buying tickets.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'Shows Events',
    featureLimit: 0,
    icon: '<Event />',
    isEnabled: true,
    isFeatured: false,
    key: 'shows-events',
    limit: 20,
    mantisCategories: 'shows_events',
    metaDescription:
      'Stay updated with the latest news and updates on upcoming shows and events with My News Assistant. Read about new releases, concerts, exhibitions, and much more.',
    metaImage: '',
    metaTitle: 'Shows Events News - Latest Updates on Upcoming Shows and Events',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: '',
    tags: '',
  },
  theatre: {
    age: 30,
    categorySlug: 'theatre',
    categoryDescription:
      'Discover different types of theater, including plays, musicals, and operas, as well as news and updates on the latest productions and actors.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'Theatre',
    featureLimit: 0,
    icon: '<TheaterComedy />',
    isEnabled: true,
    isFeatured: false,
    key: 'theatre',
    limit: 20,
    mantisCategories: 'theatre',
    metaDescription:
      'Stay updated with the latest news and updates on plays and performances with My News Assistant. Read about new productions, reviews, and much more.',
    metaImage: '',
    metaTitle: 'Theatre News - Latest Updates on Plays and Performances',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: '',
    tags: '',
  },
  television: {
    age: 30,
    categorySlug: 'television',
    categoryDescription: 'Get the latest news and updates from the world of television, including new shows, series, and popular TV personalities.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'Movies & TV',
    featureLimit: 0,
    icon: '<Tv />',
    isEnabled: true,
    isFeatured: false,
    key: 'television',
    limit: 20,
    mantisCategories: 'movies_tv',
    metaDescription:
      'Get the latest movies & TV news and updates on upcoming shows and films with My News Assistant. Stay in the loop with the latest Hollywood gossip and trends.',
    metaImage: '',
    metaTitle: 'Movies & TV News - Latest Updates on Upcoming Shows and Films',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
  },
  'grammy-awards': {
    age: 30,
    categorySlug: 'grammy-awards',
    categoryDescription: 'Stay updated on the latest news and winners from the Grammy Awards, celebrating outstanding achievements in the music industry.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'Grammy Awards',
    featureLimit: 0,
    icon: '<MusicNote />',
    isEnabled: true,
    isFeatured: false,
    key: 'grammy-awards',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on Grammy winners and memorable moments at the Grammy Awards with My News Assistant.',
    metaImage: '',
    metaTitle: 'Grammy Awards News - Latest Winners and Highlights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'awards-and-events',
    tags: '',
    concepts: 'Grammy Award',
  },
  'academy-awards': {
    age: 30,
    categorySlug: 'academy-awards',
    categoryDescription: 'Stay updated on the latest news and winners from the Academy Awards, honoring outstanding achievements in the film industry.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'Academy Awards',
    featureLimit: 0,
    icon: '<Film />',
    isEnabled: true,
    isFeatured: false,
    key: 'academy-awards',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on Academy Award winners and memorable moments at the Oscars with My News Assistant.',
    metaImage: '',
    metaTitle: 'Academy Awards News - Latest Winners and Highlights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'awards-and-events',
    tags: '',
    concepts: 'Academy Awards',
  },
  'golden-globe-awards': {
    age: 30,
    categorySlug: 'golden-globe-awards',
    categoryDescription: 'Stay updated on the latest news and winners from the Golden Globe Awards, recognizing outstanding achievements in both film and television.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'Golden Globe Awards',
    featureLimit: 0,
    icon: '<Trophy />',
    isEnabled: true,
    isFeatured: false,
    key: 'golden-globe-awards',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on Golden Globe Award winners and memorable moments with My News Assistant.',
    metaImage: '',
    metaTitle: 'Golden Globe Awards News - Latest Winners and Highlights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'awards-and-events',
    tags: '',
    concepts: 'Golden Globe Award',
  },
  'primetime-emmy-awards': {
    age: 30,
    categorySlug: 'primetime-emmy-awards',
    categoryDescription: 'Stay updated on the latest news and winners from The Primetime Emmy Awards, honoring outstanding achievements in the American television industry.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'Primetime Emmy Awards',
    featureLimit: 0,
    icon: '<Tv />',
    isEnabled: true,
    isFeatured: false,
    key: 'primetime-emmy-awards',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on Primetime Emmy Award winners and memorable moments with My News Assistant.',
    metaImage: '',
    metaTitle: 'Primetime Emmy Awards News - Latest Winners and Highlights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'awards-and-events',
    tags: '',
    concepts: 'Primetime Emmy Award',
  },
  'screen-actors-guild-awards': {
    age: 30,
    categorySlug: 'screen-actors-guild-awards',
    categoryDescription: 'Stay updated on the latest news and winners from the Screen Actors Guild Awards, recognizing outstanding achievements in acting across film and television.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'Screen Actors Guild Awards',
    featureLimit: 0,
    icon: '<Actor />',
    isEnabled: true,
    isFeatured: false,
    key: 'screen-actors-guild-awards',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on Screen Actors Guild Award winners and memorable moments with My News Assistant.',
    metaImage: '',
    metaTitle: 'Screen Actors Guild Awards News - Latest Winners and Highlights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'awards-and-events',
    tags: '',
    concepts: 'Screen Actors Guild Award',
  },
  'writers-guild-of-america-awards': {
    age: 30,
    categorySlug: 'writers-guild-of-america-awards',
    categoryDescription: 'Stay updated on the latest news and winners from the Writers Guild of America Awards, honoring outstanding achievements in writing across film, television, and new media.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'Writers Guild of America Awards',
    featureLimit: 0,
    icon: '<Pencil />',
    isEnabled: true,
    isFeatured: false,
    key: 'writers-guild-of-america-awards',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on Writers Guild of America Award winners and notable contributions in the field of writing with My News Assistant.',
    metaImage: '',
    metaTitle: 'Writers Guild of America Awards News - Latest Winners and Highlights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'awards-and-events',
    tags: '',
    concepts: 'Writers Guild of America Award',
  },
  'brit-awards': {
    age: 30,
    categorySlug: 'brit-awards',
    categoryDescription: 'Stay updated on the latest news and winners from the BRIT Awards, celebrating outstanding achievements in the British music industry.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'BRIT Awards',
    featureLimit: 0,
    icon: '<MusicNote />',
    isEnabled: true,
    isFeatured: false,
    key: 'brit-awards',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on BRIT Award winners and memorable moments with My News Assistant.',
    metaImage: '',
    metaTitle: 'BRIT Awards News - Latest Winners and Highlights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'awards-and-events',
    tags: '',
    concepts: 'Brit Awards',
  },
  'bafta-film-awards': {
    age: 30,
    categorySlug: 'bafta-film-awards',
    categoryDescription: 'Stay updated on the latest news and winners from the BAFTA Film Awards, recognizing outstanding achievements in the British and international film industry.',
    categoryImage: '',
    categoryType: 'entertainment',
    displayName: 'BAFTA Film Awards',
    featureLimit: 0,
    icon: '<Film />',
    isEnabled: true,
    isFeatured: false,
    key: 'bafta-film-awards',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on BAFTA Film Award winners and memorable moments with My News Assistant.',
    metaImage: '',
    metaTitle: 'BAFTA Film Awards News - Latest Winners and Highlights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'awards-and-events',
    tags: '',
    concepts: 'British Academy Film Awards',
  },
  'uefa-champions-league': {
    age: 30,
    categorySlug: 'uefa-champions-league',
    categoryDescription: 'Stay updated on the latest news and matches from the UEFA Champions League, the premier club competition in European football featuring top clubs from various countries.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'UEFA Champions League',
    featureLimit: 0,
    icon: '<SoccerBall />',
    isEnabled: true,
    isFeatured: false,
    key: 'uefa-champions-league',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on the UEFA Champions League, including match results, player performances, and the journey of top European clubs in the pursuit of the prestigious trophy with My News Assistant.',
    metaImage: '',
    metaTitle: 'UEFA Champions League News - Latest Matches and Football Highlights',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
    concepts: 'UEFA Champions League',
  },
  'uefa-europa-league': {
    age: 30,
    categorySlug: 'uefa-europa-league',
    categoryDescription: 'Stay updated on the latest news and matches from the UEFA Europa League, the secondary club competition in European football featuring teams from various countries competing for the title.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'UEFA Europa League',
    featureLimit: 0,
    icon: '<SoccerBall />',
    isEnabled: true,
    isFeatured: false,
    key: 'uefa-europa-league',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on the UEFA Europa League, including match results, player performances, and the journey of clubs in this exciting European football competition with My News Assistant.',
    metaImage: '',
    metaTitle: 'UEFA Europa League News - Latest Matches and Football Highlights',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
    concepts: 'UEFA Europa League',
  },
  'premier-league': {
    age: 30,
    categorySlug: 'premier-league',
    categoryDescription: 'Stay updated on the latest news and matches from the Premier League, one of the top professional football leagues in the world featuring top football clubs and talented players.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Premier League',
    featureLimit: 0,
    icon: '<SoccerBall />',
    isEnabled: true,
    isFeatured: false,
    key: 'premier-league',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on the Premier League, including match results, player performances, and standings with My News Assistant.',
    metaImage: '',
    metaTitle: 'Premier League News - Latest Matches and Football Highlights',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
    concepts: 'Premier League',
  },
  'english-football-league': {
    age: 30,
    categorySlug: 'english-football-league',
    categoryDescription: 'Stay updated on the latest news and matches from the English Football League (EFL), comprising the three divisions below the Premier League: EFL Championship, EFL League One, and EFL League Two.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'English Football League',
    featureLimit: 0,
    icon: '<SoccerBall />',
    isEnabled: true,
    isFeatured: false,
    key: 'english-football-league',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on the English Football League, including match results, player performances, and standings across the Championship, League One, and League Two with My News Assistant.',
    metaImage: '',
    metaTitle: 'English Football League News - Latest Matches and Football Highlights',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
    concepts: 'The Football League',
  },
  'arsenal-football-club': {
    age: 30,
    categorySlug: 'arsenal-football-club',
    categoryDescription: 'Follow the latest news and updates from Arsenal Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Arsenal F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'arsenal-football-club',
    limit: 20,
    mantisCategories: 'arsenalfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Arsenal F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Arsenal F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'astonvilla-football-club': {
    age: 30,
    categorySlug: 'astonvilla-football-club',
    categoryDescription:
      'Stay up-to-date with the latest news and updates from Aston Villa Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Astonvilla F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'astonvilla-football-club',
    limit: 20,
    mantisCategories: 'astonvillafc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Astonvilla F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Astonvilla F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'brentford-football-club': {
    age: 30,
    categorySlug: 'brentford-football-club',
    categoryDescription: 'Learn about Brentford Football Club, including their history, achievements, and latest news and updates.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Brentford F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'brentford-football-club',
    limit: 20,
    mantisCategories: 'brentfordfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Brentford F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Brentford F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'brighton-football-club': {
    age: 30,
    categorySlug: 'brighton-football-club',
    categoryDescription: 'Follow the latest news and updates from Brighton Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Brighton F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'brighton-football-club',
    limit: 20,
    mantisCategories: 'brightonfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Brighton F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Brighton F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'burnley-football-club': {
    age: 30,
    categorySlug: 'burnley-football-club',
    categoryDescription:
      'Stay up-to-date with the latest news and updates from Burnley Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Burnley F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'burnley-football-club',
    limit: 20,
    mantisCategories: 'burnleyfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Burnley F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Burnley F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'celtic-football-club': {
    age: 30,
    categorySlug: 'celtic-football-club',
    categoryDescription: 'Learn about Celtic Football Club, including their history, achievements, and latest news and updates.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Celtic F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'celtic-football-club',
    limit: 20,
    mantisCategories: 'celticfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Celtic F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Celtic F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'chelsea-football-club': {
    age: 30,
    categorySlug: 'chelsea-football-club',
    categoryDescription: 'Follow the latest news and updates from Chelsea Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Chelsea F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'chelsea-football-club',
    limit: 20,
    mantisCategories: 'chelseafc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Chelsea F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Chelsea F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'derby-county-football-club': {
    age: 30,
    categorySlug: 'derby-county-football-club',
    categoryDescription:
      'Stay up-to-date with the latest news and updates from Derby County Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Derby County F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'derby-county-football-club',
    limit: 20,
    mantisCategories: 'derbycountyfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Derby County F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Derby County F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'everton-football-club': {
    age: 30,
    categorySlug: 'everton-football-club',
    categoryDescription: 'Learn about Everton Football Club, including their history, achievements, and latest news and updates.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Everton F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'everton-football-club',
    limit: 20,
    mantisCategories: 'evertonfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Everton F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Everton F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'leeds-united-football-club': {
    age: 30,
    categorySlug: 'leeds-united-football-club',
    categoryDescription: 'Follow the latest news and updates from Leeds United Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Leeds United F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'leeds-united-football-club',
    limit: 20,
    mantisCategories: 'leedsunitedfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Leeds United F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Leeds United F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'leicester-football-club': {
    age: 30,
    categorySlug: 'leicester-football-club',
    categoryDescription:
      'Stay up-to-date with the latest news and updates from Leicester Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Leicester F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'leicester-football-club',
    limit: 20,
    mantisCategories: 'leicesterfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Leicester F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Leicester F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'liverpool-football-club': {
    age: 30,
    categorySlug: 'liverpool-football-club',
    categoryDescription: 'Learn about Liverpool Football Club, including their history, achievements, and latest news and updates.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Liverpool F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'liverpool-football-club',
    limit: 20,
    mantisCategories: '',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Liverpool F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Liverpool F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
    concepts: 'Liverpool F.C.',
  },
  'manchester-city-football-club': {
    age: 30,
    categorySlug: 'manchester-city-football-club',
    categoryDescription: 'Follow the latest news and updates from Manchester City Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Manchester City F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'manchester-city-football-club',
    limit: 20,
    mantisCategories: 'mancityfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Manchester City F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Manchester City F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'manchester-united-football-club': {
    age: 30,
    categorySlug: 'manchester-united-football-club',
    categoryDescription:
      'Stay up-to-date with the latest news and updates from Manchester United Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Manchester United F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'manchester-united-football-club',
    limit: 20,
    mantisCategories: 'manutdfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Manchester United F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Manchester United F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'middles-brough-football-club': {
    age: 30,
    categorySlug: 'middles-brough-football-club',
    categoryDescription: 'Learn about Middlesbrough Football Club, including their history, achievements, and latest news and updates.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Middles Brough F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'middles-brough-football-club',
    limit: 20,
    mantisCategories: 'middlesbroughfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Middles Brough F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Middles Brough F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'newcastle-united-football-club': {
    age: 30,
    categorySlug: 'newcastle-united-football-club',
    categoryDescription: 'Follow the latest news and updates from Newcastle United Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Newcastle United F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'newcastle-united-football-club',
    limit: 20,
    mantisCategories: 'newcastleunitedfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Newcastle United F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Newcastle United F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'norwich-city-football-club': {
    age: 30,
    categorySlug: 'norwich-city-football-club',
    categoryDescription:
      'Stay up-to-date with the latest news and updates from Norwich City Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Norwich City F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'norwich-city-football-club',
    limit: 20,
    mantisCategories: 'norwichcityfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Norwich City F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Norwich City F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'nottingham-forest-football-club': {
    age: 30,
    categorySlug: 'nottingham-forest-football-club',
    categoryDescription: 'Learn about Nottingham Forest Football Club, including their history, achievements, and latest news and updates.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Nottingham Forest F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'nottingham-forest-football-club',
    limit: 20,
    mantisCategories: 'nottinghamforestfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Nottingham Forest F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Nottingham Forest F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'rangers-football-club': {
    age: 30,
    categorySlug: 'rangers-football-club',
    categoryDescription: 'Follow the latest news and updates from Rangers Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Rangers F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'rangers-football-club',
    limit: 20,
    mantisCategories: 'rangersfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Rangers F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Rangers F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'southampton-football-club': {
    age: 30,
    categorySlug: 'southampton-football-club',
    categoryDescription:
      'Stay up-to-date with the latest news and updates from Southampton Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Southampton F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'southampton-football-club',
    limit: 20,
    mantisCategories: 'southamptonfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Southampton F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Southampton F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'tottenham-football-club': {
    age: 30,
    categorySlug: 'tottenham-football-club',
    categoryDescription: 'Learn about Tottenham Football Club, including their history, achievements, and latest news and updates.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Tottenham F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'tottenham-football-club',
    limit: 20,
    mantisCategories: 'tottenhamfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Tottenham F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Tottenham F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'watford-football-club': {
    age: 30,
    categorySlug: 'watford-football-club',
    categoryDescription: 'Follow the latest news and updates from Watford Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Watford F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'watford-football-club',
    limit: 20,
    mantisCategories: 'watfordfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Watford F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Watford F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'westham-united-football-club': {
    age: 30,
    categorySlug: 'westham-united-football-club',
    categoryDescription:
      'Stay up-to-date with the latest news and updates from West Ham United Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Westham United F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'westham-united-football-club',
    limit: 20,
    mantisCategories: 'westhamunitedfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Westham United F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Westham United F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'wolves-football-club': {
    age: 30,
    categorySlug: 'wolves-football-club',
    categoryDescription: 'Learn about Wolves Football Club, including their history, achievements, and latest news and updates.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Wolves F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'wolves-football-club',
    limit: 20,
    mantisCategories: 'wolvesfc',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Wolves F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Wolves F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'real-madrid-football-club': {
    age: 30,
    categorySlug: 'real-madrid-football-club',
    categoryDescription: 'Follow the latest news and updates from Real Madrid Football Club, including match results, player news, and transfer rumors.',
    categoryImage: '',
    categoryType: 'football',
    displayName: 'Real Madrid F.C.',
    featureLimit: 0,
    icon: '<SportsSoccerIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'real-madrid-football-club',
    limit: 20,
    mantisCategories: 'real_madrid',
    metaDescription:
      'Stay up-to-date with the latest news and updates on Real Madrid F.C. with My News Assistant. Read about matches, players, and transfers of your favorite football team.',
    metaImage: '',
    metaTitle: 'Real Madrid F.C. News - Latest Updates on Matches, Players, and Transfers',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    tags: '',
  },
  'LGBTQIA+': {
    age: 30,
    categorySlug: 'lgbtqia+',
    categoryDescription: 'Learn about LGBTQIA+ issues and rights, including discrimination, activism, and representation in media and politics.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'LGBTQIA+',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'LGBTQIA+',
    limit: 20,
    mantisCategories: 'gay_life',
    metaDescription:
      'Stay up-to-date with the latest news and updates on the LGBTQIA+ community with My News Assistant. Read about community events, rights, and issues affecting the community.',
    metaImage: '',
    metaTitle: 'LGBTQIA+ News - Latest Updates on Community, Rights, and Events',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'relationships',
    tags: '',
  },
  accessories: {
    age: 30,
    categorySlug: 'accessories',
    categoryDescription: 'Get inspiration and advice for accessories, including jewelry, watches, and bags, as well as fashion trends and styling tips.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Accessories',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'accessories',
    limit: 20,
    mantisCategories: 'accessories',
    metaDescription:
      'Stay up-to-date with the latest news and updates on fashion accessories with My News Assistant. Read about new trends, styles, and brands.',
    metaImage: '',
    metaTitle: 'Accessories News - Latest Updates on Fashion Accessories',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'fashion',
    tags: '',
  },
  barbecues: {
    age: 30,
    categorySlug: 'barbecues',
    categoryDescription:
      'Learn about different types of barbecues, including gas and charcoal grills, as well as recipes, techniques, and safety tips for outdoor cooking.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Barbecue',
    featureLimit: 0,
    icon: '<OutdoorGrill />',
    isEnabled: true,
    isFeatured: false,
    key: 'barbecues',
    limit: 20,
    mantisCategories: 'barbecues_grilling',
    metaDescription:
      'Stay up-to-date with the latest news and updates on barbecuing with My News Assistant. Read about new recipes, techniques, and grilling tips.',
    metaImage: '',
    metaTitle: 'Barbecue News - Latest Updates on Grilling, Recipes, and Techniques',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: '',
    tags: '',
  },
  beauty: {
    age: 30,
    categorySlug: 'beauty',
    categoryDescription:
      'Discover the latest beauty trends and products, including skincare, makeup, haircare, and fragrances, as well as tips for achieving different looks.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Beauty',
    featureLimit: 0,
    icon: '<FaceRetouchingNatural />',
    isEnabled: true,
    isFeatured: false,
    key: 'beauty',
    limit: 20,
    mantisCategories: 'beauty',
    metaDescription:
      'Stay up-to-date with the latest news and updates on beauty trends with My News Assistant. Read about makeup, skincare, haircare, and beauty products.',
    metaImage: '',
    metaTitle: 'Beauty News - Latest Updates on Makeup, Skincare, and Haircare',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: '',
    tags: '',
  },
  beverages: {
    age: 30,
    categorySlug: 'beverages',
    categoryDescription:
      'Learn about different types of beverages, including wine, beer, cocktails, and non-alcoholic drinks, as well as tasting notes and food pairings.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Beverages',
    featureLimit: 0,
    icon: '<LocalBar />',
    isEnabled: true,
    isFeatured: false,
    key: 'beverages',
    limit: 20,
    mantisCategories: 'beverages',
    metaDescription:
      'Stay up-to-date with the latest news and updates on beverages with My News Assistant. Read about new drink recipes, cocktails, and trends.',
    metaImage: '',
    metaTitle: 'Beverages News - Latest Updates on Drinks and Cocktails',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'food-and-drink',
    tags: '',
  },
  clothing: {
    age: 30,
    categorySlug: 'clothing',
    categoryDescription: 'Get inspiration and advice for clothing, including fashion trends, styling tips, and sustainable and ethical fashion choices.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Clothing',
    featureLimit: 0,
    icon: '<Checkroom />',
    isEnabled: true,
    isFeatured: false,
    key: 'clothing',
    limit: 20,
    mantisCategories: 'clothing',
    metaDescription:
      'Stay up-to-date with the latest news and updates on fashion and apparel with My News Assistant. Read about new trends, styles, and brands.',
    metaImage: '',
    metaTitle: 'Clothing News - Latest Updates on Fashion and Apparel',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'fashion',
    tags: '',
  },
  cuisines: {
    age: 30,
    categorySlug: 'cuisines',
    categoryDescription: 'Discover different types of cuisines, including regional and international dishes, as well as cooking techniques and ingredients.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Cuisines',
    featureLimit: 0,
    icon: '<Restaurant />',
    isEnabled: true,
    isFeatured: false,
    key: 'cuisines',
    limit: 20,
    mantisCategories: 'cuisines',
    metaDescription:
      'Stay up-to-date with the latest news and updates on food and cooking with My News Assistant. Read about new recipes, cooking techniques, and food trends.',
    metaImage: '',
    metaTitle: 'Cuisines News - Latest Updates on Food and Cooking',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'food-and-drink',
    tags: '',
  },
  dating: {
    age: 30,
    categorySlug: 'dating',
    categoryDescription: 'Get expert advice on dating and relationships, including tips for online dating, communication, and building healthy relationships.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Dating',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'dating',
    limit: 20,
    mantisCategories: 'dating',
    metaDescription:
      'Stay up-to-date with the latest news and updates on dating and relationships with My News Assistant. Read about new trends, tips, and advice on dating.',
    metaImage: '',
    metaTitle: 'Dating News - Latest Updates on Relationships and Romance',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'relationships',
    tags: '',
  },
  'desserts-baking': {
    age: 30,
    categorySlug: 'desserts-baking',
    categoryDescription:
      'Learn about different types of desserts and baking, including recipes, techniques, and equipment, as well as tips for decorating and presentation.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Baking',
    featureLimit: 0,
    icon: '<Cake />',
    isEnabled: true,
    isFeatured: false,
    key: 'desserts-baking',
    limit: 20,
    mantisCategories: 'desserts_baking',
    metaDescription:
      'Stay up-to-date with the latest news and updates on baking and desserts with My News Assistant. Read about new recipes, baking techniques, and trends.',
    metaImage: '',
    metaTitle: 'Baking News - Latest Updates on Desserts and Baking Techniques',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'food-and-drink',
    tags: '',
  },
  'dining-out': {
    age: 30,
    categorySlug: 'dining-out',
    categoryDescription:
      'Discover the latest trends and recommendations for dining out, including restaurant reviews, foodie destinations, and etiquette tips.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Dining out',
    featureLimit: 0,
    icon: '<Restaurant />',
    isEnabled: true,
    isFeatured: false,
    key: 'dining-out',
    limit: 20,
    mantisCategories: 'dining_out',
    metaDescription:
      'Stay up-to-date with the latest news and updates on dining out with My News Assistant. Read about new restaurants, food trends, and reviews.',
    metaImage: '',
    metaTitle: 'Dining Out News - Latest Updates on Restaurants and Food',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'food-and-drink',
    tags: '',
  },
  exercise: {
    age: 30,
    categorySlug: 'exercise',
    categoryDescription:
      'Get expert advice on exercise and fitness, including workout plans, equipment, and tips for staying motivated and achieving your goals.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Exercise',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'exercise',
    limit: 20,
    mantisCategories: 'exercise',
    metaDescription:
      'Stay up-to-date with the latest news and updates on exercise and fitness with My News Assistant. Read about new workouts, health tips, and trends.',
    metaImage: '',
    metaTitle: 'Exercise News - Latest Updates on Fitness, Workouts, and Health',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'health-fitness-nutrition',
    tags: '',
  },
  fashion: {
    age: 30,
    categorySlug: 'fashion',
    categoryDescription: 'Stay up-to-date with the latest fashion news and trends, including designer collections, street style, and fashion events.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Fashion',
    featureLimit: 0,
    icon: '<Checkroom />',
    isEnabled: true,
    isFeatured: false,
    key: 'fashion',
    limit: 20,
    mantisCategories: 'fashion_designers',
    metaDescription:
      'Stay up-to-date with the latest news and updates on fashion and designers with My News Assistant. Read about new trends, styles, and fashion events.',
    metaImage: '',
    metaTitle: 'Fashion News - Latest Updates on Fashion and Designers',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'fashion',
    tags: '',
  },
  'fashion-industry': {
    age: 30,
    categorySlug: 'fashion-industry',
    categoryDescription:
      'Learn about different aspects of the fashion industry, including design, manufacturing, and retail, as well as sustainability and social responsibility.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Fashion Industry',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'fashion-industry',
    limit: 20,
    mantisCategories: 'fashion_industry',
    metaDescription:
      'Stay updated with the latest news and trends in the fashion industry. My News Assistant brings you the latest updates and insights on fashion events, runway shows, and more.',
    metaImage: '',
    metaTitle: 'Latest Fashion Industry News and Trends',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'fashion',
    tags: '',
  },
  food: {
    age: 30,
    categorySlug: 'food',
    categoryDescription:
      'Discover different types of food, including ingredients, recipes, and cooking techniques, as well as cultural and historical context.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Food',
    featureLimit: 0,
    icon: '<LunchDining />',
    isEnabled: true,
    isFeatured: false,
    key: 'food',
    limit: 20,
    mantisCategories: 'food',
    metaDescription:
      'Find the latest news and trends in the world of food with My News Assistant. Get updates on recipes, food trends, restaurant reviews, and more.',
    metaImage: '',
    metaTitle: 'Latest Food News and Trends',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'food-and-drink',
    tags: '',
  },
  'food-allergies': {
    age: 30,
    categorySlug: 'food-allergies',
    categoryDescription: 'Get expert advice on managing food allergies and intolerances, including tips for dining out and preparing meals at home.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Food Allergies',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'food-allergies',
    limit: 20,
    mantisCategories: 'food_allergies',
    metaDescription:
      'Stay informed on the latest news and information on food allergies with My News Assistant. Get updates on food allergy symptoms, treatments, and more.',
    metaImage: '',
    metaTitle: 'Latest News and Information on Food Allergies',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: '',
    tags: '',
  },
  'food-grocery-retailers': {
    age: 30,
    categorySlug: 'food-grocery-retailers',
    categoryDescription:
      'Stay up-to-date with the latest news and updates from the food and grocery retail industry, including new products, store openings, and trends.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Grocery Retailers',
    featureLimit: 0,
    icon: '<LocalGroceryStore />',
    isEnabled: true,
    isFeatured: false,
    key: 'food-grocery-retailers',
    limit: 20,
    mantisCategories: 'food_grocery_retailers',
    metaDescription:
      'Stay updated on the latest news and updates on grocery retailers with My News Assistant. Get information on supermarket chains, online grocery shopping, and more.',
    metaImage: '',
    metaTitle: 'Latest News and Updates on Grocery Retailers',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: '',
    tags: '',
  },
  'food-industry': {
    age: 30,
    categorySlug: 'food-industry',
    categoryDescription:
      'Learn about different aspects of the food industry, including production, distribution, and marketing, as well as sustainability and safety issues.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Food Industry',
    featureLimit: 0,
    icon: '<FoodBank />',
    isEnabled: true,
    isFeatured: false,
    key: 'food-industry',
    limit: 20,
    mantisCategories: 'food_industry',
    metaDescription: 'Get the latest news and insights on the food industry with My News Assistant. Stay informed on food processing, food safety, and more.',
    metaImage: '',
    metaTitle: 'Latest News and Insights on the Food Industry',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: '',
    tags: '',
  },
  'gardening-landscaping': {
    age: 30,
    categorySlug: 'gardening-landscaping',
    categoryDescription:
      'Get inspiration and advice for gardening and landscaping, including plant selection, maintenance, and design, as well as sustainable practices.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Gardening',
    featureLimit: 0,
    icon: '<Yard />',
    isEnabled: true,
    isFeatured: false,
    key: 'gardening-landscaping',
    limit: 20,
    mantisCategories: 'gardening_landscaping',
    metaDescription:
      'Stay updated on the latest gardening news and tips with My News Assistant. Get information on gardening tools, tips on garden design, and more.',
    metaImage: '',
    metaTitle: 'Latest Gardening News and Tips',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: '',
    tags: '',
  },
  'healthy-cooking': {
    age: 30,
    categorySlug: 'healthy-cooking',
    categoryDescription:
      'Learn about different aspects of healthy cooking, including meal planning, ingredient substitutions, and cooking techniques for specific diets.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Healthy Cooking',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'healthy-cooking',
    limit: 20,
    mantisCategories: 'health_lowfat_cooking',
    metaDescription: 'Discover the best healthy cooking tips and recipes for a nutritious and delicious diet. Get inspired with My News Assistant.',
    metaImage: '',
    metaTitle: 'Healthy Cooking Tips and Recipes',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'food-and-drink',
    tags: '',
  },
  'healthy-eating': {
    age: 30,
    categorySlug: 'healthy-eating',
    categoryDescription: 'Get expert advice on healthy eating, including tips for meal planning, grocery shopping, and making healthy choices at restaurants.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Healthy Eating',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'healthy-eating',
    limit: 20,
    mantisCategories: 'healthy_eating',
    metaDescription: 'Explore the latest healthy eating habits and tips for a balanced and nourishing lifestyle. Stay informed with My News Assistant.',
    metaImage: '',
    metaTitle: 'Healthy Eating Habits and Tips',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'food-and-drink',
    tags: '',
  },
  jewelry: {
    age: 30,
    categorySlug: 'jewelry',
    categoryDescription: 'Discover different types of jewelry, including rings, necklaces, and bracelets, as well as trends and styling tips.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Jewelry',
    featureLimit: 0,
    icon: '<Diamond />',
    isEnabled: true,
    isFeatured: false,
    key: 'jewelry',
    limit: 20,
    mantisCategories: 'jewelry',
    metaDescription: 'Stay up-to-date with the latest jewelry trends and news from around the world. Find your perfect piece with My News Assistant.',
    metaImage: '',
    metaTitle: 'Latest Jewelry Trends and News',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'fashion',
    tags: '',
  },
  'kosher-food': {
    age: 30,
    categorySlug: 'kosher-food',
    categoryDescription: 'Learn about kosher food and its preparation, as well as the cultural and religious significance of kosher eating.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Kosher Food',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'kosher-food',
    limit: 20,
    mantisCategories: 'kosher_food',
    metaDescription: 'Explore the rich culture and delicious recipes of kosher food. Discover new flavors with My News Assistant.',
    metaImage: '',
    metaTitle: 'Kosher Food Recipes and Culture',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'food-and-drink',
    tags: '',
  },
  'mens-fashion': {
    age: 30,
    categorySlug: 'mens-fashion',
    categoryDescription: "Get inspiration and advice for men's fashion, including style tips, grooming advice, and trends.",
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Mens Fashion',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'mens-fashion',
    limit: 20,
    mantisCategories: 'men_s_fashion',
    metaDescription: 'Get the latest updates on mens fashion trends and styles from around the world. Stay stylish with My News Assistant.',
    metaImage: '',
    metaTitle: 'Latest Mens Fashion Trends and Styles',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'fashion',
    tags: '',
  },
  'mens-health': {
    age: 30,
    categorySlug: 'mens-health',
    categoryDescription: "Stay up-to-date with the latest news and advice on men's health issues, including fitness, nutrition, and mental health.",
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Mens Health',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'mens-health',
    limit: 20,
    mantisCategories: 'mens_health',
    metaDescription: 'Stay healthy and fit with the latest mens health tips and advice. Get expert insights with My News Assistant.',
    metaImage: '',
    metaTitle: 'Mens Health Tips and Advice',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'health-fitness-nutrition',
    tags: '',
  },
  nutrition: {
    age: 30,
    categorySlug: 'nutrition',
    categoryDescription:
      'Discover different aspects of nutrition, including healthy eating, dietary supplements, and the role of nutrition in disease prevention and management.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Nutrition',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'nutrition',
    limit: 20,
    mantisCategories: 'nutrition',
    metaDescription: 'Stay up-to-date with the latest nutrition news and tips for a healthy and balanced diet. Get informed with My News Assistant.',
    metaImage: '',
    metaTitle: 'Latest Nutrition News and Tips',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'health-fitness-nutrition',
    tags: '',
  },
  sex: {
    age: 30,
    categorySlug: 'sex',
    categoryDescription: 'Get expert advice on sexual health and relationships, including tips for communication, consent, and safe sex practices.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Sex',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'sex',
    limit: 20,
    mantisCategories: 'sex',
    metaDescription: 'Stay informed with the latest sex news and advice for a healthy and satisfying sex life. Get expert insights with My News Assistant.',
    metaImage: '',
    metaTitle: 'Latest Sex News and Advice',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'relationships',
    tags: '',
  },
  sexuality: {
    age: 30,
    categorySlug: 'sexuality',
    categoryDescription: 'Learn about different aspects of sexuality, including sexual orientation, gender identity, and the history of LGBTQIA+ rights.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Sexuality',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'sexuality',
    limit: 20,
    mantisCategories: 'sexuality',
    metaDescription: 'Discover new perspectives and insights on sexuality and relationships with My News Assistant. Expand your horizons today.',
    metaImage: '',
    metaTitle: 'Explore Your Sexuality with My News Assistant',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'relationships',
    tags: '',
  },
  'sports-medicine': {
    age: 30,
    categorySlug: 'sports-medicine',
    categoryDescription:
      'Stay up-to-date with the latest news and advice on sports medicine, including injury prevention and treatment, as well as performance enhancement.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Sports Medicine',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'sports-medicine',
    limit: 20,
    mantisCategories: 'sports_medicine',
    metaDescription:
      'Stay up-to-date with the latest sports medicine news and insights for a safe and healthy athletic lifestyle. Get informed with My News Assistant.',
    metaImage: '',
    metaTitle: 'Sports Medicine News and Insights',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'health-fitness-nutrition',
    tags: '',
  },
  swimwear: {
    age: 30,
    categorySlug: 'swimwear',
    categoryDescription: 'Get inspiration and advice for swimwear, including trends, styling tips, and sustainable and ethical choices.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Swimwear',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'swimwear',
    limit: 20,
    mantisCategories: 'swimwear',
    metaDescription: 'Find the perfect swimsuit and stay stylish with the latest swimwear trends and advice from My News Assistant. Get inspired today.',
    metaImage: '',
    metaTitle: 'Stay Stylish with the Latest Swimwear Trends',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'fashion',
    tags: '',
  },
  tanning: {
    age: 30,
    categorySlug: 'tanning',
    categoryDescription: 'Learn about different types of tanning, including indoor and outdoor tanning, as well as safety tips and product recommendations.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Tanning',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'tanning',
    limit: 20,
    mantisCategories: 'tanning',
    metaDescription: 'Get expert tips and advice on tanning and sun safety for a perfect and healthy tan. Stay informed with My News Assistant.',
    metaImage: '',
    metaTitle: 'Achieve a Perfect Tan with My News Assistant',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: '',
    tags: '',
  },
  underwear: {
    age: 30,
    categorySlug: 'underwear',
    categoryDescription: 'Discover different types of underwear, including briefs, boxers, and lingerie, as well as trends and styling tips.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Underwear',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'underwear',
    limit: 20,
    mantisCategories: 'underwear',
    metaDescription: 'Find the perfect underwear for any occasion with the latest trends and styles from My News Assistant. Stay stylish and comfortable.',
    metaImage: '',
    metaTitle: 'Explore the Latest Underwear Trends and Styles',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'fashion',
    tags: '',
  },
  vegan: {
    age: 30,
    categorySlug: 'vegan',
    categoryDescription: 'Get inspiration and advice for vegan living, including recipes, tips for dining out, and ethical and environmental considerations.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Vegan',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'vegan',
    limit: 20,
    mantisCategories: 'vegan',
    metaDescription: 'Discover the latest tips and recipes for a healthy and sustainable vegan lifestyle. Get inspired with My News Assistant.',
    metaImage: '',
    metaTitle: 'Vegan Lifestyle Tips and Recipes',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'food-and-drink',
    tags: '',
  },
  vegetarian: {
    age: 30,
    categorySlug: 'vegetarian',
    categoryDescription: 'Learn about vegetarianism and its benefits, as well as recipes, dining out tips, and ethical and environmental considerations.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Vegetarian',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'vegetarian',
    limit: 20,
    mantisCategories: 'vegetarian',
    metaDescription: 'Explore the latest vegetarian recipes and lifestyle tips for a healthy and sustainable diet. Get informed with My News Assistant.',
    metaImage: '',
    metaTitle: 'Vegetarian Recipes and Lifestyle Tips',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'food-and-drink',
    tags: '',
  },
  'weight-loss': {
    age: 30,
    categorySlug: 'weight-loss',
    categoryDescription:
      'Get expert advice on weight loss and management, including diet and exercise plans, as well as tips for motivation and achieving long-term success.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Weight Loss',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'weight-loss',
    limit: 20,
    mantisCategories: 'weight_loss',
    metaDescription:
      'Discover the most effective weight loss tips and strategies for a healthy and sustainable lifestyle. Get expert insights with My News Assistant.',
    metaImage: '',
    metaTitle: 'Effective Weight Loss Tips and Strategies',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'health-fitness-nutrition',
    tags: '',
  },
  'womens-health': {
    age: 30,
    categorySlug: 'womens-health',
    categoryDescription:
      "Stay up-to-date with the latest news and advice on women's health issues, including reproductive health, nutrition, and mental health.",
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Womens Health',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'womens-health',
    limit: 20,
    mantisCategories: 'womens_health',
    metaDescription: 'Stay healthy and informed with the latest womens health tips and advice. Get expert insights with My News Assistant.',
    metaImage: '',
    metaTitle: 'Womens Health Tips and Advice',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'health-fitness-nutrition',
    tags: '',
  },
  'deals': {
    age: 30,
    categorySlug: 'deals-and-reviews',
    categoryDescription: 'Stay updated with the best deals and insightful reviews of various products. Explore the latest offers across different categories and get valuable insights into the products you love.',
    categoryImage: '',
    categoryType: 'life-and-style',
    displayName: 'Deals And Reviews',
    featureLimit: 0,
    icon: '<Diamond />',
    isEnabled: true,
    isFeatured: false,
    key: 'deals-and-reviews',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Explore the latest news and updates on deals, promotions, and product reviews. From luxury fashion to premium travel, My News Assistant keeps you informed about the best offers.',
    metaImage: '',
    metaTitle: 'Latest Deals, Offers, and Product Reviews',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: '',
    tags: 'articleSubType-affiliate',
    excludeTags: 'articleSubType-skimlinksSubTypeUpdate',
  },
  'international-news': {
    age: 30,
    categorySlug: 'international-news',
    categoryDescription: 'Get the latest news and updates from around the world, including breaking news, politics, and social issues.',
    categoryImage: '',
    categoryType: 'news',
    displayName: 'World News',
    featureLimit: 0,
    icon: '<Language />',
    isEnabled: true,
    isFeatured: false,
    key: 'international-news',
    limit: 20,
    mantisCategories: 'international_news',
    metaDescription: 'Stay up-to-date with the latest world news and current events from around the globe. Get informed with My News Assistant.',
    metaImage: '',
    metaTitle: 'Latest World News and Current Events',
    relatedCategories: '',
    sectionSlug: 'news',
    subSectionSlug: '',
    tags: '',
  },
  'local-news': {
    age: 30,
    categorySlug: 'local-news',
    categoryDescription: 'Stay informed about the latest news and events in your local community, including politics, business, and community events.',
    categoryImage: '',
    categoryType: 'news',
    displayName: 'Local News',
    featureLimit: 0,
    icon: '<Newspaper />',
    isEnabled: true,
    isFeatured: false,
    key: 'local-news',
    limit: 20,
    mantisCategories: 'local_news',
    metaDescription: 'Stay informed with the latest local news headlines and stories from your community. Get connected with My News Assistant.',
    metaImage: '',
    metaTitle: 'Get the Latest Local News Headlines',
    relatedCategories: '',
    sectionSlug: 'news',
    subSectionSlug: '',
    tags: '',
  },
  'national-news': {
    age: 30,
    categorySlug: 'national-news',
    categoryDescription: 'Stay up-to-date with the latest news and events in your country, including politics, business, and cultural events.',
    categoryImage: '',
    categoryType: 'news',
    displayName: 'UK News',
    featureLimit: 0,
    icon: '<Newspaper />',
    isEnabled: true,
    isFeatured: false,
    key: 'national-news',
    limit: 20,
    mantisCategories: 'national_news',
    metaDescription: 'Get the latest UK news and updates on politics, business, sports, and more. Stay informed with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest UK News',
    relatedCategories: '',
    sectionSlug: 'news',
    subSectionSlug: '',
    tags: '',
  },
  'tech-news': {
    age: 30,
    categorySlug: 'tech-news',
    categoryDescription: 'Get the latest news and updates on technology, including new products, innovations, and trends in the tech industry.',
    categoryImage: '',
    categoryType: 'news',
    displayName: 'Tech News',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'tech-news',
    limit: 20,
    mantisCategories: 'tech_bespoke',
    metaDescription: 'Stay up-to-date with the latest tech news and updates on gadgets, software, and more. Get informed with My News Assistant.',
    metaImage: '',
    metaTitle: 'Latest Tech News and Updates',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  'adult-education': {
    age: 30,
    categorySlug: 'adult-education',
    categoryDescription: 'Learn about different types of adult education, including degree programs, vocational training, and personal enrichment courses.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Adult Education',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'adult-education',
    limit: 20,
    mantisCategories: 'adult_education',
    metaDescription: 'Discover new courses and opportunities for adult education and lifelong learning. Expand your horizons with My News Assistant.',
    metaImage: '',
    metaTitle: 'Adult Education Courses and Opportunities',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'education',
    tags: '',
  },
  'alumni-reunions': {
    age: 30,
    categorySlug: 'alumni-reunions',
    categoryDescription:
      'Stay connected with your alumni community by learning about upcoming reunions and events, as well as networking and mentorship opportunities.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Alumni Reunions',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'alumni-reunions',
    limit: 20,
    mantisCategories: 'alumni_reunions',
    metaDescription: 'Connect with fellow alumni and attend networking events and reunions. Stay connected with My News Assistant.',
    metaImage: '',
    metaTitle: 'Alumni Reunions and Networking Events',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'education',
    tags: '',
  },
  biology: {
    age: 30,
    categorySlug: 'biology',
    categoryDescription:
      'Discover different aspects of biology, including genetics, evolution, and ecology, as well as the latest research and scientific discoveries.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Biology',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'biology',
    limit: 20,
    mantisCategories: 'biology',
    metaDescription: 'Discover the latest research and developments in the field of biology. Get informed and inspired with My News Assistant.',
    metaImage: '',
    metaTitle: 'Explore the Fascinating World of Biology',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'science',
    tags: '',
  },
  biomedical: {
    age: 30,
    categorySlug: 'biomedical',
    categoryDescription: 'Stay up-to-date with the latest news and research on biomedical science, including new treatments and technologies.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Biomedical',
    featureLimit: 0,
    icon: '<BiotechRounded />',
    isEnabled: true,
    isFeatured: false,
    key: 'biomedical',
    limit: 20,
    mantisCategories: 'biomedical',
    metaDescription: 'Stay informed with the latest developments in biomedical research and healthcare. Get expert insights with My News Assistant.',
    metaImage: '',
    metaTitle: 'Explore the Latest Developments in Biomedical Research',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'science',
    tags: '',
  },
  chemistry: {
    age: 30,
    categorySlug: 'chemistry',
    categoryDescription:
      'Learn about different aspects of chemistry, including chemical reactions, materials science, and biochemistry, as well as the latest research and scientific discoveries.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Chemistry',
    featureLimit: 0,
    icon: '<Science />',
    isEnabled: true,
    isFeatured: false,
    key: 'chemistry',
    limit: 20,
    mantisCategories: 'chemistry',
    metaDescription: 'Explore the latest research and developments in the field of chemistry. Get informed and inspired with My News Assistant.',
    metaImage: '',
    metaTitle: 'Discover the Fascinating World of Chemistry',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'science',
    tags: '',
  },
  'computer-science': {
    age: 30,
    categorySlug: 'computer-science',
    categoryDescription: 'Get the latest news and updates on computer science, including new technologies, software development, and cybersecurity.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Computer Science',
    featureLimit: 0,
    icon: '<Computer />',
    isEnabled: true,
    isFeatured: false,
    key: 'computer-science',
    limit: 20,
    mantisCategories: 'computer_science',
    metaDescription: 'Stay up-to-date with the latest news and insights on computer science and technology. Get informed with My News Assistant.',
    metaImage: '',
    metaTitle: 'Latest News and Insights on Computer Science',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'science',
    tags: '',
  },
  'distance-learning': {
    age: 30,
    categorySlug: 'distance-learning',
    categoryDescription:
      'Learn about different types of distance learning, including online courses, webinars, and remote degree programs, as well as tips for success.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Distance Learning',
    featureLimit: 0,
    icon: '<SocialDistance />',
    isEnabled: true,
    isFeatured: false,
    key: 'distance-learning',
    limit: 20,
    mantisCategories: 'distance_learning',
    metaDescription: 'Discover new courses and opportunities for distance learning and online education. Expand your horizons with My News Assistant.',
    metaImage: '',
    metaTitle: 'Distance Learning Courses and Opportunities',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'education',
    tags: '',
  },
  ecology: {
    age: 30,
    categorySlug: 'ecology',
    categoryDescription:
      'Discover different aspects of ecology, including conservation, biodiversity, and ecosystem management, as well as the latest research and scientific discoveries.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Ecology',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'ecology',
    limit: 20,
    mantisCategories: 'ecology',
    metaDescription: 'Explore the latest research and developments in the field of ecology. Get informed and inspired with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Ecology News and Research',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'science',
    tags: '',
  },
  engineering: {
    age: 30,
    categorySlug: 'engineering',
    categoryDescription:
      'Stay up-to-date with the latest news and research on engineering, including new technologies, construction projects, and innovations.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Engineering',
    featureLimit: 0,
    icon: '<Engineering />',
    isEnabled: true,
    isFeatured: false,
    key: 'engineering',
    limit: 20,
    mantisCategories: 'engineering',
    metaDescription: 'Stay up-to-date with the latest news and insights on engineering and technology. Get informed with My News Assistant.',
    metaImage: '',
    metaTitle: 'Latest News and Insights on Engineering',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'science',
    tags: '',
  },
  'english-as-a-second-language': {
    age: 30,
    categorySlug: 'english-as-a-second-language',
    categoryDescription:
      'Learn about different aspects of learning English as a second language, including language acquisition, cultural immersion, and language proficiency tests.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'English as a Second Language',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'english-as-a-second-language',
    limit: 20,
    mantisCategories: 'english_as_a_second_language',
    metaDescription: 'Explore a variety of resources to improve your English skills as a second language learner. Get expert guidance with My News Assistant.',
    metaImage: '',
    metaTitle: 'Improve Your English Skills with ESL Resources',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'education',
    tags: '',
  },
  geography: {
    age: 30,
    categorySlug: 'geography',
    categoryDescription:
      'Discover different aspects of geography, including physical and cultural geography, as well as the latest research and geographic information systems (GIS) technology.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Geography',
    featureLimit: 0,
    icon: '<Map />',
    isEnabled: true,
    isFeatured: false,
    key: 'geography',
    limit: 20,
    mantisCategories: 'geography',
    metaDescription: 'Discover the latest research and developments in the field of geography. Get informed and inspired with My News Assistant.',
    metaImage: '',
    metaTitle: 'Explore the Fascinating World of Geography',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'science',
    tags: '',
  },
  geology: {
    age: 30,
    categorySlug: 'geology',
    categoryDescription:
      'Learn about different aspects of geology, including mineralogy, paleontology, and tectonics, as well as the latest research and scientific discoveries.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Geology',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'geology',
    limit: 20,
    mantisCategories: 'geology',
    metaDescription: 'Explore the latest research and developments in the field of geology. Get informed and inspired with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Geology News and Research',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'science',
    tags: '',
  },
  'grade-school': {
    age: 30,
    categorySlug: 'grade-school',
    categoryDescription:
      'Get expert advice and resources for grade school education, including teaching strategies, curriculum standards, and child development.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Grade School',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'grade-school',
    limit: 20,
    mantisCategories: 'grade_school',
    metaDescription: 'Find resources and tips to help your child succeed in grade school. Get expert guidance and advice with My News Assistant.',
    metaImage: '',
    metaTitle: 'Help Your Child Succeed in Grade School',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'education',
    tags: '',
  },
  'graduate-school': {
    age: 30,
    categorySlug: 'graduate-school',
    categoryDescription:
      "Learn about different types of graduate school programs, including master's and doctoral degrees, as well as admissions requirements and career opportunities.",
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Graduate School',
    featureLimit: 0,
    icon: '<School />',
    isEnabled: true,
    isFeatured: false,
    key: 'graduate-school',
    limit: 20,
    mantisCategories: 'graduate_school',
    metaDescription: 'Find resources and tips to help you succeed in graduate school. Get expert guidance and advice with My News Assistant.',
    metaImage: '',
    metaTitle: 'Get Ahead in Graduate School with My News Assistant',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'education',
    tags: '',
  },
  'green-solutions': {
    age: 30,
    categorySlug: 'green-solutions',
    categoryDescription:
      'Discover different types of green solutions, including renewable energy, sustainable agriculture, and waste reduction, as well as tips for sustainable living.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Green Solutions',
    featureLimit: 0,
    icon: '<Autorenew />',
    isEnabled: true,
    isFeatured: false,
    key: 'green-solutions',
    limit: 20,
    mantisCategories: 'green_solutions',
    metaDescription: 'Explore green solutions and sustainable living options to help protect the planet. Get informed and inspired with My News Assistant.',
    metaImage: '',
    metaTitle: 'Discover Green Solutions and Sustainable Living',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'science',
    tags: '',
  },
  'high-school': {
    age: 30,
    categorySlug: 'high-school',
    categoryDescription: 'Get the best deals and discounts on Black Friday, including tips for shopping smart and maximizing your savings.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'High School',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'high-school',
    limit: 20,
    mantisCategories: 'high_school',
    metaDescription: 'Find resources and tips to help your child succeed in high school. Get expert guidance and advice with My News Assistant.',
    metaImage: '',
    metaTitle: 'Help Your Child Succeed in High School',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'education',
    tags: '',
  },
  homeschooling: {
    age: 30,
    categorySlug: 'homeschooling',
    categoryDescription:
      'Learn about different aspects of homeschooling, including curriculum options, socialisation, and legal requirements, as well as tips for success.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Home Schooling',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'homeschooling',
    limit: 20,
    mantisCategories: 'homeschooling',
    metaDescription: 'Discover a variety of resources and options for home schooling. Get informed and inspired with My News Assistant.',
    metaImage: '',
    metaTitle: 'Explore Home Schooling Resources and Options',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'education',
    tags: '',
  },
  'homework-study-tips': {
    age: 30,
    categorySlug: 'homework-study-tips',
    categoryDescription:
      'Get expert advice and resources for homework and study tips, including time management, organisation, and effective learning strategies.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Homework Study Tips',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'homework-study-tips',
    limit: 20,
    mantisCategories: 'homework_study_tips',
    metaDescription: 'Discover a variety of study tips and homework resources to help you succeed. Get expert guidance and advice with My News Assistant.',
    metaImage: '',
    metaTitle: 'Find the Best Study Tips and Homework Resources',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'education',
    tags: '',
  },
  'language-learning': {
    age: 30,
    categorySlug: 'language-learning',
    categoryDescription:
      'Discover different types of language learning, including immersion programs, language classes, and online courses, as well as tips for language acquisition and proficiency.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Language Learning',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'language-learning',
    limit: 20,
    mantisCategories: 'language_learning',
    metaDescription: 'Explore a variety of resources and tips to help you learn a new language. Get informed and inspired with My News Assistant.',
    metaImage: '',
    metaTitle: 'Discover the Best Language Learning Resources',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'education',
    tags: '',
  },
  mathematics: {
    age: 30,
    categorySlug: 'mathematics',
    categoryDescription:
      'Learn about different aspects of mathematics, including algebra, calculus, and geometry, as well as the latest research and mathematical modeling.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Mathematics',
    featureLimit: 0,
    icon: '<CalculateIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'mathematics',
    limit: 20,
    mantisCategories: 'mathematics',
    metaDescription: 'Explore the latest research and developments in the field of mathematics. Get informed and inspired with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Mathematics News and Research',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'education',
    tags: '',
  },
  medicine: {
    age: 30,
    categorySlug: 'medicine',
    categoryDescription:
      'Stay up-to-date with the latest news and research on medicine, including new treatments and technologies, as well as healthcare policy and ethics.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Medicine',
    featureLimit: 0,
    icon: '<Medication />',
    isEnabled: true,
    isFeatured: false,
    key: 'medicine',
    limit: 20,
    mantisCategories: 'medicine',
    metaDescription: 'Stay informed with the latest developments in medicine and healthcare. Get expert insights with My News Assistant.',
    metaImage: '',
    metaTitle: 'Explore the Latest Developments in Medicine and Healthcare',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'science',
    tags: '',
  },
  physics: {
    age: 30,
    categorySlug: 'physics',
    categoryDescription:
      'Discover different aspects of physics, including mechanics, thermodynamics, and quantum physics, as well as the latest research and scientific discoveries.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Physics',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'physics',
    limit: 20,
    mantisCategories: 'physics',
    metaDescription: 'Explore the latest research and developments in the field of physics. Get informed and inspired with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Physics News and Research',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'science',
    tags: '',
  },
  school: {
    age: 30,
    categorySlug: 'school',
    categoryDescription: 'Get expert advice and resources for school education, including teaching strategies, curriculum standards, and child development.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'School',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'school',
    limit: 20,
    mantisCategories: 'school',
    metaDescription: 'Explore the latest news and trends in the field of education. Get informed and inspired with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Education News and Trends',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: '',
    tags: '',
  },
  'social-science': {
    age: 30,
    categorySlug: 'social-science',
    categoryDescription:
      'Learn about different aspects of social science, including sociology, psychology, and anthropology, as well as the latest research and social issues.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Social Science',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'social-science',
    limit: 20,
    mantisCategories: 'social_science',
    metaDescription: 'Explore the latest research and developments in the field of social science. Get informed and inspired with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Social Science News and Research',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'science',
    tags: '',
  },
  'special-education': {
    age: 30,
    categorySlug: 'special-education',
    categoryDescription:
      'Get expert advice and resources for special education, including teaching strategies, accommodations, and support services, as well as disability rights and advocacy.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Special Education',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'special-education',
    limit: 20,
    mantisCategories: 'special_education',
    metaDescription: 'Stay informed with the latest news and resources for special education. Get expert guidance and advice with My News Assistant.',
    metaImage: '',
    metaTitle: 'Discover the Latest News and Resources for Special Education',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'education',
    tags: '',
  },
  'studying-business': {
    age: 30,
    categorySlug: 'studying-business',
    categoryDescription:
      'Learn about different aspects of studying business, including entrepreneurship, finance, and management, as well as career opportunities and networking.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Studying Business',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'studying-business',
    limit: 20,
    mantisCategories: 'studying_business',
    metaDescription: 'Explore the latest news and trends in the field of business. Get informed and inspired with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Business News and Trends',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'education',
    tags: '',
  },
  'teaching-classroom-resources': {
    age: 30,
    categorySlug: 'teaching-classroom-resources',
    categoryDescription:
      'Discover different types of teaching resources, including lesson plans, educational technology, and professional development opportunities, as well as teaching strategies for different subjects and age groups.',
    categoryImage: '',
    categoryType: 'science-and-education',
    displayName: 'Teaching Resources',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'teaching-classroom-resources',
    limit: 20,
    mantisCategories: 'teaching_classroom_resources',
    metaDescription: 'Explore a variety of teaching resources and tips to help you succeed. Get expert guidance and advice with My News Assistant.',
    metaImage: '',
    metaTitle: 'Discover the Best Teaching Resources and Tips',
    relatedCategories: '',
    sectionSlug: 'science-and-education',
    subSectionSlug: 'education',
    tags: '',
  },
  archery: {
    age: 30,
    categorySlug: 'archery',
    categoryDescription:
      'Learn about different aspects of archery, including equipment, techniques, and competitions, as well as tips for beginners and advanced archers.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Archery',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'archery',
    limit: 20,
    mantisCategories: 'archery',
    metaDescription: 'Stay up-to-date with the latest news and insights on archery. Find expert tips and advice to improve your skills with My News Assistant.',
    metaImage: '',
    metaTitle: 'Get the Latest News and Insights on Archery',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  'auto-racing': {
    age: 30,
    categorySlug: 'auto-racing',
    categoryDescription:
      'Stay up-to-date with the latest news and updates on auto racing, including Formula 1, NASCAR, and other motorsports events, as well as driver profiles and race analysis.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Racing',
    featureLimit: 0,
    icon: '<SportsMotorsports />',
    isEnabled: true,
    isFeatured: false,
    key: 'auto-racing',
    limit: 20,
    mantisCategories: 'auto_racing',
    metaDescription: 'Explore the latest news and results from the world of auto racing. Get insights and analysis from experts with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Auto Racing News and Results',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  badminton: {
    age: 30,
    categorySlug: 'badminton',
    categoryDescription:
      'Discover different aspects of badminton, including equipment, techniques, and competitions, as well as tips for beginners and advanced players.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Badminton',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'badminton',
    limit: 20,
    mantisCategories: 'badminton',
    metaDescription: 'Explore the latest news and results from the world of badminton. Get insights and analysis from experts with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Badminton News and Results',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  baseball: {
    age: 30,
    categorySlug: 'baseball',
    categoryDescription:
      'Get the latest news and updates on baseball, including MLB, college baseball, and other leagues, as well as player profiles and game analysis.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Baseball',
    featureLimit: 0,
    icon: '<SportsBaseball />',
    isEnabled: true,
    isFeatured: false,
    key: 'baseball',
    limit: 20,
    mantisCategories: 'baseball',
    metaDescription:
      'Stay up-to-date with the latest news and insights on baseball. Find expert tips and advice to improve your skills with My News Assistant.',
    metaImage: '',
    metaTitle: 'Get the Latest News and Insights on Baseball',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  basketball: {
    age: 30,
    categorySlug: 'basketball',
    categoryDescription:
      'Stay up-to-date with the latest news and updates on basketball, including the NBA, college basketball, and other leagues, as well as player profiles and game analysis.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Basketball',
    featureLimit: 0,
    icon: '<SportsBaseball />',
    isEnabled: true,
    isFeatured: false,
    key: 'basketball',
    limit: 20,
    mantisCategories: 'basketball',
    metaDescription: 'Explore the latest news and results from the world of basketball. Get insights and analysis from experts with My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Basketball News and Results',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: '',
    tags: '',
  },
  cycling: {
    age: 30,
    categorySlug: 'cycling',
    categoryDescription:
      'Learn about different aspects of cycling, including road cycling, mountain biking, and competitive cycling, as well as equipment, techniques, and tips for beginners and advanced riders.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Cycling',
    featureLimit: 0,
    icon: '<DirectionsBikeIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'cycling',
    limit: 20,
    mantisCategories: 'bicycling',
    metaDescription: 'Stay up-to-date with the latest news and insights on cycling. Find expert tips and advice to improve your skills with My News Assistant.',
    metaImage: '',
    metaTitle: 'Get the Latest News and Insights on Cycling',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  billiards: {
    age: 30,
    categorySlug: 'billiards',
    categoryDescription:
      'Discover different aspects of billiards, including equipment, techniques, and competitions, as well as tips for beginners and advanced players.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Billiards',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'billiards',
    limit: 20,
    mantisCategories: 'billiards',
    metaDescription:
      'Stay updated with the latest news, scores, and highlights of Billiards. Follow My News Assistant for breaking news, interviews, and more!',
    metaImage: '',
    metaTitle: 'Latest News and Updates on Billiards',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  bodybuilding: {
    age: 30,
    categorySlug: 'bodybuilding',
    categoryDescription:
      'Get expert advice on bodybuilding and strength training, including workout plans and nutrition tips, as well as information on competitions and events.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Body Building',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'bodybuilding',
    limit: 20,
    mantisCategories: 'bodybuilding',
    metaDescription: 'Find out about the latest trends and techniques in bodybuilding. Follow My News Assistant for the latest news, interviews, and more!',
    metaImage: '',
    metaTitle: 'Get the Latest Body Building News and Updates',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  bowling: {
    age: 30,
    categorySlug: 'bowling',
    categoryDescription:
      'Learn about different aspects of bowling, including equipment, techniques, and competitions, as well as tips for beginners and advanced players.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Bowling',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'bowling',
    limit: 20,
    mantisCategories: 'bowling',
    metaDescription: 'Get the latest updates on Bowling, including scores, schedules, and interviews. Follow My News Assistant for breaking news and analysis!',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Bowling News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  boxing: {
    age: 30,
    categorySlug: 'boxing',
    categoryDescription:
      'Stay up-to-date with the latest news and updates on boxing, including professional and amateur fights, as well as fighter profiles and analysis.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Boxing',
    featureLimit: 0,
    icon: '<SportsMma />',
    isEnabled: true,
    isFeatured: false,
    key: 'boxing',
    limit: 20,
    mantisCategories: 'boxing',
    metaDescription: 'Stay up-to-date with the latest news, interviews, and scores on Boxing. Follow My News Assistant for all the latest updates!',
    metaImage: '',
    metaTitle: 'All the Latest News and Updates on Boxing',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  'canoeing-kayaking': {
    age: 30,
    categorySlug: 'canoeing-kayaking',
    categoryDescription:
      'Discover different aspects of canoeing and kayaking, including equipment, techniques, and competitions, as well as tips for beginners and advanced paddlers.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Canoeing & Kayaking',
    featureLimit: 0,
    icon: '<Kayaking />',
    isEnabled: true,
    isFeatured: false,
    key: 'canoeing-kayaking',
    limit: 20,
    mantisCategories: 'canoeing_kayaking',
    metaDescription: 'Find out about the latest news, events, and interviews in Canoeing & Kayaking. Follow My News Assistant for all the latest updates!',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Canoeing & Kayaking News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  cheerleading: {
    age: 30,
    categorySlug: 'cheerleading',
    categoryDescription:
      'Get expert advice on cheerleading, including techniques, choreography, and competition preparation, as well as safety tips and injury prevention.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Cheerleading',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'cheerleading',
    limit: 20,
    mantisCategories: 'cheerleading',
    metaDescription: 'Stay up-to-date with the latest news, events, and interviews in Cheerleading. Follow My News Assistant for all the latest updates!',
    metaImage: '',
    metaTitle: 'Get the Latest Cheerleading News and Updates',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  climbing: {
    age: 30,
    categorySlug: 'climbing',
    categoryDescription:
      'Learn about different aspects of climbing, including rock climbing, mountaineering, and bouldering, as well as equipment, techniques, and safety tips.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Climbing',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'climbing',
    limit: 20,
    mantisCategories: 'climbing',
    metaDescription: 'Get the latest news, events, and interviews in Climbing. Follow My News Assistant for all the latest updates on Climbing!',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest Climbing News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  cricket: {
    age: 30,
    categorySlug: 'cricket',
    categoryDescription:
      'Stay up-to-date with the latest news and updates on cricket, including international and domestic matches, as well as player profiles and game analysis.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Cricket',
    featureLimit: 0,
    icon: '<SportsCricket />',
    isEnabled: true,
    isFeatured: false,
    key: 'cricket',
    limit: 20,
    mantisCategories: 'cricket',
    metaDescription:
      'Stay up-to-date with the latest news, scores, and highlights of Cricket. Follow My News Assistant for breaking news, interviews, and more!',
    metaImage: '',
    metaTitle: 'Get the Latest Cricket News and Updates',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  curling: {
    age: 30,
    categorySlug: 'curling',
    categoryDescription:
      'Discover different aspects of curling, including equipment, techniques, and competitions, as well as tips for beginners and advanced players.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Curling',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'curling',
    limit: 20,
    mantisCategories: 'curling',
    metaDescription: 'Get the latest updates on Curling, including scores, schedules, and interviews. Follow My News Assistant for breaking news and analysis!',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Curling News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  dance: {
    age: 30,
    categorySlug: 'dance',
    categoryDescription:
      'Get expert advice and resources for different types of dance, including ballet, hip hop, and ballroom dancing, as well as tips for beginners and advanced dancers.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Dance',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'dance',
    limit: 20,
    mantisCategories: 'dance',
    metaDescription: 'Stay up-to-date with the latest news, events, and interviews in Dance. Follow My News Assistant for all the latest updates!',
    metaImage: '',
    metaTitle: 'Get the Latest Dance News and Updates',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  diving: {
    age: 30,
    categorySlug: 'diving',
    categoryDescription:
      'Learn about different aspects of diving, including competitive diving, scuba diving, and free diving, as well as equipment, techniques, and safety tips.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Diving',
    featureLimit: 0,
    icon: '<ScubaDiving />',
    isEnabled: true,
    isFeatured: false,
    key: 'diving',
    limit: 20,
    mantisCategories: 'diving',
    metaDescription:
      'Stay up-to-date with the latest news, scores, and highlights of Diving. Follow My News Assistant for breaking news, interviews, and more!',
    metaImage: '',
    metaTitle: 'All the Latest News and Updates on Diving',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  fencing: {
    age: 30,
    categorySlug: 'fencing',
    categoryDescription:
      'Discover different aspects of fencing, including equipment, techniques, and competitions, as well as tips for beginners and advanced fencers.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Fencing',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'fencing',
    limit: 20,
    mantisCategories: 'fencing',
    metaDescription: 'Get the latest updates on Fencing, including scores, schedules, and interviews. Follow My News Assistant for breaking news and analysis!',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Fencing News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  fishing: {
    age: 30,
    categorySlug: 'fishing',
    categoryDescription:
      'Stay up-to-date with the latest news and updates on fishing, including saltwater and freshwater fishing, as well as tips for different types of fishing and equipment.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Fishing',
    featureLimit: 0,
    icon: '<Phishing />',
    isEnabled: true,
    isFeatured: false,
    key: 'fishing',
    limit: 20,
    mantisCategories: 'fishing',
    metaDescription: 'Stay up-to-date with the latest news, events, and interviews in Fishing. Follow My News Assistant for all the latest updates!',
    metaImage: '',
    metaTitle: 'Get the Latest Fishing News and Updates',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  golf: {
    age: 30,
    categorySlug: 'golf',
    categoryDescription: 'Get expert advice on golf, including equipment, techniques, and competitions, as well as tips for beginners and advanced golfers.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Golf',
    featureLimit: 0,
    icon: '<GolfCourse />',
    isEnabled: true,
    isFeatured: false,
    key: 'golf',
    limit: 20,
    mantisCategories: 'golf',
    metaDescription: 'Stay up-to-date with the latest news, scores, and highlights of Golf. Follow My News Assistant for breaking news, interviews, and more!',
    metaImage: '',
    metaTitle: 'All the Latest News and Updates on Golf',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: '',
    tags: '',
  },
  gymnastics: {
    age: 30,
    categorySlug: 'gymnastics',
    categoryDescription:
      'Learn about different aspects of gymnastics, including artistic gymnastics, rhythmic gymnastics, and trampoline, as well as equipment, techniques, and safety tips.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Gymnastics',
    featureLimit: 0,
    icon: '<SportsGymnastics />',
    isEnabled: true,
    isFeatured: false,
    key: 'gymnastics',
    limit: 20,
    mantisCategories: 'gymnastics',
    metaDescription: 'Get the latest news, events, and interviews in Gymnastics. Follow My News Assistant for all the latest updates on Gymnastics!',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest Gymnastics News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  handball: {
    age: 30,
    categorySlug: 'handball',
    categoryDescription:
      'Discover different aspects of handball, including equipment, techniques, and competitions, as well as tips for beginners and advanced players.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Handball',
    featureLimit: 0,
    icon: '<SportsHandball />',
    isEnabled: true,
    isFeatured: false,
    key: 'handball',
    limit: 20,
    mantisCategories: 'handball',
    metaDescription:
      'Stay up-to-date with the latest news, scores, and highlights of Handball. Follow My News Assistant for breaking news, interviews, and more!',
    metaImage: '',
    metaTitle: 'Get the Latest Handball News and Updates',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  hockey: {
    age: 30,
    categorySlug: 'hockey',
    categoryDescription:
      'Stay up-to-date with the latest news and updates on hockey, including NHL, college hockey, and other leagues, as well as player profiles and game analysis.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Hockey',
    featureLimit: 0,
    icon: '<SportsHockey />',
    isEnabled: true,
    isFeatured: false,
    key: 'hockey',
    limit: 20,
    mantisCategories: 'hockey',
    metaDescription:
      'Stay up-to-date with the latest news, scores, and highlights of Hockey. Follow My News Assistant for breaking news, interviews, and more!',
    metaImage: '',
    metaTitle: 'All the Latest News and Updates on Hockey',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  'horse-racing': {
    age: 30,
    categorySlug: 'horse-racing',
    categoryDescription:
      'Get the latest news and updates on horse racing, including thoroughbred and harness racing, as well as jockey and trainer profiles and race analysis.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Horse Racing',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'horse-racing',
    limit: 20,
    mantisCategories: 'horse_racing',
    metaDescription:
      'Stay up-to-date with the latest news, events, and interviews in Horse Racing. Follow My News Assistant for all the latest updates on Horse Racing!',
    metaImage: '',
    metaTitle: 'Get the Latest Horse Racing News and Updates',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  hunting: {
    age: 30,
    categorySlug: 'hunting',
    categoryDescription:
      'Learn about different aspects of hunting, including equipment, techniques, and safety tips, as well as information on different types of game.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Hunting',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'hunting',
    limit: 20,
    mantisCategories: 'hunting_shooting',
    metaDescription: 'Get the latest updates on Hunting, including scores, schedules, and interviews. Follow My News Assistant for breaking news and analysis!',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Hunting News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  'martial-arts': {
    age: 30,
    categorySlug: 'martial-arts',
    categoryDescription:
      'Discover different types of martial arts, including karate, judo, and taekwondo, as well as techniques, competitions, and training tips.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Martial Arts',
    featureLimit: 0,
    icon: '<SportsMartialArts />',
    isEnabled: true,
    isFeatured: false,
    key: 'martial-arts',
    limit: 20,
    mantisCategories: 'martial_arts',
    metaDescription:
      'Stay up-to-date with the latest news, events, and interviews in Martial Arts. Follow My News Assistant for all the latest updates on Martial Arts!',
    metaImage: '',
    metaTitle: 'Get the Latest Martial Arts News and Updates',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  olympics: {
    age: 30,
    categorySlug: 'olympics',
    categoryDescription:
      'Stay up-to-date with the latest news and updates on the Olympic Games, including athlete profiles, event schedules, and medal counts.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Olympics',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'olympics',
    limit: 20,
    mantisCategories: 'olympics',
    metaDescription: 'Get the latest news, events, and interviews in Olympics. Follow My News Assistant for all the latest updates on Olympics!',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest Olympics News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  paintball: {
    age: 30,
    categorySlug: 'paintball',
    categoryDescription: 'Get expert advice on paintball, including equipment, techniques, and competitions, as well as safety tips and injury prevention.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Paintball',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'paintball',
    limit: 20,
    mantisCategories: 'paintball',
    metaDescription:
      'Stay up-to-date with the latest news, events, and interviews in Paintball. Follow My News Assistant for all the latest updates on Paintball!',
    metaImage: '',
    metaTitle: 'Get the Latest Paintball News and Updates',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  parachuting: {
    age: 30,
    categorySlug: 'parachuting',
    categoryDescription:
      'Learn about different aspects of parachuting, including skydiving, base jumping, and military parachuting, as well as equipment, techniques, and safety tips.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Parachuting',
    featureLimit: 0,
    icon: '<Paragliding />',
    isEnabled: true,
    isFeatured: false,
    key: 'parachuting',
    limit: 20,
    mantisCategories: 'parachuting',
    metaDescription:
      'Get the latest updates on Parachuting, including scores, schedules, and interviews. Follow My News Assistant for breaking news and analysis!',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Parachuting News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  polo: {
    age: 30,
    categorySlug: 'polo',
    categoryDescription:
      'Discover different aspects of polo, including equipment, techniques, and competitions, as well as tips for beginners and advanced players.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Polo',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'polo',
    limit: 20,
    mantisCategories: 'polo',
    metaDescription: 'Get the latest news, events, and interviews in Polo. Follow My News Assistant for all the latest updates on Polo!',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest Polo News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  rodeo: {
    age: 30,
    categorySlug: 'rodeo',
    categoryDescription:
      'Rodeo is a popular sport in which cowboys and cowgirls compete in various events such as bull riding, steer wrestling, and barrel racing.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Rodeo',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'rodeo',
    limit: 20,
    mantisCategories: 'rodeo',
    metaDescription: 'Get the latest news, events, and interviews in Rodeo. Follow My News Assistant for all the latest updates on Rodeo!',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest Rodeo News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  rowing: {
    age: 30,
    categorySlug: 'rowing',
    categoryDescription:
      'Rowing is a sport that involves propelling a boat using oars. It is a popular activity for both competitive and recreational purposes.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Rowing',
    featureLimit: 0,
    icon: '<Rowing />',
    isEnabled: true,
    isFeatured: false,
    key: 'rowing',
    limit: 20,
    mantisCategories: 'rowing',
    metaDescription:
      'Stay up-to-date with the latest news, scores, and highlights of Rowing. Follow My News Assistant for breaking news, interviews, and more!',
    metaImage: '',
    metaTitle: 'Get the Latest Rowing News and Updates',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  rugby: {
    age: 30,
    categorySlug: 'rugby',
    categoryDescription:
      "Rugby is a contact sport that originated in England. It involves two teams of fifteen players each, who try to score points by carrying, passing, kicking, or grounding the ball in the opponent's goal area.",
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Rugby',
    featureLimit: 0,
    icon: '<SportsRugby />',
    isEnabled: true,
    isFeatured: false,
    key: 'rugby',
    limit: 20,
    mantisCategories: 'rugby',
    metaDescription: 'Stay up-to-date with the latest news, scores, and highlights of Rugby. Follow My News Assistant for breaking news, interviews, and more!',
    metaImage: '',
    metaTitle: 'All the Latest News and Updates on Rugby',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  jogging: {
    age: 30,
    categorySlug: 'jogging',
    categoryDescription: 'Jogging is a form of running that is done at a slower pace. It is a popular form of exercise and is often done outdoors.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Jogging',
    featureLimit: 0,
    icon: '<DirectionsRun />',
    isEnabled: true,
    isFeatured: false,
    key: 'jogging',
    limit: 20,
    mantisCategories: 'running_jogging',
    metaDescription: 'Get the latest updates on Jogging, including tips, techniques, and interviews. Follow My News Assistant for breaking news and analysis!',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Jogging News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  sailing: {
    age: 30,
    categorySlug: 'sailing',
    categoryDescription:
      'Sailing is a recreational and competitive sport that involves navigating a boat through the water using wind power. It is a popular activity for both individuals and teams.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Sailing',
    featureLimit: 0,
    icon: '<Sailing />',
    isEnabled: true,
    isFeatured: false,
    key: 'sailing',
    limit: 20,
    mantisCategories: 'sailing',
    metaDescription:
      'Stay up-to-date with the latest news, scores, and highlights of Sailing. Follow My News Assistant for breaking news, interviews, and more!',
    metaImage: '',
    metaTitle: 'Get the Latest Sailing News and Updates',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  'scuba-diving': {
    age: 30,
    categorySlug: 'scuba-diving',
    categoryDescription:
      'Scuba diving is a sport that involves diving underwater with the help of a self-contained breathing apparatus. It is a popular activity for exploring underwater environments.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Scuba Diving',
    featureLimit: 0,
    icon: '<ScubaDiving />',
    isEnabled: true,
    isFeatured: false,
    key: 'scuba-diving',
    limit: 20,
    mantisCategories: 'scuba_diving',
    metaDescription: 'Get the latest news, events, and interviews in Scuba Diving. Follow My News Assistant for all the latest updates on Scuba Diving!',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest Scuba Diving News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  skateboarding: {
    age: 30,
    categorySlug: 'skateboarding',
    categoryDescription:
      'Skateboarding is a sport that involves riding a board and performing various tricks and maneuvers. It is a popular activity for both recreational and competitive purposes.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Skateboarding',
    featureLimit: 0,
    icon: '<Skateboarding />',
    isEnabled: true,
    isFeatured: false,
    key: 'skateboarding',
    limit: 20,
    mantisCategories: 'skateboarding',
    metaDescription: 'Get the latest news, events, and interviews in Skateboarding. Follow My News Assistant for all the latest updates on Skateboarding!',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest Skateboarding News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  skating: {
    age: 30,
    categorySlug: 'skating',
    categoryDescription:
      'Skating is a sport that involves moving on a surface using ice skates, roller skates, or inline skates. It is a popular activity for both recreational and competitive purposes.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Skating',
    featureLimit: 0,
    icon: '<Skateboarding />',
    isEnabled: true,
    isFeatured: false,
    key: 'skating',
    limit: 20,
    mantisCategories: 'skating',
    metaDescription:
      'Stay up-to-date with the latest news, scores, and highlights of Skating. Follow My News Assistant for breaking news, interviews, and more!',
    metaImage: '',
    metaTitle: 'Get the Latest Skating News and Updates',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  skiing: {
    age: 30,
    categorySlug: 'skiing',
    categoryDescription:
      'Skiing is a sport that involves gliding over snow using skis. It is a popular activity for both recreational and competitive purposes.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Skiing',
    featureLimit: 0,
    icon: '<DownhillSkiing />',
    isEnabled: true,
    isFeatured: false,
    key: 'skiing',
    limit: 20,
    mantisCategories: 'skiing',
    metaDescription: 'Get the latest updates on Skiing, including tips, techniques, and interviews. Follow My News Assistant for breaking news and analysis!',
    metaImage: '',
    metaTitle: 'Stay Informed with the Latest Skiing News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  snowboarding: {
    age: 30,
    categorySlug: 'snowboarding',
    categoryDescription:
      'Snowboarding is a sport that involves gliding over snow using a single board. It is a popular activity for both recreational and competitive purposes.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Snowboarding',
    featureLimit: 0,
    icon: '<Snowboarding />',
    isEnabled: true,
    isFeatured: false,
    key: 'snowboarding',
    limit: 20,
    mantisCategories: 'snowboarding',
    metaDescription:
      'Stay up-to-date with the latest news, scores, and highlights of Snowboarding. Follow My News Assistant for breaking news, interviews, and more!',
    metaImage: '',
    metaTitle: 'Get the Latest Snowboarding News and Updates',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  soccer: {
    age: 30,
    categorySlug: 'soccer',
    categoryDescription:
      "Soccer, also known as football in some countries, is a team sport that involves kicking a ball into the opponent's goal. It is the most popular sport in the world.",
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Soccer',
    featureLimit: 0,
    icon: '<SportsSoccer />',
    isEnabled: true,
    isFeatured: false,
    key: 'soccer',
    limit: 20,
    mantisCategories: 'soccer',
    metaDescription:
      'Stay up-to-date with the latest news, scores, and highlights of Soccer. Follow My News Assistant for breaking news, interviews, and more!',
    metaImage: '',
    metaTitle: 'All the Latest News and Updates on Soccer',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: '',
    tags: '',
  },
  softball: {
    age: 30,
    categorySlug: 'softball',
    categoryDescription:
      'Softball is a variant of baseball that is played with a larger ball on a smaller field. It is a popular sport for both recreational and competitive purposes.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Softball',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'softball',
    limit: 20,
    mantisCategories: 'softball',
    metaDescription:
      'Stay up-to-date with the latest news, scores, and highlights of Softball. Follow My News Assistant for breaking news, interviews, and more!',
    metaImage: '',
    metaTitle: 'Get the Latest Softball News and Updates',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  surfing: {
    age: 30,
    categorySlug: 'surfing',
    categoryDescription:
      'Surfing is a water sport that involves riding a board on the waves. It is a popular activity for both recreational and competitive purposes.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Surfing',
    featureLimit: 0,
    icon: '<Surfing />',
    isEnabled: true,
    isFeatured: false,
    key: 'surfing',
    limit: 20,
    mantisCategories: 'surfing_bodyboarding',
    metaDescription:
      'Stay up-to-date with the latest surfing news from around the world with My News Assistant. Get the latest updates, photos, and videos on surfing competitions, surfers, and more.',
    metaImage: '',
    metaTitle: 'Latest Surfing News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  swimming: {
    age: 30,
    categorySlug: 'swimming',
    categoryDescription:
      'Swimming is a sport that involves propelling oneself through water using the arms and legs. It is a popular activity for both recreational and competitive purposes.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Swimming',
    featureLimit: 0,
    icon: '<Pool />',
    isEnabled: true,
    isFeatured: false,
    key: 'swimming',
    limit: 20,
    mantisCategories: 'swimming',
    metaDescription:
      'Stay up-to-date with the latest swimming news from around the world with My News Assistant. Get the latest updates, photos, and videos on swimming competitions, swimmers, and more.',
    metaImage: '',
    metaTitle: 'Latest Swimming News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  'table-tennis': {
    age: 30,
    categorySlug: 'table-tennis',
    categoryDescription:
      'Table tennis, also known as ping-pong, is a sport that involves hitting a lightweight ball across a table using small paddles. It is a popular activity for both recreational and competitive purposes.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Table Tennis',
    featureLimit: 0,
    icon: '<SportsTennis />',
    isEnabled: true,
    isFeatured: false,
    key: 'table-tennis',
    limit: 20,
    mantisCategories: 'table_tennis_pingpong',
    metaDescription:
      'Stay up-to-date with the latest table tennis news from around the world with My News Assistant. Get the latest updates, photos, and videos on table tennis competitions, players, and more.',
    metaImage: '',
    metaTitle: 'Latest Table Tennis News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  tennis: {
    age: 30,
    categorySlug: 'tennis',
    categoryDescription:
      'Tennis is a sport that involves hitting a ball across a net using a racket. It is a popular activity for both recreational and competitive purposes.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Tennis',
    featureLimit: 0,
    icon: '<SportsTennis />',
    isEnabled: true,
    isFeatured: false,
    key: 'tennis',
    limit: 20,
    mantisCategories: 'tennis',
    metaDescription:
      'Stay up-to-date with the latest tennis news from around the world with My News Assistant. Get the latest updates, photos, and videos on tennis competitions, players, and more.',
    metaImage: '',
    metaTitle: 'Latest Tennis News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: '',
    tags: '',
  },
  trekking: {
    age: 30,
    categorySlug: 'trekking',
    categoryDescription:
      'Trekking is a form of hiking that involves walking for an extended period of time, often through rugged terrain. It is a popular activity for exploring nature and outdoor environments.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Trekking',
    featureLimit: 0,
    icon: '<Hiking />',
    isEnabled: true,
    isFeatured: false,
    key: 'trekking',
    limit: 20,
    mantisCategories: 'trekking',
    metaDescription:
      'Stay up-to-date with the latest trekking news from around the world with My News Assistant. Get the latest updates, photos, and videos on trekking destinations, gear, and more.',
    metaImage: '',
    metaTitle: 'Latest Trekking News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  volleyball: {
    age: 30,
    categorySlug: 'volleyball',
    categoryDescription:
      'Volleyball is a team sport that involves hitting a ball over a net using hands, arms, or other body parts. It is a popular activity for both recreational and competitive purposes.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Volleyball',
    featureLimit: 0,
    icon: '<SportsVolleyball />',
    isEnabled: true,
    isFeatured: false,
    key: 'volleyball',
    limit: 20,
    mantisCategories: 'volleyball',
    metaDescription:
      'Stay up-to-date with the latest volleyball news from around the world with My News Assistant. Get the latest updates, photos, and videos on volleyball competitions, players, and more.',
    metaImage: '',
    metaTitle: 'Latest Volleyball News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  wakeboarding: {
    age: 30,
    categorySlug: 'wakeboarding',
    categoryDescription:
      'Wakeboarding is a water sport that involves riding a board while being pulled by a motorboat. It is a popular activity for both recreational and competitive purposes.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Wakeboarding',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'wakeboarding',
    limit: 20,
    mantisCategories: 'wakeboarding',
    metaDescription:
      'Stay up-to-date with the latest wakeboarding news from around the world with My News Assistant. Get the latest updates, photos, and videos on wakeboarding competitions, athletes, and more.',
    metaImage: '',
    metaTitle: 'Latest Wakeboarding News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  walking: {
    age: 30,
    categorySlug: 'walking',
    categoryDescription:
      'Walking is a form of exercise that involves moving at a slow pace on foot. It is a popular activity for both recreational and health-related purposes.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Walking',
    featureLimit: 0,
    icon: '<NordicWalking />',
    isEnabled: true,
    isFeatured: false,
    key: 'walking',
    limit: 20,
    mantisCategories: 'walking',
    metaDescription:
      'Stay up-to-date with the latest walking news from around the world with My News Assistant. Get the latest updates, photos, and videos on walking events, routes, and more.',
    metaImage: '',
    metaTitle: 'Latest Walking News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  'water-polo': {
    age: 30,
    categorySlug: 'water-polo',
    categoryDescription:
      'Water polo is a team sport that involves swimming and passing a ball in an attempt to score goals. It is a popular activity for both recreational and competitive purposes.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Water Polo',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'water-polo',
    limit: 20,
    mantisCategories: 'water_polo',
    metaDescription:
      'Stay up-to-date with the latest water polo news from around the world with My News Assistant. Get the latest updates, photos, and videos on water polo competitions, players, and more.',
    metaImage: '',
    metaTitle: 'Latest Water Polo News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  weightlifting: {
    age: 30,
    categorySlug: 'weightlifting',
    categoryDescription: 'A form of exercise that involves lifting heavy weights to build muscle strength and size.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Weightlifting',
    featureLimit: 0,
    icon: '<FitnessCenter />',
    isEnabled: true,
    isFeatured: false,
    key: 'weightlifting',
    limit: 20,
    mantisCategories: 'weightlifting',
    metaDescription:
      'Stay up-to-date with the latest weightlifting news from around the world with My News Assistant. Get the latest updates, photos, and videos on weightlifting competitions, athletes, and more.',
    metaImage: '',
    metaTitle: 'Latest Weightlifting News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  windsurfing: {
    age: 30,
    categorySlug: 'windsurfing',
    categoryDescription: 'A water sport that combines elements of surfing and sailing, where the rider stands on a board and uses a sail to catch the wind.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Windsurfing',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'windsurfing',
    limit: 20,
    mantisCategories: 'windsurfing',
    metaDescription:
      'Stay up-to-date with the latest windsurfing news from around the world with My News Assistant. Get the latest updates, photos, and videos on windsurfing competitions, athletes, and more.',
    metaImage: '',
    metaTitle: 'Latest Windsurfing News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  wrestling: {
    age: 30,
    categorySlug: 'wrestling',
    categoryDescription:
      'A combat sport involving grappling techniques such as takedowns, holds, and pins, with the objective of controlling and subduing the opponent.',
    categoryImage: '',
    categoryType: 'sports',
    displayName: 'Wrestling',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'wrestling',
    limit: 20,
    mantisCategories: 'wrestling',
    metaDescription:
      'Stay up-to-date with the latest wrestling news from around the world with My News Assistant. Get the latest updates, photos, and videos on wrestling competitions, athletes, and more.',
    metaImage: '',
    metaTitle: 'Latest Wrestling News',
    relatedCategories: '',
    sectionSlug: 'sports',
    subSectionSlug: 'other-sports',
    tags: '',
  },
  'computer-certification': {
    age: 30,
    categorySlug: 'computer-certification',
    categoryDescription:
      "The process of obtaining a credential that validates an individual's knowledge and skills in a particular area of computer technology.",
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Computer Certification',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'computer-certification',
    limit: 20,
    mantisCategories: 'computer_certification',
    metaDescription:
      'Stay up-to-date with the latest computer certification news from around the world with My News Assistant. Get the latest updates, tips, and tricks on computer certification exams, courses, and more.',
    metaImage: '',
    metaTitle: 'Latest Computer Certification News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  'computer-crime': {
    age: 30,
    categorySlug: 'computer-crime',
    categoryDescription: 'Illegal activities involving computers or computer networks, such as hacking, fraud, and identity theft.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Computer Crime',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'computer-crime',
    limit: 20,
    mantisCategories: 'computer_crime',
    metaDescription:
      'Stay up-to-date with the latest computer crime news from around the world with My News Assistant. Get the latest updates, insights, and analysis on cybercrime, hacking, and more.',
    metaImage: '',
    metaTitle: 'Latest Computer Crime News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  'computer-reviews': {
    age: 30,
    categorySlug: 'computer-reviews',
    categoryDescription:
      'Evaluations of computer hardware, software, and other related products, often conducted by experts or consumers to help others make informed purchasing decisions.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Computer Reviews',
    featureLimit: 0,
    icon: '<Computer />',
    isEnabled: true,
    isFeatured: false,
    key: 'computer-reviews',
    limit: 20,
    mantisCategories: 'computer_reviews',
    metaDescription:
      'Stay up-to-date with the latest computer reviews from around the world with My News Assistant. Get the latest insights, ratings, and recommendations on laptops, desktops, and more.',
    metaImage: '',
    metaTitle: 'Latest Computer Reviews',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  'computer-security': {
    age: 30,
    categorySlug: 'computer-security',
    categoryDescription:
      'The practice of protecting computer systems and networks from unauthorized access, theft, damage, or disruption, using various methods such as encryption, firewalls, and antivirus software.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Computer Security & INFOSEC',
    featureLimit: 0,
    icon: '<Security />',
    isEnabled: true,
    isFeatured: false,
    key: 'computer-security',
    limit: 20,
    mantisCategories: 'computer_security',
    metaDescription:
      'Stay up-to-date with the latest computer security news from around the world with My News Assistant. Get the latest updates, tips, and strategies on protecting your computer from malware, viruses, and more.',
    metaImage: '',
    metaTitle: 'Latest Computer Security News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  electronics: {
    age: 30,
    categorySlug: 'electronics',
    categoryDescription:
      'The branch of physics and engineering that deals with the behavior and application of electrons in devices such as transistors, diodes, and integrated circuits.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Electronics',
    featureLimit: 0,
    icon: '<Power />',
    isEnabled: true,
    isFeatured: false,
    key: 'electronics',
    limit: 20,
    mantisCategories: 'consumer_electronics',
    metaDescription:
      'Stay up-to-date with the latest electronics news from around the world with My News Assistant. Get the latest updates, reviews, and insights on consumer electronics such as smartphones, tablets, and more.',
    metaImage: '',
    metaTitle: 'Latest Electronics News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  'data-centers': {
    age: 30,
    categorySlug: 'data-centers',
    categoryDescription: 'Facilities used to house computer systems and associated components, such as telecommunications and storage systems.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Data Centers',
    featureLimit: 0,
    icon: '<Storage />',
    isEnabled: true,
    isFeatured: false,
    key: 'data-centers',
    limit: 20,
    mantisCategories: 'data_centers',
    metaDescription:
      'Stay up-to-date with the latest data center news from around the world with My News Assistant. Get the latest updates, insights, and analysis on cloud computing, data storage, and more.',
    metaImage: '',
    metaTitle: 'Latest Data Center News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  'electronic-components': {
    age: 30,
    categorySlug: 'electronic-components',
    categoryDescription:
      'Parts used in electronic circuits, such as resistors, capacitors, and transistors, which can be combined to create a wide range of devices.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Electronic Components',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'electronic-components',
    limit: 20,
    mantisCategories: 'electronic_components',
    metaDescription:
      'Stay up-to-date with the latest electronic components news from around the world with My News Assistant. Get the latest updates, trends, and insights on electronic components such as semiconductors, sensors, and more.',
    metaImage: '',
    metaTitle: 'Latest Electronic Components News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  'enterprise-technology': {
    age: 30,
    categorySlug: 'enterprise-technology',
    categoryDescription:
      'Technologies used by large organisations to manage their operations, such as enterprise resource planning (ERP) systems and customer relationship management (CRM) software.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Enterprise Technology',
    featureLimit: 0,
    icon: '<SettingsSuggest />',
    isEnabled: true,
    isFeatured: false,
    key: 'enterprise-technology',
    limit: 20,
    mantisCategories: 'enterprise_technology',
    metaDescription:
      'Stay up-to-date with the latest enterprise technology news from around the world with My News Assistant. Get the latest updates, insights, and analysis on enterprise software, cloud computing, and more.',
    metaImage: '',
    metaTitle: 'Latest Enterprise Technology News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  games: {
    age: 30,
    categorySlug: 'games',
    categoryDescription:
      'Activities designed for entertainment or competition, often involving strategy, skill, or chance, and typically played using rules and equipment.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Games',
    featureLimit: 0,
    icon: '<VideogameAsset />',
    isEnabled: true,
    isFeatured: false,
    key: 'games',
    limit: 20,
    mantisCategories: 'games',
    metaDescription:
      'Stay up-to-date with the latest gaming news from around the world with My News Assistant. Get the latest updates, reviews, and insights on video games, consoles, and more.',
    metaImage: '',
    metaTitle: 'Latest Gaming News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  hardware: {
    age: 30,
    categorySlug: 'hardware',
    categoryDescription: 'Physical components of a computer system, such as the central processing unit (CPU), memory, and storage devices.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Hardware',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'hardware',
    limit: 20,
    mantisCategories: 'hardware',
    metaDescription:
      'Stay up-to-date with the latest hardware news from around the world with My News Assistant. Get the latest updates, reviews, and insights on computer hardware, peripherals, and more.',
    metaImage: '',
    metaTitle: 'Latest Hardware News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  'internet-technology': {
    age: 30,
    categorySlug: 'internet-technology',
    categoryDescription:
      'Tools and techniques used to design, develop, and maintain websites and other online applications, including programming languages, databases, and web servers.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Internet Technology',
    featureLimit: 0,
    icon: '<Wifi />',
    isEnabled: true,
    isFeatured: false,
    key: 'internet-technology',
    limit: 20,
    mantisCategories: 'internet_technology',
    metaDescription:
      'Stay up-to-date with the latest internet technology news from around the world with My News Assistant. Get the latest updates, trends, and insights on internet-related technologies such as networking, web development, and more.',
    metaImage: '',
    metaTitle: 'Latest Internet Technology News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  mp3: {
    age: 30,
    categorySlug: 'mp3',
    categoryDescription: 'A digital audio format that compresses audio files to make them smaller and easier to download or stream over the internet.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'MP3',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'mp3',
    limit: 20,
    mantisCategories: 'mp3_midi',
    metaDescription:
      'Stay up-to-date with the latest MP3 news from around the world with My News Assistant. Get the latest updates, reviews, and insights on MP3 players, formats, and more.',
    metaImage: '',
    metaTitle: 'Latest MP3 News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  networking: {
    age: 30,
    categorySlug: 'networking',
    categoryDescription:
      'The process of connecting multiple computer systems or devices to share data and resources, such as printers or files, and to communicate with each other.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Networking',
    featureLimit: 0,
    icon: '<People />',
    isEnabled: true,
    isFeatured: false,
    key: 'networking',
    limit: 20,
    mantisCategories: 'networking',
    metaDescription:
      'Stay up-to-date with the latest networking news from around the world with My News Assistant. Get the latest updates, trends, and insights on networking technologies such as LAN, WAN, Wi-Fi, and more.',
    metaImage: '',
    metaTitle: 'Latest Networking News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  'operating-systems': {
    age: 30,
    categorySlug: 'operating-systems',
    categoryDescription: 'Software that manages the resources and functions of a computer system, such as Windows, MacOS, and Linux.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Operating Systems',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'operating-systems',
    limit: 20,
    mantisCategories: 'operating_systems',
    metaDescription:
      'Stay up-to-date with the latest operating system news from around the world with My News Assistant. Get the latest updates, reviews, and insights on Windows, MacOS, Linux, and more.',
    metaImage: '',
    metaTitle: 'Latest Operating System News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  'programming-languages': {
    age: 30,
    categorySlug: 'programming-languages',
    categoryDescription: 'Formal languages used to create computer programs, such as Java, Python, and C++.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Programming Languages',
    featureLimit: 0,
    icon: '<Code />',
    isEnabled: true,
    isFeatured: false,
    key: 'programming-languages',
    limit: 20,
    mantisCategories: 'programming_languages',
    metaDescription:
      'Stay up-to-date with the latest programming language news from around the world with My News Assistant. Get the latest updates, trends, and insights on programming languages such as Python, Java, C++, and more.',
    metaImage: '',
    metaTitle: 'Latest Programming Language News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  software: {
    age: 30,
    categorySlug: 'software',
    categoryDescription: 'Programs and applications that run on computer systems, such as word processors, spreadsheets, and media players.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Software',
    featureLimit: 0,
    icon: '<SettingsSuggest />',
    isEnabled: true,
    isFeatured: false,
    key: 'software',
    limit: 20,
    mantisCategories: 'software',
    metaDescription:
      'Stay up-to-date with the latest software news from around the world with My News Assistant. Get the latest updates, reviews, and insights on software applications, utilities, and more.',
    metaImage: '',
    metaTitle: 'Latest Software News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  'technical-support': {
    age: 30,
    categorySlug: 'technical-support',
    categoryDescription:
      'Assistance provided to users of computer systems or software to help resolve problems, often provided by trained professionals or online resources.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Technical Support',
    featureLimit: 0,
    icon: '<HelpCenter />',
    isEnabled: true,
    isFeatured: false,
    key: 'technical-support',
    limit: 20,
    mantisCategories: 'technical_support',
    metaDescription:
      'Stay up-to-date with the latest technical support news from around the world with My News Assistant. Get the latest updates, tips, and tricks on providing technical support for hardware, software, and more.',
    metaImage: '',
    metaTitle: 'Latest Technical Support News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  innovation: {
    age: 30,
    categorySlug: 'innovation',
    categoryDescription: 'The process of creating new ideas, products, or services that add value to society and meet the needs of consumers.',
    categoryImage: '',
    categoryType: 'tech',
    displayName: 'Innovation',
    featureLimit: 0,
    icon: '<Lightbulb />',
    isEnabled: true,
    isFeatured: false,
    key: 'innovation',
    limit: 20,
    mantisCategories: 'technological_innovation',
    metaDescription:
      'Stay up-to-date with the latest technological innovation news from around the world with My News Assistant. Get the latest updates, trends, and insights on innovative technologies, startups, and more.',
    metaImage: '',
    metaTitle: 'Latest Technological Innovation News',
    relatedCategories: '',
    sectionSlug: 'technology',
    subSectionSlug: 'tech',
    tags: '',
  },
  'hospitality-industry': {
    age: 30,
    categorySlug: 'hospitality-industry',
    categoryDescription:
      'A broad category of businesses that provide lodging, food, and entertainment to customers, such as hotels, restaurants, and theme parks.',
    categoryImage: '',
    categoryType: 'travel-and-tourism',
    displayName: 'Hospitality Industry',
    featureLimit: 0,
    icon: '<RoomService />',
    isEnabled: true,
    isFeatured: false,
    key: 'hospitality-industry',
    limit: 20,
    mantisCategories: 'hospitality_industry',
    metaDescription:
      'Stay up-to-date with the latest hospitality industry news from around the world with My News Assistant. Get the latest updates, trends, and insights on the hotel, restaurant, and tourism industry.',
    metaImage: '',
    metaTitle: 'Latest Hospitality Industry News',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: '',
    tags: '',
  },
  'budget-travel': {
    age: 30,
    categorySlug: 'budget-travel',
    categoryDescription: 'Traveling on a limited budget, often involving finding low-cost accommodations, transportation, and activities.',
    categoryImage: '',
    categoryType: 'travel-and-tourism',
    displayName: 'Budget Travel',
    featureLimit: 0,
    icon: '<Savings />',
    isEnabled: true,
    isFeatured: false,
    key: 'budget-travel',
    limit: 20,
    mantisCategories: 'budget_travel',
    metaDescription:
      'Stay up-to-date with the latest budget travel news from around the world with My News Assistant. Get the latest updates, tips, and tricks on traveling on a budget without sacrificing comfort and experience.',
    metaImage: '',
    metaTitle: 'Latest Budget Travel News',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'travel',
    tags: '',
  },
  honeymoon: {
    age: 30,
    categorySlug: 'honeymoon',
    categoryDescription: 'A trip taken by newlyweds to celebrate their marriage and spend time together in a romantic setting.',
    categoryImage: '',
    categoryType: 'travel-and-tourism',
    displayName: 'Honeymoon',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'honeymoon',
    limit: 20,
    mantisCategories: 'honeymoons_getaways',
    metaDescription:
      'Stay up-to-date with the latest honeymoon news from around the world with My News Assistant. Get the latest updates, trends, and insights on romantic destinations, activities, and more.',
    metaImage: '',
    metaTitle: 'Latest Honeymoon News',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'travel',
    tags: '',
  },
  hotels: {
    age: 30,
    categorySlug: 'hotels',
    categoryDescription: 'Establishments that provide lodging and other services to travelers, often featuring amenities such as restaurants, spas, and pools.',
    categoryImage: '',
    categoryType: 'travel-and-tourism',
    displayName: 'Hotels',
    featureLimit: 0,
    icon: '<Hotel />',
    isEnabled: true,
    isFeatured: false,
    key: 'hotels',
    limit: 20,
    mantisCategories: 'hotels',
    metaDescription:
      'Stay up-to-date with the latest hotel news from around the world with My News Assistant. Get the latest updates, reviews, and insights on hotels, resorts, and accommodations.',
    metaImage: '',
    metaTitle: 'Latest Hotel News',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'travel',
    tags: '',
  },
  'specialty-travel': {
    age: 30,
    categorySlug: 'specialty-travel',
    categoryDescription: 'Travel focused on specific interests or activities, such as adventure travel, eco-tourism, or cultural immersion.',
    categoryImage: '',
    categoryType: 'travel-and-tourism',
    displayName: 'Specialty Travel',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'specialty-travel',
    limit: 20,
    mantisCategories: 'specialty_travel',
    metaDescription:
      'Stay up-to-date with the latest specialty travel news from around the world with My News Assistant. Get the latest updates, trends, and insights on niche travel such as adventure travel, eco-tourism, and more.',
    metaImage: '',
    metaTitle: 'Latest Specialty Travel News',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'travel',
    tags: '',
  },
  'tourist-destinations': {
    age: 30,
    categorySlug: 'tourist-destinations',
    categoryDescription: 'Popular locations visited by tourists, such as cities, historical sites, and natural wonders.',
    categoryImage: '',
    categoryType: 'travel-and-tourism',
    displayName: 'Tourist Destinations',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'tourist-destinations',
    limit: 20,
    mantisCategories: 'tourist_destinations',
    metaDescription:
      'Stay up-to-date with the latest tourist destinations news from around the world with My News Assistant. Get the latest updates, reviews, and insights on popular tourist destinations, attractions, and landmarks.',
    metaImage: '',
    metaTitle: 'Latest Tourist Destinations News',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'travel',
    tags: '',
  },
  'tourist-facilities': {
    age: 30,
    categorySlug: 'tourist-facilities',
    categoryDescription: 'Services and amenities provided to tourists, such as transportation, accommodations, and guided tours.',
    categoryImage: '',
    categoryType: 'travel-and-tourism',
    displayName: 'Tourist Facilities',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'tourist-facilities',
    limit: 20,
    mantisCategories: 'tourist_facilities',
    metaDescription:
      'Stay up-to-date with the latest tourist facilities news from around the world with My News Assistant. Get the latest updates, trends, and insights on tourist facilities such as airports, train stations, and bus terminals.',
    metaImage: '',
    metaTitle: 'Latest Tourist Facilities News',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'travel',
    tags: '',
  },
  'travel-agencies': {
    age: 30,
    categorySlug: 'travel-agencies',
    categoryDescription:
      'Businesses that provide assistance in planning and booking travel arrangements, such as flights, accommodations, and activities, often with the help of travel agents or online platforms.',
    categoryImage: '',
    categoryType: 'travel-and-tourism',
    displayName: 'Travel Agencies',
    featureLimit: 0,
    icon: '<ModeOfTravel />',
    isEnabled: true,
    isFeatured: false,
    key: 'travel-agencies',
    limit: 20,
    mantisCategories: 'travel_agencies',
    metaDescription:
      'Stay up-to-date with the latest travel agencies news from around the world with My News Assistant. Get the latest updates, reviews, and insights on travel agencies, tour operators, and travel booking platforms.',
    metaImage: '',
    metaTitle: 'Latest Travel Agencies News',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'travel',
    tags: '',
  },
  'travel guides': {
    age: 30,
    categorySlug: 'travel guides',
    categoryDescription:
      'Resources that provide information about travel destinations, including recommendations for activities, accommodations, and local culture.',
    categoryImage: '',
    categoryType: 'travel-and-tourism',
    displayName: 'Travel Guides',
    featureLimit: 0,
    icon: '<Tour />',
    isEnabled: true,
    isFeatured: false,
    key: 'travel guides',
    limit: 20,
    mantisCategories: 'travel_guides',
    metaDescription:
      'Stay up-to-date with the latest travel guides news from around the world with My News Assistant. Get the latest updates, tips, and recommendations on travel destinations, attractions, and activities.',
    metaImage: '',
    metaTitle: 'Latest Travel Guides News',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'travel',
    tags: '',
  },
  'traveling-with-kids': {
    age: 30,
    categorySlug: 'traveling-with-kids',
    categoryDescription: 'Tips and advice for families traveling with children, including suggestions for child-friendly activities and accommodations.',
    categoryImage: '',
    categoryType: 'travel-and-tourism',
    displayName: 'Traveling with Kids',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'traveling-with-kids',
    limit: 20,
    mantisCategories: 'traveling_with_kids',
    metaDescription:
      'Stay up-to-date with the latest traveling with kids news from around the world with My News Assistant. Get the latest updates, tips, and tricks on traveling with children, including family-friendly destinations, activities, and accommodations.',
    metaImage: '',
    metaTitle: 'Latest Traveling with Kids News',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'travel',
    tags: '',
  },
  'vacation-rentals': {
    age: 30,
    categorySlug: 'vacation-rentals',
    categoryDescription: 'Accommodations rented to travelers for short-term stays, such as vacation homes, apartments, and villas.',
    categoryImage: '',
    categoryType: 'travel-and-tourism',
    displayName: 'Vacation Rentals',
    featureLimit: 0,
    icon: '<Villa />',
    isEnabled: true,
    isFeatured: false,
    key: 'vacation-rentals',
    limit: 20,
    mantisCategories: 'vacation_rentals',
    metaDescription:
      'Stay up-to-date with the latest vacation rentals news from around the world with My News Assistant. Get the latest updates, reviews, and insights on vacation rentals, including villas, apartments, and holiday homes.',
    metaImage: '',
    metaTitle: 'Latest Vacation Rentals News',
    relatedCategories: '',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'travel',
    tags: '',
  },
  'star-trek': {
    age: 30,
    categorySlug: 'star-trek',
    categoryDescription: 'Embark on a timeless odyssey with Star Trek, an iconic science fiction franchise that transcends generations. Follow the intrepid crews of starships as they journey through the cosmos, encountering new worlds, civilizations, and the boundless wonders of space. Explore the latest Star Trek news, behind-the-scenes stories, and exclusive insights with My News Assistant.',
    categoryImage: '',
    categoryType: 'science-fiction',
    displayName: 'Star Trek',
    featureLimit: 0,
    icon: '<OndemandVideo />',
    isEnabled: true,
    isFeatured: false,
    key: 'star-trek',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Stay up-to-date with the latest Star Trek news from around the universe with My News Assistant. Explore updates, behind-the-scenes, and insights on the iconic science fiction franchise.',
    metaImage: '',
    metaTitle: 'Latest Star Trek News',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'Star Trek',
  },
  'star-wars': {
    age: 30,
    categorySlug: 'star-wars',
    categoryDescription: 'Immerse yourself in the epic saga of Star Wars, a galaxy far, far away. Join iconic characters, brave Jedi knights, and rebel forces as they confront the dark side. Experience thrilling space battles, epic lightsaber duels, and the timeless struggle between good and evil. Stay updated on the latest Star Wars news, behind-the-scenes stories, and exclusive insights with My News Assistant.',
    categoryImage: '',
    categoryType: 'science-fiction',
    displayName: 'Star Wars',
    featureLimit: 0,
    icon: '<OndemandVideo />',
    isEnabled: true,
    isFeatured: false,
    key: 'star-wars',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Stay up-to-date with the latest Star Wars news from across the galaxy with My News Assistant. Explore updates, behind-the-scenes, and insights on the iconic science-fantasy franchise.',
    metaImage: '',
    metaTitle: 'Latest Star Wars News',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'movies',
    tags: '',
    concepts: 'Star Wars',
  },
  'stargate': {
    age: 30,
    categorySlug: 'stargate',
    categoryDescription: 'Embark on a journey through the Stargate, a portal to other worlds and civilizations. Join SG-1 and other exploration teams as they traverse the universe, encountering ancient technology, extraterrestrial threats, and unraveling the mysteries of the cosmos. Stay connected with the latest Stargate news, behind-the-scenes stories, and exclusive insights with My News Assistant.',
    categoryImage: '',
    categoryType: 'science-fiction',
    displayName: 'Stargate',
    featureLimit: 0,
    icon: '<OndemandVideo />',
    isEnabled: true,
    isFeatured: false,
    key: 'stargate',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Stay up-to-date with the latest Stargate news from across the galaxies with My News Assistant. Explore updates, behind-the-scenes, and insights on the iconic science fiction franchise.',
    metaImage: '',
    metaTitle: 'Latest Stargate News',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'Stargate',
  },
  'doctor-who': {
    age: 30,
    categorySlug: 'doctor-who',
    categoryDescription: "Join the Doctor in the TARDIS, a time-traveling spaceship, on adventures through time and space. Encounter aliens, historical figures, and unravel the mysteries of the universe in the long-running British science fiction series.",
    categoryImage: '',
    categoryType: 'science-fiction',
    displayName: 'Doctor Who',
    featureLimit: 0,
    icon: '<OndemandVideo />',
    isEnabled: true,
    isFeatured: false,
    key: 'doctor-who',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Stay up-to-date with the latest Doctor Who news from across time and space with My News Assistant. Explore updates, behind-the-scenes, and insights on the iconic science fiction series.',
    metaImage: '',
    metaTitle: 'Latest Doctor Who News',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'Doctor Who',
  },
  'the-expanse': {
    age: 30,
    categorySlug: 'the-expanse',
    categoryDescription: "Dive into The Expanse, a gripping space opera set in a future where humanity has colonized the solar system. Follow the interplay between Earth, Mars, and the Belt as tensions rise, and a conspiracy threatens the fragile peace.",
    categoryImage: '',
    categoryType: 'science-fiction',
    displayName: 'The Expanse',
    featureLimit: 0,
    icon: '<OndemandVideo />',
    isEnabled: true,
    isFeatured: false,
    key: 'the-expanse',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Stay up-to-date with the latest news from The Expanse with My News Assistant. Explore updates, behind-the-scenes, and insights on the acclaimed space opera.',
    metaImage: '',
    metaTitle: 'Latest The Expanse News',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'The Expanse',
  },
  'battlestar-galactica': {
    age: 30,
    categorySlug: 'battlestar-galactica',
    categoryDescription: "Experience the intense saga of Battlestar Galactica, where the last remnants of humanity are on the run from robotic Cylons. Navigate political intrigue, survival, and the quest for a new home in this acclaimed space drama.",
    categoryImage: '',
    categoryType: 'science-fiction',
    displayName: 'Battlestar Galactica',
    featureLimit: 0,
    icon: '<OndemandVideo />',
    isEnabled: true,
    isFeatured: false,
    key: 'battlestar-galactica',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Stay up-to-date with the latest news from the Battlestar Galactica universe with My News Assistant. Explore updates, behind-the-scenes, and insights on the acclaimed space drama.',
    metaImage: '',
    metaTitle: 'Latest Battlestar Galactica News',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'Battlestar Galactica',
  },
  'firefly': {
    age: 30,
    categorySlug: 'firefly',
    categoryDescription: "Enter the 'Verse with Firefly, a space-Western following the crew of the Serenity as they navigate life on the fringes of society. Join Captain Mal and his eclectic team on adventures filled with humor, heart, and danger.",
    categoryImage: '',
    categoryType: 'science-fiction',
    displayName: 'Firefly',
    featureLimit: 0,
    icon: '<OndemandVideo />',
    isEnabled: true,
    isFeatured: false,
    key: 'firefly',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Stay up-to-date with the latest news from the Firefly Verse with My News Assistant. Explore updates, behind-the-scenes, and insights on the beloved space-Western series.',
    metaImage: '',
    metaTitle: 'Latest Firefly News',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'Firefly',
  },
  'black-mirror': {
    age: 30,
    categorySlug: 'black-mirror',
    categoryDescription: "Explore thought-provoking and dystopian futures with Black Mirror. Each standalone episode offers a glimpse into the dark side of technology and its impact on society, leaving viewers questioning the consequences of innovation.",
    categoryImage: '',
    categoryType: 'science-fiction',
    displayName: 'Black Mirror',
    featureLimit: 0,
    icon: '<OndemandVideo />',
    isEnabled: true,
    isFeatured: false,
    key: 'black-mirror',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Stay up-to-date with the latest news from the eerie worlds of Black Mirror with My News Assistant. Explore updates, behind-the-scenes, and insights on the thought-provoking science fiction series.',
    metaImage: '',
    metaTitle: 'Latest Black Mirror News',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'Black Mirror',
  },
  'love-island': {
    age: 2,
    categorySlug: 'love-island',
    categoryDescription: 'A reality television series where contestants live in a villa and compete for love and a cash prize.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'Love Island',
    featureLimit: 0,
    icon: '<OndemandVideo />',
    isEnabled: true,
    isFeatured: false,
    key: 'love-island',
    limit: 20,
    mantisCategories: 'love_island',
    metaDescription:
      'Stay up-to-date with the latest Love Island news from around the world with My News Assistant. Get the latest updates, gossip, and insights on the popular reality TV show.',
    metaImage: '',
    metaTitle: 'Latest Love Island News',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: '',
  },
  'im-a-celebrity-get-me-out-of-here': {
    age: 2,
    categorySlug: 'im-a-celebrity-get-me-out-of-here',
    categoryDescription: 'A reality television series where celebrities live in a jungle camp and compete in challenges to win food and survival supplies.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: "I'm A Celebrity...",
    featureLimit: 0,
    icon: '<OndemandVideo />',
    isEnabled: true,
    isFeatured: false,
    key: 'im-a-celebrity-get-me-out-of-here',
    limit: 20,
    mantisCategories: 'Im_a_celeb',
    metaDescription:
      "Stay up-to-date with the latest I'm A Celebrity news from around the world with My News Assistant. Get the latest updates, gossip, and insights on the popular reality TV show, including behind-the-scenes details and exclusive interviews with the contestants.",
    metaImage: '',
    metaTitle: "Latest I'm A Celebrity News",
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: '',
  },
  gogglebox: {
    age: 2,
    categorySlug: 'gogglebox',
    categoryDescription: 'A British television series where families and friends watch and comment on popular television programs.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'Gogglebox',
    featureLimit: 0,
    icon: '<OndemandVideo />',
    isEnabled: true,
    isFeatured: false,
    key: 'gogglebox',
    limit: 20,
    mantisCategories: 'gogglebox',
    metaDescription:
      'Stay up-to-date with the latest Gogglebox news from around the world with My News Assistant. Get the latest updates, reviews, and insights on the popular reality TV show that features people watching and commenting on TV shows.',
    metaImage: '',
    metaTitle: 'Latest Gogglebox News',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
  },
  'britains-got-talent': {
    age: 2,
    categorySlug: 'britains-got-talent',
    categoryDescription: 'A British television talent competition where contestants showcase their talents in front of judges and a live audience.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: "Britain's Got Talent",
    featureLimit: 0,
    icon: '<OndemandVideo />',
    isEnabled: true,
    isFeatured: false,
    key: 'britains-got-talent',
    limit: 20,
    mantisCategories: 'bgt',
    metaDescription:
      "Stay up-to-date with the latest Britain's Got Talent news from around the world with My News Assistant. Get the latest updates, gossip, and insights on the popular reality TV show, including auditions, performances, and interviews with the judges and contestants.",
    metaImage: '',
    metaTitle: "Latest Britain's Got Talent News",
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
  },
  'the-chase': {
    age: 30,
    categorySlug: 'the-chase',
    categoryDescription: 'A British television quiz show where contestants compete against a professional quizzer to win cash prizes.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'The Chase',
    featureLimit: 0,
    icon: '<OndemandVideo />',
    isEnabled: true,
    isFeatured: false,
    key: 'the-chase',
    limit: 20,
    mantisCategories: 'the_chase',
    metaDescription:
      "Stay up-to-date with the latest The Chase news from around the world with My News Assistant. Get the latest updates, reviews, and insights on the popular game show that features contestants answering questions and competing against a 'Chaser'.",
    metaImage: '',
    metaTitle: 'Latest The Chase News',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
  },
  'strictly-come-dancing': {
    age: 30,
    categorySlug: 'strictly-come-dancing',
    categoryDescription:
      'A British television dance competition where celebrities are paired with professional dancers and compete in ballroom and Latin dances.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'Strictly Come Dancing',
    featureLimit: 0,
    icon: '<OndemandVideo />',
    isEnabled: true,
    isFeatured: false,
    key: 'strictly-come-dancing',
    limit: 20,
    mantisCategories: '',
    metaDescription:
      'Stay up-to-date with the latest Strictly Come Dancing news from around the world with My News Assistant. Get the latest updates, gossip, and insights on the popular reality TV show, including rehearsals, performances, and interviews with the judges and contestants.',
    metaImage: '',
    metaTitle: 'Latest Strictly Come Dancing News',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'Strictly Come Dancing',
  },
  'keeping-up-with-the-kardashians': {
    age: 30,
    categorySlug: 'keeping-up-with-the-kardashians',
    categoryDescription: 'Stay updated on the latest news and episodes from the reality TV series "Keeping Up with the Kardashians," providing insights into the lives of the Kardashian-Jenner family.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'Keeping Up with the Kardashians',
    featureLimit: 0,
    icon: '<Tv />',
    isEnabled: true,
    isFeatured: false,
    key: 'keeping-up-with-the-kardashians',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on "Keeping Up with the Kardashians," including episode highlights, family dynamics, and behind-the-scenes moments with My News Assistant.',
    metaImage: '',
    metaTitle: 'Keeping Up with the Kardashians News - Latest Episodes and Family Insights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'Keeping Up with the Kardashians',
  },
  'real-housewives-of-new-jersey': {
    age: 30,
    categorySlug: 'real-housewives-of-new-jersey',
    categoryDescription: 'Stay updated on the latest news and episodes from the reality TV series "The Real Housewives of New Jersey," offering a glimpse into the lives of the affluent women in the Garden State.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'The Real Housewives of New Jersey',
    featureLimit: 0,
    icon: '<Tv />',
    isEnabled: true,
    isFeatured: false,
    key: 'real-housewives-of-new-jersey',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on "The Real Housewives of New Jersey," including episode highlights, dramatic moments, and the lifestyles of the cast members with My News Assistant.',
    metaImage: '',
    metaTitle: 'The Real Housewives of New Jersey News - Latest Episodes and Cast Insights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'The Real Housewives of New Jersey',
  },
  'real-housewives-of-new-york-city': {
    age: 30,
    categorySlug: 'real-housewives-of-new-york-city',
    categoryDescription: 'Stay updated on the latest news and episodes from the reality TV series "The Real Housewives of New York City," providing an inside look into the lives of affluent women in the Big Apple.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'The Real Housewives of New York City',
    featureLimit: 0,
    icon: '<Tv />',
    isEnabled: true,
    isFeatured: false,
    key: 'real-housewives-of-new-york-city',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on "The Real Housewives of New York City," including episode highlights, dramatic moments, and the lifestyles of the cast members with My News Assistant.',
    metaImage: '',
    metaTitle: 'The Real Housewives of New York City News - Latest Episodes and Cast Insights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'The Real Housewives of New York City',
  },
  'real-housewives-of-beverly-hills': {
    age: 30,
    categorySlug: 'real-housewives-of-beverly-hills',
    categoryDescription: 'Stay updated on the latest news and episodes from the reality TV series "The Real Housewives of Beverly Hills," offering an inside view into the glamorous lives of affluent women in the 90210.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'The Real Housewives of Beverly Hills',
    featureLimit: 0,
    icon: '<Tv />',
    isEnabled: true,
    isFeatured: false,
    key: 'real-housewives-of-beverly-hills',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on "The Real Housewives of Beverly Hills," including episode highlights, glamorous moments, and the lifestyles of the cast members with My News Assistant.',
    metaImage: '',
    metaTitle: 'The Real Housewives of Beverly Hills News - Latest Episodes and Cast Insights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'The Real Housewives of Beverly Hills',
  },
  'real-housewives-of-orange-county': {
    age: 30,
    categorySlug: 'real-housewives-of-orange-county',
    categoryDescription: 'Stay updated on the latest news and episodes from the reality TV series "The Real Housewives of Orange County," providing a glimpse into the lives of affluent women in the picturesque Orange County.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'The Real Housewives of Orange County',
    featureLimit: 0,
    icon: '<Tv />',
    isEnabled: true,
    isFeatured: false,
    key: 'real-housewives-of-orange-county',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on "The Real Housewives of Orange County," including episode highlights, scenic moments, and the lifestyles of the cast members with My News Assistant.',
    metaImage: '',
    metaTitle: 'The Real Housewives of Orange County News - Latest Episodes and Cast Insights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'The Real Housewives of Orange County',
  },
  'real-housewives-of-atlanta': {
    age: 30,
    categorySlug: 'real-housewives-of-atlanta',
    categoryDescription: 'Stay updated on the latest news and episodes from the reality TV series "The Real Housewives of Atlanta," offering an inside look into the lives of affluent women in Atlanta, Georgia.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'The Real Housewives of Atlanta',
    featureLimit: 0,
    icon: '<Tv />',
    isEnabled: true,
    isFeatured: false,
    key: 'real-housewives-of-atlanta',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on "The Real Housewives of Atlanta," including episode highlights, dramatic moments, and the lifestyles of the cast members with My News Assistant.',
    metaImage: '',
    metaTitle: 'The Real Housewives of Atlanta News - Latest Episodes and Cast Insights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'The Real Housewives of Atlanta',
  },
  'real-housewives-of-cheshire': {
    age: 30,
    categorySlug: 'real-housewives-of-cheshire',
    categoryDescription: 'Stay updated on the latest news and episodes from the reality TV series "The Real Housewives of Cheshire," offering a glimpse into the lives of affluent women in Cheshire, England.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'The Real Housewives of Cheshire',
    featureLimit: 0,
    icon: '<Tv />',
    isEnabled: true,
    isFeatured: false,
    key: 'real-housewives-of-cheshire',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on "The Real Housewives of Cheshire," including episode highlights, dramatic moments, and the lifestyles of the cast members with My News Assistant.',
    metaImage: '',
    metaTitle: 'The Real Housewives of Cheshire News - Latest Episodes and Cast Insights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: 'tags-real-housewives-of-cheshire',
    concepts: '',
  },
  'the-bachelorette': {
    age: 30,
    categorySlug: 'the-bachelorette',
    categoryDescription: 'Stay updated on the latest news and episodes from the reality TV series "The Bachelorette," where a single woman embarks on a journey to find love among a group of eligible bachelors.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'The Bachelorette',
    featureLimit: 0,
    icon: '<Heart />',
    isEnabled: true,
    isFeatured: false,
    key: 'the-bachelorette',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on "The Bachelorette," including romantic dates, rose ceremonies, and the quest for love with My News Assistant.',
    metaImage: '',
    metaTitle: 'The Bachelorette News - Latest Episodes and Romantic Adventures',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'The Bachelorette',
  },
  'married-at-first-sight': {
    age: 30,
    categorySlug: 'married-at-first-sight',
    categoryDescription: 'Stay updated on the latest news and episodes from the reality TV series "Married at First Sight," where singles agree to marry a stranger chosen by relationship experts and navigate the ups and downs of married life.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'Married at First Sight',
    featureLimit: 0,
    icon: '<Ring />',
    isEnabled: true,
    isFeatured: false,
    key: 'married-at-first-sight',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on "Married at First Sight," including wedding ceremonies, relationship challenges, and the journey of couples in this unique social experiment with My News Assistant.',
    metaImage: '',
    metaTitle: 'Married at First Sight News - Latest Episodes and Relationship Insights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: 'tags-married-at-first-sight',
    concepts: '',
  },
  'the-only-way-is-essex': {
    age: 30,
    categorySlug: 'the-only-way-is-essex',
    categoryDescription: 'Stay updated on the latest news and episodes from the reality TV series "The Only Way Is Essex" (TOWIE), offering a glimpse into the lives of a glamorous group of friends in Essex, England.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'The Only Way Is Essex',
    featureLimit: 0,
    icon: '<Tv />',
    isEnabled: true,
    isFeatured: false,
    key: 'the-only-way-is-essex',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on "The Only Way Is Essex," including episode highlights, dramatic moments, and the lifestyles of the cast members with My News Assistant.',
    metaImage: '',
    metaTitle: 'The Only Way Is Essex News - Latest Episodes and Cast Insights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'The Only Way Is Essex',
  },
  'big-brother': {
    age: 30,
    categorySlug: 'big-brother',
    categoryDescription: 'Stay updated on the latest news and episodes from the reality TV series "Big Brother," where a group of housemates live together under constant surveillance and compete in challenges to avoid eviction.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'Big Brother',
    featureLimit: 0,
    icon: '<Eye />',
    isEnabled: true,
    isFeatured: false,
    key: 'big-brother',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on "Big Brother," including house dynamics, eviction twists, and the challenges faced by housemates with My News Assistant.',
    metaImage: '',
    metaTitle: 'Big Brother News - Latest Episodes and House Insights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'Big Brother (UK)',
  },
  'the-great-british-bake-off': {
    age: 30,
    categorySlug: 'the-great-british-bake-off',
    categoryDescription: 'Stay updated on the latest news and episodes from the popular baking competition, "The Great British Bake Off," where amateur bakers showcase their skills in a friendly culinary competition.',
    categoryImage: '',
    categoryType: 'reality-tv',
    displayName: 'The Great British Bake Off',
    featureLimit: 0,
    icon: '<Cake />',
    isEnabled: true,
    isFeatured: false,
    key: 'the-great-british-bake-off',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Get the latest updates on "The Great British Bake Off," including episode highlights, baking challenges, and the delightful creations of the contestants with My News Assistant.',
    metaImage: '',
    metaTitle: 'The Great British Bake Off News - Latest Episodes and Baking Highlights',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'tv-shows',
    tags: '',
    concepts: 'The Great British Bake Off',
  },
  humor: {
    age: 30,
    categorySlug: 'humor',
    categoryDescription: 'Forms of entertainment that provoke laughter and amusement, such as jokes, comedy, and satire.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Humor',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'humor',
    limit: 20,
    mantisCategories: 'humor',
    metaDescription:
      'Stay up-to-date with the latest humor news from around the world with My News Assistant. Get the latest updates, trends, and insights on funny memes, videos, and jokes that are going viral on the internet.',
    metaImage: '',
    metaTitle: 'Latest Humor News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  'certified-preowned': {
    age: 30,
    categorySlug: 'certified-preowned',
    categoryDescription:
      'A program offered by manufacturers or dealerships to sell used vehicles that have undergone a thorough inspection and meet certain quality standards.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Certified Preowned',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'certified-preowned',
    limit: 20,
    mantisCategories: 'certified_preowned',
    metaDescription:
      'Stay up-to-date with the latest Certified Preowned news from around the world with My News Assistant. Get the latest updates, reviews, and insights on the latest models, deals, and trends in the certified preowned car market.',
    metaImage: '',
    metaTitle: 'Latest Certified Preowned News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  'aerospace-defense': {
    age: 30,
    categorySlug: 'aerospace-defense',
    categoryDescription: 'Industries involved in the design, development, and production of aircraft, spacecraft, and defense systems.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Aerospace Defense',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'aerospace-defense',
    limit: 20,
    mantisCategories: 'aerospace_defense',
    metaDescription:
      'Stay up-to-date with the latest Aerospace Defense news from around the world with My News Assistant. Get the latest updates, reviews, and insights on the latest technologies, developments, and deals in the aerospace and defense industry.',
    metaImage: '',
    metaTitle: 'Latest Aerospace Defense News',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  'agriculture-forestry': {
    age: 30,
    categorySlug: 'agriculture-forestry',
    categoryDescription: 'Industries involved in the cultivation, processing, and management of crops, livestock, and forests.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Agriculture',
    featureLimit: 0,
    icon: '<Agriculture />',
    isEnabled: true,
    isFeatured: false,
    key: 'agriculture-forestry',
    limit: 20,
    mantisCategories: 'agriculture_forestry',
    metaDescription:
      'Stay up-to-date with the latest Agriculture news from around the world with My News Assistant. Get the latest updates, reviews, and insights on the latest trends, technologies, and deals in the agriculture and forestry industries.',
    metaImage: '',
    metaTitle: 'Latest Agriculture News',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  automation: {
    age: 30,
    categorySlug: 'automation',
    categoryDescription: 'The use of technology to automate tasks and processes, such as manufacturing, transportation, and information technology.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Automation',
    featureLimit: 0,
    icon: '<AutoMode />',
    isEnabled: true,
    isFeatured: false,
    key: 'automation',
    limit: 20,
    mantisCategories: 'automation',
    metaDescription:
      'Stay up-to-date with the latest Automation news from around the world with My News Assistant. Get the latest updates, reviews, and insights on the latest technologies, trends, and deals in the automation industry.',
    metaImage: '',
    metaTitle: 'Latest Automation News',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  'chemicals-industry': {
    age: 30,
    categorySlug: 'chemicals-industry',
    categoryDescription:
      'Industries involved in the production and distribution of chemicals for various applications, such as pharmaceuticals, plastics, and fertilizers.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Chemicals Industry',
    featureLimit: 0,
    icon: '<Science />',
    isEnabled: true,
    isFeatured: false,
    key: 'chemicals-industry',
    limit: 20,
    mantisCategories: 'chemicals_industry',
    metaDescription:
      'Stay up-to-date with the latest Chemicals Industry news from around the world with My News Assistant. Get the latest updates, reviews, and insights on the latest trends, technologies, and deals in the chemicals industry.',
    metaImage: '',
    metaTitle: 'Latest Chemicals Industry News',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  construction: {
    age: 30,
    categorySlug: 'construction',
    categoryDescription: 'Industries involved in the design, planning, and building of structures and infrastructure, such as buildings, roads, and bridges.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Construction',
    featureLimit: 0,
    icon: '<Construction />',
    isEnabled: true,
    isFeatured: false,
    key: 'construction',
    limit: 20,
    mantisCategories: 'construction',
    metaDescription:
      'Stay up-to-date with the latest Construction news from around the world with My News Assistant. Get the latest updates, reviews, and insights on the latest projects, technologies, and deals in the construction industry.',
    metaImage: '',
    metaTitle: 'Latest Construction News',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  energy: {
    age: 30,
    categorySlug: 'energy',
    categoryDescription:
      'Industries involved in the production, distribution, and consumption of energy, including fossil fuels, renewable energy, and nuclear power.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Energy',
    featureLimit: 0,
    icon: '<ElectricBolt />',
    isEnabled: true,
    isFeatured: false,
    key: 'energy',
    limit: 20,
    mantisCategories: 'energy',
    metaDescription:
      'Stay updated with the latest news and trends in the energy industry. Get expert insights and analysis on renewable energy, oil, gas, and electricity markets. Read more on My News Assistant.',
    metaImage: '',
    metaTitle: 'Latest Energy News and Updates',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  'iron-steel-industry': {
    age: 30,
    categorySlug: 'iron-steel-industry',
    categoryDescription:
      'Industries involved in the production and processing of iron and steel for various applications, such as construction, machinery, and transportation.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Iron Steel Industry',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'iron-steel-industry',
    limit: 20,
    mantisCategories: 'iron_steel_industry',
    metaDescription:
      'Get the latest news and insights on the iron and steel industry. Stay informed about the global steel market, new technologies, and sustainability initiatives. Read more on My News Assistant.',
    metaImage: '',
    metaTitle: 'Latest News and Updates on Iron and Steel Industry',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  logistics: {
    age: 30,
    categorySlug: 'logistics',
    categoryDescription: 'The planning, management, and execution of the transportation, storage, and distribution of goods and materials.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Logistics',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'logistics',
    limit: 20,
    mantisCategories: 'logistics',
    metaDescription:
      'Stay informed with the latest news and trends in logistics. Get expert insights and analysis on supply chain management, transportation, and distribution. Read more on My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay Informed with Latest Logistics News and Updates',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  manufacturing: {
    age: 30,
    categorySlug: 'manufacturing',
    categoryDescription:
      'Industries involved in the production of goods, typically using machinery and assembly lines, such as automobiles, electronics, and consumer products.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Manufacturing',
    featureLimit: 0,
    icon: '<Factory />',
    isEnabled: true,
    isFeatured: false,
    key: 'manufacturing',
    limit: 20,
    mantisCategories: 'manufacturing',
    metaDescription:
      'Stay updated with the latest news and trends in the manufacturing industry. Get expert insights and analysis on automation, digital transformation, and Industry 4.0. Read more on My News Assistant.',
    metaImage: '',
    metaTitle: 'Latest News and Updates on Manufacturing Industry',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  metals: {
    age: 30,
    categorySlug: 'metals',
    categoryDescription:
      'Industries involved in the extraction, processing, and distribution of metals for various applications, such as construction, manufacturing, and transportation.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Metals',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'metals',
    limit: 20,
    mantisCategories: 'metals',
    metaDescription:
      'Get the latest news and insights on the metals industry. Stay informed about the global metal markets, new technologies, and sustainability initiatives. Read more on My News Assistant.',
    metaImage: '',
    metaTitle: 'Latest News and Updates on Metals Industry',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  'mining-industry': {
    age: 30,
    categorySlug: 'mining-industry',
    categoryDescription: 'Industries involved in the extraction of minerals and resources from the earth, such as coal, metals, and oil.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Mining Industry',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'mining-industry',
    limit: 20,
    mantisCategories: 'mining_industry',
    metaDescription:
      'Stay informed with the latest news and trends in the mining industry. Get expert insights and analysis on mineral exploration, production, and sustainability. Read more on My News Assistant.',
    metaImage: '',
    metaTitle: 'Stay Informed with Latest News and Updates on Mining Industry',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  'paper-industry': {
    age: 30,
    categorySlug: 'paper-industry',
    categoryDescription: 'Industries involved in the production and distribution of paper and paper products, such as packaging, printing, and tissue.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Paper Industry',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'paper-industry',
    limit: 20,
    mantisCategories: 'paper_industry',
    metaDescription:
      'Stay up-to-date with the latest news and trends in the paper industry with My News Assistant. Get breaking news, industry updates, and more.',
    metaImage: '',
    metaTitle: 'Latest News and Updates on the Paper Industry',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  'pharmaceutical-industry': {
    age: 30,
    categorySlug: 'pharmaceutical-industry',
    categoryDescription: 'Industries involved in the research, development, and production of pharmaceutical drugs for various medical conditions.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Pharmaceutical Industry',
    featureLimit: 0,
    icon: '<Medication />',
    isEnabled: true,
    isFeatured: false,
    key: 'pharmaceutical-industry',
    limit: 20,
    mantisCategories: 'pharmaceutical_industry',
    metaDescription:
      'Stay informed on the pharmaceutical industry with My News Assistant. Get the latest news, analysis, and insights on drug development, clinical trials, and more.',
    metaImage: '',
    metaTitle: 'Breaking News and Insights on the Pharmaceutical Industry',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  publishing: {
    age: 30,
    categorySlug: 'publishing',
    categoryDescription: 'Industries involved in the production and distribution of printed materials, such as books, magazines, and newspapers.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Publishing',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'publishing',
    limit: 20,
    mantisCategories: 'publishing',
    metaDescription:
      'Get the latest news, trends, and analysis on the publishing industry with My News Assistant. Stay ahead of the curve with breaking news and insights.',
    metaImage: '',
    metaTitle: 'Stay Informed on the Publishing Industry with My News Assistant',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  'record-company': {
    age: 30,
    categorySlug: 'record-company',
    categoryDescription: 'Companies involved in the production and distribution of recorded music, such as albums and singles.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Record Company',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'record-company',
    limit: 20,
    mantisCategories: 'record_company',
    metaDescription:
      'Stay informed on the music industry with My News Assistant. Get the latest news, analysis, and insights on record labels, artists, and more.',
    metaImage: '',
    metaTitle: 'Breaking News and Insights on Record Companies',
    relatedCategories: '',
    sectionSlug: 'entertainment',
    subSectionSlug: 'music',
    tags: '',
  },
  shipbuilding: {
    age: 30,
    categorySlug: 'shipbuilding',
    categoryDescription: 'Industries involved in the design, construction, and maintenance of ships and other marine vessels.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Shipbuilding',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'shipbuilding',
    limit: 20,
    mantisCategories: 'shipbuilding',
    metaDescription:
      'Get the latest news, analysis, and trends on the shipbuilding industry with My News Assistant. Stay informed on the latest developments in naval architecture, ship design, and more.',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date on the Shipbuilding Industry with My News Assistant',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  'textile-industry': {
    age: 30,
    categorySlug: 'textile-industry',
    categoryDescription:
      'Industries involved in the production and distribution of textiles and fabrics for various applications, such as clothing, upholstery, and bedding.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Textile Industry',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'textile-industry',
    limit: 20,
    mantisCategories: 'textile_industry',
    metaDescription:
      'Get breaking news, analysis, and insights on the textile industry with My News Assistant. Stay informed on the latest trends in fashion, sustainability, and more.',
    metaImage: '',
    metaTitle: 'Stay Ahead of the Curve with My News Assistant on the Textile Industry',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  'war-industry': {
    age: 30,
    categorySlug: 'war-industry',
    categoryDescription: 'Industries involved in the production and distribution of weapons, military equipment, and technology for military purposes.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'War Industry',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'war-industry',
    limit: 20,
    mantisCategories: 'war_industry',
    metaDescription:
      'Stay informed on the latest news and developments in the war industry with My News Assistant. Get breaking news, analysis, and insights on defense contractors, military technology, and more.',
    metaImage: '',
    metaTitle: 'Breaking News and Analysis on the War Industry',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: '',
    tags: '',
  },
  'job-fairs': {
    age: 30,
    categorySlug: 'job-fairs',
    categoryDescription:
      'Events where employers and job seekers can meet and discuss job opportunities, typically featuring booths or tables where employers can provide information and accept resumes.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Job Fairs',
    featureLimit: 0,
    icon: '<WorkOutline />',
    isEnabled: true,
    isFeatured: false,
    key: 'job-fairs',
    limit: 20,
    mantisCategories: 'job_fairs',
    metaDescription:
      'Stay up-to-date on the latest job fairs and career expos with My News Assistant. Discover new job opportunities and make valuable connections with employers in your field.',
    metaImage: '',
    metaTitle: 'Find Your Next Job Opportunity with My News Assistant | Job Fairs',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'business',
    tags: '',
  },
  'Job-search': {
    age: 30,
    categorySlug: 'Job-search',
    categoryDescription:
      'The process of seeking employment, including activities such as researching job openings, submitting resumes and applications, and preparing for interviews.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Job Search',
    featureLimit: 0,
    icon: '<Search />',
    isEnabled: true,
    isFeatured: false,
    key: 'Job-search',
    limit: 20,
    mantisCategories: 'job_search',
    metaDescription:
      'Get expert advice and insights on your job search with My News Assistant. Find the latest job postings, learn about hiring trends, and get tips on resume writing and interviewing.',
    metaImage: '',
    metaTitle: 'Expert Tips and Advice for Your Job Search',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'business',
    tags: '',
  },
  nursing: {
    age: 30,
    categorySlug: 'nursing',
    categoryDescription:
      'A profession focused on the care and treatment of patients, typically in healthcare settings such as hospitals, clinics, and long-term care facilities.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Nursing',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'nursing',
    limit: 20,
    mantisCategories: 'nursing',
    metaDescription:
      'Get the latest news, analysis, and trends in the nursing industry with My News Assistant. Stay informed on the latest developments in healthcare, nursing education, and more.',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date on the Nursing Industry with My News Assistant',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  'resume-writing-advice': {
    age: 30,
    categorySlug: 'resume-writing-advice',
    categoryDescription: 'Tips and advice for creating effective resumes, including formatting, content, and keywords.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Resume Writing Advice',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'resume-writing-advice',
    limit: 20,
    mantisCategories: 'resume_writing_advice',
    metaDescription:
      'Get expert advice and tips for writing your resume with My News Assistant. Learn how to write a standout resume that highlights your skills and accomplishments.',
    metaImage: '',
    metaTitle: 'Expert Advice and Tips for Writing Your Resume',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'business',
    tags: '',
  },
  telecommuting: {
    age: 30,
    categorySlug: 'telecommuting',
    categoryDescription:
      'A work arrangement where employees can work from home or other remote locations, using technology to communicate with their colleagues and complete their work.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Telecommuting',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'telecommuting',
    limit: 20,
    mantisCategories: 'telecommuting',
    metaDescription:
      'Get the latest news, analysis, and best practices on telecommuting with My News Assistant. Stay informed on the latest trends in remote work, learn how to be more productive, and more.',
    metaImage: '',
    metaTitle: 'Stay Informed on Telecommuting Trends and Best Practices',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'business',
    tags: '',
  },
  'us-military': {
    age: 30,
    categorySlug: 'us-military',
    categoryDescription: 'The armed forces of the United States, including the Army, Navy, Air Force, Marines, and Coast Guard.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'US Military',
    featureLimit: 0,
    icon: '<MilitaryTech />',
    isEnabled: true,
    isFeatured: false,
    key: 'us-military',
    limit: 20,
    mantisCategories: 'us_military',
    metaDescription:
      'Get the latest news, analysis, and insights on the US military with My News Assistant. Stay informed on the latest developments in defense policy, military technology, and more.',
    metaImage: '',
    metaTitle: 'Stay Informed on the Latest News and Trends in the US Military',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  adoption: {
    age: 30,
    categorySlug: 'adoption',
    categoryDescription: "The legal process of assuming parental responsibilities for a child who is not biologically one's own.",
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Adoption',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'adoption',
    limit: 20,
    mantisCategories: 'adoption',
    metaDescription:
      'Get expert advice and tips on adoption with My News Assistant. Learn about the adoption process, find resources for adoptive parents, and stay informed on the latest news and trends in adoption.',
    metaImage: '',
    metaTitle: 'Expert Advice and Tips on Adoption',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  'babies-toddlers': {
    age: 30,
    categorySlug: 'babies-toddlers',
    categoryDescription:
      'Information and resources for parents and caregivers of infants and young children, including advice on feeding, sleep, development, and health.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Babies Toddlers',
    featureLimit: 0,
    icon: '<BedroomBaby />',
    isEnabled: true,
    isFeatured: false,
    key: 'babies-toddlers',
    limit: 20,
    mantisCategories: 'babies_toddlers',
    metaDescription:
      'Get expert advice and tips for raising babies and toddlers with My News Assistant. Stay informed on the latest news and trends in parenting, find resources for new parents, and more.',
    metaImage: '',
    metaTitle: 'Expert Advice and Tips for Parents of Babies and Toddlers',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  children: {
    age: 30,
    categorySlug: 'children',
    categoryDescription: 'Resources and information for parents and caregivers of children of all ages, including advice on education, behavior, and health.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Children',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'children',
    limit: 20,
    mantisCategories: 'children',
    metaDescription:
      'Get expert advice and tips for raising children with My News Assistant. Stay informed on the latest news and trends in parenting, find resources for parents of school-aged children, and more.',
    metaImage: '',
    metaTitle: 'Expert Advice and Tips for Parents of Children',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  eldercare: {
    age: 30,
    categorySlug: 'eldercare',
    categoryDescription:
      'The care and support of elderly individuals, typically involving assistance with daily activities, healthcare management, and social engagement.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Eldercare',
    featureLimit: 0,
    icon: '<Elderly />',
    isEnabled: true,
    isFeatured: false,
    key: 'eldercare',
    limit: 20,
    mantisCategories: 'eldercare',
    metaDescription:
      'Get expert advice and tips for caring for the elderly with My News Assistant. Learn about eldercare resources, find tips for managing the challenges of aging, and more.',
    metaImage: '',
    metaTitle: 'Expert Advice and Tips for Eldercare',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  motherhood: {
    age: 30,
    categorySlug: 'motherhood',
    categoryDescription:
      'The experience of being a mother, including the joys and challenges of raising children and balancing family and other responsibilities.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Motherhood',
    featureLimit: 0,
    icon: '<PregnantWoman />',
    isEnabled: true,
    isFeatured: false,
    key: 'motherhood',
    limit: 20,
    mantisCategories: 'motherhood',
    metaDescription:
      'Get expert advice and tips for motherhood with My News Assistant. Learn about the challenges and joys of motherhood, find resources for new moms, and stay informed on the latest news and trends in parenting.',
    metaImage: '',
    metaTitle: 'Expert Advice and Tips for Motherhood',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  'parenting-teens': {
    age: 30,
    categorySlug: 'parenting-teens',
    categoryDescription: 'Advice and resources for parents and caregivers of teenagers, including information on behavior, communication, and education.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Parenting Teens',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'parenting-teens',
    limit: 20,
    mantisCategories: 'parenting_teens',
    metaDescription:
      'Get expert advice and tips for parenting teens with My News Assistant. Learn about the challenges and joys of raising teenagers, find resources for parents of teenagers, and stay informed on the latest news and trends in parenting.',
    metaImage: '',
    metaTitle: 'Expert Advice and Tips for Parenting Teens',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  'special-needs-kids': {
    age: 30,
    categorySlug: 'special-needs-kids',
    categoryDescription:
      'Information and resources for parents and caregivers of children with special needs, including advice on healthcare, education, and social support.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Special needs kids',
    featureLimit: 0,
    icon: '<Accessible />',
    isEnabled: true,
    isFeatured: false,
    key: 'special-needs-kids',
    limit: 20,
    mantisCategories: 'special_needs_kids',
    metaDescription:
      'Get expert advice and resources for raising special needs kids with My News Assistant. Find resources for parents of children with disabilities, learn about special education services, and stay informed on the latest news and trends in special needs parenting.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources for Special Needs Kids',
    relatedCategories: '',
    sectionSlug: 'topic',
    subSectionSlug: '',
    tags: '',
  },
  bank: {
    age: 30,
    categorySlug: 'bank',
    categoryDescription: 'Financial institutions that provide various services such as savings accounts, loans, credit cards, and investment services.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Bank',
    featureLimit: 0,
    icon: '<AccountBalance />',
    isEnabled: true,
    isFeatured: false,
    key: 'bank',
    limit: 20,
    mantisCategories: 'bank',
    metaDescription:
      'Get the latest news, analysis, and insights on banking with My News Assistant. Stay informed on the latest developments in the banking industry, including regulatory changes, digital banking trends, and more.',
    metaImage: '',
    metaTitle: 'Stay Informed on the Latest News and Trends in Banking',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'finance',
    tags: '',
  },
  'grants-scholarships-financial-aid': {
    age: 30,
    categorySlug: 'grants-scholarships-financial-aid',
    categoryDescription: 'Programs and resources that provide financial assistance to students and individuals, including grants, scholarships, and loans.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Grants and Financial Aid',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'grants-scholarships-financial-aid',
    limit: 20,
    mantisCategories: 'grants_scholarships_financial_aid',
    metaDescription:
      'Get expert advice and resources on grants and financial aid with My News Assistant. Learn about the latest financial aid options, find resources for scholarship applications, and more.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources on Grants and Financial Aid',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'finance',
    tags: '',
  },
  'personal-finance': {
    age: 30,
    categorySlug: 'personal-finance',
    categoryDescription: "The management of one's personal finances, including budgeting, investing, and debt management.",
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Personal Finance',
    featureLimit: 0,
    icon: '<AccountBalanceWallet />',
    isEnabled: true,
    isFeatured: false,
    key: 'personal-finance',
    limit: 20,
    mantisCategories: 'personal_finance',
    metaDescription:
      'Get expert advice and tips for managing your personal finances with My News Assistant. Learn about budgeting, saving for retirement, managing debt, and more.',
    metaImage: '',
    metaTitle: 'Expert Advice and Tips for Personal Finance',
    relatedCategories: '',
    sectionSlug: 'business-and-finance',
    subSectionSlug: 'finance',
    tags: '',
  },
  gastronomy: {
    age: 30,
    categorySlug: 'gastronomy',
    categoryDescription: 'The study and appreciation of food and its culture, including cooking techniques, regional cuisine, and food history.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Gastronomy',
    featureLimit: 0,
    icon: '<Restaurant />',
    isEnabled: true,
    isFeatured: false,
    key: 'gastronomy',
    limit: 20,
    mantisCategories: 'gastronomy',
    metaDescription:
      'Get the latest news, analysis, and insights on gastronomy with My News Assistant. Stay informed on the latest culinary trends, restaurant reviews, and more.',
    metaImage: '',
    metaTitle: 'Stay Informed on the Latest News and Trends in Gastronomy',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'food-and-drink',
    tags: '',
  },
  addiction: {
    age: 30,
    categorySlug: 'addiction',
    categoryDescription:
      "A persistent and compulsive dependence on a substance or behavior, often resulting in negative consequences for the individual's health and well-being.",
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Addiction',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'addiction',
    limit: 20,
    mantisCategories: 'addiction',
    metaDescription:
      'Get expert advice and resources on addiction with My News Assistant. Learn about the latest treatments and therapies for addiction, find resources for addicts and their families, and stay informed on the latest news and trends in addiction recovery.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources on Addiction',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'health-fitness-nutrition',
    tags: '',
  },
  aging: {
    age: 30,
    categorySlug: 'aging',
    categoryDescription:
      'The process of growing older, including changes in physical, cognitive, and social functioning, and the challenges and opportunities associated with later life.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Aging',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'aging',
    limit: 20,
    mantisCategories: 'aging',
    metaDescription:
      'Get expert advice and tips for aging gracefully with My News Assistant. Learn about health and wellness tips for seniors, find resources for caregivers, and stay informed on the latest news and trends in aging.',
    metaImage: '',
    metaTitle: 'Expert Advice and Tips on Aging Gracefully',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'health-fitness-nutrition',
    tags: '',
  },
  'alternative-medicine': {
    age: 30,
    categorySlug: 'alternative-medicine',
    categoryDescription: 'Therapies and treatments that fall outside of mainstream medicine, such as herbal remedies, acupuncture, and chiropractic care.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Alternative Medicine',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'alternative-medicine',
    limit: 20,
    mantisCategories: 'alternative_medicine',
    metaDescription:
      'Get expert advice and resources on alternative medicine with My News Assistant. Learn about the latest treatments and therapies in natural medicine, find resources for alternative health practitioners, and stay informed on the latest news and trends in alternative medicine.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources on Alternative Medicine',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'health-fitness-nutrition',
    tags: '',
  },
  'dental-care': {
    age: 30,
    categorySlug: 'dental-care',
    categoryDescription: 'The practice of maintaining oral health, including routine cleanings, check-ups, and treatments for dental problems.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Dental Care',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'dental-care',
    limit: 20,
    mantisCategories: 'dental_care',
    metaDescription:
      'Get expert advice and tips for maintaining good dental health with My News Assistant. Learn about oral hygiene, find resources for finding a dentist, and stay informed on the latest news and trends in dental care.',
    metaImage: '',
    metaTitle: 'Expert Advice and Tips for Dental Care',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'health-fitness-nutrition',
    tags: '',
  },
  disease: {
    age: 30,
    categorySlug: 'disease',
    categoryDescription: "Disorders and conditions that affect the body's normal functioning, often requiring medical treatment or management.",
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Disease',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'disease',
    limit: 20,
    mantisCategories: 'disease',
    metaDescription:
      'Get the latest news, analysis, and insights on disease with My News Assistant. Stay informed on the latest medical breakthroughs, find resources for patients and caregivers, and learn about disease prevention strategies.',
    metaImage: '',
    metaTitle: 'Stay Informed on the Latest News and Trends in Disease',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'health-fitness-nutrition',
    tags: '',
  },
  'abuse-support': {
    age: 30,
    categorySlug: 'abuse-support',
    categoryDescription:
      'Resources and support for individuals who have experienced physical, emotional, or sexual abuse, including counseling and advocacy services.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Abuse Support',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'abuse-support',
    limit: 20,
    mantisCategories: 'incest_abuse_support',
    metaDescription:
      'Get expert advice and resources on abuse support with My News Assistant. Find resources for victims of abuse and their families, learn about abuse prevention strategies, and stay informed on the latest news and trends in abuse support.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources on Abuse Support',
    relatedCategories: '',
    sectionSlug: 'topic',
    subSectionSlug: '',
    tags: '',
  },
  therapy: {
    age: 30,
    categorySlug: 'therapy',
    categoryDescription:
      'The practice of providing psychological or emotional support and treatment to individuals experiencing mental health challenges, including talk therapy and medication management.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Therapy',
    featureLimit: 0,
    icon: '<Spa />',
    isEnabled: true,
    isFeatured: false,
    key: 'therapy',
    limit: 20,
    mantisCategories: 'therapy',
    metaDescription:
      'Get expert advice and resources on therapy with My News Assistant. Learn about the latest therapies and treatments for mental health, find resources for finding a therapist, and stay informed on the latest news and trends in therapy and mental health.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources on Therapy',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'health-fitness-nutrition',
    tags: '',
  },
  astrology: {
    age: 30,
    categorySlug: 'astrology',
    categoryDescription: 'The study of celestial bodies and their influence on human behavior and events, often used for divination or horoscopes.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Astrology',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'astrology',
    limit: 20,
    mantisCategories: 'astrology',
    metaDescription:
      'Get the latest news, analysis, and insights on astrology with My News Assistant. Stay informed on the latest developments in astrology, including horoscopes, zodiac signs, and more.',
    metaImage: '',
    metaTitle: 'Stay Informed on the Latest News and Trends in Astrology',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  cigars: {
    age: 30,
    categorySlug: 'cigars',
    categoryDescription: 'Tobacco products made from fermented and aged tobacco leaves, often associated with luxury and leisure.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Cigars',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'cigars',
    limit: 20,
    mantisCategories: 'cigars',
    metaDescription:
      'Get the latest news, analysis, and insights on cigars with My News Assistant. Stay informed on the latest developments in the cigar industry, find reviews on top brands, and more.',
    metaImage: '',
    metaTitle: 'Stay Informed on the Latest News and Trends in Cigars',
    relatedCategories: '',
    sectionSlug: 'topic',
    subSectionSlug: '',
    tags: '',
  },
  collecting: {
    age: 30,
    categorySlug: 'collecting',
    categoryDescription: 'The hobby of gathering and organizing items of interest, such as stamps, coins, or art.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Collecting',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'collecting',
    limit: 20,
    mantisCategories: 'collecting',
    metaDescription:
      'Get the latest news, analysis, and insights on collecting with My News Assistant. Stay informed on the latest developments in the world of collectibles, find resources for collectors, and more.',
    metaImage: '',
    metaTitle: 'Stay Informed on the Latest News and Trends in Collecting',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  'getting-published': {
    age: 30,
    categorySlug: 'getting-published',
    categoryDescription:
      'Tips and advice for aspiring writers on how to get their work published, including writing, editing, and submitting to publishers and agents.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Getting Published',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'getting-published',
    limit: 20,
    mantisCategories: 'getting_published',
    metaDescription:
      'Get expert advice and resources for getting published with My News Assistant. Learn about the publishing industry, find resources for writers, and stay informed on the latest news and trends in publishing.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources for Getting Published',
    relatedCategories: '',
    sectionSlug: 'topic',
    subSectionSlug: '',
    tags: '',
  },
  guitar: {
    age: 30,
    categorySlug: 'guitar',
    categoryDescription: 'A musical instrument with strings that can be played by plucking or strumming, often used in various genres of music.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Guitar',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'guitar',
    limit: 20,
    mantisCategories: 'guitar',
    metaDescription:
      'Get expert advice and resources for guitar players with My News Assistant. Learn about the latest guitar techniques, find resources for buying a guitar, and stay informed on the latest news and trends in guitar playing.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources for Guitar Players',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  'home-recording': {
    age: 30,
    categorySlug: 'home-recording',
    categoryDescription:
      'The process of recording music or audio at home using digital recording equipment and software, often used by independent musicians and producers.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Home_recording',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'home-recording',
    limit: 20,
    mantisCategories: 'home_recording',
    metaDescription:
      'Get expert advice and resources for home recording with My News Assistant. Learn about the latest recording techniques, find resources for buying recording equipment, and stay informed on the latest news and trends in home recording.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources for Home Recording',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  'inventors-patents': {
    age: 30,
    categorySlug: 'inventors-patents',
    categoryDescription:
      'The process of obtaining legal protection for an invention, including the application process and the rights and responsibilities of patent holders.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Patents',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'inventors-patents',
    limit: 20,
    mantisCategories: 'inventors_patents',
    metaDescription:
      'Get expert advice and resources for inventors and patents with My News Assistant. Learn about the patent process, find resources for filing a patent, and stay informed on the latest news and trends in patent law and intellectual property.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources for Inventors and Patents',
    relatedCategories: '',
    sectionSlug: 'topic',
    subSectionSlug: '',
    tags: '',
  },
  magic: {
    age: 30,
    categorySlug: 'magic',
    categoryDescription: 'The performance of illusions and sleight of hand, often used for entertainment or artistic expression.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Magic',
    featureLimit: 0,
    icon: '<AutoFixHigh />',
    isEnabled: true,
    isFeatured: false,
    key: 'magic',
    limit: 20,
    mantisCategories: 'magic_illusion',
    metaDescription:
      'Get the latest news, analysis, and insights on magic with My News Assistant. Stay informed on the latest developments in the world of magic, find resources for learning magic tricks, and more.',
    metaImage: '',
    metaTitle: 'Stay Informed on the Latest News and Trends in Magic',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  needlework: {
    age: 30,
    categorySlug: 'needlework',
    categoryDescription: 'Activities involving sewing, embroidery, or other forms of fabric and needlecraft.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Needlework',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'needlework',
    limit: 20,
    mantisCategories: 'needlework',
    metaDescription:
      'Get expert advice and resources for needlework with My News Assistant. Learn about the latest needlework techniques, find resources for buying supplies, and stay informed on the latest news and trends in needlework.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources for Needlework',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  paranormal: {
    age: 30,
    categorySlug: 'paranormal',
    categoryDescription:
      'Phenomena that fall outside of mainstream scientific understanding or explanation, often associated with ghosts, UFOs, and psychic abilities.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Paranormal',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'paranormal',
    limit: 20,
    mantisCategories: 'paranormal_phenomena',
    metaDescription:
      'Get the latest news, analysis, and insights on the paranormal with My News Assistant. Stay informed on the latest developments in the world of the paranormal, find resources for learning about supernatural phenomena, and more.',
    metaImage: '',
    metaTitle: 'Stay Informed on the Latest News and Trends in the Paranormal',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  appliances: {
    age: 30,
    categorySlug: 'appliances',
    categoryDescription: 'Electronic devices used for various household tasks, such as cooking, cleaning, and laundry.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Appliances',
    featureLimit: 0,
    icon: '<Power />',
    isEnabled: true,
    isFeatured: false,
    key: 'appliances',
    limit: 20,
    mantisCategories: 'appliances',
    metaDescription:
      'Get the latest news, analysis, and insights on appliances with My News Assistant. Stay informed on the latest developments in the world of appliances, find reviews on top brands, and more.',
    metaImage: '',
    metaTitle: 'Stay Informed on the Latest News and Trends in Appliances',
    relatedCategories: '',
    sectionSlug: 'topic',
    subSectionSlug: '',
    tags: '',
  },
  'environmental-safety': {
    age: 30,
    categorySlug: 'environmental-safety',
    categoryDescription:
      'Practices and policies aimed at protecting the natural environment and preventing harm to human health, such as air and water pollution control and hazardous waste management.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Environmental Safety',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'environmental-safety',
    limit: 20,
    mantisCategories: 'environmental_safety',
    metaDescription:
      'Get expert advice and resources on environmental safety with My News Assistant. Learn about the latest safety regulations and standards, find resources for ensuring a safe environment, and stay informed on the latest news and trends in environmental safety.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources on Environmental Safety',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  laundry: {
    age: 30,
    categorySlug: 'laundry',
    categoryDescription: 'The process of cleaning and caring for clothing and other textiles, often involving washing, drying, and ironing.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Laundry',
    featureLimit: 0,
    icon: '<LocalLaundryService />',
    isEnabled: true,
    isFeatured: false,
    key: 'laundry',
    limit: 20,
    mantisCategories: 'laundry',
    metaDescription:
      'Get expert advice and resources for laundry care with My News Assistant. Learn about the latest laundry techniques and products, find resources for removing stains, and stay informed on the latest news and trends in laundry care.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources for Laundry Care',
    relatedCategories: '',
    sectionSlug: 'topic',
    subSectionSlug: '',
    tags: '',
  },
  'luxury-homes': {
    age: 30,
    categorySlug: 'luxury-homes',
    categoryDescription: 'High-end residences featuring luxurious amenities, finishes, and locations.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Luxury_homes',
    featureLimit: 0,
    icon: '<Villa />',
    isEnabled: true,
    isFeatured: false,
    key: 'luxury-homes',
    limit: 20,
    mantisCategories: 'luxury_homes',
    metaDescription:
      'Get the latest news, analysis, and insights on luxury homes with My News Assistant. Stay informed on the latest developments in the world of luxury real estate, find resources for buying or selling a luxury home, and more.',
    metaImage: '',
    metaTitle: 'Stay Informed on the Latest News and Trends in Luxury Homes',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  nursery: {
    age: 30,
    categorySlug: 'nursery',
    categoryDescription:
      'A facility or room used for the care and education of young children, often providing a safe and stimulating environment for play and learning.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Nursery',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'nursery',
    limit: 20,
    mantisCategories: 'nursery_playroom',
    metaDescription:
      'Get expert advice and resources for creating a safe and stylish nursery with My News Assistant. Learn about the latest trends in nursery design, find resources for choosing safe and high-quality products for your baby, and stay informed on the latest news and trends in nursery care.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources for Creating a Safe and Stylish Nursery',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  'pest-control': {
    age: 30,
    categorySlug: 'pest-control',
    categoryDescription: 'The management and prevention of pests, such as insects, rodents, and other animals, that can cause damage or health hazards.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Pest Control',
    featureLimit: 0,
    icon: '<PestControl />',
    isEnabled: true,
    isFeatured: false,
    key: 'pest-control',
    limit: 20,
    mantisCategories: 'pest_control',
    metaDescription:
      'Get expert advice and resources for pest control with My News Assistant. Learn about the latest techniques for preventing and eliminating pests, find resources for choosing the right pest control products, and stay informed on the latest news and trends in pest control.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources for Pest Control',
    relatedCategories: '',
    sectionSlug: 'topic',
    subSectionSlug: '',
    tags: '',
  },
  'remodeling-construction': {
    age: 30,
    categorySlug: 'remodeling-construction',
    categoryDescription: 'The process of renovating or constructing buildings and other structures, often involving design, planning, and building activities.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Remodeling Construction',
    featureLimit: 0,
    icon: '<Construction />',
    isEnabled: true,
    isFeatured: false,
    key: 'remodeling-construction',
    limit: 20,
    mantisCategories: 'remodeling_construction',
    metaDescription:
      'Get expert advice and resources for home remodeling and construction with My News Assistant. Learn about the latest remodeling and construction techniques, find resources for hiring contractors and getting permits, and stay informed on the latest news and trends in home remodeling and construction.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources for Home Remodeling and Construction',
    relatedCategories: '',
    sectionSlug: 'topic',
    subSectionSlug: '',
    tags: '',
  },
  'armed-forces': {
    age: 30,
    categorySlug: 'armed-forces',
    categoryDescription:
      'Military organisations responsible for national defense and security, often involving various branches and specialties such as infantry, aviation, and logistics.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Armed Forces',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'armed-forces',
    limit: 20,
    mantisCategories: 'armed_forces',
    metaDescription:
      'Stay up-to-date with the latest news and analysis on the armed forces with My News Assistant. Get expert insight on military strategy, find resources for military families and veterans, and stay informed on the latest developments in military technology and policy.',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest News and Analysis on the Armed Forces',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: '',
    tags: '',
  },
  commentary: {
    age: 30,
    categorySlug: 'commentary',
    categoryDescription: 'Analysis and opinion on current events or cultural phenomena, often provided by journalists, scholars, and public figures.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Commentary',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'commentary',
    limit: 20,
    mantisCategories: 'commentary',
    metaDescription:
      'Get expert analysis and commentary on current events with My News Assistant. Stay informed on the latest news and trends, and get insightful analysis and commentary from top experts in the field.',
    metaImage: '',
    metaTitle: 'Expert Analysis and Commentary on Current Events',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: 'politics',
    tags: '',
  },
  government: {
    age: 30,
    categorySlug: 'government',
    categoryDescription:
      'The institutions and processes that make and enforce laws and regulations, manage public resources, and provide services to citizens.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Government',
    featureLimit: 0,
    icon: '<AccountBalance />',
    isEnabled: true,
    isFeatured: false,
    key: 'government',
    limit: 20,
    mantisCategories: 'government',
    metaDescription:
      'Stay informed on the latest news and developments in government with My News Assistant. Get expert analysis on policy and legislation, find resources for understanding how government works, and stay up-to-date on the latest news and trends in government.',
    metaImage: '',
    metaTitle: 'Stay Informed on the Latest News and Developments in Government',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: 'politics',
    tags: '',
  },
  'espionage-intelligence': {
    age: 30,
    categorySlug: 'espionage-intelligence',
    categoryDescription:
      'Activities involving the gathering and analysis of information for national security purposes, often conducted by government agencies or intelligence organisations.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Espionage',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'espionage-intelligence',
    limit: 20,
    mantisCategories: 'espionage_intelligence',
    metaDescription:
      'Stay up-to-date with the latest news and analysis on espionage and intelligence with My News Assistant. Get expert insight on national security issues, find resources for understanding intelligence operations, and stay informed on the latest developments in intelligence technology and policy.',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest News and Analysis on Espionage and Intelligence',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: '',
    tags: '',
  },
  immigration: {
    age: 30,
    categorySlug: 'immigration',
    categoryDescription:
      'The process of moving to a new country, often involving legal and bureaucratic procedures, cultural adjustment, and social integration.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Immigration',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'immigration',
    limit: 20,
    mantisCategories: 'immigration',
    metaDescription:
      'Get expert advice and resources for immigration with My News Assistant. Learn about the latest immigration policies and laws, find resources for navigating the immigration process, and stay informed on the latest news and trends in immigration.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources for Immigration',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: 'politics',
    tags: '',
  },
  'law-enforcement': {
    age: 30,
    categorySlug: 'law-enforcement',
    categoryDescription:
      'The agencies and individuals responsible for upholding and enforcing laws and regulations, often involving investigation, arrest, and prosecution of criminal activities.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Law Enforcement',
    featureLimit: 0,
    icon: '<LocalPolice />',
    isEnabled: true,
    isFeatured: false,
    key: 'law-enforcement',
    limit: 20,
    mantisCategories: 'law_enforcement',
    metaDescription:
      'Get expert advice and resources for law enforcement with My News Assistant. Learn about the latest law enforcement techniques and technologies, find resources for training and education, and stay informed on the latest news and trends in law enforcement.',
    metaImage: '',
    metaTitle: 'Expert Advice and Resources for Law Enforcement',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: 'politics',
    tags: '',
  },
  'legal-issues': {
    age: 30,
    categorySlug: 'legal-issues',
    categoryDescription: 'Matters related to the law and legal system, including civil and criminal law, contracts, and disputes.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Legal',
    featureLimit: 0,
    icon: '<Gavel />',
    isEnabled: true,
    isFeatured: false,
    key: 'legal-issues',
    limit: 20,
    mantisCategories: 'legal_issues',
    metaDescription:
      'Stay up-to-date with the latest legal news and analysis with My News Assistant. Get expert insight on legal issues, find resources for understanding the legal system, and stay informed on the latest developments in law and policy.',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest Legal News and Analysis',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: 'politics',
    tags: '',
  },
  apartments: {
    age: 30,
    categorySlug: 'apartments',
    categoryDescription: 'Housing units typically located within multi-unit buildings, often rented or leased by tenants.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Apartments',
    featureLimit: 0,
    icon: '<Apartment />',
    isEnabled: true,
    isFeatured: false,
    key: 'apartments',
    limit: 20,
    mantisCategories: 'apartments',
    metaDescription:
      'Find the latest news and trends on apartments with My News Assistant. Get expert advice on finding the right apartment, learn about the latest apartment design trends, and stay informed on the latest developments in the apartment market.',
    metaImage: '',
    metaTitle: 'Find the Latest News and Trends on Apartments',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  architects: {
    age: 30,
    categorySlug: 'architects',
    categoryDescription:
      'Professionals trained in the design and planning of buildings and other structures, often working with clients to create functional and aesthetically pleasing spaces.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Architects',
    featureLimit: 0,
    icon: '<Architecture />',
    isEnabled: true,
    isFeatured: false,
    key: 'architects',
    limit: 20,
    mantisCategories: 'architects',
    metaDescription:
      'Stay up-to-date with the latest news and trends on architects with My News Assistant. Get expert advice on finding the right architect for your project, learn about the latest architectural design trends, and stay informed on the latest developments in the architectural field.',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest News and Trends on Architects',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  'buying-selling-homes': {
    age: 30,
    categorySlug: 'buying-selling-homes',
    categoryDescription: 'The process of purchasing or selling residential properties, often involving real estate agents, inspections, and negotiations.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Buying Selling Homes',
    featureLimit: 0,
    icon: '<Home />',
    isEnabled: true,
    isFeatured: false,
    key: 'buying-selling-homes',
    limit: 20,
    mantisCategories: 'buying_selling_homes',
    metaDescription:
      'Stay up-to-date with the latest news and trends on buying and selling homes with My News Assistant. Get expert advice on buying and selling real estate, learn about the latest home design trends, and stay informed on the latest developments in the real estate market.',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest News and Trends on Buying and Selling Homes',
    relatedCategories: '',
    sectionSlug: 'topic',
    subSectionSlug: '',
    tags: '',
  },
  housing: {
    age: 30,
    categorySlug: 'housing',
    categoryDescription:
      'The provision of housing units for individuals and families, often involving government policies and programs, as well as private sector investments.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Housing',
    featureLimit: 0,
    icon: '<Roofing />',
    isEnabled: true,
    isFeatured: false,
    key: 'housing',
    limit: 20,
    mantisCategories: 'low_income_housing',
    metaDescription:
      'Stay up-to-date with the latest news and trends on housing with My News Assistant. Get expert advice on finding affordable housing, learn about the latest housing policies and programs, and stay informed on the latest developments in the housing market.',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest News and Trends on Housing',
    relatedCategories: '',
    sectionSlug: 'topic',
    subSectionSlug: '',
    tags: '',
  },
  'alternative-religions': {
    age: 30,
    categorySlug: 'alternative-religions',
    categoryDescription:
      'Belief systems and practices that fall outside of mainstream religious traditions, often emphasising personal spirituality, nature, or alternative lifestyles.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Alternative Religions',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'alternative-religions',
    limit: 20,
    mantisCategories: 'alternative_religions',
    metaDescription:
      'Discover the latest news and trends on alternative religions with My News Assistant. Learn about different belief systems and practices, get expert insight on alternative spirituality, and stay informed on the latest developments in the alternative religion community.',
    metaImage: '',
    metaTitle: 'Discover the Latest News and Trends on Alternative Religions',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  'atheism-agnosticism': {
    age: 30,
    categorySlug: 'atheism-agnosticism',
    categoryDescription: 'Philosophical and ideological perspectives that question or reject the existence of deities or supernatural forces.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Atheism Agnosticism',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'atheism-agnosticism',
    limit: 20,
    mantisCategories: 'atheism_agnosticism',
    metaDescription:
      'Stay up-to-date with the latest news and trends on atheism and agnosticism with My News Assistant. Get expert insight on non-religious belief systems, learn about the latest philosophical discussions on the nature of belief, and stay informed on the latest developments in the atheist and agnostic communities.',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest News and Trends on Atheism and Agnosticism',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  buddhism: {
    age: 30,
    categorySlug: 'buddhism',
    categoryDescription:
      'A major world religion and philosophical tradition that originated in ancient India, emphasising the pursuit of wisdom, compassion, and enlightenment.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Buddhism',
    featureLimit: 0,
    icon: '<TempleBuddhist />',
    isEnabled: true,
    isFeatured: false,
    key: 'buddhism',
    limit: 20,
    mantisCategories: 'buddhism',
    metaDescription:
      'Discover the latest news and trends on Buddhism with My News Assistant. Learn about Buddhist philosophy and practices, get expert insight on mindfulness and meditation, and stay informed on the latest developments in the Buddhist community.',
    metaImage: '',
    metaTitle: 'Discover the Latest News and Trends on Buddhism',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  christianity: {
    age: 30,
    categorySlug: 'christianity',
    categoryDescription: 'A major world religion based on the life and teachings of Jesus Christ, emphasising faith, love, and salvation.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Christianity',
    featureLimit: 0,
    icon: '<Church />',
    isEnabled: true,
    isFeatured: false,
    key: 'christianity',
    limit: 20,
    mantisCategories: 'christianity',
    metaDescription:
      'Stay up-to-date with the latest news and trends on Christianity with My News Assistant. Get expert insight on Christian philosophy and practices, learn about the latest debates and discussions in the Christian community, and stay informed on the latest developments in Christian theology.',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest News and Trends on Christianity',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  hinduism: {
    age: 30,
    categorySlug: 'hinduism',
    categoryDescription:
      'A major world religion and philosophical tradition that originated in ancient India, emphasising karma, dharma, and spiritual liberation.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Hinduism',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'hinduism',
    limit: 20,
    mantisCategories: 'hinduism',
    metaDescription:
      'Discover the latest news and trends on Hinduism with My News Assistant. Learn about Hindu philosophy and practices, get expert insight on Hindu spirituality and culture, and stay informed on the latest developments in the Hindu community.',
    metaImage: '',
    metaTitle: 'Discover the Latest News and Trends on Hinduism',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  islam: {
    age: 30,
    categorySlug: 'islam',
    categoryDescription:
      'A major world religion based on the teachings of the Quran and the life of the Prophet Muhammad, emphasising submission to the will of Allah and the pursuit of righteousness.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Islam',
    featureLimit: 0,
    icon: '<Mosque />',
    isEnabled: true,
    isFeatured: false,
    key: 'islam',
    limit: 20,
    mantisCategories: 'islam',
    metaDescription:
      'Stay up-to-date with the latest news and trends on Islam with My News Assistant. Get expert insight on Islamic philosophy and practices, learn about the latest debates and discussions in the Muslim community, and stay informed on the latest developments in Islamic theology.',
    metaImage: '',
    metaTitle: 'Stay Up-to-Date with the Latest News and Trends on Islam',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  judaism: {
    age: 30,
    categorySlug: 'judaism',
    categoryDescription:
      'A major world religion and cultural tradition based on the beliefs and practices of the Jewish people, emphasising the worship of one God and the importance of law, tradition, and community.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Judaism',
    featureLimit: 0,
    icon: '<Synagogue />',
    isEnabled: true,
    isFeatured: false,
    key: 'judaism',
    limit: 20,
    mantisCategories: 'judaism',
    metaDescription:
      'Discover the latest news and trends on Judaism with My News Assistant. Learn about Jewish philosophy and practices, get expert insight on Jewish spirituality and culture, and stay informed on the latest developments in the Jewish community.',
    metaImage: '',
    metaTitle: 'Discover the Latest News and Trends on Judaism',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  weather: {
    age: 30,
    categorySlug: 'weather',
    categoryDescription:
      "The atmospheric conditions and meteorological phenomena that affect the earth's climate, often studied and predicted by weather scientists and forecasters.",
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Weather',
    featureLimit: 0,
    icon: '<WbSunny />',
    isEnabled: true,
    isFeatured: false,
    key: 'weather',
    limit: 20,
    mantisCategories: 'weather',
    metaDescription:
      'Stay up-to-date with the latest weather news and updates from around the world. Get detailed forecasts and live updates on My News Assistant.',
    metaImage: '',
    metaTitle: 'Latest Weather News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  auctions: {
    age: 30,
    categorySlug: 'auctions',
    categoryDescription: 'Events or platforms where goods or services are sold to the highest bidder, often involving live or online bidding.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Auctions',
    featureLimit: 0,
    icon: '<Gavel />',
    isEnabled: true,
    isFeatured: false,
    key: 'auctions',
    limit: 20,
    mantisCategories: 'auctions',
    metaDescription:
      'Get the latest news and updates on top auctions around the world. From art to antiques, My News Assistant has all the latest auction updates for you.',
    metaImage: '',
    metaTitle: 'Top Auction News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  classifieds: {
    age: 30,
    categorySlug: 'classifieds',
    categoryDescription:
      'Belief systems and practices that fall outside of mainstream religious traditions, often emphasizing personal spirituality, nature, or alternative lifestyles.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Classifieds',
    featureLimit: 0,
    icon: '<Key />',
    isEnabled: true,
    isFeatured: false,
    key: 'classifieds',
    limit: 20,
    mantisCategories: 'classifieds',
    metaDescription:
      'Get the latest news and updates on classifieds, from real estate to job listings. Stay informed with My News Assistant and find what you need.',
    metaImage: '',
    metaTitle: 'Latest Classifieds News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  gifts: {
    age: 30,
    categorySlug: 'gifts',
    categoryDescription: 'Items given to others as a gesture of appreciation, celebration, or affection, often chosen for their sentimental or symbolic value.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Gifts',
    featureLimit: 0,
    icon: '<CardGiftcard />',
    isEnabled: true,
    isFeatured: false,
    key: 'gifts',
    limit: 20,
    mantisCategories: 'gifts',
    metaDescription: 'Find the latest gift ideas and news on My News Assistant. Discover unique gift ideas and stay up-to-date on the latest trends.',
    metaImage: '',
    metaTitle: 'Gift Ideas and News',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  resources: {
    age: 30,
    categorySlug: 'resources',
    categoryDescription: 'Information and tools available to individuals and organisations for various purposes, such as education, research, or advocacy.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Resources',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'resources',
    limit: 20,
    mantisCategories: 'resources',
    metaDescription: 'Find the top resources and information on My News Assistant. From educational resources to business tools, stay informed with us.',
    metaImage: '',
    metaTitle: 'Top Resources and Information',
    relatedCategories: '',
    sectionSlug: 'topic',
    subSectionSlug: '',
    tags: '',
  },
  retail: {
    age: 30,
    categorySlug: 'retail',
    categoryDescription: 'The sale of goods or services to consumers, often involving physical or online storefronts and marketing and advertising efforts.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Retail',
    featureLimit: 0,
    icon: '<ShoppingCart />',
    isEnabled: true,
    isFeatured: false,
    key: 'retail',
    limit: 20,
    mantisCategories: 'retail',
    metaDescription: 'Stay up-to-date with the latest retail news and updates from around the world. Get the latest trends and insights on My News Assistant.',
    metaImage: '',
    metaTitle: 'Latest Retail News and Updates',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  toys: {
    age: 30,
    categorySlug: 'toys',
    categoryDescription: 'Playthings and games for children and adults, often designed for entertainment, education, or skill development.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Toys',
    featureLimit: 0,
    icon: '<Toys />',
    isEnabled: true,
    isFeatured: false,
    key: 'toys',
    limit: 20,
    mantisCategories: 'toys',
    metaDescription:
      'Get the latest news and updates on toys, from popular brands to new releases. Stay informed and find the perfect toy for your child with My News Assistant.',
    metaImage: '',
    metaTitle: 'Latest Toy News and Updates',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  crime: {
    age: 30,
    categorySlug: 'crime',
    categoryDescription: 'Violations of laws and regulations that carry legal penalties, often involving harm to individuals or society as a whole.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Crime',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'crime',
    limit: 20,
    mantisCategories: 'crime',
    metaDescription:
      'Stay up-to-date with the latest crime news and updates from around the world. Get breaking news and expert analysis on My News Assistant.',
    metaImage: '',
    metaTitle: 'Top Crime News and Updates',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: 'society-and-culture',
    tags: '',
  },
  racism: {
    age: 30,
    categorySlug: 'racism',
    categoryDescription:
      'Attitudes, behaviors, and policies that discriminate against individuals or groups based on race or ethnicity, often resulting in inequality and injustice.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Racism',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'racism',
    limit: 20,
    mantisCategories: 'racism',
    metaDescription:
      'Stay informed with the latest news and updates on racism. My News Assistant provides in-depth coverage and expert analysis on this important issue.',
    metaImage: '',
    metaTitle: 'Latest Racism News and Updates',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: 'society-and-culture',
    tags: '',
  },
  'senior-living': {
    age: 30,
    categorySlug: 'senior-living',
    categoryDescription:
      'Housing and care facilities for elderly individuals, often offering various levels of assistance and support for daily activities and healthcare management.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Senior Living',
    featureLimit: 0,
    icon: '<Elderly />',
    isEnabled: true,
    isFeatured: false,
    key: 'senior-living',
    limit: 20,
    mantisCategories: 'senior_living',
    metaDescription:
      'Stay informed with the latest news and updates on senior living. From retirement communities to aging in place, My News Assistant has all the latest information.',
    metaImage: '',
    metaTitle: 'Latest Senior Living News and Updates',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: '',
    tags: '',
  },
  'social-institution': {
    age: 30,
    categorySlug: 'social-institution',
    categoryDescription:
      'Institutions and organisations that provide support and services to individuals and communities, such as healthcare, education, and social welfare.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Social Institution',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'social-institution',
    limit: 20,
    mantisCategories: 'social_institution',
    metaDescription:
      'Stay up-to-date with the latest news and updates on social institutions. From education to healthcare, My News Assistant has all the latest insights and trends.',
    metaImage: '',
    metaTitle: 'Latest Social Institution News and Updates',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: 'society-and-culture',
    tags: '',
  },
  teens: {
    age: 30,
    categorySlug: 'teens',
    categoryDescription:
      'Individuals in the age range between childhood and adulthood, often facing unique challenges and opportunities related to identity, education, and socialisation.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Teens',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'teens',
    limit: 20,
    mantisCategories: 'teens',
    metaDescription:
      'Get the latest news and updates on issues affecting teens today. From education to mental health, My News Assistant has all the latest information and resources.',
    metaImage: '',
    metaTitle: 'Latest News and Updates for Teens',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: '',
    tags: '',
  },
  welfare: {
    age: 30,
    categorySlug: 'welfare',
    categoryDescription:
      'Government programs and policies aimed at supporting individuals and families facing financial and social challenges, often involving assistance with basic needs such as food, housing, and healthcare.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Welfare',
    featureLimit: 0,
    icon: '<Spa />',
    isEnabled: true,
    isFeatured: false,
    key: 'welfare',
    limit: 20,
    mantisCategories: 'welfare',
    metaDescription:
      'Stay up-to-date with the latest welfare news and updates from around the world. From healthcare to social services, My News Assistant has all the latest information.',
    metaImage: '',
    metaTitle: 'Latest Welfare News and Updates',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: 'society-and-culture',
    tags: '',
  },
  work: {
    age: 30,
    categorySlug: 'work',
    categoryDescription: 'Employment and career-related activities, such as job seeking, training, and professional development.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Work',
    featureLimit: 0,
    icon: '<Work />',
    isEnabled: true,
    isFeatured: false,
    key: 'work',
    limit: 20,
    mantisCategories: 'work',
    metaDescription:
      'Stay informed with the latest news and updates on work. From career advice to job market trends, My News Assistant has all the latest insights.',
    metaImage: '',
    metaTitle: 'Latest Work News and Updates',
    relatedCategories: '',
    sectionSlug: 'politics-and-society',
    subSectionSlug: 'society-and-culture',
    tags: '',
  },
  footwear: {
    age: 30,
    categorySlug: 'footwear',
    categoryDescription: 'Articles of clothing worn on the feet for protection, comfort, and style, often designed for specific activities or occasions.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Footwear',
    featureLimit: 0,
    icon: '<ArrowDropDownCircleIcon />',
    isEnabled: true,
    isFeatured: false,
    key: 'footwear',
    limit: 20,
    mantisCategories: 'footwear',
    metaDescription:
      'Stay up-to-date with the latest footwear news and updates. From fashion trends to comfort and performance, My News Assistant has all the latest information.',
    metaImage: '',
    metaTitle: 'Latest Footwear News and Updates',
    relatedCategories: '',
    sectionSlug: 'life-and-style',
    subSectionSlug: 'fashion',
    tags: '',
  },
  'luxury-goods': {
    age: 30,
    categorySlug: 'luxury-goods',
    categoryDescription: 'High-end products and services associated with exclusivity, quality, and prestige, often catering to affluent consumers.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Luxury goods',
    featureLimit: 0,
    icon: '<Diamond />',
    isEnabled: true,
    isFeatured: false,
    key: 'luxury-goods',
    limit: 20,
    mantisCategories: 'luxury_goods',
    metaDescription:
      'Stay informed with the latest news and updates on luxury goods. From high-end fashion to luxury travel, My News Assistant has all the latest insights.',
    metaImage: '',
    metaTitle: 'Latest Luxury Goods News and Updates',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: '',
    tags: '',
  },
  'children-in-need': {
    age: 30,
    categorySlug: 'children-in-need',
    categoryDescription: 'Stay informed about Children in Need, a charitable initiative supporting children facing various challenges. Get updates on events, fundraising, and heartwarming stories.',
    categoryImage: '',
    categoryType: 'misc',
    displayName: 'Children in Need',
    featureLimit: 0,
    icon: '<Heart />',
    isEnabled: true,
    isFeatured: false,
    key: 'children-in-need',
    limit: 20,
    mantisCategories: '',
    metaDescription: 'Stay updated on Children in Need initiatives, events, and inspiring stories of positive impact with My News Assistant.',
    metaImage: '',
    metaTitle: 'Children in Need News - Updates on Initiatives and Heartwarming Stories',
    relatedCategories: '',
    sectionSlug: 'discover',
    subSectionSlug: 'charity',
    tags: '',
    concepts: 'Children in Need',
  },
};

export {
  getCategoryByType,
  getCategoryBySection,
  getCategoryKeys,
  getCategoryByName,
  getCategorys,
  getCategoryMap,
  getCategoryTreeMap,
  getAllDisplayNames,
  filterCategory,
  getMantisValuesByDisplayName,
  categoryTypes,
  getSuggestedCategories,
};
