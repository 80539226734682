import { getQueryParameter } from "./pageUtilities.js";
import { __log } from "./utils.js";
import featureConfig from "../config/featureFlags.js";

/**
 * Checks if a feature is enabled by looking at the config params
 * 
 * @param featureFlag - name of feature
 * @returns {boolean} - true if feature is enabled, false otherwise
 */
function getFeatureFlag(featureFlag) {
  const queryVal = getQueryParameter(featureFlag);
  const queryBool = queryVal === "true" ? true : queryVal === "false" ? false : undefined;
  if (queryBool !== undefined) {
    __log('Feature flag', `${featureFlag} ${queryBool ? "enabled" : "disabled"} via URL query parameter`);
    return queryBool;
  } else if (featureConfig?.[featureFlag] === true) {
    __log('Feature flag',`${featureFlag} enabled in config features section parameter`);
    return true;
  }
  return false;
}

/**
 * Gets a feature flag value
 * - value will be retrieved from a cms section parameter or a query parameter on the page url
 *
 * @param feature - name of feature
 * @param flagPrefix - prefix of flag in section params - 'feature' (default) or 'public'
 * @returns {string} - feature flag value
 */
function getFeatureValue(feature, flagPrefix = 'features') {
  const featureSectionParam = featureConfig?.[flagPrefix]?.[feature];
  const featureQueryParam = getQueryParameter(feature);
  const featureValue = featureQueryParam !== null ? featureQueryParam : featureSectionParam;
  
  __log('Feature value',`${feature  + ' =', featureValue}`);

  return featureValue;
}

export { getFeatureFlag, getFeatureValue };