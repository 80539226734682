import { createTheme } from '@mui/material/styles';
export const globalTheme = createTheme({
  typography: {
    fontFamily: "'Roboto', sans-serif",
    size08: '0.5rem',
    size12: '0.75rem',
    size14: '0.875rem',
    size16: '1rem',
    size20: '1.25rem',
    size22: '1.375rem',
    size24: '1.5rem',
    size38: '2.375rem',
    h1: {
      fontSize: '1.5rem',
      fontWeight: '400',
      lineHeight: 'normal',
      '@media (min-width:768px)': {
        fontSize: '2.375rem',
      },
    },
    h1bold: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: 'normal',
      '@media (min-width:768px)': {
        fontSize: '2.375rem',
      },
    },
    h2: {
      fontSize: '1.375rem',
      fontWeight: '400',
      lineHeight: 'normal',
      '@media (min-width:768px)': {
        fontSize: '2.1875rem',
      },
    },
    h2bold: {
      fontSize: '1.375rem',
      fontWeight: 'bold',
      lineHeight: 'normal',
      '@media (min-width:768px)': {
        fontSize: '2.1875rem',
      },
    },
    h3: {
      fontSize: '1.25rem',
      lineHeight: 'normal',
      '@media (min-width:768px)': {
        fontSize: '2rem',
      },
    },
    h3bold: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      lineHeight: 'normal',
      '@media (min-width:768px)': {
        fontSize: '2rem',
      },
    },
    h4: {
      fontSize: '1.125rem',
      lineHeight: 'normal',
      '@media (min-width:768px)': {
        fontSize: '1.75rem',
      },
    },
    h4bold: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
      lineHeight: 'normal',
      '@media (min-width:768px)': {
        fontSize: '1.75rem',
      },
    },
    h5: {
      fontSize: '1.0625rem',
      lineHeight: 'normal',
      '@media (min-width:768px)': {
        fontSize: '1.5rem',
      },
    },
    h5bold: {
      fontSize: '1.0625rem',
      fontWeight: 'bold',
      lineHeight: 'normal',
      '@media (min-width:768px)': {
        fontSize: '1.5rem',
      },
    },
    h6: {
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: 'normal',
      '@media (min-width:768px)': {
        fontSize: '1.125rem',
      },
    },
    h6bold: {
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: 'normal',
      '@media (min-width:768px)': {
        fontSize: '1.125rem',
      },
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
    },
    body1Bold: {
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      fontWeight: 'bold',
    },
    label1: {
      fontSize: '0.813rem',
      '@media (min-width:768px)': {
        fontSize: '0.875rem',
      },
    },
    label1Bold: {
      fontSize: '0.813rem',
      fontWeight: 'bold',
      '@media (min-width:768px)': {
        fontSize: '0.875rem',
      },
    },
    label2: {
      fontSize: '0.688rem',
      '@media (min-width:768px)': {
        fontSize: '0.813rem',
      },
    },
    label2Bold: {
      fontSize: '0.688rem',
      fontWeight: 'bold',
      '@media (min-width:768px)': {
        fontSize: '0.813rem',
      },
    },
    label3: {
      fontSize: '0.625rem',
      '@media (min-width:768px)': {
        fontSize: '0.75rem',
      },
    },
    label3Bold: {
      fontSize: '0.625rem',
      fontWeight: 'bold',
      '@media (min-width:768px)': {
        fontSize: '0.75rem',
      },
    },
    label4: {
      '@media (min-width:768px)': {
        fontSize: '1rem',
      },
    },
    label4Bold: {
      fontWeight: 'bold',
      '@media (min-width:768px)': {
        fontSize: '1rem',
      },
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: 'normal',
    }
  },
  palette: {
    common: {
      black: '#141414',
      white: '#FFF',
    },
    primary: {
      main: '#00ADEC',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#485459',
      contrastText: '#FFF',
    },
    timberGreen: {
      main: '#1A292F',
    },
    osloGrey: {
      main: '#878E91',
    },
    gainsboro: {
      main: '#DADCE0',
    },
    lightGray: {
      main: '#F8F8F8',
    },
    error: {
      main: '#FF000D',
      dark: '#FF000D',
    },
  },
  breakpoints: {
    values: {
      mobile: 414,
      tablet: 768,
      laptop: 1024,
      desktop: 1200,
    },
  },
  contrastThreshold: 4.5,
  tonalOffset: 0.2,
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1bold: 'h1',
          h2bold: 'h2',
          h3bold: 'h3',
          h4bold: 'h4',
          h5bold: 'h5',
          h6bold: 'h6',
        },
      },
    },
  },
});
