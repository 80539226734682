import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomPopup from '../CustomPopup/CustomPopup';

function MissingPersonalisationPopup({ show, handleClosePersonalisationPopup }) {
  const navigate = useNavigate();
  const personalisationMissingActions = [
    {
      onClick: () => navigate('/signup/interests'),
      label: 'Personalise',
    },
  ];
  let text = (
    <>
      <p>
        You're already signed in and ready to go. Now, it's time to personalise your experience and make your news truly tailored to your interests. By
        personalising your preferences, you can enjoy the following benefits:
      </p>{' '}
      <ul>
        <li>Follow and stay informed on the latest news, topics, and local updates that matter to you.</li>
        <li>Create a personalised feed based on your interests, ensuring you receive the most relevant and engaging content.</li>
        <li>Access a diverse range of trusted news articles from reputable sources, providing you with a well-rounded perspective.</li>
      </ul>
    </>
  );

  return (
    <CustomPopup
      isOpen={show}
      onClose={handleClosePersonalisationPopup}
      logo={require(`../../assets/mna_alt_logo.png`)}
      divider
      title="Personalise Your Experience"
      actionButtons={personalisationMissingActions}
    >
      {text}
    </CustomPopup>
  );
}

export default MissingPersonalisationPopup;
