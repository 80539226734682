import categoryPageAdConfig from './categoryPageConfig/categoryPageTargetting';
import categoryDesktopSlots from './categoryPageConfig/categorySlotsDesktop';
import categoryMobileSlots from './categoryPageConfig/categorySlotsMobile';
import homepageAdConfig from './homePageConfig/pageTargeting';
import homepageDesktopSlots from './homePageConfig/slotsDesktop';
import homepageMobileSlots from './homePageConfig/slotsMobile';
import sectionAdConfig from './sectionPageConfig/sectionPageTargetting';
import sectionDesktopSlots from './sectionPageConfig/sectionSlotsDesktop';
import sectionMobileSlots from './sectionPageConfig/sectionSlotsMobile';
import { getQueryParameter } from '../../helpers/pageUtilities';
import { __log } from '../../helpers/utils';

export function getAdConfig(pageType) {
  const pageTypes = { homepage: homepageAdConfig, sectionpage: sectionAdConfig, category: categoryPageAdConfig };

  const slotsConfig = {
    mobile_homepage: homepageMobileSlots,
    desktop_homepage: homepageDesktopSlots,
    mobile_sectionpage: sectionMobileSlots,
    desktop_sectionpage: sectionDesktopSlots,
    desktop_category: categoryDesktopSlots,
    mobile_category: categoryMobileSlots,
  };
  const breakpoint = window.innerWidth > 768 ? 'desktop' : 'mobile';
  const slotsKey = `${breakpoint}_${pageType}`;

  const adConfig = {
    ...pageTypes[pageType],
    slots: slotsConfig[slotsKey],
  };
  let subsectionOne = window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname;
  let path = `/5293/mynewsassistant.com${subsectionOne}`;
  if (path) {
    adConfig.path = path;
    adConfig.slots = adConfig.slots.map((slot) => {
      slot.adUniturl = path;
      return slot;
    });
  }

  let tokenValue = getQueryParameter('token');
  if (tokenValue) {
    adConfig.targeting.token = tokenValue;
  }

  __log('Ads config', adConfig);
  return adConfig;
}
