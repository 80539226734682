import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { constructPath } from '../../../helpers/utils.js';
import { getFeatureFlag, getFeatureValue } from '../../../helpers/configUtilities';
import ShareButton from './../../breadcrumbsAndSocialSharing/ShareButton/ShareButton.js';
import './TrendingBar.scss';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const TrendingBar = () => {
  const [selectedTab, setSelectedTab] = useState(false);

  const location = useLocation();
  const siteRoot = getFeatureValue('siteRoot');

  const trendingKeywords = getFeatureFlag('TrendingComponentEnabled') ? getFeatureValue('trendingKeywords') : '';

  const trendingItems = trendingKeywords
    ? Object.keys(trendingKeywords).map((key) => {
        const category = trendingKeywords[key];
        return {
          ...category,
          name: category.displayName,
          url: constructPath(category.sectionSlug, category.subSectionSlug, category.categorySlug),
        };
      })
    : [];

  useEffect(() => {
    const splittedPathname = location.pathname.split('/').filter(Boolean);
    const slug = splittedPathname.slice(-1)[0];
    const isTabInTrendingItems = slug && trendingItems.some((item) => item.categorySlug === slug);
    isTabInTrendingItems ? setSelectedTab(slug) : setSelectedTab(false);
  }, [location.pathname]);

  const trendingList = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      ...trendingItems.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': `${siteRoot}${item.url}`,
          name: item.name,
        },
      })),
    ],
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(trendingList)}</script>
      </Helmet>
      <div className="trending-bar">
        <Tabs
          value={selectedTab}
          textColor="secondary"
          variant="scrollable"
          selectionFollowsFocus={true}
          scrollButtons={false}
          aria-label="scrollable auto tabs"
          sx={{ ml: 2 }}
        >
          {trendingItems.map((item, index) => (
            <Tab
              label={item.displayName}
              value={item.categorySlug}
              key={index}
              component={Link}
              to={item.url}
              {...a11yProps(index)}
              onClick={() => setSelectedTab(item.categorySlug)}
            />
          ))}
        </Tabs>
        <div className='share-button'>
          <ShareButton />
        </div>
      </div>
    </>
  );
}

export default TrendingBar;