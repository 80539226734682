import './searchPage.scss';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';

import { __log, getScrollPercent, retrieveExcludedDomains, isEmbedPagePresent } from '../../helpers/utils';
import { getLocalConfig, isPersonalisedExperience } from '../../helpers/localConfigUtils';
import { generateSearchQuery, createUserDefinedCategory } from '../../helpers/savedSearchUtils';
import { getFeatureFlag } from '../../helpers/configUtilities';
import { fetchTermData } from '../../services/api';
import { getCategoryByName } from '../../config/categories';
import CircularProgress from '@mui/material/CircularProgress';
import CustomPopup from '../../components/CustomPopup/CustomPopup';
import MissingPersonalisationPopup from '../../components/shared/MissingPersonalisationPopup';
import PausedPersonalisationPopup from '../../components/shared/PausedPersonalisationPopup';
import Signup from '../../components/CustomPopup/Signup/Signup';
import SearchResults from './SearchResults';
import TooltipComponent from '../../components/tooltip/Tooltip';

let isEndOfThePage = false;

function SearchPage() {
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);
  const [searchData, setSearchData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [exactSearchQuery, setExactSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isDataAvailable, setIsDataAvailable] = useState(undefined);
  const [responseTime, setResponseTime] = useState(0);
  const [articleNumber, setArticleNumber] = useState(0);
  const [pageStart, setPageStart] = useState(0);
  const [pageLimit, setPageLimit] = useState(30);
  const [paginateCount, setPaginateCount] = useState(1);
  const [isPaginate, setIsPaginate] = useState(false);
  const [selectValue, setSelectValue] = useState('date');
  const [domains, setDomains] = useState([]);
  const [category, setCategory] = useState('');
  const [orSearchQuery, setOrSearchQuery] = useState('');
  const [excludeQuery, setExcludeQuery] = useState('');
  const [author, setAuthor] = useState('');
  const [isCircularLoading, setCircularLoading] = useState(false);
  const [isSavedSearch, setIsSavedSearch] = useState(false);
  const [isEnhancedSignInPopupOpen, setIsEnhancedSignInPopupOpen] = useState(false);
  const [isPausedPopupOpen, setIsPausedPopupOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMissingPersonalisationPopup, setIsMissingPersonalisationPopup] = useState(false);
  const [alert, setAlert] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const limitPerLoad = 30;
  const UserDefinedCategoriesEnabled = getFeatureFlag('UserDefinedCategoriesEnabled');
  const isSearchQueryPresent = exactSearchQuery || orSearchQuery ? true : false;

  let localConfig = getLocalConfig();
  let personalisedExperience = isPersonalisedExperience();
  let expectedPageNr = Math.ceil(articleNumber / 30);
  let storedExclusions = retrieveExcludedDomains();

  const handleLogin = () => {
    setIsEnhancedSignInPopupOpen(false);

    const event = new CustomEvent('auth-ui.authenticate', {
      detail: { origin: window.location.pathname },
    });
    window.dispatchEvent(event);
  };
  const enhancedSignInPopupActions = [
    {
      onClick: () => handleLogin(),
      label: 'sign in',
    },
    {
      onClick: () => navigate('/signup/interests'),
      label: 'signup',
    },
  ];

  const handleClosePausedPopup = () => {
    setIsPausedPopupOpen(false);
  };

  const handleClosePersonalisationPopup = () => {
    setIsMissingPersonalisationPopup(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktopView(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isDesktopView]);

  useEffect(() => {
    setAlert(false);
    setIsLoading(true);
    let exactQueryParam = searchParams.get('q');
    setExactSearchQuery(exactQueryParam ? exactQueryParam : '');
    let domainsQueryParam = searchParams.get('domains');
    setDomains(domainsQueryParam || (location.state ? location.state.domains : ''));
    setCategory(location.state ? location.state.category : '');
    let excludeParam = searchParams.get('exclude_q');
    setExcludeQuery(excludeParam || (location.state ? location.state.excludeQuery : ''));
    let orSearchQueryParam = searchParams.get('as_oq');
    setOrSearchQuery(orSearchQueryParam ? orSearchQueryParam : '');
    setAuthor(searchParams.get('author') ?? '');
    setIsPaginate(false);
    setPageStart(0);
    setSearchData([]);
    setPaginateCount(1);
    setPageLimit(30);
    let sortParam = searchParams.get('sort');
    setSelectValue(sortParam ? sortParam : selectValue);
    setArticleNumber(0);
    window.scrollTo(0, 0);

    setIsLoading(false);
  }, [searchParams, orSearchQuery, location.state]);

  useEffect(() => {
    document.title = (exactSearchQuery || orSearchQuery || 'No results found') + ' Search | My News Assistant';

    if (exactSearchQuery.length > 0 || orSearchQuery.length > 0 || !!author) {
      const getData = () => {
        setIsLoading(true);

        fetchTermData(
          exactSearchQuery.length ? exactSearchQuery : '',
          orSearchQuery.length ? orSearchQuery : '',
          excludeQuery.length ? excludeQuery : '',
          category.length ? category : '',
          '', //Empty tags string
          domains.length ? domains : '',
          isPaginate ? pageStart : 0,
          pageLimit,
          selectValue,
          storedExclusions,
          author
        )
          .then((response) => {
            let time = response.responseTime;
            setResponseTime(time);
            let total = response.totalNumberofArticlesMatched;
            setArticleNumber(total);

            const data = response.articleData;
            if (data && data.length > 0) {
              setIsDataAvailable(true);
              data.forEach((article) => {
                article.title = article.title.replace(/(<([^>]+)>)/gi, ''); //escape html tag
              });
              if (isPaginate) {
                setSearchData((prevState) => [...prevState, ...data]);
                setIsLoading(false);
              } else {
                setSearchData(data);
                setIsLoading(false);
              }
            } else {
              setIsDataAvailable(false);
              setIsLoading(false);
              setResponseTime(response.responseTime);
              setArticleNumber(0);
            }
            isEndOfThePage = false;
            setCircularLoading(false);
          })
          .catch((error) => {
            __log('getSearchTextData error', error);
          });
      };
      getData();
    }

    // Check if the current exactSearchQuery matches any saved search
    localConfig = getLocalConfig();
    personalisedExperience = isPersonalisedExperience();
    if (localConfig) {
      const savedSearches = localConfig?.savedSearches || {};
      const { key } = generateSearchQuery(exactSearchQuery, orSearchQuery);
      setIsSavedSearch(key && key !== '' && savedSearches?.[key] ? true : false);
    }

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('auth-ui.authentication', handleAuthAndSaveSearch);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('auth-ui.authentication', handleAuthAndSaveSearch);
    };
  }, [exactSearchQuery, orSearchQuery, excludeQuery, domains, category, paginateCount, isDataAvailable, selectValue, expectedPageNr, author]);

  const handleAuthAndSaveSearch = async (event) => {
    if (localConfig || isEmbedPagePresent()) return;
    const data = event.data || event.detail || {};
    if (!data.isAuthenticated) return;

    setIsAuthenticated(true);
    const { key } = generateSearchQuery(exactSearchQuery, orSearchQuery);
    const mantisConfig = getCategoryByName(key);
    if (mantisConfig) {
      setAlert(true);
      return;
    }
    await createUserDefinedCategory(localConfig, exactSearchQuery, orSearchQuery, category, domains, excludeQuery, selectValue, author);
    navigate('/feed/' + key);

    if (data.isAuthenticated && !localConfig) {
      setIsMissingPersonalisationPopup(true);
      return null;
    }
  };

  const handleSaveSearch = () => {
    personalisedExperience = isPersonalisedExperience();
    if (!localConfig || !localConfig?.savedSearches) {
      if (localStorage.getItem('auth-user')) {
        setIsMissingPersonalisationPopup(true);
        return null;
      }
      setIsEnhancedSignInPopupOpen(true);
      return null;
    } else if (!personalisedExperience) {
      setIsPausedPopupOpen(true);
      return null;
    }

    const { key } = generateSearchQuery(exactSearchQuery, orSearchQuery);
    const mantisConfig = getCategoryByName(key);
    if (mantisConfig) {
      setAlert(true);
      return;
    }
    createUserDefinedCategory(localConfig, exactSearchQuery, orSearchQuery, category, domains, excludeQuery, selectValue, author);
    setIsSavedSearch((prevState) => !prevState);
    navigate('/feed/' + key);
  };

  const handleScroll = (e) => {
    let scrollPercent = getScrollPercent().toFixed();
    if (scrollPercent > 90 && !isEndOfThePage) {
      isEndOfThePage = true;
      handleLoadMore();
    }
  };

  const handleLoadMore = () => {
    if (paginateCount < expectedPageNr) {
      setIsLoading(true);
      setIsPaginate(true);
      setPageStart(pageStart + limitPerLoad);
      setPageLimit(pageLimit);
      setPaginateCount(paginateCount + 1);
      setIsDataAvailable(true);
      setIsLoading(false);
      setCircularLoading(true);
    } else {
      setCircularLoading(false);
    }
  };

  const handleChange = (e) => {
    setSelectValue(e.target.value.toLowerCase());
    setSearchParams({
      q: exactSearchQuery,
      as_oq: orSearchQuery,
      exclude_q: excludeQuery,
      domains,
      author,
      sort: e.target.value.toLowerCase(),
    });
  };

  const SelectIconProps = (props) => <KeyboardArrowDownIcon color="osloGrey" sx={{ marginLeft: '6px' }} {...props} />;

  return (
    <div className="standard-layout search-page">
      <main className={isDesktopView ? 'desktop' : 'mobile'}>
        <div className="search--layout">
          <div className="search--wrapper">
            <div className="search--header">
              <div className="search--title">
                <Typography variant="h1bold" sx={{ color: theme.palette.primary.main }}>
                  Search Results
                </Typography>
                {UserDefinedCategoriesEnabled && isSearchQueryPresent ? (
                  <TooltipComponent
                    placement={'bottom-end'}
                    title={'Category name already exists in Mantis. Please choose a different name.'}
                    open={alert}
                    close={() => setAlert(false)}
                  >
                    <Button
                      data-testid="save-button"
                      variant="contained"
                      className="follow-btn"
                      onClick={handleSaveSearch}
                      aria-label={isSavedSearch ? 'Unfollow' : 'Follow'}
                      endIcon={isSavedSearch && personalisedExperience ? <FavoriteIcon color="primary" /> : <FavoriteBorderIcon color="primary" />}
                    >
                      <Typography variant="label1" sx={{ color: theme.palette.common.black }}>
                        {isSavedSearch && personalisedExperience ? 'Unfollow' : 'Follow'}
                      </Typography>
                    </Button>
                  </TooltipComponent>
                ) : null}
              </div>
              <div className="search--info">
                <Typography variant="label1Bold">About {articleNumber} results</Typography>
                <div className="dropdown">
                  <Typography variant="label1Bold" sx={{ minWidth: 'fit-content' }}>
                    Sort by{' '}
                  </Typography>
                  <Select
                    sx={{
                      width: isDesktopView ? 'fit-content' : '100%',
                      color: theme.palette.osloGrey.main,
                      ':hover': {
                        bgcolor: 'transparent',
                        color: 'unset',
                      },
                    }}
                    SelectDisplayProps={{ style: { color: theme.palette.osloGrey.main } }}
                    IconComponent={SelectIconProps}
                    value={selectValue}
                    onChange={handleChange}
                    data-testid="select"
                  >
                    <MenuItem
                      value={'relevance'}
                      sx={{
                        color: theme.palette.osloGrey.main,
                      }}
                    >
                      Relevance
                    </MenuItem>
                    <MenuItem
                      value={'date'}
                      sx={{
                        color: theme.palette.osloGrey.main,
                      }}
                    >
                      Date
                    </MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <SearchResults
              isLoading={isLoading}
              isDataAvailable={isDataAvailable}
              searchData={searchData}
              exactSearchQuery={exactSearchQuery}
              orSearchQuery={orSearchQuery}
            />
          </div>
          <div className="ad--wrapper"></div>
        </div>
        {isCircularLoading && (
          <div className="circularLoading">
            <CircularProgress />
          </div>
        )}
        {isEnhancedSignInPopupOpen && (
          <CustomPopup
            isOpen={isEnhancedSignInPopupOpen}
            onClose={() => setIsEnhancedSignInPopupOpen(false)}
            logo={require(`../../assets/mna_alt_logo.png`)}
            title="Sign in for an enhanced My News Assistant experience"
            actionButtons={enhancedSignInPopupActions}
          >
            <Signup />
          </CustomPopup>
        )}
        {isPausedPopupOpen && <PausedPersonalisationPopup show={isPausedPopupOpen} handleClosePausedPopup={handleClosePausedPopup} />}
        {isMissingPersonalisationPopup && (
          <MissingPersonalisationPopup show={isMissingPersonalisationPopup} handleClosePersonalisationPopup={handleClosePersonalisationPopup} />
        )}
      </main>
    </div>
  );
}

export default SearchPage;
