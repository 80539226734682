import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import './static.scss';

const PolicyNotice = () => {
  useEffect(() => {
    document.title = 'Privacy Notice | My News Assistant';
    window.scrollTo(0, 0);
  });

  return (
    <div className="standard-layout static-page">
      <main>
        <section className="static-content">
          <Typography variant="h1bold" color="primary">
            Privacy Notice
          </Typography>
          <p>We have organised this Privacy Notice based on the different ways you might interact with us and what you might want to know.</p>
          <p>
            Please use the contents list below to navigate to the section of the Privacy Notice which you are interested in. At the end of each section if you
            want to return to the contents list please click the link: ‘Click here to go back to ‘Contents’’.
          </p>
          <br />
          <p>Last update: 25 March 2024</p>
          <br />
          <Typography variant="h6bold" color="primary" id="contents">
            CONTENTS
          </Typography>
          <ol>
            <li>
              <a href="#1-section">1. Who we are and how to contact us</a>
            </li>
            <li>
              <a href="#2-section">2. About this Privacy Notice</a>
            </li>
            <li>
              <a href="#3-section">3. When you browse our websites and use our apps</a>
            </li>
            <li>
              <a href="#4-section">4. When you create or sign into your online news or other Reach account</a>
            </li>
            <li>
              <a href="#5-section">5. When you buy a product or serivce from us</a>
            </li>
            <li>
              <a href="#6-section">6. When you opt to receive newsletters or marketing communications</a>
            </li>
            <li>
              <a href="#7-section">7. When you enter a competition or prize draw</a>
            </li>
            <li>
              <a href="#8-section">8. When you complete a survey</a>
            </li>
            <li>
              <a href="#9-section">9. When you subscribe to our print or “e-publishing” titles, or place a classified ad you place a classified ad</a>
            </li>
            <li>
              <a href="#10-section">10. When you visit or interact with us on social media</a>
            </li>
            <li>
              <a href="#11-section">11. When you apply for a job with us or work at other organisations we do business with</a>
            </li>
            <li>
              <a href="#12-section">12. Further ways we may receive, use and share your data</a>
            </li>
            <li>
              <a href="#13-section">13. How we keep your data secure, why we may keep it and countries we may transfer it to</a>
            </li>
            <li>
              <a href="#14-section">14. How we ensure we protect children's privacy</a>
            </li>
            <li>
              <a href="#15-section">15. UK and EU residents: your rights and how to exercise them</a>
            </li>
            <li>
              <a href="#16-section">16. US residents: relevant laws and rights</a>
            </li>
          </ol>
          <br />
          <section>
            <Typography variant="h4bold" color="primary" id="1-section">
              1. Who we are and how to contact us
            </Typography>
            <p>
              We are the Reach group, which is made up of Reach plc and its subsidiaries. Reach plc is the largest national and regional news publisher in the
              UK with a portfolio in Ireland and the United States. We aim to create engaging, relevant content which is distributed through various channels
              and media including newspapers, magazines, and digital platforms – playing a central role in our audiences’ daily lives.
            </p>
            <p>
              We are the name behind some of the UK’s most influential, iconic, and trusted news brands, such as the Daily Mirror, Daily Express, Daily Star,
              Daily Record and Sunday People as well as leading celebrity and entertainment magazine OK! and the online shopping site Yimbly.com. We also
              publish leading regional titles including the Manchester Evening News, Liverpool Echo, and Birmingham Mail, plus the Mirror US and Express US. You
              can see the list of websites and brands we publish <a href="https://reachadspecs.com/ad-guidelines/site-list">here</a>.
            </p>
            <p>
              Depending on who you are and how you interact with us, the legal “controller” of your personal data will be one or more of our group companies,
              like MGN Limited, Local World Limited, Express Newspapers or Reach Publishing Services Limited (all at the address above).
            </p>
            <p>
              We have appointed a Data Protection and Privacy Officer (DPO) to oversee our data protection and privacy practices. If you have any comments,
              concerns or questions, including any requests to exercise your legal rights, our DPO can be reached as follows:
            </p>
            <p>
              If you are from the UK/EU:
              <ul>
                <li>By email: dataprotection@reachplc.com</li>
                <li>By mail: Data Protection Enquiries, Reach Plc, One Canada Square, Canary Wharf, London E14 5AP</li>
              </ul>
            </p>
            <p>
              If you are from the US:
              <ul>
                <li>By email: privacyofficer@reachplc.com</li>
                <li>By mail: Privacy Officer, Reach Plc, 9th Floor, 200 Liberty Street, New York, NY 10281</li>
              </ul>
            </p>
            <p>
              Your point of contact for anything to do with how the Reach group uses your personal data is the office of our group Data Protection Officer, who
              you can contact by submitting the relevant form accessible within the ‘Your rights and how to exercise them’ section or by emailing
              dataprotection@reachplc.com or by writing to ‘The Data Protection Team’ at the address above.
            </p>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
            <br />
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="2-section">
              2. About this Privacy Notice
            </Typography>
            <p>This Privacy Notice tells you about the kinds of information we hold about you, what we do with it, and why.</p>
            <p>As a broad summary, in our business we use information about people:</p>
            <ul>
              <li>To carry out our work as journalists, by investigating and reporting on stories of relevance to our readers.</li>
              <li>To deliver our features and editorial content.</li>
              <li>
                To provide services such as subscriptions, newsletters or marketing that people sign up to, and to operate interactive features like
                competitions and surveys.
              </li>
              <li>
                To help us to understand our readers and target our marketing and advertising that enables us to continue to finance and bring you our feature
                and editorial content.
              </li>
              <li>To sell products and services on our sites.</li>
              <li>
                To carry out back-office administration and generally run and operate our business, for instance by administering our relationships with
                suppliers and partner organisations, or handling job applications.
              </li>
            </ul>
            <p>
              Some specific activities and websites might have their own additional and complementary Privacy Notices which sit alongside this one. Generally,
              that will be the case for very specific services such as our commercial job boards or other specialist websites, or where we have a very specific
              kind of relationship with you (for instance if you are a member of our group pension scheme). We try to make it obvious where that is the case.
            </p>
            <p className="underlined">
              <b>Privacy Notice Updates</b>
            </p>
            <p>
              We may update this Privacy Notice from time-to-time by posting a new version on our websites. You should check this page occasionally to ensure
              you are happy with any changes. Where changes are material, we may also notify you of them by email.
            </p>
            <p className="underlined">
              <b>Data Protection and Journalism</b>
            </p>
            <p className="underlined">
              <b>The UK</b>
            </p>
            <p>
              In the UK the Data Protection Act 2018 provides an exemption from data protection laws to prevent unjustified interference with the work of
              journalists. We are members of the Independent Press Standards Organisation <a href="https://www.ipso.co.uk/">(IPSO)[IPSO]</a> and therefore
              subject to the <a href="https://www.ipso.co.uk/editors-code-of-practice/">Editors’ Code [Code]</a>. If you object to a story we have published
              that concerns you personally then you can find our complaints policy here [<a href="https://www.reachplc.com/complaints-policy">UK Complaints</a>
              ], which explains how to raise a complaint and how we will handle it.
            </p>
            <p className="underlined">
              <b>Ireland</b>
            </p>
            <p>
              In Ireland we subscribe to the Code of Practice of the Press Council of Ireland and the Press Ombudsman, which set the benchmark for high
              professional standards. If you believe that we have got something wrong, or that we have breached the Code of Practice, please let us know at the
              contact details of the publication concerned.
            </p>
            <address>
              <p>The Code of Practice and details of how to lodge a complaint with the Press Ombudsman are available from:</p>
              Post: 1, 2 & 3 Westmoreland Street, Dublin 2.
              <br />
              Website: <a href="http://www.pressombudsman.ie">www.pressombudsman.ie</a>
              <br />
              Email: <a href="mailto:info@pressombudsman.ie">info@pressombudsman.ie</a>
              <br />
              Telephone: 1890 208 080
              <br />
              Fax: 01 6740046
            </address>
            <p className="underlined">
              <b>Journalism and our use of Artificial Intelligence</b>
            </p>
            <p>We are increasingly using Artificial Intelligence (AI) tools, to help us create and enhance our content.</p>
            <p>
              Meeting the data protection requirements of our consumers is at the forefront of our AI decision making so as our uses develop we will provide
              more information in this Privacy Notice. For more information see our AI Notice on each website footer.
            </p>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="3-section">
              3. When you browse our websites and use our apps
            </Typography>
            <p>
              When you browse our websites or use our apps, we may collect technical and/or behavioural information about your use of our websites or other
              content online, and the device(s) you use to access the services. For example, this information may include details about how you browse our
              website and the devices and technology you use to browse our website. This may also include unique online identifiers such as device ID, device
              type, IP address and approximate location at the time of browsing. This may also include video viewing data and may link to your activity on other
              sites and allow us to recognise you or your device. We may use this to build a more detailed profile of you and your interests. We may also
              provide you with location-based services (for example, advertising and other personalised content), where we collect geo-location data.
            </p>
            <p>
              Our advertising partners may receive some information about the device you are using and about what you look at and interact with. We do this for
              the purpose of choosing which adverts to show you, as well as to make our sites and apps work properly. It also allows us to diagnose problems
              with them and understand how they are used so we can make them better. Sometimes, we also apply a degree of personalisation in choosing which
              content to show you (for example, by recommending articles similar to those you’ve viewed previously).
            </p>
            <p>You can learn more about what we do with this information and why in the sections below.</p>

            <ul>
              <li>
                <a href="#3.1-section">3.1. Targeting by Reach</a>
              </li>
              <li>
                <a href="#3.2-section">3.2. Targeting by third party advertising networks</a>
              </li>
              <li>
                <a href="#3.3-section">3.3. Making our sites and apps work properly and understanding how they are used</a>
              </li>
              <li>
                <a href="#3.4-section">3.4. Website Notifications (Pop ups)</a>
              </li>
            </ul>
            <br />
            <section>
              <Typography variant="h5bold" color="primary" id="3.1-section">
                3.1. Targeting by Reach
              </Typography>
              <p>
                We combine the information you give to us (for example, sign up information for newsletters and competitions) with the information we receive
                when you use our websites and apps, and analyse it in order to help us to understand your preferences and interests. We then associate this with
                a unique identification number. This is then used to tailor the content and advertising that you see on our websites and in our apps, so it is
                more likely to be of interest to you. We don’t share this personalised analysis with anyone outside Reach. In particular, it is not shared with
                advertisers or with the third-party advertising networks that we work with other than in highly aggregated form so that your personal data
                cannot be identified.
              </p>
              <section>
                <Typography variant="h6bold" color="primary">
                  3.1.1. The data we collect and what we do with it
                </Typography>
                <p>The data we use for this purpose is as follows:</p>
                <ul>
                  <li>The page you view, and associated information such as how long you spend on them.</li>
                  <li>Your approximate location, to city or town level.</li>
                  <li>
                    Your email address, name and sometimes username and postcode, which we will receive when you sign up for an account on one or more of our
                    websites or apps, when you subscribe to our newsletters or marketing, when you enter one of our competitions or when you complete one of our
                    surveys.
                  </li>
                  <li>
                    The newsletters and marketing you have subscribed to, the promotions and competitions you have entered and the surveys you have completed.
                  </li>
                </ul>
                <p>
                  We will analyse this information to infer interests, preferences, and demographic characteristics, and associate it with an internal
                  identification number. For example, if our website analytics tell us that you are located in the approximate area of Newcastle-upon-Tyne and
                  you read our online title ChronicleLive (which is focused on local news for that area), and you sign up to receive an email newsletter about
                  football from our national title The Mirror, then we could infer from that you are interested in things related to a local football club or to
                  football generally.
                </p>
                <p>
                  We then divide up our identification numbers into groups based on the inferences we draw about them. Those groups are called “audience
                  segments” or “audiences”. So, for example, an identifier associated with Newcastle, ChronicleLive and the football newsletter could be
                  allocated to audiences likely to be interested in football, and events near Newcastle-upon-Tyne.
                </p>
                <p>
                  We then use those “audiences” to do two things: first, to help us to recommend content which is more likely to be interesting (for instance,
                  more articles about football), and second to help us to target advertisements when advertisers buy advertising space from us directly.
                </p>
                <p>
                  The advertiser would never see the actual data that we use – it remains with Reach at all times. All we would share with them would be the
                  criteria we used to target their advert, how many times their advert was shown and how many people clicked on it (but not who), so that they
                  know how well the advert did and we know how much to charge them.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  3.1.2. Our legal basis
                </Typography>
                <p>
                  Your activity on our websites and in our apps can be managed by you using our “cookie banner” and related Consent Management Platform (CMP).
                  The vast majority are used on the basis of your consent where you provide it. Some may use our Legitimate Interest and some are Essential –
                  Please see our <a href="/legal/cookie-notice">Cookie Notice</a> for further information or the ‘cog’ icon or link at the bottom of every
                  webpage to manage your consents. When using our apps, if you want to change the settings, go to the ‘My Account’ Link at the bottom of the
                  screen which includes ‘My Privacy’ and takes you to the CMP.
                </p>
                <p>
                  We combine that information with the other things we know about you (like your newsletter subscriptions) on the basis of our legitimate
                  interest in understanding our readership, both to serve you better and to make us more attractive to advertisers. This helps us to secure a
                  future for news reporting and journalism in the age of social media, especially local news reporting and journalism.
                </p>
                <p>
                  You do, of course, have an absolute right to object to this. You can always register your objection by completing the relevant form{' '}
                  <a href="#15.5-section">15.5. Your right to object to what we do with your data, and to have restrictions placed upon it</a>.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  3.1.3. How long we keep it for
                </Typography>
                <p>
                  We keep the information about your activity on our websites and in our apps for up to up to three years since last browsing activity, with
                  some anonymised browsing activity retained thereafter.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  3.1.4. Who we share it with and why
                </Typography>
                <p>
                  The data which we use to create the unique identifier and our “audiences” is shared with the partners who provide us with the technology
                  powering it, most notably Upland Software and Lotame, who host core data management platforms for us. They act as our “processors”. This means
                  that they are bound by law and contract to do only what we tell them to do with the data, to keep it safe, and not to share it with anyone
                  else or use it for anything other than providing their service to us.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  3.1.5. Whether we send it outside the country of collection
                </Typography>
                <p>
                  Upland Software and Lotame are global businesses and so the data they host for us may be held outside of the country of collection. We have
                  implemented the required safeguards as necessary (for example, standard agreements approved by the relevant authorities).
                </p>
              </section>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="3.2-section">
                3.2. Targeting by third party advertising networks
              </Typography>
              <p>
                Digital advertising helps us to fund the news articles and content published on our sites and enables us to provide some of our content for
                free. We and our third-party ad partners may display digital advertising on our sites that is tailored to your interests and preferences so the
                online adverts you see are more relevant to you. We work with third party ad networks to fill unsold advertising space on our sites and in our
                apps by auctioning it automatically through a process called “real time bidding”. These ad networks bid to show their ad to you when you visit
                our sites or use our apps.
              </p>
              <p>
                The ad networks exchange information through cookies, which allow them to recognise you when you visit any site that they work with (not just
                Reach sites). Where we use cookies on our sites for advertising purposes, we ask for prior consent. The cookie information will be used along
                with other information we hold about you to display digital advertising that is more relevant to you. It is important to note, however, that
                where you do not provide your consent for digital advertising you will still see digital advertising on our sites, but this will not be tailored
                to your interests and preferences.
              </p>
              <p>
                Where you provide consent to using your data to create a profile for digital advertising we may also sell or share limited data types to our
                partners with whom we have a legally binding relationship for similar purposes; typically data on the page content which you have shown an
                interest in and online identifiers, not direct identifiers such as your email address. Captify is one such partner. They may act as an
                independent controller of your data, and their privacy policy is shown here:
                <a href="https://www.captifytechnologies.com/privacy-notice/">Captify Privacy Policy</a> .
              </p>
              <section>
                <Typography variant="h6bold" color="primary" id="3.2.1-section">
                  3.2.1. The data collected and how it is used
                </Typography>
                <p>
                  On your first visit to one of our sites or apps (and on other occasions to allow you to change your mind), you will be presented with a
                  ”cookie banner”. This asks you if you consent to the use of cookies and related technologies for the purposes of, amongst other things,
                  selecting and delivering ads. If you do, then the ad networks will be able to recognise your device according to a unique identification
                  number.
                </p>
                <p>
                  We then send out a “bid request” to the advertising networks we work with. What that bid request says is, essentially, ”ID number ABC123 has
                  landed on our website, do you want to show this user an advert?” The winner then gets to display their ad.
                </p>
                <p>
                  The auction process happens automatically, in the second or so between you requesting the page and its loading and displaying. The data used
                  by the ad networks to select the ad to show is held by them, not us, and we never see it.
                </p>
                <p>
                  If we sell or share limited data to an ad partner, as described in 3.2, it will be used for profiling and digital marketing on Reach websites
                  and apps, and potentially other sites & apps with whom the partner has a relationship, if you have given permission for that site or app to do
                  so.
                </p>
                <p>
                  An important point to understand is that most of these ad networks will not know who you are in a “real world” sense. They will not know your
                  name, or your email address, or your phone number (we may know those things because you’ve given them to us in other contexts, but we never
                  share that information with them).
                </p>
                <p>
                  We say “most” because there are two significant exceptions, which are Facebook and Google. They provide targeted advertising services based on
                  the things they know about their users, and so if you are signed into your account with them on your device, they will be able to recognise
                  you and associate your browsing history across sites that use their services.
                </p>
                <p>
                  <b>Data Matching:</b>
                </p>
                <p>
                  From time to time we may allow our clients to compare their customer database to ours using partner platforms such as InfoSum. This is called
                  data matching and allows our partners to see if they can reach their audiences on our platforms and to understand if an advertising campaign
                  would be effective.
                </p>
                <p>
                  Generally this will be conducted at an aggregate audience level to provide insights, rather than individual level information, although your
                  information may be used to generate these insights. For example, this could involve matching your hashed, pseudonymised email data with the
                  same data held by our advertising clients. This then enables the advertiser to understand if you use their products and visit our sites, then
                  to use this data to target bespoke advertising messages.
                </p>
                <p>
                  Alternatively, we may allow advertising partners to build target audiences based on predefined attributes. These ‘lookalike’ audiences may be
                  similar to our partners’ existing audiences. For example, a sports brand may want to reach ‘football enthusiasts’. They could select audiences
                  who have been identified as having an interest in these areas. To do this, we segment our audiences into groups depending on what we think
                  they like or other behaviours. This helps them find the right audiences and we are able to show you more relevant advertising.
                </p>
                <p>
                  The way we match your data could also involve working with partners such as LiveRamp Inc and Tradedesk and their group companies, acting as
                  joint controllers. These partners use this information to create a secure online identification code for the purpose of recognising you for
                  cross-channel advertising. This code is placed in our cookie, does not contain any of your identifiable personal data, and will not be used by
                  them to identify you. It may be shared with our advertising partners and other third-party advertisers globally for the purpose of enabling
                  interest-based content or targeted advertising through Reach sites. These third parties may in turn use this code to link demographic or
                  interest-based information you have provided in your interactions with them to the pseudonymised code. Detailed information on LiveRamp’s data
                  processing activities is available in LiveRamp’s privacy notice and opt-out here{' '}
                  <a href="https://liveramp.uk/privacy/service-privacy-policy/">LiveRamp.</a> Information on the Trade Desk’s use and how they work with
                  partners can be found here <a href="https://www.transparentadvertising.eu/privacy">EUID Privacy Notice.</a> Alternatively to withdraw consent
                  and object to targeted advertising based on the Trade Desk EUID activity <a href="https://www.transparentadvertising.eu/">click here</a>.
                </p>
                <p>
                  If you accept the use of cookies in our Consent Management Platform, when you submit your email address to create an account or sign-up to a
                  newsletter or marketing we may share your email address in a hashed form with Amazon Publisher Services (APS). APS then links the hashed email
                  to a profile which they may already hold related to that address. The hashed email will be used in a secure, pseudonymised form within a
                  cookie to flag to APS whenever you visit an APS enabled Reach website again. APS will use this to determine, in real-time, if any of their
                  business customers want to market to a profile similar to yours. If this is the case they may initiate an advert which will appear to you on
                  the Reach website concerned. The APS Privacy Notice can be found at <a href="https://aps.amazon.com/aps/privacy-policy/">APS PN.</a>
                </p>
                <p>
                  <b>Withdrawing consent:</b>
                </p>
                <p>
                  You have the right to withdraw your consent or opt-out to the processing of your personal data at any time via our Cookie Management Platform
                  accessed via the ‘cog’ icon or link at the bottom of every webpage. For example, we will only match your hashed, pseudonymised email with an
                  advertiser in the Infosum platform, if you have consented to your data being used by our adtech provider Lotame. We will also only work with
                  partners such as LiveRamp, as described above, if you have accepted cookies in our Consent Management Platform.
                </p>
                <p>
                  Alternatively, if you would like to stop your email being used in this way please email dataprotection@reachplc.com, stating ‘opt out of
                  hashed email’.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  3.2.2. Our legal basis
                </Typography>
                <p>
                  Where required by applicable law advertising networks undertake this activity on the basis of your consent, which we ask for on their behalf
                  through the ‘cookie banner’ which appears when you first visit our site. The ‘cookie banner’ message box will reappear from time to time in
                  case you change your mind, or when something changes (typically every 30 days). Please see our
                  <a href="/legal/cookie-notice">Cookie Notice</a> for further information or the ‘cog’ icon or link at the bottom of every webpage to manage
                  your consents. When using our apps, if you want to change the settings, go to the ‘My Account’ Link at the bottom of the screen which includes
                  ‘My Privacy’ and takes you to the CMP.
                </p>
                <p>
                  Most of our third-party advertising partners operate within an industry framework and code of practice issued by the
                  <a href="https://iabeurope.eu/"> Internet Advertising Bureau (IAB)</a> called the “Transparency and Consent Framework”, which sets out the
                  technical means for your choices to be passed through the ad networks. It also requires that members adhere to IAB policies and standards of
                  behaviour.
                </p>
                <p>
                  Advertising partners who do not work within the IAB framework are listed separately in the platform which can be viewed in our Consent
                  Management Platform (CMP) – please see our <a href="/legal/cookie-notice">Cookie Notice</a> for further information or click on the ‘cog’ icon
                  or link at the bottom of every webpage. In the Consent Management Platform popup go to the ‘Partners’ page and scroll down - IAB partners are
                  listed first, then you'll see Non-IAB vendors and finally Google partners.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  3.2.3. How long we keep it for
                </Typography>
                <p>
                  We do not ourselves hold a copy of the data gathered by the advertising networks. They will each have their own retention policies which you
                  can find in their privacy notices linked through our ‘cookie banner’ – these are often short (between 30 and 60 days), because shopping
                  interests change over time.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  3.2.4. Who we share it with and why
                </Typography>
                <p>
                  When we participate in auctions for unsold advertising space, we send bid requests to third party advertising networks. You can find the list
                  of third-party advertising networks who we work with by clicking on the ‘cog’ icon or link at the bottom of every webpage and reviewing our
                  Consent Management Platform.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  3.2.5. Whether we send it outside the country of collection
                </Typography>
                <p>
                  The digital advertising networks are global in nature and so, while we are predominantly a UK business, this data will travel across
                  international borders, and in particular many automated advertising transactions will be processed in the USA. Where we are the ones making
                  the transfer (typically, we are not), we have implemented the required safeguards as necessary (for example, standard agreements approved by
                  the relevant authorities).
                </p>
              </section>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="3.3-section">
                3.3. Making our sites and apps work properly, and understanding how they are used
              </Typography>
              <p>
                We use the information logged by our servers and certain third-party analytics tools (such as Google Analytics, Amplitude, Mpulse and Hotjar) to
                help us to spot problems with our digital services, such as page errors or slow loading times, as well as understanding usage patterns and
                watching for security problems. This may include statistical and analytical data about your visits and activity on our sites, such as the pages
                you view, how long for, and which links are followed. This helps us to improve the way we deliver content and the products and services offered
                to you. We also use this data to report on our performance, provide audience insights and measurement, and to sell advertising.
              </p>
              <p>
                We may also collect information from your devices that tells us whether you are using an ad blocker. This is so that we can manage our
                compliance with the law and also determine if we are able to show you advertising. We may also ask you to suspend or whitelist our sites or
                platforms so that we can show you adverts.
              </p>
              <p>You can find more information below.</p>
              <section>
                <Typography variant="h6bold" color="primary">
                  3.3.1. The data we collect and what we do with it
                </Typography>
                <p>We gather data for this purpose in three ways.</p>
                <p>
                  Firstly, our servers record certain information provided by your devices when they request content, consisting mostly of technical information
                  about your device such as language, screen size and the features and functions it supports, but also including the internet or “IP” address
                  from which it is connecting, and the page or data requested. The data is used for system performance, content delivery and security purposes.
                  It is saved together with the dates and times the requests occurred into files stored on our servers called “logs” or “log files”.
                </p>
                <p>
                  Secondly, on your first visit to one of our sites or apps (and every so often again after your first visit), you will be presented with a
                  ’cookie banner’ asking you if you consent to the use of cookies and related technologies for our use of analytics. If you do, then several
                  “cookies” (small text files) will be stored on your device containing identification numbers. Those numbers can then be read on your
                  subsequent visits to recognise your device, and our analytics providers will use that information to provide us with various reports and
                  metrics on how our sites are used. Google also uses the data that it gathers as a result for other purposes, which you can read about in
                  <a href="https://policies.google.com/privacy"> Google Privacy Policy</a>. If you wish to opt-out of the use of Google Analytics you can access
                  and download the Opt-out browser add-on from here: <a href="https://tools.google.com/dlpage/gaoptout">GA Opt-Out</a>.
                </p>

                <p>
                  Thirdly, we also work with the analytics provider Amplitude, who collect measurement data on our behalf, as our processor. They collect this
                  data for us, using their cookie, and only process your data with your consent. If you do not consent they will only provide us with anonymised
                  data.
                </p>
                <p>
                  To withdraw your consent for how we are using your information, please see <a href="15.4-section">15.4 Your right to withdraw consent.</a>
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  3.3.2. Our legal basis
                </Typography>
                <p>
                  We process the personal data captured in our server log files on the basis of our legitimate interest in monitoring our systems, understanding
                  how they are used, fixing problems with them, and for security monitoring and management purposes.
                </p>
                <p>
                  We process the data for determining an adblocker, on the basis of legitimate interest. As a predominantly advertising funded business, we do
                  rely on our ability to show you advertising to help ensure we are able to provide you with the products and services you request.
                </p>
                <p>
                  We process the personal data captured by our analytics providers on the basis of your consent, which we ask for through a “cookie banner”
                  which appears when you first visit our site. That message box will reappear from time to time in case you change your mind (typically every
                  thirty days), and on your first visit after we make a significant change.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  3.3.3. How long we keep it for
                </Typography>
                <p>We keep the information in our server log files for up to 90 days, depending upon the log type and purpose.</p>
                <p>
                  Our analytics providers will keep the information they gather from our sites and apps in line with their own privacy notices, which you can
                  access through our ‘cookie banner’ and Consent Management Platform (see the ‘cog icon’ or link at the bottom of every webpage).
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  3.3.4. Who we share it with and why
                </Typography>
                <p>We use AWS and CloudFront to help us to analyse our server log files. They act at our direction, as our “processor”.</p>
                <p>
                  The information gathered using Google Analytics is held by Google and is made accessible to us by Google through various dashboards and
                  reporting tools.
                </p>
                <p>The following providers are key to providing additional website analytics or diagnostic capability:</p>
                <ul>
                  <li>
                    <p>
                      Akamai Mpulse (<a href="https://www.akamai.com/legal/privacy-and-policies">https://www.akamai.com/legal/privacy-and-policies</a>)
                    </p>
                  </li>
                  <li>
                    <p>
                      Chartbeat (<a href="https://chartbeat.com/privacy/">https://chartbeat.com/privacy/</a>)
                    </p>
                  </li>
                  <li>
                    <p>
                      Hotjar (<a href="https://www.hotjar.com/privacy/">https://www.hotjar.com/privacy/</a>)
                    </p>
                  </li>
                  <li>
                    <p>
                      Viafoura (<a href="https://viafoura.com/privacy-policy/">https://viafoura.com/privacy-policy/</a>)
                    </p>
                  </li>
                  <li>
                    <p>
                      Amplitude (<a href="https://amplitude.com/privacy/">https://amplitude.com/privacy/</a>)
                    </p>
                  </li>
                </ul>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  3.3.5. Whether we send it outside the country of collection
                </Typography>
                <p>
                  The technology providers we use are global businesses. The data is located in Ireland with some being routed through AWS outside of the UK and
                  EEA. We have implemented the required safeguards as necessary (for example, standard agreements approved by the relevant authorities).
                </p>
              </section>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="3.4-section">
                3.4. Website Notifications (Pop ups)
              </Typography>
              <p>
                We provide news and updates about our sites via push notification messages or ‘pop-ups’. This is a free service. Our legal basis is consent, as
                the notifications are only sent to you if you have clicked on a button to allow it (you may have accidentally selected to receive
                notifications).
              </p>
              <p>
                We have no control over your subscription to notifications as it is controlled by your browser and device. We do not store any recognisable data
                about you or your device in connection with the push notification service.
              </p>
              <p>
                If you wish to unsubscribe from receiving notifications please follow the instructions here: Airship-
                <a href="https://support.airship.com/hc/en-us/articles/4446152158619-How-to-opt-out-from-the-web-notification">
                  https://support.airship.com/hc/en-us/articles/4446152158619-How-to-opt-out-from-the-web-notification
                </a>
              </p>
              <p>
                If you need additional assistance in relation to push notifications, please contact the team at dataprotection@reachplc.com - with the Subject
                heading: Push Notifications
              </p>
            </section>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="4-section">
              4. When you create or sign into your online news or other Reach account
            </Typography>
            <p>
              Some elements of our websites and apps require you to create an account in order to use them; for example, the ability to leave comments under
              articles or to use some of the functions of “InYourArea”.
            </p>
            <p>This section covers the following:</p>
            <section>
              <ul>
                <li>
                  <a href="#4.1-section">4.1 Online News account</a>
                </li>
              </ul>
              <ul>
                <li>
                  {' '}
                  <a href="#4.2-section">4.2 Specialist Accounts including Funeral Notices, Bookanad, Marketplacelive, and Family Notices sites </a>
                </li>
              </ul>
              <br />
              <Typography variant="h5bold" color="primary" id="4.1-section">
                4.1. Online News account
              </Typography>
              <p>
                We use your email address and the other information that you give to us as part of the account creation process to operate your account and
                provide those functions. When you are signed in with your Reach account, we will also have the ability to associate your account details with
                the information we gather through our analytics tools about how you use our sites.
              </p>
            </section>
            <section>
              <Typography variant="h6bold" color="primary">
                4.1.1 The data we collect and what we do with it
              </Typography>
              <p>When you create an account we may collect your name and email address.</p>
              <p>
                For services that provide local information (like InYourArea) we will also collect your postcode and any associated areas of local interest that
                you want to be updated about. If you subscribe to a paid version of our sites such as{' '}
                <a href="https://www.express.co.uk/premium-subscription">Express Premium</a> we will collect your payment details, in order for us to fulfil
                your subscription. For more information on subscriptions see <a href="#9-section">9. When you subscribe to our print or “Digital Edition” </a>
                titles.
              </p>
              <p>
                Some services may let you upload your own content to our sites, such as comments or pictures. Those things will, by the nature of the service,
                be generally visible on the site. Your comments may be used in print, online or on the social pages for brands owned and published by Reach plc.
                We may also record what types of content you have commented on, and associate this with our profile of you. Please do not provide any personal
                data which you do not want to be published. Comments will be subject to moderation checks before publication.
              </p>
              <p>
                We use this information to operate your account and to enable you to log in and use our services; for example, on InYourArea we use your
                postcode to identify the correct local information to display to you.
              </p>
              <p>
                You may also be invited during the account creation process to subscribe to one or more of our newsletters, or to opt in to receive certain
                marketing offers and promotions. You can read more about that by navigating to the appropriate section of this Privacy Notice via the
                <a href="#contents"> ‘Contents’ list.</a> Click here to go back to ‘Contents’
              </p>
              <p>
                Unless you object, we will also associate your online account sign-up data with the other things we know about you, like your page views. You
                can learn more about that, including how to object, here.
              </p>
            </section>
            <section>
              <Typography variant="h6bold" color="primary">
                4.1.2. Our legal basis
              </Typography>
              <p>
                We process your email address and the other information you give to us in order to provide the relevant function or service of our sites on the
                legal basis of consent. We associate the data which you provide on sign-up with the profile created from your browsing activity on the basis of
                our legitimate interest. If you wish to object to this, where permitted by applicable law, please see section
                <a href="#3-section"> Targeting by Reach</a>.
              </p>
              <p>
                Our legal basis for any resulting email marketing is your consent – <a href="#6-section">see here for further information.</a>
              </p>
            </section>
            <section>
              <Typography variant="h6bold" color="primary">
                4.1.3. How long we keep it for
              </Typography>
              <p>We will keep your account active for as long as you continue to use it, and for up to 3 years afterwards.</p>
            </section>
            <section>
              <Typography variant="h6bold" color="primary">
                4.1.4. Who we share it with and why
              </Typography>
              <p>
                We use a technology provider called LoginRadius <a href="https://www.loginradius.com/">LoginRadius</a> to handle the technical aspects of
                operating your account on our behalf. They host your email address and password. They act as our “processor”, meaning that they are bound by law
                and contract to keep your data safe, and to do only what we tell them to do with it.
              </p>
            </section>
            <section>
              <Typography variant="h6bold" color="primary">
                4.1.5. Whether we send it outside the country of collection
              </Typography>
              <p>
                Our user account technology provider LoginRadius is based in California and India, and so your personal data will be processed from those
                locations as part of their provision of their service to us. We have implemented the required safeguards as necessary (for example, standard
                agreements and frameworks, approved by the relevant authorities).
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="4.2-section">
                4.2. Specialist Site Accounts
              </Typography>
              <p>
                This section covers when you create an account and use our services on the Funeral Notices, Bookanad, Marketplacelive, and Family Notices sites.
              </p>
              <section>
                <Typography variant="h6bold" color="primary">
                  4.2.1 The data we collect and what we do with it
                </Typography>
                <p>
                  In order to use many of our website features you will have to create an account, for example, if you wish to post a funeral notice, upload a
                  tribute to a notice, post an announcement, place an advertisement, or place a notice.
                </p>
                <p>
                  When you create an account we will collect, at a minimum, your name and email address. In some circumstances, we may also collect your address
                  and your telephone number. We use personal data as part of the account creation process, and to ensure ongoing operation of your account, and
                  access to the services available on our websites.
                </p>
                <p>
                  We use all this personal data to (i) operate your account and to enable you to log in and use our services, (ii) manage and administer your
                  notice, announcement, and/or advertisement, and (iii) provide you with some of the features and functionalities that are reserved for account
                  holders.
                </p>
                <p>Some examples of using the personal data you provide include:</p>
                <ul>
                  <li>
                    When creating a local obituary notification on our Funeral Notices website, we use your city/town to ensure that we provide information that
                    is relevant and useful for you.
                  </li>
                  <li>
                    When managing a live advertisement, if your advertisement has been successful, you can log in to your account and change the status of the
                    advertisement to closed, ensuring that you no longer receive communications relating to that advertisement.
                  </li>
                </ul>
                <p>
                  When you post a notice and/or an announcement and, as part of this post, provide personal data that relates to a living third party, you are
                  responsible for making sure that you have obtained the appropriate permissions for sharing their personal data. For example, uploading a
                  photograph or a video to a notice that contains the image of another person(s).
                </p>
                <p>
                  Some of our services may allow you to upload your own content to our websites, for example any comments, pictures, or videos uploaded as part
                  of an advertisement or a notice. Such content, by the nature of our service, will be visible on the website to other users and the general
                  public. When creating a new notice or announcement, or interacting with an existing one, please do not provide any personal data which you do
                  not want to be published and accessible by the general public.
                </p>
                <p>
                  The “controller” for advertising activities on some of our websites is Proweb. <a href="#4.2.7-section">See 4.2.7.</a>
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  4.2.2 Funeral Notices Pay-to-use services
                </Typography>
                <p>Once you have created an account on Funeral Notices, our products/services will be made available for purchase.</p>
                <p>
                  When you purchase a product/service, either online, via our website, or offline, via our call centre, we will use the personal data that you
                  have provided during the account creation process to administer the sale of the product/service you have selected. We may also collect
                  additional personal data in order to enable the sale, including the processing of payment information provided by you during the checkout
                  process.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  4.2.3 Funeral Notices Free-to-use services
                </Typography>
                <p>
                  Some of our Funeral Notice services are made available for free, and may allow you to upload your own content to our websites, for example any
                  comments, pictures, or videos uploaded as part of a tribute. Such content, by the nature of our service, will be visible on the website to
                  other users and the general public.
                </p>
                <p>
                  Once you have created an account, some of our products/services may enable the disclosure of information typically viewed as sensitive, for
                  example providing information about your religious beliefs when dedicating a prayer or a religious notice to a loved one.
                </p>
                <p>
                  When uploading information to our websites, please do not provide any personal data which you do not want to be published. It is also
                  important to note that all comments on active notices will be subject to moderation checks before publication.
                </p>
                <p>
                  When you request either of the above Funeral Notices services and, as part of this service, provide information concerning a living third
                  party, you are responsible for making sure that you have obtained the appropriate permissions for sharing this information. For example,
                  uploading a photograph or a video to a tribute that contains the image of a large number of individuals.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  4.2.4 Notices placed by Funeral Directors
                </Typography>
                <p>
                  We offer Funeral Directors across the UK the option to use our service, and to post funeral notices for publication on the relevant area of
                  our website.
                </p>
                <p>
                  If you instruct your Funeral Director to post an online funeral notice on our website, then the information that you provide your Funeral
                  Director will be posted on our website.
                </p>
                <p>
                  Please note that when collecting information to inform their funeral notice, Funeral Directors will do so as “data controllers”. This means
                  that they are responsible for how they handle and use the personal data that they collect from you.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  4.2.5 Our legal basis
                </Typography>
                <p>
                  We process your email address and the other personal data provided during the account creation process so that we may fulfil the contractual
                  obligations owed for the sale of a product/provision of a service, e.g., the posting of an online and/or in-print advertisement and/or notice.
                  This includes the processing of any payment information that is required to facilitate the sale of such products/services.
                </p>
                <p>
                  We process your email address and the other personal data you provide during the account creation process on the basis of your consent for us
                  to provide and operate an account on your behalf.
                </p>
                <p>
                  We process any personal data that you upload to our websites to deliver notices on the basis of our legitimate interest in operating an online
                  obituary service. Please note that, when uploading content, you are in complete control of what you post.
                </p>
                <p>
                  We process your payment information in order to fulfil contractual obligations owed for the sale of a product/provision of a service, e.g.,
                  the posting of an online and/or print funeral notice.
                </p>
                <p>
                  We process any sensitive information you provide regarding your religious beliefs on the condition that, by making a post on a generally
                  accessible website, you have manifestly made the information public.
                </p>
                <p>
                  The posting of a funeral notice, by a Funeral Director, is always performed based on the instructions that are provided to individual Funeral
                  Directors by the families of the deceased, and in accordance with the performance of the contract that you have entered into with the Funeral
                  Director.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  4.2.6 How long we keep it for
                </Typography>
                <p>
                  As a general principle, we will only retain your personal data for as long as it is necessary. More information on particular retention
                  periods is provided below.
                </p>
                <p>
                  <b>Website data</b>
                </p>
                <p>
                  Our analytics providers will keep the information they gather from our websites in line with their own privacy notices, which you can access
                  through our ‘cookie banner’ and Consent Management Platform (see the ‘cog icon’ at the bottom left of every webpage)
                </p>
                <p>
                  <b>Account data</b>
                </p>
                <p>
                  Bookanad - When you make a booking through our bookanad website, we will keep your account active for as long as you continue to use it, and
                  it will remain active until you request its deletion, or an inactive period of 7 years has elapsed.
                </p>
                <p>
                  Funeral Notices, Family Notices, and Marketplacelive - When you register for a new account on Funeral Notices, Family Notices, and
                  Marketplacelive, we will keep your account active for as long as you continue to use it, and it will remain active until you request its
                  deletion.
                </p>
                <p>If you have made a purchase, we will keep a record of your transactions for a period of 7 years post-transaction.</p>
                <p>
                  <b>Funeral Notice data</b>
                </p>
                <p>
                  All funeral notices, posted by Funeral Directors and the general public, will remain accessible on our website indefinitely, so that the
                  passing of a loved one may remain remembered forever. This will include any tributes posted on a funeral notice, and all other related
                  services, for example remembrances.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary" id="4.2.7-section">
                  4.2.7 Sharing your personal data with third parties
                </Typography>
                <p>
                  We will only share your personal data with a third party if there is an appropriate contract in place that binds that third party to act in
                  accordance with our instructions.
                </p>
                <p>Further information regarding the third parties that we share your personal data with is detailed below.</p>
                <p>
                  <b>Proweb.</b> We use a third-party provider called Proweb to handle the technical aspects of operating an account on our behalf, managing
                  bookings made through Bookanad, and administering our email lists. In order to provide us with this service, they will process the personal
                  data that you provide when you create an account and place a booking, in addition to other information that is captured from your use of our
                  website.
                </p>
                <p>
                  When providing these services, they act as our “data processor”, meaning that they are bound by law and contract to keep your data safe, and
                  to do only what we tell them to do with it.
                </p>
                <p>
                  On two of our websites, Marketplacelive and Family Notices, Proweb manages the online advertisements that are displayed on-site. These
                  advertisements will be personalised to you, based on your use of the site, if you have accepted the placement of advertising cookies on-site.
                  For this activity, Proweb will be acting as a data controller; their Privacy Notice can be accessed here:{' '}
                  <a href="https://www.proweb.net/terms-and-conditions/proweb-privacy-statement">Proweb Privacy Statement.</a>
                </p>
                <p>
                  <b>Donatis.</b> We use a third-party provider called Donatis to manage all of the charitable donations made through our Funeral Notices
                  website. Please note that Donatis are a “data controller”, therefore, all on-site donations will be processed in accordance with their own{' '}
                  <a href="https://www.donatispayments.co.uk/terms/privacy/">Privacy Notice</a>, and Terms and Conditions.
                </p>
                <p>
                  When you write a message to accompany your donation, this message will be posted as a tribute on the funeral notice and, once the donation
                  period has closed, this message will be shared with the Funeral Director that posted the notice, so that they may share all of the donation
                  messages with the family of the deceased. If, when writing a message, you choose to make it private then, once the donation period has closed,
                  this message will be shared with the Funeral Director and the family. A private message will not be posted on our website.
                </p>
                <p>
                  When you make a donation, and select Gift Aid, we will share some personal data with the relevant charity. This will include your name, email
                  address, and donation amount. This information enables the chosen charity to avail of the UK Government’s tax relief program, meaning that
                  they receive more of your donation than they otherwise would. Please note that Gift Aid is only available to UK tax residents; further
                  information regarding Gift Aid can be found <a href="https://www.gov.uk/claim-gift-aid">here.</a>
                </p>
                <p>
                  <b>Marketplacelive</b> When you decide to place an advertisement on Marketplace Live, through our Bookanad website, the information that you
                  have provided will be shared with Marketplace Live, so that the publication and administration of your advertisement may be effectively
                  managed.
                </p>
                <p>
                  <b>InYourArea.</b> When you elect to place a notice on the InYourArea website, through our Bookanad website, the information that you have
                  provided when creating an account, and a notice, will be shared with InYourArea so that they may manage and administer your online post. For
                  information about how InYourArea will handle your personal data, please see their own published{' '}
                  <a href="https://www.inyourarea.co.uk/news/privacy-notice/">Privacy Notice.</a>
                </p>
                <p>
                  <b>Payment processors.</b> We use third party payment processors to handle payments made through our websites. These third party payment
                  processors may act as both a “data controller”, meaning that they define why and how to process the payment information you provide, and a
                  “data processor”, meaning that they act in accordance with our specific instructions when processing your transactional data.
                </p>
                <p>
                  <b>Ancestry.</b> Ancestry is a sponsor of our Funeral Notices website. A link to their website can be found on the sponsorship icon visible on
                  all funeral notices. Please note that by clicking on the ancestry sponsorship area of the funeral notice page, you will be taken to Ancestry’s
                  own website, where an automatic search will be performed on the name of the deceased. This search will be performed based on the URL that you
                  have visited Ancestry from. We do not share any of your personal data with Ancestry; however, if you register for an Ancestry account, after
                  following a link within a notice, Ancestry will be able to identify that you came from our website.
                </p>
                <p>
                  <b>AddThis (Oracle).</b> We have installed a tool called AddThis, provided by Oracle, on various pages of our websites. This tool provides you
                  with the ability to share a notice on social media platforms of your choice. When you interact with the AddThis tool, and decide to share a
                  notice on social media, AddThis will collect some personal data from your interaction. AddThis collects your personal data as a “data
                  controller”, meaning that they will use it for their own purposes. Further information on how AddThis uses personal data can be found in their{' '}
                  <a href="https://www.oracle.com/uk/legal/privacy/addthis-privacy-policy.html">Privacy Policy.</a>
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  4.2.8 Working at an organisation that we do business with
                </Typography>
                <p>
                  For all of our customers and other organisations we do business with, we will have the business contact details of the people we work with at
                  those organisations, and we will use them to manage and administer our relationship with that organisation. We do this on the basis of our
                  legitimate interest in managing and administering those relationships.
                </p>
                <p>
                  We keep the details of our contacts at organisations we do business with for as long as we continue to have a business relationship, and for a
                  period thereafter for business development purposes and in case of issues or disputes.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  4.2.9 Advertising as a company that we do business with
                </Typography>
                <p>
                  When you place an ad, e.g. as a funeral director, we will collect your name and contact details, your postcode, your payment information, and
                  of course the content of your ad. We will use that information to publish and administer your ad, and to communicate with you about it. The
                  content of your ad will be published and therefore accessible to the public. We do this on the basis that it is necessary to fulfil the
                  contract agreed with you when you place your ad.
                </p>
                <p>
                  We will retain the contact details of private individuals for up to 7 years from the point of last interaction, and trade contact details for
                  as long as we have a business purpose.
                </p>
                <p>
                  Your ad itself will be retained indefinitely, on the basis of our legitimate interest, in order to maintain our archive and back issues
                  services.
                </p>
              </section>
            </section>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="5-section">
              5. When you buy a product or service from us
            </Typography>
            <p>
              At Reach, we manage two main online e-commerce platforms: <a href="htts://Yimbly.com">Yimbly.com</a> and{' '}
              <a href="https://www.okbeautybox.co.uk/">OK! Beauty Box.</a> In addition we run a number of ecommerce sites associated with our newspaper brands
              such as <a href="https://reachsportshop.com/">Reach Sport Shop</a> or{' '}
              <a href="https://shop.regionalnewspapers.co.uk/">our Regional Newspaper Shop.</a>
            </p>
            <p>
              When you place an order for a product or service with us, it is necessary to share specific personal details to ensure the seamless fulfilment of
              your order. This information may be gathered during the registration process or by placing an order via our checkout. In certain situations, we
              share with a third party that will collect and process this information on our behalf to fulfil your order.
            </p>
            <section>
              <Typography variant="h5bold" color="primary" id="sendyou">
                5.1 The data we collect and what we do with it
              </Typography>
              <p>We may collect and use data in a number of ways when you buy a product or service from us. These include:</p>
              <ul>
                <li>When you explore any of our websites and utilise your account for purchasing products, services, or redeeming digital vouchers.</li>
                <li>When you make an online purchase and opt for guest checkout (in which case, we only gather transaction-based data).</li>
                <li>When you opt-in to marketing</li>
                <li>When you create an account with us.</li>
                <li>
                  While shopping online, we gather information through cookies and similar technologies – you have the control to manage these settings during
                  your site visit.
                </li>
                <li>When you interact with us on social media.</li>
                <li>When you engage with chatbots on our site, to communicate with us and ask us questions.</li>
                <li>
                  Upon signing up for an account you will join our loyalty program, you gain access to a designated area on our sites. This space is designed to
                  collect and store all your receipts, guarantees, and warranties for your convenience with each purchase whilst generating points that convert
                  into monetary value.
                </li>
                <li>
                  When you reach out to us through various channels with queries, complaints, etc. For example, when you contact our customer service team
                  regarding an order.
                </li>
                <li>Upon participating in prize draws or competitions.</li>
                <li>When you opt to complete surveys we send your way.</li>
                <li>When you share comments or reviews on our products and services.</li>
                <li>
                  Any individual has the right to access personal data related to them, including opinions. If your comment or review contains information about
                  the sellers who provided the service, it may be shared with them upon request.
                </li>
                <li>When you authorise a third party to share information they hold about you with us.</li>
                <li>
                  We collect data from publicly available sources when you have given consent to share information or when the information is made public as a
                  matter of law.
                </li>
                <li>
                  When you join our Loyalty programme we will use the relevant personal data that will allow us to give you rewards, for more details about
                  personal data please see the <a href="https://www.yotpo.com/privacy-policy/">Yotpo privacy policy.</a>
                </li>
              </ul>
              <section>
                <Typography variant="h5bold" color="primary">
                  5.2. Our legal basis
                </Typography>
                <p>The regulations on data protection outline various reasons for which a company may gather and process your personal data, including:</p>
                <p>
                  Consent: In specific instances, we may collect and process your data with your explicit consent. This can happen, for example, when you
                  willingly opt into marketing communications or create an account with us. When gathering your personal data, we will always communicate
                  clearly which data is essential for a particular service.
                </p>
                <p>
                  Contractual Obligations: Under certain circumstances, we may require your personal data to fulfil our contractual obligations. For instance,
                  when you place an order for delivery, we will collect your address, billing/delivery, mobile and name to ensure the successful delivery of
                  your purchase, sharing these details with a courier as needed.
                </p>
                <p>
                  Legal Compliance: If mandated by law, we may be compelled to collect and process your data. For instance, we might share information about
                  individuals involved in fraudulent or criminal activities impacting us or our sellers with law enforcement.
                </p>
                <p>
                  Legitimate Interest: In specific scenarios, we may require your data to pursue our legitimate interests in a manner that is reasonably
                  expected in the course of operating our business, and that does not significantly affect your rights, freedom, or interests. For example:
                </p>
                <ul>
                  <li>We will share your personal data for the fulfilment of your order.</li>
                  <li>We may utilise your purchase history to determine the offers we send you.</li>
                  <li>Direct marketing emails may be sent when you shop with us and choose not to opt out, commonly known as a 'soft opt-in.'</li>
                  <li>Combining the shopping history of numerous customers helps us identify trends, meet demand, and develop new products/services.</li>
                  <li>
                    Your address details may be used to send you direct marketing information by post, informing you about products and services that we believe
                    may interest you.
                  </li>
                  <li>We may reach out to you for market research and to enhance our services.</li>
                  <li>
                    With your explicit consent, or if you shop with us and do not opt out of receiving our emails ('soft opt-in'), we may use your personal
                    data, preferences, and transaction details to keep you informed through email, text, telephone, and our contact centres about relevant
                    products and services, including tailored special offers, discounts, promotions, events, competitions, etc. You can opt out of this at any
                    time.
                  </li>
                  <li>
                    When you come to our eCommerce site via one of our social media websites, we will receive information, such as your name, email and ID (via
                    an API) that will help us detect how you have come on to our site. This helps us to understand how our advertisements are performing on
                    different social media platforms.
                  </li>
                  <li>We will share your personal data in the fulfilment of our Loyalty Programme.</li>
                  <li>
                    Sharing the information you provide and other information held about you within our Group companies, for the purposes of this Privacy
                    Notice. For more information see section 12.9 How we work with Group Companies
                  </li>
                </ul>
              </section>
              <section>
                <Typography variant="h5bold" color="primary">
                  5.3 How long we keep it for
                </Typography>
                <p>
                  Reach will retain your personal data in accordance with the law and relevant regulations and will store the data for as long as is necessary
                  to fulfil the purpose for which it was collected. The period for which we keep your personal data will be determined by the purpose for which
                  we are using the data, and our legal and regulatory obligations.
                </p>
              </section>
              <section>
                <Typography variant="h5bold" color="primary">
                  5.4 Who we share it with and why
                </Typography>
                <p>
                  We use Klevu, Shopify and Mirakl to manage and connect the different parts of our Yimbly.com and OK! Beauty Box ecommerce sites. They act at
                  our discretion, as our “processor”. For more information on what each processor specifically does please see below:
                </p>
                <ul>
                  <li>
                    <p>
                      Klevu: <a href="https://www.klevu.com/privacy-policy/">https://www.klevu.com/privacy-policy/</a>
                    </p>
                  </li>
                  <li>
                    <p>
                      Mirakl: <a href="https://www.mirakl.com/privacy-policy">https://www.mirakl.com/privacy-policy</a>
                    </p>
                  </li>
                  <li>
                    <p>
                      Shopify: <a href="https://www.shopify.com/uk/legal/privacy">https://www.shopify.com/uk/legal/privacy</a>
                    </p>
                  </li>
                  <li>
                    <p>
                      Recharge: <a href="https://rechargepayments.com/">https://rechargepayments.com/</a>
                    </p>
                  </li>
                  <li>
                    <p>
                      TrustPilot:
                      <a href="https://uk.legal.trustpilot.com/for-businesses/business-privacy-policy">
                        https://uk.legal.trustpilot.com/for-businesses/business-privacy-policy
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      Yotpo: <a href="https://www.yotpo.com/privacy-policy/">https://www.yotpo.com/privacy-policy/</a>
                    </p>
                  </li>
                  <li>
                    <p>
                      Paypal: <a href="https://www.paypal.com/uk/legalhub/privacy-full">https://www.paypal.com/uk/legalhub/privacy-full</a>
                    </p>
                  </li>
                  <li>
                    <p>
                      Klarna: <a href="https://www.klarna.com/international/privacy-policy/">https://www.klarna.com/international/privacy-policy/</a>
                    </p>
                  </li>
                  <li>
                    <p>
                      Clearpay:
                      <a href="https://help.clearpay.co.uk/hc/en-gb/articles/900006078786-Privacy-Policy-">
                        https://help.clearpay.co.uk/hc/en-gb/articles/900006078786-Privacy-Policy-
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      Salesforce:
                      <a href="Salesforce: https://www.salesforce.com/uk/company/privacy/">Salesforce: https://www.salesforce.com/uk/company/privacy/</a>
                    </p>
                  </li>
                </ul>
                <p>
                  We will also share your name, address, email and payment details with our sellers in order to fulfil an order. Our legal basis for doing this
                  would be contractual as it is necessary in order for you to receive your goods.
                </p>
                <p>
                  We may also provide sellers with information that allows them to serve you with more useful and relevant ads and to see how effective they
                  are. We would not share your name or other information that directly identifies you when we do this. Instead, we use an advertising identifier
                  like a cookie, a device identifier, or a code that would anonymise information like an email address. Our legal basis for doing this would be
                  consent. We would attain your consent when you accept our use of cookies on our site and you have the right to opt out at any time. For more
                  information see section 3. When you browse our websites and use our apps
                </p>
                <p>
                  <b>Generative AI Tools</b>
                </p>
                <p>We may share personal data with Generative tools like OpenAI on a 30 day or zero data retention basis for the purposes of:</p>
                <p>
                  <b>Customer Services Experience.</b> To automate our responses to you and provide you with context relevant to your enquiry we may use data we
                  have stored about you such as previous enquiries, order history, recent site activity, product reviews, loyalty & rewards.
                </p>
                <p>
                  <b>Personalised product recommendations & merchandising.</b> To enable us to present the right product to you as you progress through the
                  website, or via marketing communications (if we have the permission), based on your purchase history and recent activity.
                </p>
                <p>
                  We may also use AI, although not involving personal data, for Business intelligence purposes, to identify patterns, spot inconsistencies,
                  predict trends, and deliver insights that lead to a better understanding of our business across all functions. Our legal basis for doing this
                  would be legitimate interest. To opt out of your data being shared in this way email dataprotection@reachplc.com, stating ‘opt out of AI
                  Tools’.
                </p>
                <section>
                  <Typography variant="h5bold" color="primary">
                    5.6 Whether we send it outside the country of collection
                  </Typography>
                  <p>
                    From time to time we may transfer your personal data to service providers based outside of the UK and EEA for the purposes described in this
                    Privacy Notice. In particular, we do this in regard to our email and SMS service providers, managing our marketing activities and for the
                    processing of orders, subscriptions and payments. Where these transfers take place, your personal data will continue to be subject to
                    safeguards set out in the law and we have mechanisms in place to ensure your data receives equivalence in protection to that within the UK
                    and EEA. An example safeguard is standard agreements that have been approved by the relevant authorities. For more information see section{' '}
                    <a href="13-section">13: How we keep your data secure, why we may keep it and countries we may transfer it to.</a>
                  </p>
                </section>
              </section>
              <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
              <section>
                <Typography variant="h4bold" color="primary" id="6-section">
                  6. When you opt to receive newsletters or other marketing communications
                </Typography>
                <p>
                  We use the information you provide us with, such as email, address, or phone number, to communicate with you for the purpose you provided it,
                  either based on your consent or where we have legitimate grounds to do so. For example, if you sign up for a newsletter or other marketing
                  communication then we will use the email address you provide to send you that newsletter or marketing, and we may also send you text messages
                  if you have opted in to receive these. These may include special offers and promotions. Alternatively, If you have bought a product from us,
                  from one of our ecommerce sites <a href="#5-section">[see 5. When you buy a product from our ecommerce sites]</a>, we may send you emails
                  about similar products and services, from the brand you purchased the product from, provided you didn’t opt out when we collected your data.
                </p>
                <p>
                  The links in our emails are unique to your email address, and so if you click on them our servers will attribute that click to your email
                  address and that information will be associated with your <a href="#3-section">unique identifier</a>. If you choose to allow your email
                  program to load the images in our emails, we will also be able to see that you opened the email. You can find out more about each of these
                  activities below. You can update your marketing preferences at any time. If you would like to unsubscribe (or opt-out) of receiving these
                  emails please click the links on the emails you receive, text STOP to opt out of text messages, or contact dataprotection@reachplc.com.
                </p>
                <p>
                  <b>Working with Partner Companies</b>
                </p>
                <p>
                  When you give us your email address for marketing purposes, we may convert it into a hashed, pseudonymous form, and subsequently share it with
                  selected partners who use this information to create an online identification code, for the purpose of recognising you on your device.This
                  code does not contain any of your identifiable personal data and cannot be used to re-identify you.
                </p>
                <p>
                  We convert your email based on legitimate interest and you can opt out of your email being shared in this way by emailing
                  dataprotection@reachplc.com, and stating ‘opt out of hashed email’. For more information on how these partners may subsequently use this code
                  to serve you targeted advertising, with your consent, please see section <a href="3.2.1-section">Data Matching</a>.
                </p>
                <p>
                  In addition, we may work with a partner company to send our communications to you. For example, you may have consented to an email from Reach
                  via the Substack platform. For these emails Substack are the controllers of your data, and you will have agreed to their{' '}
                  <a href="https://substack.com/privacy">Privacy Notice</a> when you signed up. You can opt out of receiving their newsletters at any time via
                  the email you receive or by emailing privacy@substackinc.com.
                </p>
              </section>
              <section>
                <Typography variant="h5bold" color="primary">
                  6.1 Sending you the newsletters and marketing you subscribe for
                </Typography>
                <p>We will use the email address you give to us to send newsletters or our marketing offers and promotions to you.</p>
                <p>
                  When you subscribe to one of our email newsletters, or marketing emails, we will send those newsletters (or marketing emails) to the email
                  address you provided. It will always be clear who the newsletter or marketing offer or promotion has come from, and we don’t sell or give our
                  email lists to anyone else.
                </p>
                <p>
                  If you want us to stop, the easiest way is to click on the “unsubscribe” link which you will find at the bottom of every email, text STOP to
                  opt out of text messages, or contact dataprotection@reachplc.com.
                </p>
                <p>
                  In some jurisdictions outside of the EU/UK, and where permitted by applicable law, we may purchase/rent lists of persons who may be interested
                  in our products and services. We may contact such persons by email.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  6.1.1 The data we collect and what we do with it
                </Typography>
                <p>
                  We collect your email address in order to send you the newsletters or marketing you subscribe to. When you sign up for newsletters from our
                  “In Your Area” local information website, we will also collect your postcode in order to provide the correct local information.
                </p>
                <p>Sometimes, our newsletters will contain advertisements.</p>
                <p>
                  Unless you object, we will also associate your newsletter and marketing subscription with the other things we know about you, like your page
                  views. You can learn more about that <a href="#3-section">here</a>.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  6.1.2 Our legal basis
                </Typography>
                <p>
                  We use your email address to send you the newsletters and marketing you subscribe for on the basis of your consent. You can withdraw that
                  consent at any time. The easiest way to do so is to click on the “unsubscribe” link at the bottom of every newsletter we send.
                </p>
                <p>
                  We associate your email subscription with the other things we know about you on the basis of our legitimate interests. You can learn more
                  about that <a href="#3-section">here</a>.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  6.1.3. How long we keep it for
                </Typography>
                <p>
                  We will keep your email address on the subscription list for the relevant newsletters or marketing emails until you unsubscribe from them.
                  Furthermore, we will stop sending you a newsletter (or marketing email) if we see no activity (e.g. no links clicked on) for a period of time,
                  and generally but not exhaustively, after 24 months.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  6.1.4. Who we share it with and why
                </Typography>
                <p>
                  Processors provide the technology platforms which we use to administer our email lists and to manage the adverts in our emails. They act as
                  our “processors”, meaning that they are bound by law and contract to keep your data safe, and to do only what we tell them to do with it.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  6.1.5. Whether we send it outside the country of collection
                </Typography>
                <p>Email administration data is hosted within the EEA. Advert management services data is processed from the UK and EEA.</p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  6.1.6. Whether we send it abroad
                </Typography>
                <p>The data is hosted and processed within the EEA.</p>
              </section>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                6.2. Tracking technologies used in our emails
              </Typography>
              <p>
                If you tell your email application to load images we will know when you open one of our emails. When you click on links in our emails, we will
                know that it was you who clicked on it. We use this information to inform the content of future newsletters or marketing(by looking at what’s
                popular), and to understand whether you continue to be interested in receiving them.
              </p>
              <p>
                Unless you have previously objected, we will also associate that information with other things we know about you. You can learn more about that{' '}
                <a href="#3.1-section">here in Targeting by Reach.</a>.
              </p>
              <section>
                <Typography variant="h6bold" color="primary">
                  6.2.1. The data we collect and what we do with it
                </Typography>
                <p>We track engagement with our emails in two ways.</p>
                <p>
                  Firstly, as for most email newsletters and promotions worldwide, our emails don’t attach any pictures they use to the email itself, but
                  instead load them from a remote server on demand. By default, most email programs don’t load remote images automatically, but only when you
                  click on the button or link in the program to “show images” (or similar wording). When you do that, the images linked in the email are
                  downloaded from our technology provider’s server so they can be displayed in your email program.
                </p>
                <p>If the images are downloaded, our technology provider can tell us that you opened the email.</p>
                <p>
                  Secondly, the links in our emails to articles and other external content are personalised, such that if you click through to the content using
                  the link in our email, we will know that it was you who clicked it (or at least, someone reading the email we sent to you). This is because we
                  use web beacons in our emails to track the success of our marketing campaigns. Our web beacons don’t store any information on your computer
                  but, by communicating with our cookies on your computer, they can help us to understand how you engage with our emails.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  6.2.2. Our legal basis
                </Typography>
                <p>
                  We record the opening of emails on the basis of your consent. You can withdraw your consent at any time either by configuring your email
                  client not to load images, or by unsubscribing from the relevant email.
                </p>
                <p>
                  We use the personalised URLs for links to external content on the basis of our legitimate interest in understanding how you engage with our
                  emails and which kinds of content are of most interest to you. You can object to this use of your personal data by completing the relevant
                  form <a href="#15.5-section">Your right to object to what we do with your data, and to have restrictions placed upon it.</a>
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  6.2.3. How long we keep it for
                </Typography>
                <p>
                  The data gathered to understand email opening and interests related to articles and links within the emails is retained for as long as you
                  continue to engage with our emails, and for a period of up to 3 years afterwards.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  6.2.4. Who we share it with and why
                </Typography>
                <p>
                  Third party processors provide the technology platforms which we use to administer our email lists and to manage the adverts in our emails.
                  They act as our “processors”, meaning that they are bound by law and contract to keep your data safe, and to do only what we tell them to do
                  with it.
                </p>
              </section>
              <section>
                <Typography variant="h6bold" color="primary">
                  6.2.5. Whether we send it outside the country of collection
                </Typography>
                <p>The data is hosted in the Republic of Ireland, the UK and Denmark.</p>
              </section>
            </section>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="7-section">
              7. When you enter a competition or prize draw
            </Typography>
            <p>
              From time to time we run competitions and prize draws which require you to provide your name and contact details. We use that information to
              administer the competition or prize draw and to communicate with you about it (for example if you win a prize).
            </p>
            <p>
              We may also use that email address to send you occasional special offers and promotions, if you opted in to receive them.
              <a href="#6-section"> See here</a> for more information on that and how to unsubscribe.
            </p>
            <p>
              Unless you have objected, we will associate the fact that you entered the competition with the other things we know about you, like your page
              views. You can learn more about that <a href="#3.1-section">here</a>. We may also run some competitions or prize draws for which we utilise your
              declared responses to build into our profile about you. When we do so, this will be communicated in the associated text, and you will be asked to
              either consent to this use or will be given the option to ‘object’ via a tick box.
            </p>
            <section>
              <Typography variant="h5bold" color="primary">
                7.1 The data we collect and what we do with it
              </Typography>
              <p>
                When you enter a competition or prize draw we will collect the information you provide to us, such as your name, email address and answers to
                any questions. We will use that information to run the competition or prize draw. If you happen to win a prize, we may ask you for additional
                information, such as an address to send the prize to. As highlighted above, we may also retain your responses as part of a profile about you.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                7.2 Our legal basis
              </Typography>
              <p>
                We do this on the basis that it is necessary to fulfil the contract formed with you when you enter the competition or prize draw. Use of your
                declared data for profiling purposes will be based either upon consent (via tick box) or our legitimate interest, for which you will be given
                the option to ‘object’.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                7.3 How long we keep it for
              </Typography>
              <p>
                We will keep your competition entry for the duration of the competition and for up to 90 days after the closing date. Data utilised for
                profiling will be retained for up to 3 years.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                7.4. Who we share it with and why
              </Typography>
              <p>
                Information about competition and prize draw entries is only shared with the service providers who provide us with the technology powering our
                systems and – if you win a prize – with the companies who supply and deliver the prize to you. The technology providers act as our “processors”,
                meaning that they are bound by law and contract to keep your data safe, and to do only what we tell them to do with it.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                7.5. Whether we send it outside the country of collection
              </Typography>
              <p>
                Our online form technology provider may process your personal data outside of the UK and EEA. We have implemented safeguards to secure and limit
                the processing of the data, including standard agreements, and frameworks, approved by the relevant authorities.
              </p>
            </section>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="8-section">
              8. When you complete a survey
            </Typography>
            <p>
              Sometimes we run surveys to help us to understand more about our readership or about public opinion generally. They can also help us better
              understand the underlying motivations behind the trends we are seeing, response to new content ideas and formats we are developing and how we can
              improve the service we currently offer.
            </p>
            <p>
              These surveys could be in the form of online surveys, polls, video projects, diary studies and discussion groups, and we record the responses
              (answers) of those who take part.
            </p>
            <p>
              If you take part, we use your answers to those surveys to produce anonymous statistics which we use to inform our editorial content and, to an
              extent, our advertising. For example, if a majority of survey respondents express a preference for one TV show over another, then we might write
              an entertainment piece based on that or factor it in when deciding which adverts appear where.
            </p>
            <p>
              We may also run some surveys for which we utilise your declared responses to build into our profile about you. When we do so, this will be
              communicated in the associated text, and you will be asked to either consent to this use or will be given the option to ‘object’ via a tick box.
            </p>
            <p>
              Occasionally, those surveys might ask you about things which the law considers to be especially sensitive, such as your voting intentions or your
              religious beliefs. Where that’s the case, we’ll ask clearly for your permission first, and we’ll always provide a “prefer not to say” or similar
              option. We don’t use that kind of information for advertising purposes.
            </p>
            <section>
              <Typography variant="h5bold" color="primary">
                8.1. The data we collect and what we do with it
              </Typography>
              <ul>
                <li>The types of information we collect and process about you when you take part in a survey may include:</li>
                <li>Personal and contact details you have provided to us, such as your name, email address or postal address</li>
                <li>Demographic information such as date of birth, gender, work occupation, ethnicity and readership habits.</li>
                <li>Attitudinal, behavioural or purchase information shared within our surveys or forums.</li>
                <li>
                  Information about your use of third party products or services or from third parties who we may use to operate certain aspects of our
                  business.
                </li>
                <li>
                  We may also collect information from cookies on your devices and this includes information about your IP address, MAC address and other
                  identifying information, information about your devices, including your location, from third party advertising networks and how you use our
                  products and services.
                </li>
              </ul>
              <p>
                We will collect your answers to the survey questions and use them to compile anonymous statistics along the lines of “35% of people preferred X
                over Y”. Some surveys may also ask for your email address, for example if we are offering entry into a prize draw for completing it.
                <a href="#7-section"> Go here</a> to learn more about how we use your data for prize draws. As highlighted above, we may also retain your
                responses as part of a profile about you.
              </p>
              <p>When you have completed a survey, you may be invited to subscribe to a newsletter or to receive special offers and promotions from us.</p>
              <p>
                <span className="underlined">
                  Sometimes we may operate “panels” of people who we send survey questions to periodically. If you join one of those panels
                </span>
                then there may be additional kinds of information about you which we will receive, in which case we will provide further information to you
                before you sign up to the panel.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                8.2 Our legal basis
              </Typography>
              <p>
                We collect and compile your survey answers on the basis of your consent, which we ask for in each survey. Use of your declared data for
                profiling purposes will be based either upon consent (via tick box) or our legitimate interest, for which you will be given the option to
                ‘object’.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                8.3. How long we keep it for
              </Typography>
              <p>
                We retain your survey responses for however long the survey runs for, and for up to 90 days afterwards. If you participated in our research
                panel we may keep your data for up to a year, for the purposes of insight and tracking responses over time.
              </p>
              <p>
                The resulting statistics may be kept longer, but those are always anonymous and don’t contain your personal data. Data utilised for profiling
                will be retained for up to 3 years.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                8.4. Who we share it with and why
              </Typography>
              <p>
                We typically publish the overall results of our surveys, and often share those results with the brands involved (if any), but those results are
                always anonymous and statistical in nature (“50% of respondents thought X”) and don’t contain your personal data. Actual survey responses are
                only shared with the service providers who provide us with the technology powering our systems. They act as our “processors”, meaning that they
                are bound by law and contract to keep your data safe, and to do only what we tell them to do with it.
              </p>
              <p>
                Once we’ve gathered your survey responses, we will combine them with the answers from other respondents and turn them into valuable insights
                which may be shared with third parties. Unless specifically stated at the start of the research activity and with your explicit permission, this
                information will only be disclosed to third parties in anonymised or aggregated form, stripped of any identifiable data (example forms of
                anonymised / aggregate data include: “X% of males from Birmingham agree that…” or “a 35 year male from Birmingham said …”)
              </p>
              <p>
                Please be aware that if you disclose information on forums it may be possible for other research participants to use this information. We are
                not responsible for the disclosure of any information you post in this way.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                8.5. Whether we send it outside the country of collection
              </Typography>
              <p>
                Our online form technology providers may process your personal data outside of the UK and EEA. We have implemented safeguards to secure and
                limit the processing of the data, including standard agreements and frameworks approved by the relevant authorities.
              </p>
            </section>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="9-section">
              9. When you subscribe to our print or “Digital Edition” titles or place a Classified Ad
            </Typography>
            <br />
            <section>
              <Typography variant="h5bold" color="primary">
                9.1 When you subscribe to our print or “Digital Edition” titles.
              </Typography>
            </section>
            <p>
              We will collect your name, address other contact details, and payment information. We will use that information to administer your subscription.
              We do this on the basis that it is necessary in order to provide the subscription to you with the support of our provider. We will retain this
              information for as long as you maintain your subscription, and for a period thereafter, in case of disputes or problems.
            </p>
            <p>
              When you subscribe to our digital edition titles your subscription is also processed via our provider and this information is retained for as long
              as you maintain your subscription, and for a period thereafter, in case of disputes or problems.
            </p>
            <p>
              As part of the subscription process you may be asked if you want to receive other products or services from Reach. Where this is the case you will
              be asked to consent to each product or service type. Such activities are managed in accordance with the relevant sections elsewhere in this
              Privacy Notice.
            </p>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h5bold" color="primary" id="whenclassified">
              9.2 When you place a classified ad
            </Typography>
            <p>
              When you place a classified ad in one of our print titles, we will collect your name and contact details, your payment information, and of course
              the content of your ad. We will use that information to publish and administer your ad, and to communicate with you about it. The content of your
              ad will be published and therefore accessible to the public. We do this on the basis that it is necessary to fulfil the contract agreed with you
              when you place your ad.
            </p>
            <p>
              We will retain the contact details of private individuals for up to 6 years from the point of last interaction, and trade contact details for as
              long as we have a business purpose. We do not store personally identifiable payment card information.
            </p>
            <p>
              Your ad itself will be retained indefinitely, on the basis of our legitimate interest, in order to maintain our archive and back issues services.
            </p>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="10-section">
              10. When you visit or interact with us on Social Media
            </Typography>
            <p>
              We operate accounts on different social media platforms, and you may choose to visit or to interact with us through these channels. If you choose
              to do so, we can usually only see information stored in your public profile on the social media platform, when you are logged in to your profile,
              while visiting our social media account; although we may process data that you provide to us when you contact us (for example: if you create a
              post, or send us a private message via Facebook).
            </p>
            <p>
              The social media platform also provides us with anonymous usage statistics, which we use to improve user experience and understand activity across
              our sites. We do not have access to the usage data that the operator of the social network collects to create these statistics.
            </p>
            <p>
              The operator of the social media platform also processes your data, regardless of whether you yourself have a profile on the platform. In
              addition, some social media platforms, such as Facebook, use cookies that are stored on your terminal device when you visit our social media
              account, even if you do not have your own profile in the network or are not logged in. These cookies enable the social media platform to create
              user profiles based on your preferences and interests and to show you advertising tailored to these.
            </p>
            <p>
              For information on the cookies we use, and the social media operators and their privacy policies please see our{' '}
              <a href="https://www.reachplc.com/site-services/cookie-notice">Cookie Notice.</a> For further information on how we may we may receive, use and
              share your data see <a href="#12.4-section">12.4 Social media platforms.</a>
            </p>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="11-section">
              11. When you apply for a job with us or work at other organisations we do business with
            </Typography>
            <section>
              <Typography variant="h5bold" color="primary">
                11.1 When you apply for a Job with us
              </Typography>
              <p>
                When you apply for a job with us, we will process the information you or the recruitment agency gives to us in order to consider your
                application and to communicate with you (or the agency) about it.
              </p>
              <p>
                If we offer you a position and you accept it, then we will use your information to liaise with you about start dates, contracts, arranging
                induction and so on, and we may ask you for additional information to that end. That information will then be handled in accordance with our
                internal HR policies and Employee Privacy Notice.
              </p>
              <p>
                If we don’t offer you a position or we do but you don’t accept it, then we will keep your application on file for 12 months in case of future
                queries or issues.
              </p>
              <p>
                We process your data on the basis that it is necessary in order to take steps to enter into an employment contract with you. We process
                information relating to unsuccessful applications or job offers that are not accepted on the basis of our legitimate interest in keeping records
                of job applications.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                11.2 If you work at other organisations that we do business with
              </Typography>
              <p>
                For all of our customers and other organisations we do business with, we will have the business contact details of the people we work with at
                those organisations, and we will use them to manage and administer our relationship with that organisation. We do this on the basis of our
                legitimate interest in managing and administering those relationships.
              </p>
              <p>
                If you work at a current or prospective advertising or publishing services client or related agencies, revenue sharing partners (for the sale of
                your products or services) or are users of Reach software we will also contact you from time to time using your work contact details to promote
                our services. We will have those contact details either because we have worked with you before, you shared them with us for this purpose,
                because we or our agencies have looked them up from publicly available information, or because you have attended an event we have hosted. We do
                this on the basis of our legitimate interest in promoting our advertising and publishing services to businesses. We will always stop contacting
                you for this purpose if you ask us to. Typically, the best way to do that is to respond to the person contacting you, but you can also make the
                request by completing the <a href="#15-section">relevant form.</a>
              </p>
              <p>
                We keep the details of our contacts at organisations we do business with for as long as we continue to have a business relationship, and for a
                period thereafter for business development purposes and in case of issues or disputes.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                11.3 Site visits and CCTV
              </Typography>
              <p>
                When you visit a Reach site we will record your name, the date, and in some locations your company name. This information is retained for one
                week.
              </p>
              <p>
                In some circumstances, non-employees may be provided with a site pass. Where this applies, the personal data obtained, including a photograph of
                you, will be retained for the duration of your visit or contract with Reach and for 7 years thereafter for fraud, security, and financial
                reporting purposes.
              </p>
              <p>
                We use CCTV systems at our premises on the basis of our legitimate interest in protecting the safety and security of our staff and our property.
              </p>
            </section>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="12-section">
              12. Site visits and CCTV at our premises
            </Typography>
            <p>This section covers the following:</p>
            <p>
              <ul>
                <li>
                  <a href="#12.1-section">12.1 Further data sharing by Reach</a>
                </li>
                <li>
                  <a href="#12.2-section">12.2 Links to other websites and third-party affiliates</a>
                </li>
                <li>
                  <a href="#12.3-section">12.3 Behavioural remarketing services</a>
                </li>
                <li>
                  <a href="#12.4-section">12.4 Social media platforms</a>
                </li>
                <li>
                  <a href="#12.5-section">12.5 WhatsApp Instant messaging platform</a>
                </li>
                <li>
                  <a href="#12.6-section">12.6 Chatbots and other onsite data collection</a>
                </li>
                <li>
                  <a href="#12.7-section">12.7 Discount codes, vouchers and cashback accounts</a>
                </li>
                <li>
                  <a href="#12.8-section">12.8 Affiliate Sites, such as Mirror Bingo</a>
                </li>
                <li>
                  <a href="#12.9-section">12.9 How we work with Group Companies</a>
                </li>
                <li>
                  <a href="#12.10-section">12.10 Transfer as part of the sale of our business</a>
                </li>
              </ul>
            </p>
            <section>
              <Typography variant="h5bold" color="primary" id="12.1-section">
                12.1 Further data sharing by Reach
              </Typography>
              <p>
                We may pass your information to our processors – companies that we use to provide services on our behalf, for example for home delivery
                services, event management, prize fulfilment agencies, market research or other purposes mentioned in this Privacy Notice such as analytics,
                direct marketing or online advertising. These processors can only use your information in accordance with our instructions and for no other
                purpose
              </p>
              <p>
                In addition, further to the data sharing described elsewhere in this Privacy Notice, Reach will disclose your information and co-operate with
                appropriate bodies and authorities in good faith where we are required to by law, a court order, a regulatory authority, or otherwise, including
                with the police, trading standards, regulatory authorities, other relevant authorities, or credit reference agencies.
              </p>
              <p>
                Where permitted by applicable laws, where you interact with us, we may monitor, record, and retain all associated data, correspondence, and
                communications, such as written letters, telephone call recordings, emails, text messages, social media messages, in person meetings and any
                other communications. We will do this to, (i) comply with our legal and regulatory obligations (ii), prevent, or detect crime (iii), maintain
                appropriate evidential records (iv), protect the security of our communications systems and (v) for training and quality purposes. Where
                necessary, we will also use your personal information to defend ourselves from any legal claims brought by you in connection with the provision
                of our products and services.
              </p>
              <p>We will also use personal information to manage and administer our business generally.</p>
              <p>
                We will share information with security consultants and IT security system providers, where necessary, to monitor and manage data security and
                the security of our sites and networks, and to develop security threat intelligence data.
              </p>
              <p>
                We may also share information to facilitate the sale of one or more parts of our business, including if we are approached by a potential buyer
                or the restructuring of one or more parts of our business and with auditing organisations such as the Audit Bureau of Circulation.
              </p>
              <p>
                When we share information with third parties, we only permit them to process this data for specified purposes. We do not share more information
                with them than is necessary for the relevant purpose and we require them to treat this information with at least the same protection we do.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="12.2-section">
                12.2 Links to other websites and third-party affiliates
              </Typography>
              <p>
                Our websites may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may
                allow third parties to collect or share data about you. We do not control these third-party websites and, when you leave our website, we
                encourage you to read the Privacy Notice of every website you visit.
              </p>
              <p>
                We may monetise some of these links through the use of third-party affiliate programmes. These affiliate links help pay the costs of producing
                our websites and ensure that the content is free to you. However, it doesn’t mean that we are in any way indebted to Amazon or any other
                company. Nor do they or any other company have any influence over editorial coverage, how we rate products, or whether they get a positive
                review. Notwithstanding such affiliate programmes, we are not responsible for the content or privacy policies of these websites or for
                third-party advertisers, nor for the way in which they use the information they collect about you.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="12.3-section">
                12.3 Behavioural remarketing services
              </Typography>
              <p>
                After you have visited our site, we may use remarketing services provided by companies such as Facebook and Google and Bing, to show you
                advertising on third-party websites, based on your browsing patterns and interactions. For this purpose, visitors are grouped by certain actions
                on our services, for example, by duration of visit. This enables us to understand your preferences and to show you personalised advertising even
                if you are currently on another website within the network.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="12.4-section">
                12.4 Social media platforms
              </Typography>
              <p>
                We may also share de-identified information with social media platforms for advertising and analysis purposes. For example, we may share a
                hashed, non-identifiable version of your email with, for example, Facebook or Google, with the aim of showing you advertising on those
                platforms, or to exclude you from seeing our advertising on those platforms.
              </p>
              <p>
                Combined with the use of their respective pixels/advanced matching and Conversions/Events APIs (a secure server-to-server interface that allows
                us to share specific actions you take on our website directly with Meta and TikTok), this enables us to better understand how you interact with
                our website, measure the impact of our advertising and website conversions, deliver relevant advertising that may be of interest to you and
                protect your privacy.
              </p>
              <p>
                Processing your data in this way enables us to deliver relevant advertising that may be of interest to you and protect your privacy. It also
                enables us to measure the impact of our advertising and website conversions, improve targeted advertising, improve marketing attribution and
                improve conversion tracking.
              </p>
              <p>
                To opt out of your email being shared in this way by email dataprotection@reachplc.com, and stating ‘opt out of hashed email’. You have the
                right to withdraw your consent or opt-out to the processing of your personal data at any time via our Cookie Management Platform accessed via
                the ‘cog’ icon or link at the bottom of every webpage.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="12.5-section">
                12.5 WhatsApp Instant messaging platform
              </Typography>
              <p>
                When you sign up to one of our Whats App communities to receive the latest updates, exclusives and comments straight to your phone. As part of
                this process you are allowing WhatsApp to receive and share data with us, including your phone number. The use of your data will be in
                accordance with this Privacy Notice, and also WhatsApp Privacy Notice{' '}
                <a href="https://www.whatsapp.com/legal/privacy-policy-eea#privacy-policy-key-updates.">here.</a>
              </p>
              <p>
                You can exit the Community you joined at any time by going to the Communities tab of your Whatsapp account, and following these instructions:
                <a href="https://faq.whatsapp.com/1312647189536807/?cms_platform=web">How to exit a community.</a>
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="12.6-section">
                12.6 Chatbots and other onsite data collection
              </Typography>
              <p>
                If you engage with chatbots on our sites to communicate with us and ask questions, your information will be used in accordance with this Privacy
                notice, and specifically to:
              </p>
              <ul>
                <li>Provide responses to your questions.</li>
                <li>Analyse and report on user engagement with the chatbot.</li>
                <li>Review and improve the quality performance of the chatbot.</li>
                <li>Develop related products and services.</li>
                <li>Please note that our chatbots may leverage third party generative AI platforms. Where this is the case, we will:</li>
                <li>Undertake a data protection impact assessment, where legally required.</li>
                <li>Put in place appropriate controls to ensure that data protection risks are mitigated to an acceptable level.</li>
                <li>Follow a Privacy by Design approach when developing the chatbot.</li>
              </ul>
              <p>
                Where our chatbots leverage third party generative AI platforms, the information you disclose via the chatbot may be shared with the provider of
                the AI platform for its purposes, over which we have no control. Please do not submit any information with our chatbots that you would not be
                willing to share publicly. <b>For more information see our AI Notice on our websites.</b>
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="12.7-section">
                12.7 Discount codes, vouchers and cashback accounts
              </Typography>
              <p>
                Some of our sites offer discount codes. When you use one of our discount codes we will collect information about how you use our site. For
                example, when you redeem a voucher we’ll record details of the offer, the date and time and the device you’ve redeemed it on. Although we do not
                share your data with the code providers, when you claim your code your personal data may be collected.
              </p>
              <p>
                Reach is not responsible for the content or use of personal data on third party websites linked to from our sites which offer Discount Codes.
                When you navigate to and use these sites, for example to redeem a discount code, we would recommend that you check the Privacy Notice associated
                with that site for more information on how those sites process your information.
              </p>
              <p>
                Similarly if you create a Cashback account, as part of the registration your data will be shared directly by you with our cashback partner in
                line with their terms and conditions which are provided to you at the point of registration. For more information on how they use your data see
                their Privacy Notice we recommend you check their Privacy Notice for more information on how those sites process your information.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="12.8-section">
                12.8 Affiliate Sites, such as Mirror Bingo
              </Typography>
              <p>
                <a href="https://www.reachgamingaffiliates.com/">Reach Gaming Affiliates</a> is a website operated by Reach, to promote our Gaming Affiliate
                programs, and provide users with the ability to join the program. We provide direct links to our nationally recognised and trusted gaming brands
                on our Reach Gaming Affiliates webpage, such as Mirror Bingo, OK! Bingo and Express Wins. We also provide links to web pages where you can
                submit your information in order to join our Reach Gaming Affiliates Account.
              </p>
              <p>
                Please note that we do not directly collect any personal data, concerning your browsing behaviour or your participation in our affiliate
                program, on this website.
              </p>
              <p>
                When you navigate to these web pages, please note that our partner, Jumpman, will be the data controller for the personal data that you provide,
                and Reach will be the data processor. The information we share will be used in accordance with our partner’s privacy policy, which you should
                read when you sign up with the partner. For more information about how your Affiliate data will be used, please see the Jumpman Privacy Notice
                <a href="https://www.mirrorbingo.com/privacy-policy"> here</a>.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="12.9-section">
                12.9 How we work with Group Companies
              </Typography>
              <p>
                Reach receives information from, and shares information with, other companies within the Reach Group to help operate, provide, improve,
                understand, customise, support, and market our services and their offerings. This includes:
              </p>
              <ul>
                <li>helping improve infrastructure and delivery systems;</li>
                <li>understanding how our services or theirs are used;</li>
                <li>
                  promoting safety, security and integrity across the Reach Group e.g. securing systems and fighting spam, threats, abuse, or infringement
                  activities;
                </li>
                <li>
                  performing analytics and analysis of how you interact with the group’s products and services, to facilitate business efficiencies and develop
                  products and services that would be relevant to you; and
                </li>
                <li>sharing information to deliver more relevant advertising from across the Reach Group network.</li>
              </ul>
              <p>
                In addition, a key way we share data across systems and Group companies is for advertising and targeting purposes. This can be based on your
                online behaviour on our sites, for example:
              </p>
              <ul>
                <li>
                  When you go onto one of our sites we can track your behaviour on those sites, or any of our other sites across our Group companies, and use
                  this to target advertising or email content to you. For example, if you use our ecommerce site yimbly.com, we can see if you later visited one
                  of our other sites and you may see advertising for the products you looked at based on this. If you would prefer to opt out, you can do so via
                  the consent management pop-up.
                </li>
                <li>
                  This also applies if you open an email from us and this takes you online as we can use the information from your online behaviour to later
                  target marketing to you. So for example if you subscribe to marketing emails on one of our regional news sites and we see you looked at
                  sporting content on our other sites we may provide you with sport related content in your marketing emails from the regional news site. You
                  can unsubscribe from emails at any time.
                </li>
                <li>
                  We may also upload emails (in hashed, pseudonymous form) straight to the advertising data system and this enables us to identify the same
                  non-identifiable user across devices. So we know, for example, that the person who looks at Reach lifestyle content on their phone and then
                  Reach fashion content on their desktop, is in fact the same non-identified person. You can unsubscribe from emails at any time.
                </li>
                <li>
                  We may also use the other data you have provided to us, combined with your online behaviour, to personalise your email campaigns. For example,
                  if you signed up an account when browsing ‘football’ content on our news sites, we may classify you as a ‘football intender’. Later when you
                  go online we may see you are also interested in content on films and classify you as a ‘football and films intender’. As a result you may see
                  advertising content related to football and films, when you visit our sites.
                </li>
              </ul>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="12.10-section">
                12.10 Transfer as part of the sale of our business
              </Typography>
              <p>
                If we sell part or all of our business, personal information may be one of the transferred business assets. If this happens, your information
                may be disclosed to any successors of our business for them to use for the purpose set out in this Privacy Notice.
              </p>
            </section>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="13-section">
              13. How we keep your data secure, why we may keep it, and countries we may transfer it to
            </Typography>
            <section>
              <Typography variant="h5bold" color="primary" id="sitevisit">
                13.1 How we keep your data secure.
              </Typography>
              <p>
                We are committed to protecting the personal data we hold and keep your information secure by taking appropriate technical and organisational
                measures, including for example encryption techniques and access control, to protect personal and sensitive data against its unauthorised or
                unlawful processing and against its accidental loss, destruction or damage.
              </p>
              <p>
                Where we use third-party service providers to store data, we have appropriate agreements in place to ensure that your personal data is
                protected. In addition, we limit access to your personal information to those employees, agents, contractors and other third parties who have a
                business need to know. We also require any third parties to whom we may transfer personal data to have appropriate security measures in place.
                They will only process your personal information on our instructions and they are subject to a duty of confidentiality.
              </p>
              <p>
                Our websites may permit you to create an account, or have the facility for an account to be created on your behalf. When this happens, you may
                be prompted to create a password. You are responsible for maintaining the confidentiality of your password, and you are responsible for any
                access to or use of your account by someone else that has obtained your password, whether or not such access or use has been authorised by you.
                You should notify us of any unauthorised use of your password or account. In the unlikely event of a breach we will notify the affected
                individuals in a timely manner, as required by law. If you believe your account has been compromised, please contact us at
                dataprotection@reachpc.com, or if you are in the US email privacyofficer@reachplc.com stating which state you are a resident of.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="sitevisit">
                13.2 Reason for keeping it
              </Typography>
              <p>
                We hold personal data for a variety of different purposes and the length of time we keep your data will vary depending on the services or
                products we are providing our customers with.
              </p>
              <p>
                We will only keep your data for a reasonable period of time, which is based on the purpose for which we are using your data. For example, we
                review our marketing contact lists and delete those contacts where there has been no activity for three years. In addition, by law, for tax and
                other purposes, we may have to keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for
                seven years after they cease being customers. We may also need to keep information to evidence a contract or prevent fraud.
              </p>
              <p>
                Once the purpose has been fulfilled, we will securely delete that data or anonymise your information (so that we, or anyone else, can no longer
                tell that data relates to you) unless we are required to retain the data longer for legal, tax or accounting reasons.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="sitevisit">
                13.3 Countries we may transfer your data to
              </Typography>
              <p>
                When you provide information to us, we may transfer your personal information to our group companies and processors who are based in a country
                other than your country of residence. For example, if you are a UK citizen you may find your data is processed by a supplier or service based
                outside the UK or EEA, such as in the United States. If such a transfer requires us to apply additional safeguards to your personal information
                under, for example, UK or European data protection laws, we will do so.
              </p>
              <p>
                These steps may include implementing the UK’s International Data Transfer Agreement clauses or European Commission’s Standard Contractual
                Clauses, with additional UK-specific clauses for transfers of personal information to our service providers and business partners. Alternatively
                we will ensure the third parties we work with will offer an adequate level of protection by meeting the legally agreed requirements of the EU-US
                Data Privacy Framework, and the UK extension, as appropriate.
              </p>
              <p>
                To the extent applicable, we may rely on derogations as set forth in Article 49 of the GDPR for the transfer and onward transfer of personal
                information collected from individuals in the UK or EEA to the United States, and other countries that the EU views as not providing adequate
                data protection. Specifically, we may transfer such information to another party in accordance with our lawful basis for processing.
              </p>
              <p>
                By using our website, products or services or by interacting with us in the ways described in this Privacy Notice, you consent to the transfer,
                storage and processing of your personal information outside your country of residence, including out of the EEA, in the circumstances set out in
                this Privacy Notice. If you do not want your information to be transferred in this way you should not use our website, applications or services.
              </p>
            </section>

            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="14-section">
              14. How we ensure we protect children’s privacy
            </Typography>
            <p>
              Our products and services are for a general audience and not aimed at children, although we recognise that some of the content of our publications
              may appeal to a younger audience, for example our football content and Curiously site.
            </p>
            <p>
              Across our portfolio, we have taken active measures to ensure we comply with all relevant laws and regulations, and do not knowingly collect any
              personal information from children under the age of sixteen or send any marketing communications to children.
            </p>
            <p>
              If we are running a competition, for example, which might be of interest to children, our competition rules specify that a parent or guardian must
              consent to entry (if the child is under eighteen). You must also be at least eighteen to purchase products or services from us. If you are under
              the age of eighteen, you must ensure that your parent or guardian purchases the products or services on your behalf.
            </p>
            <p>
              If, in the future, we collect personally identifiable information from children in connection with our products and services, we will do so in
              compliance with all relevant laws and regulations including, without limitation, obtaining parental consent where necessary. If you are a parent
              or guardian and are concerned that we may be processing personal data related to your child, please contact dataprotection@reachplc.com.
            </p>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="15-section">
              15. UK and EU residents: Your rights and how to exercise them
            </Typography>
            <p>The law gives you certain rights over your personal information.</p>
            <p>
              You can exercise your rights by contacting our group Data Protection Officer’s team through the use of the relevant forms provided below. It is
              helpful to be as specific as possible about what you want us to do or what information you are looking for, because it enables us to respond to
              you more quickly.
            </p>
            <p>
              Depending on your request, we may ask you to prove your identity to us first, in order to make sure that someone isn’t impersonating you. For
              example, if you contact us about your Reach account using a different email address, we may first require you to prove that you control the email
              address which you used to set up the account. If you want information about a subscription or some other paid service then we may ask you to
              provide the last four digits of the credit card used to pay for it.
            </p>
            <p>
              If you are making a request on behalf of someone else, you must include proof of their permission for you to do so, or provide evidence of a power
              of attorney, court order, or health professional evidence that they are unable to provide consent.
            </p>
            <p>
              Most of your rights do have exceptions to them, designed to protect the rights of others or to ensure that we can comply with the law and operate
              our business in a prudent manner (for example, by keeping certain records). If we decline all or part of your request on the basis of one or more
              of those exceptions, we will tell you that we are doing so, and we will explain our reasons. We may also reject your request entirely if it is
              excessive or unfounded.
            </p>
            <section>
              <Typography variant="h5bold" color="primary">
                15.1. Your right to access the information we hold about you
              </Typography>
              <p>
                You have the right to have access to the information we hold about you. There are a few exceptions to that right, designed to protect the rights
                of other people.
              </p>
              <p>
                To exercise this right please use our <a href="https://forms.gle/ezGJBuK5sshqVdj1A">right of access request form</a>.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                15.2. Your right to have inaccurate information about you rectified
              </Typography>
              <p>
                If we hold information about you that is factually inaccurate (for example, we have spelled your name wrong) then you have the right to have it
                corrected. This right does not extend to matters of opinion; for example, moderation decisions we might make about your comments on our
                websites.
              </p>
              <p>
                To exercise this right please use our <a href="https://forms.gle/k9ZNqDagVifLcyrR6">other rights request form</a>.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                15.3. Your right to have your information erased in some circumstances
              </Typography>
              <p>
                You may have the right to have some or all of your information deleted, principally if the information you want deleted is no longer needed or
                if we don’t have a legal basis to continue using it (for example, if we are using it on the basis of your consent and you withdraw your
                consent).
              </p>
              <p>
                To exercise this right please use our <a href="https://forms.gle/wGirf7BvPLuQzsms7">right to erasure request form</a>.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="15.4-section">
                15.4. Your right to withdraw your consent
              </Typography>
              <p>
                If we’re relying on your consent to do something with your information (for example, to send you newsletters) then you have the right to
                withdraw that consent at any time.
              </p>
              <p>For newsletters and other email communications, you can do this by clicking the “unsubscribe” link at the bottom of the message.</p>
              <p>
                To withdraw your consent for how we are using your information, please use our
                <a href="https://forms.gle/DLJVJwd7jZKoJvXe9"> unsubscribe request form</a>.
              </p>
              <p>
                For targeted advertising on our websites, you can access the screen to do this by clicking the “Privacy” tag with the ‘cog’ icon or link at the
                bottom of every webpage to go to the Consent Management Platform (CMP).
              </p>
              <p>
                For targeted advertising in our apps, you will be presented with the CMP on initial download and whenever it is updated thereafter. You can also
                change your CMP settings by navigating to ‘My Account’ at the bottom of the app screen and clicking on ‘My Privacy’.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary" id="15.5-section">
                15.5. Your right to object to what we do with your data, and to have restrictions placed upon it
              </Typography>
              <p>
                If we are doing something with your data not on the basis of your consent but on the basis of our “legitimate interest”, then you have the right
                to object to what we are doing, and we must stop unless we can show that there is a compelling and legitimate reason to continue or what we are
                doing relates to a legal claim. Activities such as fraud prevention will fall into that category.
              </p>
              <p>
                In the case of direct marketing and profiling, your right to object is absolute. Regardless of what other mechanisms we may make available, you
                can always object by unsubscribing from marketing emails and by rejecting the use of cookies on our websites (see the Privacy ‘cog’ or link at
                the bottom of every webpage). When using our apps, if you want to change the settings, go to the ‘My Account’ Link at the bottom of the screen
                which includes ‘My Privacy’ and takes you to the Consent Management Platform (CMP).
              </p>
              <p>
                To exercise this request please use our <a href="https://forms.gle/k9ZNqDagVifLcyrR6">other rights request form</a>.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                15.6. Your right to restrict what we do with your information in some circumstances
              </Typography>
              <p>
                In certain circumstances, you have the right to restrict our use of your information to simply storing it and using it only for legal claims,
                protecting the rights of others and matters of important public interest. Those circumstances are: if you dispute the accuracy of information we
                hold about you; if we do something unlawful with your information but you don’t want us to delete it; if we don’t need the information anymore
                but you want us to preserve it in connection with a legal claim; or for the duration of the period in which we are considering a valid objection
                you have raised under your right to object discussed above.
              </p>
              <p>
                To exercise this right please use our <a href="https://forms.gle/k9ZNqDagVifLcyrR6">other rights request form</a>.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                15.7. Your right to portability
              </Typography>
              <p>
                You have the right to receive the personal data concerning you which you have provided to a controller in a portable electronic format, or have
                it transmitted to another controller, where processing is based upon consent and undertaken by automated means.
              </p>
              <p>
                To exercise this right please use our <a href="https://forms.gle/k9ZNqDagVifLcyrR6">other rights request form</a>.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                15.8. How to Complain
              </Typography>
              <p>
                If you have a complaint or disagree with a decision we have made, we ask that you discuss it with us first by contacting the group Data
                Protection Officer using our{' '}
                <a href="https://docs.google.com/forms/d/1HqiwFBdi3amJGQYGKtGwBjVbNhQx--YXQZG4L1Cmz5k/edit"> data protection complaints form </a>or via the
                address given at the top of this Privacy Notice.
              </p>
              <p>You also have the right to complain to your data protection supervisory authority.</p>
              <address>
                <p className="underlined">In the UK this is the Information Commissioner’s Office:</p>
                <p>The ICO’s contact information is:</p>
                Information Commissioner’s Office <br />
                Wycliffe House <br />
                Wilmslow <br />
                Cheshire <br />
                SK95AF
                <br />
                ICO website: <a href="https://ico.org.uk/">https://ico.org.uk</a>
                <br />
                Helpline Number: <a href="tel:0303 123 1113">0303 123 1113</a>
              </address>
              <address>
                <p className="underlined">In the Republic of Ireland, it is the Data Protection Commissioner:</p>
                <br />
                21 Fitzwilliam Square South
                <br />
                Dublin 2
                <br />
                D02 RD28
                <br />
                Ireland
                <br />
                DPC IE website:<a href="https://www.dataprotection.ie/">https://www.dataprotection.ie</a>
                <br />
                DPC IE phone number is 01 7650100 / 1800437 737
              </address>
              <p>
                If you are based in another EU country, you can find the details of your local supervising authority
                <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en"> here.</a> EU SAs.
              </p>
            </section>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="16-section">
              16. US and Canadian Residents: relevant laws and rights
            </Typography>
            <p>
              Privacy legislation in the US and Canada is evolving and as a result there are special rights for residents of certain states. This section
              details the existing state laws and consumer privacy rights and will be updated as the new laws are introduced.
            </p>
            <p>
              We have appointed a Privacy Officer to oversee our data protection and privacy practices, in the US and Canada. Our Data Protection Officer holds
              this position. If you have any comments, concerns, or questions, including any requests to exercise your legal rights, you can contact our Privacy
              Officer as follows:
            </p>
            <ul>
              <li>By email: privacyofficer@reachplc.com</li>
              <li>By post: Privacy Officer, Reach Plc, 9th Floor, 200 Liberty Street, New York, NY 10281</li>
            </ul>
            <p>
              It would be very helpful for us if you could include your full name, your postcode, details of the service you are using and/or account
              information when contacting us.
            </p>
            <p>This section covers the following:</p>
            <ul><li>
            <b>
              <a href="#16.1-section">16.1 California Residents</a>
            </b>
            data collection, use and sharing, and privacy rights</li>
            <li>
            <b>
              <a href="#16.2-section">16.2 Virginia, Colorado, Connecticut, and Utah Residents</a>
            </b>
            specific information and privacy rights</li>
            <li>
                <b>
                  <a href="#16.3-section">16.3 Other state residents with applicable legislation</a>
                </b></li>
            </ul>
            <section>
              <Typography variant="h5bold" color="primary" id="16.1-section">
                16.1 California Residents
              </Typography>
              <p>
                This section applies only to California residents. It describes how we collect, use and share Personal Information of California residents in
                operating our business, and their rights with respect to that Personal Information. This section should be read in conjunction with the rest of
                this Privacy Notice which provides further details on the nature of our data processing.
              </p>
              <p>
                For purposes of this section, <b>“Personal Information”</b> has the meaning given in the California Consumer Privacy Act of 2018 <b>(“CCPA”)</b>{' '}
                and amended by the California Privacy Rights Act of 2020 <b>(”CPRA”).</b> It does not include: (i) information that is lawfully made available
                from federal, state or local government records; (ii) de-identified or aggregated data; or (iii) information excluded from the scope of the
                CCPA/CPRA.
              </p>
              <p>
                On some occasions, in order to provide our Services to our clients, we may process service data that we receive from or on behalf of our
                clients. If you are a California resident, you may have certain CCPA rights with regards to this information. Under the CCPA, when we act as a
                "service provider" (as opposed to a "business" or a "third party") over such information, we only process your information in a way required to
                provide our services to our clients, at our clients' direction. If you wish to exercise any of your rights under CCPA regarding such
                information, please contact the relevant business that collected the information from you, as they control and determine how your information is
                processed.
              </p>
              <section>
                <Typography variant="h6bold" color="primary">
                  16.1.1 Personal Information that we collect, use and share
                </Typography>
                <p>
                  The table below provides a summary of the categories of your personal information Reach may have collected, the sources of that data, the
                  reasons for collection and who we may have disclosed the data to over the last twelve months. The information gathered in this table may apply
                  to you in different ways depending on how you have interacted with us.
                </p>

                <p>
                  <b>Please see the keys below the table to inform the second and third column:</b>
                </p>

                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <b>Category</b>
                        </p>
                      </td>
                      <td>
                        <p>
                          <b>Business Purpose for Disclosure</b>
                        </p>
                      </td>
                      <td>
                        <p>
                          <b>Categories of Recipients of Disclosures</b>
                        </p>
                      </td>
                      <td>
                        <p>
                          <b>Do we sell this data?</b>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Personal Information</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <b>Identifiers</b>
                        </p>
                        <br />
                        such as name, email address, billing information, username, any provided phone numbers, such as home, or mobile, online screen name,
                        security questions and password.
                      </td>
                      <td>
                        <p>P1, P2, P3, P4, P5, P6, P7</p>
                      </td>
                      <td>
                        <p>D1, D2, D3, D4, D5, D6, D7, D8</p>
                      </td>
                      <td>
                        <p>No</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <b>Commercial Data</b>
                        </p>
                        <br />
                        such as records of your purchases and transaction data.
                      </td>
                      <td>
                        <p>P1, P2, P3, P4, P6, P7</p>
                      </td>
                      <td>
                        <p>D1, D2, D3, D6, D7, D8</p>
                      </td>
                      <td>
                        <p>No</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <b>Customer Records</b>
                        </p>
                        <br />
                        such as financial or payment information to process payments and information about your transactions and purchases with us.
                      </td>
                      <td>
                        <p>P1, P7</p>
                      </td>
                      <td>
                        <p>D2, D6, D7, D8</p>
                      </td>
                      <td>
                        <p>No</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <b>Internet & electronic network activity information</b>
                        </p>
                        <br />
                        including, but not limited to browsing history, search history, and information regarding interactions with an internet website,
                        application, or advertisement, including other usage data related to your use of any of our Services or other online services. Please
                        see our <a href="https://www.reachplc.com/site-services/cookie-notice">Cookie Notice</a> for further information.
                      </td>
                      <td>
                        <p>P1, P2, P3, P4, P5, P6, P7</p>
                      </td>
                      <td>
                        <p>D1, D2, D3, D4, D5, D6, D7, D8</p>
                      </td>
                      <td>
                        <p>Yes</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <b>Device information and identifiers</b>
                          <br />
                          such as IP address; browser type and language; operating system; platform type; device type; software and hardware attributes; and
                          unique device, advertising, and app identifiers.
                        </p>
                        <p>
                          including, but not limited to browsing history, search history, and information regarding interactions with an internet website,
                          application, or advertisement, including other usage data related to your use of any of our Services or other online services. Please
                          see our <a href="/legal/cookie-notice">Cookie Notice</a> for further information.
                        </p>
                      </td>
                      <td>
                        <p>P1, P2, P3, P4, P5, P6, P7</p>
                      </td>
                      <td>
                        <p>D1, D2, D3, D4, D5, D6, D7, D8</p>
                      </td>
                      <td>
                        <p>Yes</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <b>Geolocation Data</b>
                        </p>
                        <br />
                        such as general location, and, if you provide permission, precise GPS location.
                      </td>
                      <td>
                        <p>P1, P2, P3, P4, P6, P7</p>
                      </td>
                      <td>
                        <p>D1, D2, D3, D4, D5, D6, D7, D8</p>
                      </td>
                      <td>
                        <p>Yes</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <b>Sensory Data</b>
                        </p>
                        <br /> such as audio recordings if you call our customer service or volunteer this information as part of using our services and
                        photographs in connection with your social media account.
                      </td>
                      <td>
                        <p>P1, P2, P5, P7</p>
                      </td>
                      <td>
                        <p>D1, D2, D6, D7, D8</p>
                      </td>
                      <td>
                        <p>No</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <b>Financial Data</b>
                        </p>
                        <br />
                        such as credit or debit card number, verification number, and expiration date, to process payments and information about your
                        transactions and purchases with us.
                      </td>
                      <td>
                        <p>P1, P7</p>
                      </td>
                      <td>
                        <p>D2, D6, D7, D8</p>
                      </td>
                      <td>
                        <p>No</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <b>Social media information</b>
                        </p>
                        <p>
                          (which may include personal identifiers, photos, location, user generated content, demographic information). If you link your account
                          or access the Services through a third-party connection or log-in, we may have access to any information you provide to that social
                          network depending on your privacy settings, such as your name, email address, photo, location, and current city; and information you
                          provide to us directly through our pages on social networking and blogging platforms (e.g., Facebook, Instagram, YouTube, and
                          Twitter).
                        </p>
                      </td>
                      <td>
                        <p>P1, P2, P3, P4, P5, P6, P7</p>
                      </td>
                      <td>
                        <p>D1, D2, D3, D4, D5, D6, D7, D8</p>
                      </td>
                      <td>
                        <p>No</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <b>Research, survey, or sweepstakes information</b>
                        </p>
                        <p>
                          (including personal identifiers and user generated content) collected if you participate in a survey or sweepstakes; includes
                          information needed for you to participate (such as contact information), and to fulfil your prize.
                        </p>
                      </td>
                      <td>
                        <p>P1, P2, P3, P4, P5, P6, P7</p>
                      </td>
                      <td>
                        <p>D1, D2, D3, D5, D6, D7, D8</p>
                      </td>
                      <td>
                        <p>No</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <b>Inference Data</b>
                        </p>
                        <p>
                          including inferences drawn from any of the information identified above to create a profile reflecting a consumer’s preferences,
                          characteristics, behavior or attitudes.
                        </p>
                      </td>
                      <td>
                        <p>P1, P2, P3, P4, P6, P7</p>
                      </td>
                      <td>
                        <p>D1, D2, D3, D5, D6, D7, D8</p>
                      </td>
                      <td>
                        <p>No</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <b>Sensitive Personal Information</b>
                        </p>
                        <p>Account log-in in combination with any required security questions or password collected if you create an account with us.</p>
                      </td>
                      <td>
                        <p>P1, P2, P7</p>
                      </td>
                      <td>
                        <p>D2, D6, D7, D8</p>
                      </td>
                      <td>
                        <p>No</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                Data collection key
              </Typography>
              <p>
                <b>Purposes</b>
              </p>
              <ul>
                <li>
                  <p>
                    P1 - Performing services; including but not limited to customer service and related requests, processing orders or other transactions,
                    managing and maintaining your account, marketing or advertorial services, analytics.
                  </p>
                </li>
                <li>
                  <p>
                    P2 - Analysis and improvement; reviewing our activities to enhance either services offered or improve commercial capabilities such as by
                    improving the positioning or quality of our adverts and developing of new functionality and tools.{' '}
                  </p>
                </li>
                <li>
                  <p>
                    P3 - Commercial interests; including but not limited to the promotion of goods and services and to drive engagement, page views,
                    subscriptions, registrations, purchases and similar.{' '}
                  </p>
                </li>
                <li>
                  <p>P4 - Ephemeral; data processed in a transient manner and not retained by Reach.</p>
                </li>
                <li>
                  <p>P5 - Editorial; to support the production of editorial content across Reach titles.</p>
                </li>
                <li>
                  <p>P6 - Repairs; review of assets and management of debugging and other fixes to maintain and improve functionality.</p>
                </li>
                <li>
                  <p>
                    P7 - Security, fraud and legal compliance; actions deemed necessary to protect and defend Reach assets against illegal, malicious and
                    damaging activity.{' '}
                  </p>
                </li>
              </ul>
              <br />
              <p>
                <b>Disclosures</b>
              </p>
              <ul>
                <li>
                  <p>
                    D1 - Reach subsidiaries and affiliates; we may disclose information in order to deliver services in the ways described in this Privacy
                    Notice. Marketing will only be sent to you where the necessary consent has been provided.
                  </p>
                </li>
                <li>
                  <p>D2 - Service partners; we may disclose information where we work in partnership to provide you with products or services. </p>
                </li>
                <li>
                  <p>
                    D3 - Commercial partners; we may disclose information with third parties to provide you with relevant content and advertising on websites
                    and mobile apps where we have the necessary permissions to do so. We may also disclose information to enable you to receive marketing you
                    have asked to receive.
                  </p>
                </li>
                <li>
                  <p>D4 - Social media; we may disclose information with social media networks where we have the necessary permission to do so.</p>
                </li>
                <li>
                  <p>D5 - Analytics providers; we may disclose information with data analytic providers to review, evaluate and improve our services.</p>
                </li>
                <li>
                  <p>
                    D6 - Security; we may disclose information where it is necessary to do so in order to protect the rights, assets, employees and customers of
                    Reach.
                  </p>
                </li>
                <li>
                  <p>
                    D7 - Legal requirements; we may disclose information where necessary to comply with our legal obligations or if we believe it is required in
                    relation to the investigation or prevention of suspected or actual illegal activity.
                  </p>
                </li>
                <li>
                  <p>
                    D8 - Business Development; we may disclose information for the purpose of business transfers, mergers, acquisitions, bankruptcy proceedings
                    or similar.
                  </p>
                </li>
              </ul>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                16.1.2 Sharing of your data
              </Typography>
              <p>
                <ul>
                  <li>
                    Like many companies, we use services that help deliver interest-based ads, content and services to you and may transfer personal information
                    to business partners for their use. California law classifies our use of these services as “sharing” your Personal Information to the
                    companies that provide the services. This is because we allow them to collect information from our website users (e.g., online identifiers
                    and browsing activity) so they can help serve ads, content and services more likely to interest you.This sharing is limited to advertising
                    partners with whom we have a contractual agreement and to the online marketing related purposes agreed by you via Reach’s Consent Management
                    Platform (CMP). The data types include an online identifier, URLs from Reach pages, a referrer identifier, and a truncated IP address.
                  </li>
                </ul>
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                16.1.3 Sources of Personal Information.
              </Typography>
              <p>In general, we may collect the categories of personal information identified in the table above from the following categories of sources:</p>
              <ul>
                <li>Directly from you and your devices.</li>
                <li>Through your use of our services.</li>
                <li>
                  From third parties and service providers, including affiliates, commercial partners, social media networks, analytic providers, credit
                  reference agencies.
                </li>
              </ul>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                16.1.4. California Residents’ Rights
              </Typography>
              <p>As a California resident, you have the following rights (subject to certain limitations):</p>
              <ul>
                <li>
                  <b>Information.</b> You can request the following information about how we have collected and used your Personal Information during the past
                  12 months:
                </li>
                <ul>
                  <li>The categories of Personal Information that we have collected.</li>
                  <li>The categories of sources from which we collected Personal Information.</li>
                  <li>The categories of information collected both directly and indirectly, including through a service provider or contractor.</li>
                  <li>The business or commercial purpose for collecting and/or selling Personal Information.</li>
                  <li>The categories of third parties with whom we share Personal Information.</li>
                  <li>
                    Whether we have disclosed your Personal Information for a business purpose, and if so, the categories of Personal Information received by
                    each category of third party recipient.
                  </li>
                  <li>
                    Whether we’ve sold your Personal Information, and if so, the categories of Personal Information received by each category of third party
                    recipient.
                  </li>
                </ul>
                <li>
                  <b>Access.</b> You can request a copy of the Personal Information that we have collected about you during the past 12 months.
                </li>
                <li>
                  <b>Deletion.</b> You can ask us to delete the Personal Information that we have collected from you.
                </li>
                <li>
                  <b>Correction.</b> You can request that we correct inaccurate Personal Information.
                </li>
                <li>
                  <b>Portability.</b> You have the right to receive your information in an easily understandable, machine readable and transmittable form.
                </li>
                <li>
                  <b>Opt-out of sale or sharing. </b> You have the right to opt out of the sale or sharing of your Personal Information.
                </li>
                <li>
                  <b>Limit the use of your Sensitive Personal Information.</b> You have the right to limit the use or disclosure of your sensitive personal
                  information ("SPI") to the information necessary to perform services or provide goods reasonably expected.
                </li>
                <li>
                  <b>Non-discrimination. </b> The right not to be subject to discriminatory treatment for exercising their rights under the CPRA/CPRA.
                </li>
              </ul>
              <p>In the past 12 months, we have shared your Personal Information with third parties in the following circumstances:</p>
              <ul>
                <li>supplying your data to third parties with your express consent (e.g. where you opt-in to receive notifications or create an account);</li>
                <li>
                  using services that help deliver interest-based ads, content and services to you and may transfer personal information to business partners
                  for their use. California law classifies our use of these services as “sharing” your Personal Information to the companies that provide the
                  services. This is because we allow them to collect information from our website users (e.g., online identifiers and browsing activity) so they
                  can help serve ads, content and services more likely to interest you.
                </li>
              </ul>
            </section>
            <br/>
            <section>
              <Typography variant="h5bold" color="primary">
                16.1.5 Do Not “Sell” or “Share” My Personal Information
              </Typography>
              <p>California residents may opt out of the “sale” or “sharing” of their personal information.</p>

              <ul>
                <li>
                  <b>Sale: </b>
                  Reach does not "sell" any of your personal information in exchange for money. However, the term "sell" has a broader meaning under the
                  CCPA/CPRA than would ordinarily be understood in a non-legal context. If we “sell” your Personal Information, you can opt-out. In addition, if
                  you direct us not to “sell” your Personal Information, we will consider it a request pursuant to California’s “Shine the Light'' law to stop
                  sharing your personal information covered by that law with third parties for their direct marketing purposes.
                </li>
                <li>
                  <b>Sharing: </b>We do “share” your Personal Information, as defined, with trusted business partners to deliver interest-based ads, content and
                  services to you, including for site measurement. See section 16.1.2. Depending on what services you use, we may provide the following
                  categories of personal information to third parties for these purposes:
                </li>
                <ul>
                  <li>
                    For online targeted advertising purposes: demographic and statistical information, contact and registration information, device information
                    and identifiers, browser and usage data, geolocation, and social media information.
                  </li>
                </ul>
              </ul>
              <p>
                <b>To opt out of the “sale” and “sharing” of your information.</b>
              </p>
              <ul>
                <li>
                  Whilst browsing our websites you have the right under CPRA to opt out of these purposes by selecting the{' '}
                  <b>‘Do Not Sell or Share My Personal Information’</b> link in the footer of the relevant website. If you access this site and/or app from
                  other devices or browsers, or clear your cookies on your devices or browsers, you will need to indicate your preferences again from those
                  devices or browsers. Alternatively to opt out of “sales” or “sharing” of personal information you can toggle your cookies off in the cookie
                  preference centre or enable Global Privacy Control (“GPC”) on your browser. To learn more about GPC, please visit
                  <a href="http://globalprivacycontrol.org">http://globalprivacycontrol.org.</a>
                </li>
                <li>
                  To opt out of the “sale” of other data, including email, you may also submit a request directly by submitting the{' '}
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_S4UU9agFYuaYgp0IJr_OmXICpKF8oS8N2AaP-ybt29L-rw/viewform">rights request form</a> or by
                  emailing privacyofficer@reachplc.com quoting ‘California Privacy Rights-(insert name of Reach Group brand)’ in the subject header and state
                  ‘Opt out of Sale’. We reserve the right to ask for evidence of your residency in California before processing your request.
                </li>
              </ul>
              <p>
                Please note that a ‘Do Not Sell or Share My Personal Information’ request does not cover information that has previously been disclosed to third
                parties for these purposes and does not prevent the ongoing disclosure of data to our service providers.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                16.1.6 Limit the use of your Sensitive Personal Information.
              </Typography>
              <p>
                California residents have the right to limit the use or disclosure of their sensitive personal information ("SPI") if we are using your SPI
                beyond what is reasonable and proportionate to provide the requested goods or services. While we recognise opt-out preference signals (aka,
                Global Privacy Controls) when required to do so by law, given the specific methods by which Reach may collect your SPI in the limited
                circumstances it collects SPI, opt-out preference signals would be an ineffective and technologically impractical means for honouring limit the
                use requests. Therefore, please make your request to limit the use of your SPI using the{' '}
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_S4UU9agFYuaYgp0IJr_OmXICpKF8oS8N2AaP-ybt29L-rw/viewform">rights request form </a>or by
                emailing privacyofficer@reachplc.com quoting ‘California Privacy Rights-(insert name of Reach Group brand)’ in the subject header and state
                ‘Limit the use of Sensitive Data’. We reserve the right to ask for evidence of your residency in California before processing your request.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                16.1.7 Exercising your rights to Information Access and Deletion
              </Typography>
              <p>
                California residents wishing to exercise their right to access their personal information or request that we delete it can do so using the
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_S4UU9agFYuaYgp0IJr_OmXICpKF8oS8N2AaP-ybt29L-rw/viewform">rights request form </a>or by
                emailing privacyofficer@reachplc.com quoting ‘California Privacy Rights-(insert name of Reach Group brand)’ in the subject header and state
                which right(s) you wish to exercise.
              </p>
              <p>
                We will take steps to verify your request by (i) sending you an email confirmation to which you must respond to confirm your request; and (ii)
                matching the information provided by you with the information we have in our records. You must complete all required fields on our webform (or
                otherwise provide us with such information to verify your request.
              </p>
              <p>
                We will process your request based upon the personal information in our records that is linked or reasonably linkable to the information
                provided in your request. In some cases, we may request additional information in order to verify your request or where necessary to process
                your request. If we are unable to adequately verify a request, we will notify the requestor.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                16.1.8 Our Response Time to Your Request
              </Typography>
              <p>
                We will make every effort to respond to your request within forty-five (45) days from when you contacted us. If you have a complex request, the
                CPRA allows us up to ninety (90) days to respond. We will still contact you within forty-five (45) days from when you contacted us to let you
                know we need more time to respond and the reason for the extension.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                16.1.9 Authorised Agents
              </Typography>
              <p>
                Authorised agents may initiate a request on behalf of another individual by providing evidence of authority from the individual; authorised
                agents will be required to provide proof of their authorisation and we may also require that the relevant consumer directly verify their
                identity and the authority of the authorised agent.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                16.1.10 Shine The Light
              </Typography>
              <p>
                alifornia residents may request and obtain a list of any third parties that we have disclosed personal information to over the previous calendar
                year for the purposes of sending you direct marketing about the third party’s own products and services. If you would like to request this
                information please contact us using the{' '}
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_S4UU9agFYuaYgp0IJr_OmXICpKF8oS8N2AaP-ybt29L-rw/viewform">rights request form </a>or by
                emailing privacyofficer@reachplc.com quoting ‘California Privacy Rights-(insert name of Reach Group brand)’ in the subject header and state
                which right(s) you wish to exercise.
              </p>
            </section>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="16.2-section">
              16.2 Residents of Virginia, Colorado, Connecticut, and Utah
            </Typography>
            <p>This section applies solely to individual residents of the States of Virginia, Colorado, Connecticut, and Utah.</p>
            <section>
              <Typography variant="h5bold" color="primary">
                16.2.1 Residents Rights
              </Typography>
              <p>
                Privacy laws in these states, namely the Virginia Consumer Data Protection Act (VCDPA), Colorado Privacy Act (CPA), Connecticut Data Privacy Act
                (CTDPA) and Utah Consumer Privacy Act (UCPA), give residents certain rights with respect to their personal data.
              </p>
              <p>These rights include:</p>
              <ul>
                <li>
                  <b>Access.</b> You can request a copy of the Personal Information we collected about you.
                </li>
                <li>
                  <b>Right to correct.</b> You have the right to correct inaccuracies in the personal information we collect from you, taking into account the
                  nature of the personal information and the purposes for which we process it.
                </li>
                <li>
                  <b>Right to delete.</b> You have the right to delete personal information that you have provided or that we have obtained about you. Please
                  note that we may deny such a request if the requested deletion falls under an exception to this right set as provided by the relevant law.
                  Additionally, if you request the deletion of your personal information and we have obtained such information from a third-party source, we may
                  retain such data by keeping a record of the deletion request and the minimum data necessary to ensure that your personal information remains
                  deleted from our records and that such retained data is not used for any other purpose, or we may opt you out of the processing of such
                  personal information for any purpose except for those allowed under the relevant legislation.
                </li>
                <li>
                  <b>Right to data portability.</b> You have the right to access and obtain a copy of your personal information that you previously provided to
                  us in a portable and, to the extent technically feasible, readily usable format that allows you to transmit the data to another business
                  without hindrance, where the processing is carried out by automated means.
                </li>
                <li>
                  <b>Right to opt out of targeted ads, sales to third parties, or profiling.</b> You have the right to opt-out of the processing of your
                  personal information for purposes of: (i) targeted advertising; (ii) the sale of personal information; or (iii) profiling in furtherance of
                  decisions that produce legal or similarly significant effects concerning you. As described in this privacy notice:
                </li>
                <ul>
                  <li>We DO process personal information for the purposes of targeted advertising;</li>
                  <li>We DO NOT sell your personal information in exchange for monetary consideration;</li>
                  <li>
                    We DO NOT engage in profiling decisions based on your personal information that produce legal or similarly significant effects concerning
                    you.
                  </li>
                </ul>
              </ul>
            </section>
            <br/>
            <section>
              <Typography variant="h5bold" color="primary">
                16.2.2 Exercising these Rights
              </Typography>
              <p>
                <b>How to exercise your right to information, access, and deletion</b>
              </p>
              <p>
                You may exercise your privacy rights by submitting the{' '}
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_S4UU9agFYuaYgp0IJr_OmXICpKF8oS8N2AaP-ybt29L-rw/viewform">rights request form</a> or by
                emailing privacyofficer@reachplc.com quoting ‘US Privacy Rights (insert name of Reach Group brand)’ in the subject header and detail which
                right(s) you wish to exercise, and the US state you live in.
              </p>
              <p>
                <b>How to exercise your right to opt-out of the “sale or sharing” of your Personal Information</b>
              </p>
              <ul>
                <li>
                  <b>For internet and mobile users.</b> If you do not wish for us or our online third-party partners (such as advertising networks) to sell or
                  share your personal information with others, please click on the “Do Not Sell or Share My Personal Information” link in the footer of the
                  relevant website. If you access this site and/or app from other devices or browsers, or clear your cookies on your devices or browsers, you
                  will need to indicate your preferences again from those devices or browsers.
                </li>
                <li>
                  <b>For other offline users eg account holders:</b> You may also submit a request directly by submitting the{' '}
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_S4UU9agFYuaYgp0IJr_OmXICpKF8oS8N2AaP-ybt29L-rw/viewform">rights request form </a>or by
                  emailing privacyofficer@reachplc.com quoting ‘US Privacy Rights-(insert name of Reach Group brand)’ in the subject header and state ‘opt-out
                  of third-party sale or share’. We reserve the right to ask for evidence of your residency before processing your request.
                </li>
              </ul>
            </section>
            <br/>
            <section>
              <Typography variant="h5bold" color="primary">
                16.2.3 Our Response Time to Your Request
              </Typography>
              <p>
                We will make every effort to respond to your request within forty-five (45) days from when you contacted us. If you have a complex request, the
                legislation allows us up to ninety (90) days to respond. We will still contact you within forty-five (45) days from when you contacted us to let
                you know we need more time to respond and the reason for the extension.
              </p>
              <p>
                Residents of Virginia, Colorado, and Connecticut may appeal a refusal to take action on a request by contacting us by emailing
                privacyofficer@reachplc.com.
              </p>
            </section>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
          <section>
            <Typography variant="h4bold" color="primary" id="16.3-section">
              16.3 Other residents with applicable legislation
            </Typography>
            <p>
              Residents may be covered by other local state data protection or privacy legislation. To exercise these rights please use the{' '}
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_S4UU9agFYuaYgp0IJr_OmXICpKF8oS8N2AaP-ybt29L-rw/viewform">rights request form</a>, to submit a
              request in relation to your data rights.
            </p>
            <section>
              <Typography variant="h5bold" color="primary">
                16.3.1 Nevada Privacy Rights
              </Typography>
              <p>
                Reach does not currently sell your personal data, as defined by Nevada Law, for monetary compensation to third parties for their own purposes.
                Nevada residents, however, may request that we include their name on a do-not-sell list in the event Reach were to do so in the future. Please
                email us at privacyofficer@reachplc.com if you want to place your name on the do not sell list.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                16.3.2 Canadian Residents
              </Typography>
              <p>
                Federal and provincial laws in Canada, such as the Personal Information Protection and Electronic Documents Act 2000 (PIPEDA) and Quebec’s Bill
                64 (Law 25) give residents certain rights with respect to their personal data.
              </p>
              <p>
                <b>Your Rights and Choices.</b>
              </p>
              <p>
                If you are a Canadian resident you may be able to exercise certain rights with respect to your personal information that we collect and control:
              </p>
              <ul>
                <li>
                  <b>Access.</b> Ask us for a copy of your Personal Information and can ask for a copy of Personal Information you provided in machine readable
                  form.
                </li>
                <li>
                  <b>Correction.</b> Ask us to correct the personal information we have about you if you believe it is inaccurate or incomplete
                </li>
                <li>
                  <b>Deletion.</b> Ask us to erase or delete all or some of your Personal Information (e.g., if it is no longer necessary to provide products or
                  services to you).
                </li>
                <li>
                  <b>Object to, or Limit or Restrict, Use of Personal Information:</b> Ask us to stop using all or some of your Personal Information (e.g., if
                  we have no legal right to keep using it) or to limit our use of it (e.g., if your Personal Information is inaccurate or unlawfully held).
                </li>
                <li>
                  <b>Data Portability:</b> You may also have the right to access and obtain a copy of your personal information that you previously provided to
                  us in a portable and, to the extent technically feasible, readily usable format that allows you to transmit the data to another business
                  without hindrance, where the processing is carried out by automated means.
                </li>
              </ul>
              <p>
                We may need to verify your identity before processing your request, which may require us to request additional personal information from you. In
                certain circumstances, we may decline or limit your request, particularly where we are unable to verify your identity or locate your information
                in our systems, or as permitted by law.
              </p>
              <p>
                We will attempt to respond to your request within thirty (30) days. We will advise you in writing if we cannot meet your request within this
                time limit.
              </p>
              <p>
                To exercise these rights please use the our{' '}
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_S4UU9agFYuaYgp0IJr_OmXICpKF8oS8N2AaP-ybt29L-rw/viewform">rights request form </a>or contact
                us by emailing privacyofficer@reachplc.com.
              </p>
            </section>
            <a href="#contents">*** Click here to go back to ‘Contents’ ***</a>
          </section>
        </section>
      </main>
    </div>
  );
};

export default PolicyNotice;
