import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { navigationRoute, constructPath } from '../../../helpers/utils.js';
import { getDisplayName } from '../../../helpers/localConfigUtils.js';
import { getFeatureValue } from '../../../helpers/configUtilities';
import useSavedCategories from './../useSavedCategories.js';
import './TabBar.scss';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function TabBar() {
  const [selectedTab, setSelectedTab] = useState('/');
  const [isTabValid, setIsTabValid] = useState(true);

  const { savedCategories, savedSearches } = useSavedCategories();

  const navigate = useNavigate();
  const location = useLocation();
  const siteRoot = getFeatureValue('siteRoot');

  useEffect(() => {
    const pathParts = location.pathname.split('/').filter((part) => part !== ''); // Split the pathname into parts and filter out empty parts
    const lastPathPart = pathParts[pathParts.length - 1]; // Get the last part of the path
    setSelectedTab(lastPathPart);
  }, [location.pathname]);

  useEffect(() => {
    const isTabInCategoryArray = savedCategories.some((tab) => tab.categorySlug === selectedTab);
    const isTabInSavedSearches = Object.keys(savedSearches).includes(selectedTab);
    setIsTabValid(isTabInCategoryArray || isTabInSavedSearches);
  }, [selectedTab, savedCategories, savedSearches]);

  const breadcrumbList = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': siteRoot,
          name: 'My News Assistant',
        },
      },
      ...savedCategories.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 2,
        item: {
          '@id': `${siteRoot}/${constructPath(item.sectionSlug, item.subSectionSlug, item.categorySlug)}`,
          name: item.displayName,
        },
      })),
    ],
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(breadcrumbList)}</script>
      </Helmet>
      <div className="tab-bar">
        <Tabs
          value={location.pathname === '/' ? '/' : isTabValid ? selectedTab : false}
          textColor="secondary"
          variant="scrollable"
          selectionFollowsFocus={true}
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
        >
          <Tab label="My Feed" value="/" key="home" component={Link} to={'/'} {...a11yProps('home')} />
          {savedCategories &&
            savedCategories.map((tab, index) => (
              <Tab
                label={getDisplayName(tab.key) ?? tab.displayName}
                value={tab.categorySlug}
                key={index}
                component={Link}
                to={constructPath(tab.sectionSlug, tab.subSectionSlug, tab.categorySlug)}
                {...a11yProps(index)}
                onClick={() => setSelectedTab(tab.categorySlug)}
              />
            ))}
          {savedSearches &&
            Object.entries(savedSearches).map(([key, value], index) => (
              <Tab
                label={value.displayName}
                value={key}
                key={index + savedCategories?.length}
                component={Link}
                to={constructPath(value.sectionSlug, value.subSectionSlug, value.categorySlug)}
                {...a11yProps(index + savedCategories?.length)}
                onClick={() => setSelectedTab(key)}
              />
            ))}
        </Tabs>
        <IconButton aria-label="Settings" onClick={() => navigationRoute(navigate, '/settings')} sx={{ mr: 2, color: 'common.black' }}>
          <SettingsOutlinedIcon />
        </IconButton>
      </div>
    </>
  );
}
