import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import './static.scss';

const AboutUs = () => {
  useEffect(() => {
    document.title = 'About Us | My News Assistant';
    window.scrollTo(0, 0);
  });

  return (
    <div className="standard-layout static-page">
      <main>
        <section className="static-content">
          <Typography variant="h1bold" color="primary">
            About My News Assistant
          </Typography>
          <p>
            Tired of navigating multiple news websites trying to filter out news and topics that’s relevant to you? Well, your search is over! My News Assistant
            offers thousands of articles published daily, from well-known trusted brands across the UK. So whether you are interested in Manchester United
            transfer news, the latest antics of the Kardashians, or advances in Neuroscience (or even all three!) …… we’ve got you covered!.
          </p>
          <ul>
            <li>
              <a href="#actionable-feedback">Tell us what you think</a>
            </li>
            <li>
              <a href="#ownership">Our business</a>
            </li>
            <li>
              <a href="#diversity-policy">Diversity policy</a>
            </li>
            <li>
              <a href="#diversity-staffing-report">Equal opportunities</a>
            </li>
          </ul>
          <section>
            <Typography variant='h4bold' color="primary" id="actionable-feedback">Tell us what you think</Typography>
            <p>There are many ways for you to get in touch with us to have your say.</p>
            <ul>
              <li>
                Message us on <a href="/social">Facebook or Twitter</a>
              </li>
              <li>Fill out a form in a story where we are asking to hear about your experience</li>
              <li>
                Apply to join our{' '}
                <a href="https://www.mouthpiecepanel.co.uk/S.aspx?s=234&amp;r=FX14ld0zG1Fh3zC9C4tN3R&amp;so=true&amp;o=hk6Yb7ne23&amp;fromdetect=1">
                  reader panel
                </a>
              </li>
              <li>
                If you have feedback or a complaint, <a href="/contact-us">find out who to contact</a>
              </li>
            </ul>
          </section>
          <section>
            <Typography variant='h4bold' color="primary" id="ownership">Our Business</Typography>
            <p>
              Mirror Online, the Daily Mirror, Sunday Mirror and Sunday People are published by MGN Ltd, part of <a href="http://www.reachplc.com/">Reach PLC</a>.
              Reach PLC is Britain’s largest newspaper, magazine and digital publisher, with a print and online portfolio reaching 38.6million people every month.
              The award-winning Reach PLC publications stretch across England, Scotland and Wales, with more than 150 newspaper titles and more than 80 websites.
              These iconic brands sit at the heart of their communities, forming part of the fabric of everyday life for thousands of people across the UK. Find
              out which of our regional titles is nearest you, whether{' '}
              <a href="http://www.trinitymirror.com/documents/brands-page/trinity-mirror-digital-portfolio-21-november.pdf">online</a> or in{' '}
              <a href="http://www.trinitymirror.com/documents/brands-page/tm-print-portfolio-25-october.pdf">print</a>.{' '}
            </p>
            <p>Reach PLC is listed on the London Stock Exchange, and is part of the FTSE SmallCap Index.</p>
            <p>
              Mirror Online does not have a paywall and is available free to readers both on the web and as an <a href="//www.mirror.co.uk/apps">app</a>. As such,
              you are kindly asked to whitelist Mirror Online if you are running an ad-blocker.{' '}
            </p>
            <p>
              The Daily Mirror is on sale Monday to Saturday, with sister titles the Sunday Mirror and Sunday People appearing weekly.{' '}
              <a href="//www.mirror.co.uk/e-edition/">E-edition</a> and <a href="//www.mirror.co.uk/subscriptions/">print subscriptions</a> are available.{' '}
            </p>
            <p>
              In addition to newspaper sale price, our titles are funded by advertising, partnerships, sponsorships and affiliate commission, which are{' '}
              <a href="https://www.asa.org.uk/">regulated by the ASA</a>. We occasionally apply for grants, for example for{' '}
              <a href="//www.mirror.co.uk/all-about/road-to-wigan-pier-2017">The Wigan Pier Project</a>.{' '}
            </p>
            <address>
              <b>Registered office:</b> MGN Limited, One Canada Square, Canary Wharf, London E14 5AP. Registered No. 2571173, England &amp; Wales.
            </address>
          </section>
          <section>
            <Typography variant='h4bold' color="primary" id="diversity-policy">Diversity Policy</Typography>
            <p>
              The Company is committed to increasing the service quality, profitability and efficiency of the Company by attracting and recruiting the people who
              are best suited to meet the standards for the role and the Company without regard to race, creed, colour, nationality (subject to legal
              eligibility), ethnic origin, religion, gender, age, sex change, sexual orientation, marital status, connections with a national minority, membership
              or non-membership of a trade union or, unless justifiable, disability.
            </p>
            <p>We pursue a policy of equal opportunities for all employees and potential employees.</p>
          </section>
          <section>
            <Typography variant='h4bold' color="primary" id="diversity-staffing-report">Equal Opportunities</Typography>
            <p>
              Reach PLC continues its commitment to equality of opportunity in all its employment practices to ensure we attract and retain the best people. In
              2016, both the number of women within the company and the number of women occupying senior managerial roles increased to 42% of staff (2015: 37%)
              and 31% (2015: 24%) respectively. Senior managers have responsibility for key businesses or functions within the company.
            </p>
          </section>
        </section>
      </main>
    </div>
  );
};

export default AboutUs;
