import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { getFeatureValue } from '../../../helpers/configUtilities';
import { constructPath } from '../../../helpers/utils';
import './Trending.scss';

function Trending({ trendingKeywords, icon, text }) {
  if (!trendingKeywords || Object.keys(trendingKeywords).length === 0) {
    return <nav aria-label="trending" className="trending-list" role="navigation"></nav>;
  }

  const siteRoot = getFeatureValue('siteRoot');
  const currentDate = new Date();

  const filteredAndSortedTrendingItems = Object.keys(trendingKeywords)
    .map(key => {
      const category = trendingKeywords[key];
      return {
        ...category,
        name: category.displayName,
        url: `/${constructPath(category?.sectionSlug, category?.subSectionSlug, category?.categorySlug)}`,
        startDate: category.startDate ? new Date(category.startDate) : undefined,
        endDate: category.endDate ? new Date(category.endDate) : undefined,
      };
    })
    .filter(item => {
      // If no startDate is provided, consider it as valid from any date
      const startDateIsValid = !item.startDate || item.startDate <= currentDate;
      // If no endDate is provided, consider it as valid until any date
      const endDateIsValid = !item.endDate || item.endDate >= currentDate;
      // Include items that are valid for the current date
      return startDateIsValid && endDateIsValid;
    })
    .sort((a, b) => {
      // Items without an endDate are considered to last indefinitely, hence sorted last
      const endDateA = a.endDate ? a.endDate : new Date('9999-12-31');
      const endDateB = b.endDate ? b.endDate : new Date('9999-12-31');
      return endDateA - endDateB;
    });

  const breadcrumbList = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      ...filteredAndSortedTrendingItems.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': `${siteRoot}${item.url}`,
          name: item.name,
        },
      })),
    ],
  };

  return (
    <nav aria-label="trending" className="trending-list" role="navigation">
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(breadcrumbList)}</script>
      </Helmet>
      <div className="trending-list">
        {icon && <TrendingUpIcon color='error' sx={{ mr: 1 }} />}
        {text && <Typography variant="label3" className="trending-text">Related :</Typography>}
        <div className="trending-wrapper">
          <Tabs value={false} variant="scrollable" scrollButtons={false} aria-label="scrollable auto tabs" sx={{ marginTop: '15px' }}>
            {filteredAndSortedTrendingItems.map((item, index) => (
              <Tab
                key={index}
                className="trending-list-item"
                component={Link}
                to={item.url}
                label={
                  <Typography
                    sx={{
                      textTransform: 'none',
                    }}
                    variant="label3"
                  >
                    {item.name}
                  </Typography>
                }
              />
            ))}
          </Tabs>
        </div>
      </div>
    </nav>
  );
}

export default Trending;
