import React, { useState, useEffect, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getCategoryByName } from '../../config/categories';
import { avoid } from '../../helpers/utils';
import { getInterestKeys } from '../../helpers/localConfigUtils';
import InterestTreeMenu from '../InterestTreeMenu/InterestTreeMenu';

const Interest = forwardRef((props, ref) => {
  const [isLimitExist, setLimitExist] = useState(false);
  const [isMinim, setIsMinim] = useState(false);
  const [activeKeys, setActiveKeys] = useState([]);
  const [openNodes, setOpenNodes] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  let isNewCategorySelected = true;

  let navigate = useNavigate();
  // GA4 tracking via gtag.js
  const gtag = window.gtag || avoid;

  const handleDelete = (chipToDelete) => () => {
    const categoryConfig = getCategoryByName(chipToDelete);
    const renderKey = categoryConfig.categoryType + '/' + chipToDelete;

    setSelectedCategories((categories) => {
      if (categories.includes(chipToDelete)) {
        return categories.filter((activeCategory) => activeCategory !== chipToDelete);
      }
      return categories;
    });

    setActiveKeys((keys) => {
      if (keys.includes(renderKey)) {
        return keys.filter((activeKey) => activeKey !== renderKey);
      }
      return [...keys, renderKey];
    });
  };

  const toggleItem = (event) => {
    if (event.level === 0) {
      if (openNodes.find(key => key  === event.key)) {
        setOpenNodes([]);
        return;
      }
      setOpenNodes(Array.from(Array(event.key)));
      return;
    } else {
      const categoryKey = event.categoryKey;
      const categoryConfig = getCategoryByName(categoryKey);
      const renderKey = categoryConfig.categoryType + '/' + categoryKey;
      if (selectedCategories.length < 50) {
        setSelectedCategories((categories) => {
          if (categories.includes(categoryKey)) {
            return categories.filter((activeCategory) => activeCategory !== categoryKey);
          }
          return [...categories, categoryKey];
        });
      }

      if (activeKeys.length >= 50) {
        setSelectedCategories((categories) => {
          if (categories.includes(categoryKey)) {
            isNewCategorySelected = false;
            return categories.filter((activeCategory) => activeCategory !== categoryKey);
          }
          return categories;
        });
        if (isNewCategorySelected) {
          setLimitExist(true);
          return;
        }
      }
      setActiveKeys((keys) => {
        if (keys.includes(renderKey)) {
          return keys.filter((activeKey) => activeKey !== renderKey);
        }
        return [...keys, renderKey];
      });
    }
  };

  const handleInfoClose = () => {
    setLimitExist(false);
    setIsMinim(false);
  };

  const handleSave = () => {
    if (activeKeys.length < 1) {
      setIsMinim(true);
      return;
    }

    gtag('event', 'personalisation_interest', {
      event: 'clicked_categories',
      source: 'sign_up_flow',
    });

    const categoriesArray = selectedCategories.map((categoryKey) => categoryKey);
    navigate('/signup/location', { state: { categories: categoriesArray } });
  };

  const handlePersonalizedFields = () => {
    const storedCategories = getInterestKeys();

    let categoryArray = [];
    let categoryKeysArray = [];

    if (storedCategories) {
      storedCategories.forEach((categoryKey) => {
        const categoryConfig = getCategoryByName(categoryKey);
        const renderKey = `${categoryConfig.categoryType}/${categoryKey}`;
        categoryArray.push(categoryKey);
        categoryKeysArray.push(renderKey);
      });
      setSelectedCategories(categoryArray);
      setActiveKeys(categoryKeysArray);
    }
  };

  useEffect(() => {
    document.title = 'My Interests | My News Assistant';
    window.scrollTo(0, 0);
    handlePersonalizedFields();
  }, []);

  return (
    <div>
      {
        <div className='bordered-container'>
          <div className='top-container'>
            <IconButton aria-label='go back' edge='start' onClick={() => navigate('/signup')}>
              <ArrowBackIcon color='timberGreen' />
            </IconButton>
            <Typography variant='body1'>
              <b>Customise</b> | step 1 of 2
            </Typography>
          </div>
          <Typography variant='h1'>
            My <span className='highlighted'>Interests</span>
          </Typography>
          <Typography variant='label4' sx={{ display: 'block', mb: 2 }}>
            Customise your news feed by selecting or searching for up to 50 of your interests.
          </Typography>
          <div className='form-container'>
            <InterestTreeMenu
              activeKeys={activeKeys}
              openNodes={openNodes}
              selectedCategories={selectedCategories}
              toggleItem={toggleItem}
              handleDelete={handleDelete}
            />
          </div>
          <Container className='flex-btn'>
            <Button
              onClick={() => handleSave()}
              className='btn'
              color='primary'
              variant='contained'
            >
              Continue
            </Button>
          </Container>
        </div>
      }

      {
        <Dialog open={isLimitExist} aria-labelledby="responsive-dialog-title" data-testid="maxim-popup">
          <DialogContent>
            <Typography id='responsive-dialog-title' variant='h3' sx={{ mx: 2, mt: 4, mb: 2 }}>{'Info'}</Typography>
            <Typography variant='h6' sx={{ mx: 2 }}>50 Categories can be selected at a time</Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              autoFocus
              onClick={handleInfoClose}
              color='primary'
              variant='contained'
              sx={{ width: '100%', mx: 4, mb: 4 }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      }
      {
        <Dialog open={isMinim} aria-labelledby="responsive-dialog-title" data-testid="minim-popup">
          <DialogContent>
            <Typography id='responsive-dialog-title' variant='h3' sx={{ mx: 2, mt: 4, mb: 2 }}>Select a category</Typography>
            <Typography variant='h6' sx={{ mx: 2 }}>Please select a category</Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              autoFocus
              onClick={handleInfoClose}
              color='primary'
              variant='contained'
              sx={{ width: '100%', mx: 4, mb: 4 }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
});

Interest.displayName = 'Interest';
export default Interest;
