import { getCookie } from './pageUtilities';
import { createTimestamp } from './utils';
import { __log } from './utils';

const isValidConfig = (config) => {
  const expectedTypes = {
    accessibility: 'boolean',
    bookmarks: 'array',
    consents: {
      marketingPermission: 'boolean',
      newslettersPermission: 'boolean',
      personalisedExperience: 'boolean',
      pushNotificationsPermission: 'boolean',
      termsAccepted: 'boolean',
    },
    createdDate: 'string',
    debug: 'boolean',
    dislikes: 'array',
    displayName: 'string',
    exclusions: 'array',
    interests: 'object',
    language: 'string',
    likes: 'array',
    location: {
      city: 'string',
      country: 'string',
      postcode: 'string',
      type: 'string',
    },
    mantisId: 'string',
    modifiedDate: 'string',
    notifications: {
      email: 'boolean',
      frequency: 'string',
      push: 'boolean',
    },
    readArticles: 'array',
    savedSearches: 'object',
    userId: 'string',
    version: 'number',
    displayTheme: 'string',
  };
  const checkProperty = (propertyName, propertyValue, expectedType) => {
    if (expectedType === 'array' && !Array.isArray(propertyValue)) {
      __log(`Invalid value for property '${propertyName}' (expected Array)`);
      return false;
    }

    if (expectedType === 'object') {
      if (Array.isArray(propertyValue)) {
        __log(`Invalid value for property '${propertyName}' (expected Object)`);
        return false;
      }

      if (Object.keys(propertyValue).length === 0) {
        return true;
      }
    }

    if (typeof expectedType === 'object') {
      if (typeof propertyValue !== 'object' || Array.isArray(propertyValue)) {
        __log(`Invalid value for property '${propertyName}' (expected Object)`);
        return false;
      }

      for (const [subPropertyName, subExpectedType] of Object.entries(expectedType)) {
        const subPropertyValue = propertyValue[subPropertyName];
        const isValidSubProperty = checkProperty(`${propertyName}.${subPropertyName}`, subPropertyValue, subExpectedType);
        if (!isValidSubProperty) {
          return false;
        }
      }
      return true;
    }

    if (Array.isArray(propertyValue) && expectedType === 'array') {
      return true;
    }

    if (typeof propertyValue !== expectedType) {
      __log(`Invalid value for property '${propertyName}' (expected ${expectedType})`);
      return false;
    }

    return true;
  };
  for (const propertyName in expectedTypes) {
    const expectedType = expectedTypes[propertyName];
    const propertyValue = config[propertyName];
    const isValidProperty = checkProperty(propertyName, propertyValue, expectedType);
    if (!isValidProperty) {
      return false;
    }
  }
  // Check each interest property and its sub-properties
  for (const interestName in config.interests) {
    const interest = config.interests[interestName];
    const isValidInterest =
      typeof interest === 'object' &&
      typeof interest.displayName === 'string' &&
      typeof interest.name === 'string' &&
      typeof interest.options === 'object' &&
      typeof interest.options.expandedSearch === 'boolean' &&
      Array.isArray(interest.options.locations) &&
      typeof interest.options.pinnedCategory === 'object' &&
      typeof interest.options.pinnedCategory.enabled === 'boolean' &&
      (typeof interest.options.pinnedCategory.order === 'string' || typeof interest.options.pinnedCategory.order === 'number');
    if (!isValidInterest) {
      __log(`Invalid interest types for '${interestName}'`);
      return false;
    }
  }
  return true;
};

const removeLocalConfig = () => {
  localStorage.removeItem('local-config');
};

const addOrUpdateConfigObject = (localConfig) => {
  if (!localConfig || typeof localConfig !== 'object' || Array.isArray(localConfig)) {
    // Handle case where localConfig is not provided or is not an object
    return localConfig;
  }

  const isReadArticlesArray = Array.isArray(localConfig?.readArticles);
  if (!isReadArticlesArray) {
    localConfig.readArticles = [];
  }

  const isSavedSearchesArray = Array.isArray(localConfig?.savedSearches);
  if (isSavedSearchesArray) {
    localConfig.savedSearches = {};
  }

  if (
    localConfig &&
    localConfig.interests &&
    typeof localConfig.interests === 'object' &&
    Object.keys(localConfig.interests).length > 0
  ) {
    const updatedInterests = {};
    Object.entries(localConfig.interests).forEach(([key, interestsConfig]) => {
      if (interestsConfig) {
        if (!interestsConfig.displayName || interestsConfig.displayName === '') {
          const displayName = `${interestsConfig.name ?? ''}`.replace(/[- ]/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
          interestsConfig.displayName = displayName;
        }
      }
      updatedInterests[key] = interestsConfig;
    });
    localConfig.interests = updatedInterests;
  }

  if (
    localConfig &&
    localConfig.savedSearches &&
    typeof localConfig.savedSearches === 'object' &&
    Object.keys(localConfig.savedSearches).length > 0
  ) {
    const updatedSavedSearches = {};
    Object.entries(localConfig.savedSearches).forEach(([key, savedSearchConfig]) => {
      if (savedSearchConfig) {
        if (savedSearchConfig.exactQuery || savedSearchConfig.exactQuery === '' || !savedSearchConfig.orSearchQuery) {
          savedSearchConfig.orSearchQuery = savedSearchConfig.exactQuery || '';
          delete savedSearchConfig.exactQuery;
        }
        if (savedSearchConfig.category || savedSearchConfig.category === '') {
          savedSearchConfig.categorySlug = savedSearchConfig.category || '';
          delete savedSearchConfig.category;
        }
        if (savedSearchConfig.section || savedSearchConfig.section === '') {
          savedSearchConfig.sectionSlug = savedSearchConfig.section || '';
          delete savedSearchConfig.section;
        }
        if (savedSearchConfig.subSection || savedSearchConfig.subSection === '') {
          savedSearchConfig.subSectionSlug = savedSearchConfig.subSection || '';
          delete savedSearchConfig.subSection;
        }
        savedSearchConfig.sortOrder = savedSearchConfig.sortOrder || 'date';
      }
      updatedSavedSearches[key] = savedSearchConfig;
    });
    localConfig.savedSearches = updatedSavedSearches;
  }

  return localConfig;
};

const getLocalConfig = (localConfig) => {
  try {
    localConfig = localConfig || localStorage.getItem('local-config');
    if (typeof localConfig !== 'object') {
      localConfig = JSON.parse(localConfig);
    }

    if (localConfig) {
      localConfig = addOrUpdateConfigObject(localConfig);
      setLocalConfig(localConfig);

      if (!isValidConfig(localConfig)) {
        __log('localConfig is not valid', 'and has been removed');
        removeLocalConfig();
      }
    }
  } catch (error) {
    __log('Error accessing localStorage:', error);
  }

  return localConfig;
};

const isPersonalisedExperience = () => getLocalConfig() && getLocalConfig()?.consents.personalisedExperience;

const getLocalConfigOrDefault = (localConfig) => {
  localConfig = getLocalConfig(localConfig);

  return (
    localConfig ?? {
      accessibility: false,
      bookmarks: [],
      consents: {
        marketingPermission: false,
        newslettersPermission: false,
        personalisedExperience: false,
        pushNotificationsPermission: false,
        termsAccepted: false,
      },
      createdDate: '',
      debug: false,
      dislikes: [],
      displayName: '',
      exclusions: [],
      interests: {},
      language: '',
      likes: [],
      location: {
        city: '',
        country: '',
        postcode: '',
        type: '',
      },
      mantisId: '',
      modifiedDate: '',
      notifications: {
        email: false,
        frequency: 'daily',
        push: false,
      },
      readArticles: [],
      savedSearches: {},
      userId: '',
      version: 1,
      displayTheme: 'default',
    }
  );
};

const getInterestProperty = (interestProperty, interestName, localConfig) => {
  localConfig = getLocalConfig(localConfig);
  if (!localConfig) return false;

  const mergedProperties = { ...localConfig.interests, ...localConfig.savedSearches };

  if (
    Object.prototype.hasOwnProperty.call(mergedProperties, interestName) &&
    Object.prototype.hasOwnProperty.call(mergedProperties[interestName].options, interestProperty)
  ) {
    return mergedProperties[interestName].options[interestProperty];
  } else {
    return localConfig[interestProperty];
  }
};

const getDisplayName = (interestName, localConfig) => {
  localConfig = getLocalConfig(localConfig);
  if (!localConfig) return;

  const mergedProperties = { ...localConfig.interests, ...localConfig.savedSearches };
  return mergedProperties[interestName]?.displayName;
};

const getInterestPropertiesByValue = (interestProperty, interestValue, localConfig) => {
  interestValue = interestValue || true;
  localConfig = getLocalConfig(localConfig);

  const interests = [];
  if (localConfig?.interests && interestProperty) {
    for (const interestName in localConfig.interests) {
      if (interestValue) {
        if (localConfig.interests[interestName].options[interestProperty] === interestValue) {
          interests.push(interestName);
        }
      }
    }
  }
  return interests;
};

const getInterestKeys = (localConfig) => {
  localConfig = getLocalConfig(localConfig);
  return localConfig?.interests ? Object.keys(localConfig.interests) : false;
};

const getMergedInterests = (localConfig) => {
  localConfig = getLocalConfig(localConfig);
  const interests = localConfig?.interests;
  const userDefinedCategories = localConfig?.savedSearches;
  const mergedInterests = { ...interests, ...userDefinedCategories };
  return mergedInterests;
};

function getUniqueLocations(localConfig) {
  localConfig = getLocalConfig(localConfig);

  const locations = new Set();
  if (localConfig) {
    for (const interest of Object.values(localConfig.interests)) {
      for (const location of interest.options.locations) {
        locations.add(location);
      }
    }
  }
  return Array.from(locations);
}

const setLocalConfig = (localConfig) => {
  if (localConfig) {
    localConfig.modifiedDate = createTimestamp();
    localStorage.setItem('local-config', JSON.stringify(localConfig));
    return localConfig;
  }
};

const setUserIdFromLocalStorage = (localConfig) => {
  localConfig = getLocalConfig(localConfig);
  if (!localConfig) return;

  const authUser = localStorage.getItem('auth-user');
  if (authUser) {
    const parsedAuthUser = JSON.parse(authUser);
    if (parsedAuthUser && parsedAuthUser.Uid && localConfig.userId === '') {
      localConfig.userId = parsedAuthUser.Uid;
    }
  }
  setLocalConfig(localConfig);
  return localConfig;
};

const setPinnedCategory = (interestName, isEnabled, localConfig) => {
  localConfig = getLocalConfig(localConfig);
  if (!localConfig || !localConfig.interests) {
    return false;
  }

  const interests = localConfig.interests;
  const userDefinedCategories = localConfig.savedSearches;
  const mergedInterests = { ...interests, ...userDefinedCategories };
  // Check if the interest name exists in the interests object
  if (!Object.prototype.hasOwnProperty.call(mergedInterests, interestName)) {
    return false;
  }

  // Get the current pinned category order and enabled status for the interest
  const currentOrder = mergedInterests[interestName]?.options?.pinnedCategory?.order;

  // If enabled is true, update the order to be 1 or retain the current order if numeric
  if (isEnabled) {
    if (typeof currentOrder !== 'number') {
      mergedInterests[interestName].options.pinnedCategory.order = 1;
      // If the order is updated to 1, decrease all other numeric orders by 1
      for (const [key, value] of Object.entries(mergedInterests)) {
        if (key !== interestName) {
          const { enabled, order } = value.options.pinnedCategory;
          if (enabled && !isNaN(order) && order >= 1) {
            value.options.pinnedCategory.order = order + 1;
          }
        }
      }
    } else {
      mergedInterests[interestName].options.pinnedCategory.order = currentOrder;
    }
  } else {
    // If enabled is false, remove the pinned category order and decrease all other numeric orders by 1
    if (typeof currentOrder === 'number') {
      mergedInterests[interestName].options.pinnedCategory.order = '';
      for (const [key, value] of Object.entries(mergedInterests)) {
        if (key !== interestName) {
          const { enabled, order } = value.options.pinnedCategory;
          if (enabled && !isNaN(order) && order > currentOrder) {
            value.options.pinnedCategory.order = order - 1;
          }
        }
      }
    }
  }

  // Update the enabled status
  mergedInterests[interestName].options.pinnedCategory.enabled = isEnabled;

  setLocalConfig(localConfig);

  return true;
};

// Function to compare two objects
const objectsAreEqual = (obj1, obj2) => {
  return Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
};

const updateLocalConfigValues = (localConfig, newValues) => {
  localConfig = getLocalConfig(localConfig);

  const mergeObjects = (obj1, obj2) => {
    const merged = { ...obj1 };

    for (const [key, value] of Object.entries(obj2)) {
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        merged[key] = mergeObjects(obj1[key] || {}, value);
      } else if (Array.isArray(value)) {
        merged[key] = [...(obj1[key] || []), ...value];
      } else {
        merged[key] = value;
      }
    }

    return merged;
  };

  const mergedConfig = mergeObjects(localConfig, newValues);

  if (!objectsAreEqual(localConfig, mergedConfig)) {
    setLocalConfig(mergedConfig);
    return mergedConfig;
  }

  return localConfig;
};

// Function to update local config interests
const updateInterests = (localConfig, interests = [], locations = [], expandedArray = []) => {
  localConfig = getLocalConfig(localConfig);

  const updatedInterests = {};
  interests.forEach((interestName) => {
    const existingInterest = localConfig.interests[interestName];
    const expandedSearch = expandedArray.includes(interestName) ? true : false;
    const displayName = `${existingInterest?.name ? existingInterest.name : (interestName ?? '')}`.replace(/[- ]/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    const updatedInterest = {
      displayName: displayName,
      name: interestName,
      options: {
        expandedSearch,
        locations: locations ?? [],
        pinnedCategory: {
          enabled: existingInterest?.options.pinnedCategory.enabled ?? false,
          order: existingInterest?.options.pinnedCategory.order ?? '',
        },
      },
    };
    updatedInterests[interestName] = updatedInterest;
  });

  const updatedConfig = {
    ...localConfig,
    interests: updatedInterests,
  };
  return updatedConfig;
};

const updateInterest = (
  interestName,
  locations = [],
  pinnedCategoryEnabled = false,
  pinnedCategoryOrder = '',
  expandedSearch = false
) => {
  let localConfig = getLocalConfig();
  if (!localConfig) return;

  // Get locations if not provided
  if (locations.length === 0) {
    locations = getUniqueLocations(localConfig);
  }

  // Check if the interest already exists
  const interestExists = Object.prototype.hasOwnProperty.call(
    localConfig.interests,
    interestName
  );

  const displayName = `${interestName ?? ''}`.replace(/[- ]/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());

  if (interestExists) {
    // Remove the existing interest
    delete localConfig.interests[interestName];
  } else {
    // Create a new interest
    const newInterest = {
      displayName: displayName,
      name: interestName,
      options: {
        expandedSearch,
        locations,
        pinnedCategory: {
          enabled: pinnedCategoryEnabled,
          order: pinnedCategoryOrder,
        },
      },
    };

    localConfig.interests[interestName] = newInterest;
  }

  setLocalConfig(localConfig);

  return !interestExists; // Return true if created, false if deleted
};

const updateInterestProperty = (interestName, interestProperty, value, localConfig) => {
  localConfig = getLocalConfig(localConfig);
  if (!localConfig) return false;

  if (Object.prototype.hasOwnProperty.call(localConfig.interests, interestName)) {
    localConfig.interests[interestName].options[interestProperty] = value;
    setLocalConfig(localConfig);
  }
};

const createAndStoreLocalConfig = (locations, interests, termsAccepted, newslettersPermission, newsletterFrequency, marketingPermission, pushNotification) => {
  let localConfig = getLocalConfigOrDefault();

  localConfig.consents.personalisedExperience = true;
  termsAccepted ? (localConfig.consents.termsAccepted = termsAccepted) : false;

  marketingPermission ? (localConfig.consents.marketingPermission = marketingPermission) : false;
  newslettersPermission ? (localConfig.consents.newslettersPermission = newslettersPermission) : false;
  pushNotification ? (localConfig.consents.pushNotificationsPermission = pushNotification) : false;
  newslettersPermission ? (localConfig.notifications.email = newslettersPermission) : false;
  newsletterFrequency ? (localConfig.notifications.frequency = newsletterFrequency) : 'daily';
  pushNotification ? (localConfig.notifications.push = pushNotification) : false;
  localConfig.createdDate = createTimestamp();

  const authUser = localStorage.getItem('auth-user');
  if (authUser) {
    const parsedAuthUser = JSON.parse(authUser);
    if (parsedAuthUser && parsedAuthUser.Uid) {
      localConfig.userId = parsedAuthUser.Uid;
    }
  }

  if (interests.length > 0) {
    interests.forEach((category) => {
      const displayName = `${category ?? ''}`.replace(/[- ]/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
      localConfig.interests[category] = {
        displayName: displayName,
        name: category,
        options: {
          expandedSearch: false,
          locations: locations,
          pinnedCategory: {
            enabled: false,
            order: '',
          },
        },
      };
    });
  }

  if (!localConfig.mantisId) {
    localConfig.mantisId = getCookie('mantisid');
  }

  setLocalConfig(localConfig);
  return localConfig;
};

const sortInterestsByPinnedCategoryOrder = (localConfig) => {
  localConfig = getLocalConfig(localConfig);
  if (!localConfig || !localConfig.interests) {
    return false;
  }

  const interests = localConfig.interests;
  const userDefinedCategories = localConfig.savedSearches;
  const mergedInterests = { ...interests, ...userDefinedCategories };
  const pinnedInterests = [];

  // Iterate through all interests and check if they have a pinned category enabled
  for (const [key, value] of Object.entries(mergedInterests)) {
    const { enabled, order } = value.options.pinnedCategory;
    if (enabled && order !== '') {
      pinnedInterests.push(key);
    }
  }
  // Sort pinned interests by their pinned category order
  pinnedInterests.sort((a, b) => {
    const aOrder = mergedInterests[a].options.pinnedCategory.order;
    const bOrder = mergedInterests[b].options.pinnedCategory.order;

    // If both orders are numeric, sort by ascending order
    if (!isNaN(aOrder) && !isNaN(bOrder)) {
      return aOrder - bOrder;
    }
    // If only one order is numeric, sort the numeric one first
    else if (!isNaN(aOrder)) {
      return -1;
    } else if (!isNaN(bOrder)) {
      return 1;
    }
    // If neither order is numeric, sort by interest name
    else {
      return a.localeCompare(b);
    }
  });

  // Append non-pinned interests to the end of the array
  for (const key in mergedInterests) {
    if (!pinnedInterests.includes(key)) {
      pinnedInterests.push(key);
    }
  }

  return pinnedInterests;
};

const readOldLocalConfigs = () => {
  const localConfig = {
    consents: {
      personalisedExperience: false,
      termsAccepted: false,
    },
    exclusions: [],
    interests: {},
  };

  const categoriesConfig = localStorage.getItem('categories-config');
  if (categoriesConfig) {
    const categories = categoriesConfig.split(',');
    if (categories.length > 0) {
      localConfig.consents.personalisedExperience = true;
      localConfig.createdDate = createTimestamp();

      categories.forEach((categoryName) => {
        const displayName = `${categoryName ?? ''}`.replace(/[- ]/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
        localConfig.interests[categoryName] = {
          displayName: displayName,
          name: categoryName,
          options: {
            expandedSearch: false,
            locations: [],
            pinnedCategory: {
              enabled: false,
              order: '',
            },
          },
        };
      });

      const expandedSearchConfig = localStorage.getItem('expanded-search-config');
      if (expandedSearchConfig) {
        const expandedSearch = expandedSearchConfig.split(',');
        if (expandedSearch.length > 0) {
          expandedSearch.forEach((category) => {
            if (localConfig.interests[category]) {
              localConfig.interests[category].options.expandedSearch = true;
            }
          });
        }
      }

      const pinnedCategoriesConfig = localStorage.getItem('pinned-categories-config');
      let orderNumber = 0;
      if (pinnedCategoriesConfig) {
        const pinnedCategories = pinnedCategoriesConfig.split(',');
        if (pinnedCategories.length > 0) {
          pinnedCategories.forEach((category) => {
            if (localConfig.interests[category]) {
              localConfig.interests[category].options.pinnedCategory = {
                enabled: true,
                order: (orderNumber = orderNumber + 1),
              };
            }
          });
        }
      }

      const locationConfig = localStorage.getItem('location-config');
      if (locationConfig) {
        const locations = locationConfig.split(',');
        if (locations.length > 0) {
          categories.forEach((category) => {
            localConfig.interests[category].options.locations = locations;
          });
        }
      }
    }
  }

  const exclusionsConfig = localStorage.getItem('exclusions-config');
  if (exclusionsConfig) {
    localConfig.exclusions = exclusionsConfig.split(',');
  }

  const consentConfig = localStorage.getItem('consent-config');
  if (consentConfig === 'true') {
    localConfig.consents.termsAccepted = true;
  }

  const authUser = localStorage.getItem('auth-user');
  if (authUser) {
    const parsedAuthUser = JSON.parse(authUser);
    if (parsedAuthUser && parsedAuthUser.Uid) {
      localConfig.userId = parsedAuthUser.Uid;
    }
  }

  const mantisId = getCookie('mantisid');
  if (mantisId) {
    localConfig.mantisId = mantisId;
  }

  return localConfig;
};

const removeOldLocalConfigs = () => {
  localStorage.removeItem('pinned-categories-config');
  localStorage.removeItem('location-config');
  localStorage.removeItem('categories-config');
  localStorage.removeItem('expanded-search-config');
  localStorage.removeItem('consent-config');
  localStorage.removeItem('exclusions-config');
};

const migrateConfigFromOld = () => {
  const categoriesConfig = localStorage.getItem('categories-config');
  const locationConfig = localStorage.getItem('location-config');
  if (categoriesConfig && locationConfig) {
    try {
      let oldConfig = readOldLocalConfigs();
      let localConfig = getLocalConfigOrDefault();
      localConfig = updateLocalConfigValues(localConfig, oldConfig);
      removeOldLocalConfigs();
      return localConfig;
    } catch (e) {
      console.log(e);
    }
  }
};

export {
  getLocalConfig,
  getLocalConfigOrDefault,
  setLocalConfig,
  setUserIdFromLocalStorage,
  createAndStoreLocalConfig,
  updateLocalConfigValues,
  updateInterests,
  updateInterest,
  migrateConfigFromOld,
  getInterestPropertiesByValue,
  getInterestProperty,
  getDisplayName,
  getInterestKeys,
  getUniqueLocations,
  updateInterestProperty,
  removeLocalConfig,
  sortInterestsByPinnedCategoryOrder,
  setPinnedCategory,
  isValidConfig,
  getMergedInterests,
  isPersonalisedExperience,
};
