import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Interest from '../../components/myAccount/personalisation';
import CommunicationPreferences from '../../components/myAccount/communicationPreferences';
import Profile from '../../pages/myProfile/myProfile';
import TooltipComponent from '../../components/tooltip/Tooltip';
import { isPersonalisedUser, isLoginRadiusUser } from '../../helpers/utils';
import { updateUserPreferences } from '../../services/api';
import { getFeatureFlag } from '../../helpers/configUtilities';
import { getLocalConfig, setLocalConfig } from '../../helpers/localConfigUtils';
import './userSetting.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box mt={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tooltipText = `Just to let you know, by temporarily pausing personalisation, your stored preferences
  may not be used for a little while. This includes, but is not limited to, your location, categories, saved
  searches, and customised recommendations. During this time, you may see content that is less tailored to
  your interests. However, we will still store your preferences, and you can easily re-enable personalisation
  to use them again in the future.`;

export default function UserSetting() {
  const localConfig = getLocalConfig();
  const isAuth = isLoginRadiusUser();
  const isPersonalised = isPersonalisedUser();
  const communicationPreferencesEnabled = getFeatureFlag('communicationPreferencesEnabled');

  const [value, setValue] = React.useState(0);
  const [personalisedNews, setPersonalisedNews] = useState(localConfig?.consents.personalisedExperience ?? false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  let navigate = useNavigate();

  const handleChange = (event, newValue) => {
    if (isAuth && !isPersonalised) {
      const goToSignUp = (!communicationPreferencesEnabled && newValue === 1) || (communicationPreferencesEnabled && newValue === 2);
      if (goToSignUp) {
        navigate('/signup/interests');
      }
    }
    setValue(newValue);
  };

  const handlePersonalisationChange = () => {
    localConfig.consents.personalisedExperience = !personalisedNews;
    setLocalConfig(localConfig);

    if (localStorage.getItem('auth-user')) {
      updateUserPreferences();
    }
    setPersonalisedNews((curr) => !curr);
    navigate(0);
  };

  useEffect(() => {
    document.title = 'Settings | My News Assistant';
  }, []);

  return (
    <div className="standard-layout settings-page">
      <main>
        <div className="bordered-container">
          <div className="flex-row">
            <Typography variant="h1">Settings</Typography>
            <div className="flex-row">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={personalisedNews}
                      onChange={handlePersonalisationChange}
                      color="primary"
                      name="PersonalisedNews"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label="Personalisation"
                  sx={{ mr: 'unset' }}
                  className="label"
                />
              </FormGroup>
              <TooltipComponent placement={'bottom-end'} title={tooltipText} open={tooltipOpen} close={() => setTooltipOpen(false)}>
                <IconButton onClick={() => setTooltipOpen(true)}>
                  <ErrorOutlineOutlinedIcon fontSize="small" color="osloGrey" />
                </IconButton>
              </TooltipComponent>
            </div>
          </div>
          {isAuth ? (
            <>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Personal Details & Security" className="settings-tab" {...a11yProps(0)} />
                {communicationPreferencesEnabled && <Tab label="Preferences" className="settings-tab" {...a11yProps(1)} />}
                <Tab label="My Interests" className="settings-tab" {...a11yProps(communicationPreferencesEnabled ? 2 : 1)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <Profile />
              </TabPanel>
              {communicationPreferencesEnabled && (
                <TabPanel value={value} index={1}>
                  <CommunicationPreferences />
                </TabPanel>
              )}
              <TabPanel value={value} index={communicationPreferencesEnabled ? 2 : 1}>
                <Interest personalisedNews={personalisedNews} setPersonalisedNews={setPersonalisedNews} />
              </TabPanel>
            </>
          ) : (
            <>
              <Tabs value={0} onChange={handleChange}>
                <Tab label="My Interests" className="settings-tab" {...a11yProps(0)} />
              </Tabs>
              <TabPanel value={0} index={0}>
                <Interest personalisedNews={personalisedNews} setPersonalisedNews={setPersonalisedNews} />
              </TabPanel>
            </>
          )}
        </div>
      </main>
    </div>
  );
}
