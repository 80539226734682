import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import './static.scss';

const Social = () => {
  useEffect(() => {
    document.title = 'Our Social Networks | My News Assistant';
    window.scrollTo(0, 0);
  });

  return (
    <div className="standard-layout static-page">
      <main>
        <section className="static-content">
          <Typography variant="h1bold" color="primary">
            Follow Us On Our Social Networks
          </Typography>
          <div className="card">
            <span className="card-icon">
              <TwitterIcon className="twitter social-icon" />
            </span>
            <a href="https://www.twitter.com/mynewsassistant">@mynewsassistant</a>
          </div>
            <div className="card">
              <span className="card-icon">
                <InstagramIcon className="instagram social-icon" />
              </span>
              <a href="https://www.instagram.com/mynewsassistant/">mynewsassistant</a>
            </div>
            <div className="card">
              <span className="card-icon">
                <FacebookIcon className="facebook social-icon" />
              </span>
              <a href="https://www.facebook.com/mynewsassistant">mynewsassistant</a>
            </div>
        </section>
      </main>
    </div>
  );
};

export default Social;
