import './speechBubble.scss';
import { getFeatureFlag } from '../../helpers/configUtilities';

const showSpeechBubble = (text, href) => {
  const SpeechBubbleEnabled = getFeatureFlag('SpeechBubbleEnabled');
  
  // Check if speech bubble should be displayed
  if (!SpeechBubbleEnabled || sessionStorage.getItem('speechBubbleShown')) {
    return;
  }

  // Check if screen width is greater than 729px
  const isMobileView = window.innerWidth < 729;
  if (isMobileView) {
    return;
  }

  // Create speech bubble
  const speechBubble = document.createElement('div');
  speechBubble.setAttribute('id', 'mna-speech-bubble');
  document.body.appendChild(speechBubble);

  // Animate text in speech bubble
  const message = text;
  let index = 0;
  const timer = setInterval(() => {
    if (index < message.length) {
      speechBubble.innerHTML += message.charAt(index);
      index++;
    } else {
      clearInterval(timer);
      speechBubble.innerHTML += '<br><br><button id="mna-yes-button" class="mna-speech-bubble-button">Yes</button><button id="mna-no-button" class="mna-speech-bubble-button">No</button>';
      const yesButton = document.getElementById('mna-yes-button');
      const noButton = document.getElementById('mna-no-button');
      yesButton.addEventListener('click', () => {
        sessionStorage.setItem('speechBubbleShown', true);
        window.location.href = href;
      });
      noButton.addEventListener('click', () => {
        speechBubble.classList.remove('visible');
        setTimeout(() => {
          speechBubble.style.display = 'none';
          sessionStorage.setItem('speechBubbleShown', true);
        }, 500);
      });
      speechBubble.style.cursor = 'default';
    }
  }, 50);

  // Show speech bubble when page is loaded
  window.addEventListener('load', function() {
    setTimeout(() => {
      speechBubble.classList.add('visible');
    }, 100);
  });

  // Hide speech bubble with fade out animation when clicked anywhere outside it
  document.addEventListener('click', event => {
    if (!speechBubble.contains(event.target)) {
      speechBubble.classList.remove('visible');
      setTimeout(() => {
        speechBubble.style.display = 'none';
        sessionStorage.setItem('speechBubbleShown', true);
      }, 500);
    }
  });
};

export default showSpeechBubble;