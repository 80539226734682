import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { isPersonalisedUser, isLoginRadiusUser } from '../../helpers/utils';

export default function LoginSuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Lets make it personal | My News Assistant';
    window.scrollTo(0, 0);
  }, []);

  const navigateHome = () => {
    navigate('/');
  };

  const navigatePersonalisation = () => {
    navigate('/signup');
  };

  return (
    <div id='container' className='bordered-container'>
      <Typography variant='h1'>
        {isLoginRadiusUser() && isPersonalisedUser() ? 'Your All Set!' : 'Your Account'}
      </Typography>
      <Container disableGutters className='content-text'>
        {isLoginRadiusUser() && isPersonalisedUser() ? (
          <Typography variant='body1'>
            Your personalised news feed awaits you. If you change devices, like if you lose your phone or get a new laptop, you can get your personalised
            preferences back by signing into your account.
          </Typography>
        ) : (
          <Typography variant='body1'>
            Your My News Assistant account is all set! However you still need to personalise your experience.
          </Typography>
        )}
      </Container>
      <Container className='flex-btn'>
        <Button
          onClick={isLoginRadiusUser() && isPersonalisedUser() ? navigateHome : navigatePersonalisation}
          className='btn'
          color='primary'
          variant='contained'
        >
          Done
        </Button>
      </Container>
    </div>
  );
}
