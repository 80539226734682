import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';


import './categoryNotFound.scss';
import { avoid } from '../../helpers/utils';
import { getInterestProperty, updateInterestProperty } from '../../helpers/localConfigUtils';

export default function CategoryNotFound({ categoryName }) {
  // GA4 tracking via gtag.js
  const gtag = window.gtag || avoid;
  let isArticleNotFound = false;
  const theme = useTheme()

  const isExpandedUser = (categoryName) => {
    const expandedConfig = getInterestProperty('expandedSearch', categoryName);
    if (expandedConfig) {
      isArticleNotFound = true;
      return isArticleNotFound;
    }
  };

  const expandSearch = () => {
  updateInterestProperty(categoryName, 'expandedSearch', true);
  }

  return (
    <div className="notfound--container" key={categoryName} data-testid="category-notfound">
      <Typography variant="h2bold" sx={{ color: theme.palette.primary.main }}>
        nothing to see here - yet
      </Typography>
      <Typography variant='body1' className="notFoundContent">
        Try adjusting your{' '}
        <Link aria-label="Adjust your preferences on settings page" className="link-preferences" to="/settings">
          preferences
        </Link>{' '}
        {!isExpandedUser(categoryName) && (
          <span>
            or click{' '}
            <Link className="click-here" id={`${categoryName}`} aria-label="Expand search to our full publication library " onClick={expandSearch} to="/">
              here
            </Link>{' '}
            to expand your search to our full publication library.
          </span>
        )}
      </Typography>
    </div>
  );
}
