import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import './static.scss';

const ContactUs = () => {
  useEffect(() => {
    document.title = 'Contact Us | My News Assistant';
    window.scrollTo(0, 0);
  });

  return (
    <div className="standard-layout static-page">
      <main>
        <section className="static-content">
          <Typography variant="h1bold" color="primary">
            Contact Us
          </Typography>
          <section>
            <Typography variant="h4bold" color="primary">
              General enquiries
            </Typography>
            <address>Call the switchboard <a href='tel:020 7293 3000'>020 7293 3000</a></address>
          </section>
          <section>
            <Typography variant="h4bold" color="primary">
              My News Assistant website team
            </Typography>
            <address>Email <a href="mailto:hello@mynewsassistant.com">hello@mynewsassistant.com</a></address>
          </section>
          <section>
            <Typography variant="h4bold" color="primary">
              Buy or reproduce an article or photo
            </Typography>
            <address>Call <a href='tel:020 7293 3700'>020 7293 3700</a> or email <a href="mailto:desk@mirrorpix.com">desk@mirrorpix.com</a></address>
          </section>
          <section>
            <Typography variant="h4bold" color="primary">
              Contact the Advertising team
            </Typography>
            <p>
              Reach Solutions - <a href="https://www.reachsolutions.co.uk/">https://www.reachsolutions.co.uk/</a>
            </p>
            <p>
              Digital advertising - Leslie Pacome at <a href="mailto:leslie.pacome@reachplc.com">leslie.pacome@reachplc.com</a>
            </p>
            <p>
              Digital commercial partnerships - Russell Carter on <a href="mailto:partnerships@reachplc.com">partnerships@reachplc.com</a>
            </p>
            <p>
              Reader offer &amp; e-commerce partnerships (Products, Services &amp; Travel) - Karen Brodie on <a href="tel:0207 510 6055">0207 510 6055</a> or{' '}
              <a href="karen.brodie@reachplc.com">karen.brodie@reachplc.com</a>
            </p>
          </section>
          <section>
            <Typography variant="h4bold" color="primary">
              Postal address
            </Typography>
            <address>One Canada Square, London, E14 5AP</address>
          </section>
        </section>
      </main>
    </div>
  );
};

export default ContactUs;
