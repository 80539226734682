import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { getCategoryByName } from '../../config/categories';
import { getRouteByName } from '../../config/routeConfig';
import { constructPath } from '../../helpers/utils';

import BreadcrumbsAndSocialSharingContainer from '../../components/breadcrumbsAndSocialSharing/breadcrumbsAndSocialSharing';
import SectionPage from '../section/sectionPage';
import CategoryPage from '../category/categoryPage';

const RouteHandler = () => {
  const { sectionSlug, subSectionSlug, categorySlug } = useParams();

  const lastParam = categorySlug || subSectionSlug || sectionSlug;
  const sectionConfig = getRouteByName(lastParam);
  const categoryConfig = getCategoryByName(lastParam);
  const expectedSectionURLPath = constructPath(sectionConfig?.sectionSlug, sectionConfig?.subSectionSlug);
  const expectedCategoryURLPath = constructPath(categoryConfig?.sectionSlug, categoryConfig?.subSectionSlug, categoryConfig?.categorySlug);
  const actualURLPath = constructPath(sectionSlug, subSectionSlug, categorySlug);
  const isExpectedCategoryMatch = expectedCategoryURLPath === actualURLPath;
  const isExpectedSectionMatch = expectedSectionURLPath === actualURLPath;
  const config = isExpectedCategoryMatch ? categoryConfig : sectionConfig;
  const Component = isExpectedCategoryMatch ? CategoryPage : SectionPage ;

  if (!isExpectedCategoryMatch && !isExpectedSectionMatch) {
    return <Navigate to={'/error/404'} />;
  }

  return (
    <>
      <BreadcrumbsAndSocialSharingContainer />
      <Component sectionSlug={sectionSlug} subSectionSlug={subSectionSlug} categorySlug={categorySlug} config={config} />
    </>
  ) 
};

export default RouteHandler;
