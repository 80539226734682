import { useMemo } from 'react';
import { getCategoryByType, getCategoryByName } from '../../config/categories.js';
import { getLocalConfig, getInterestKeys } from '../../helpers/localConfigUtils.js';
import { getSavedSearches } from '../../helpers/savedSearchUtils.js';

const useSavedCategories = () => {
  const localConfig = getLocalConfig();
  const primaryCategories = getCategoryByType();
  const categoriesMap = new Map(Object.entries(primaryCategories));
  const primaryCategoriesArray = Array.from(categoriesMap.values());
  const storedSavedSearches = getSavedSearches();
  const storedCategories = getInterestKeys();

  const getEnabledCategoryConfig = (categoryKey) => {
    const categoryConfig = getCategoryByName(categoryKey);
    if (categoryConfig && categoryConfig.isEnabled !== false) {
      return categoryConfig;
    }
    return null;
  };

  const savedSearches = useMemo(() => {
    return localConfig?.consents.personalisedExperience && storedSavedSearches ? storedSavedSearches : {};
  }, [localConfig, storedSavedSearches]);

  const savedCategories = useMemo(() => {
    return localConfig?.consents.personalisedExperience && storedCategories
      ? storedCategories.map((categoryKey) => getEnabledCategoryConfig(categoryKey)).filter(Boolean)
      : primaryCategoriesArray;
  }, [localConfig, storedCategories]);

  return { savedCategories, savedSearches };
};

export default useSavedCategories;
