import axios from 'axios';
import { getCookie } from '../helpers/pageUtilities';
import { getEnvConfig } from '../helpers/utils';
import { getLocalConfig, setLocalConfig, removeLocalConfig } from '../helpers/localConfigUtils';

const envConfig = getEnvConfig();

async function fetchDataFromAPI({
  age,
  concepts,
  domains,
  entities,
  exclude_concepts,
  exclude_entities,
  exclude_mantis_categories,
  exclude_tags,
  excludedDomains,
  mantisCategories,
  pageLimit,
  pageStart,
  tags
}) {
  age = !isNaN(age) ? age : 15;
  const mantis_url = envConfig.SEARCH_API;

  // Create an object to store the defined parameters
  const queryParams = {
    age,
    concepts,
    domains,
    entities,
    exclude_concepts,
    exclude_entities,
    exclude_mantis_categories,
    exclude_tags,
    excluded_domains: excludedDomains,
    mantis_categories: mantisCategories,
    page_limit: pageLimit,
    start: pageStart,
    tags
  };

  // Filter out undefined or null values
  const definedParams = Object.fromEntries(Object.entries(queryParams).filter(([_, v]) => v !== undefined && v !== null && v !== ''));

  try {
    const response = await axios.get(`${mantis_url}?${new URLSearchParams(definedParams)}`);
    return response?.data;
  } catch (error) {
    return [];
  }
}

const fetchTermData = async (exactTerm, orTerm, excludeTerm, mantisCategories, tags, domains, pageStart, pageLimit, value, excludedDomains, author) => {
  const mna_url = envConfig.SEARCH_API;
  try {
    const response = await axios.get(
      `${mna_url}?search_text_all=${exactTerm}&search_text=${orTerm}&search_text_none=${excludeTerm}&mantis_categories=${mantisCategories}&tags=${tags}&domains=${domains}&excluded_domains=${excludedDomains}&author=${author}&start=${pageStart}&limit=${pageLimit}&sort=${value}&indexAlias=12-months`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

const fetchViafouraData = async (viafouraId, viafouraPayload) => {
  if (!viafouraId)
    return {
      commentCount: 0,
    };

  const commentsApiUrl = 'https://livecomments.viafoura.co';
  const apiUrl = `${commentsApiUrl}/v4/livecomments/${viafouraId}/content-containers/comment-count-and-status/by-container-ids`;
  const data = JSON.stringify(viafouraPayload);
  const headers = { Accept: 'application/json', 'Content-Type': '*/*' };
  try {
    const response = await axios.post(apiUrl, data, headers);
    return response?.data[0];
  } catch (error) {
    console.log(error);
  }
};

function getLoginradiusId() {
  const userInfo = localStorage.getItem('auth-user');
  return (userInfo && JSON.parse(userInfo).Uid) || '';
}

function getLoginRadiusIdType() {
  return {
    idType: 'loginradiusId',
    idValue: getLoginradiusId(),
  };
}

function getMantisIdType() {
  return {
    idType: 'mantisId',
    idValue: getCookie('mantisid'),
  };
}

function getIds() {
  const ids = [];
  ids.push(getMantisIdType());
  const loginRadiusIdType = getLoginRadiusIdType();
  if (loginRadiusIdType.idValue) {
    ids.push(loginRadiusIdType);
  }
  return ids;
}

function getApiToken() {
  const strarr = 'zyxwvutsrqponmlkjihgfedcba'.split('');
  const time = new Date().getTime();
  const token = (time + '')
    .split('')
    .map((char) => strarr[char])
    .join('');
  return token;
}

function getDefaultHeaderParams() {
  return {
    token: getApiToken(),
  };
}
function getDataOptions() {
  return {
    method: 'GET',
    headers: Object.assign(
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      getDefaultHeaderParams()
    ),
  };
}

function getPostDataOptions() {
  return {
    method: 'POST',
    headers: Object.assign(
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      getDefaultHeaderParams()
    ),
  };
}

function getPutDataOptions() {
  return {
    method: 'PUT',
    headers: Object.assign(
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      getDefaultHeaderParams()
    ),
  };
}

const registerUser = async () => {
  const data = {
    ids: getIds(),
    profile: getLocalConfig(),
    profileType: 'my-news-assistant',
  };

  const options = getPostDataOptions();
  const registerUrl = envConfig.HS_BASE_URL;
  return await axios.post(registerUrl, JSON.stringify(data), options);
};

function getProfileType() {
  return 'my-news-assistant';
}

function paramsToQueryString(paramObj) {
  const params = new URLSearchParams(paramObj);
  return params.toString();
}

function addParameters(url, paramObj) {
  if (paramObj && Object.keys(paramObj).length) {
    url += `?${paramsToQueryString(paramObj)}`;
  }
  return url;
}

function getApiUrl(path = '', paramObj = {}) {
  const baseUrl = envConfig.HS_BASE_URL;
  const pathWithParams = addParameters(path, paramObj);
  const { href } = new URL(pathWithParams, baseUrl);
  return href;
}

const getUserPreferences = async () => {
  const { idType, idValue } = getLoginRadiusIdType();
  const userRegisteredApiUrl = getApiUrl('mna-user', { idType, idValue, profileType: getProfileType() });
  const opts = getDataOptions();

  try {
    const userData = await axios.get(userRegisteredApiUrl, opts);
    if (userData) {
      const userProfile = userData.data.user?.UserProfiles[0];
      return userProfile;
    }
  } catch (error) {
    console.error('Error requesting user preferences:', error);
    throw error;
  }
};

const updateUserPreferences = async () => {
  const data = {
    ids: getIds(),
    profileType: 'my-news-assistant',
  };

  const userPreferences = getLocalConfig();
  if (userPreferences && Object.keys(userPreferences).length != 0) {
    data.profile = userPreferences;
  }

  const options = getPostDataOptions();
  const registerUrl = envConfig.HS_BASE_URL;

  try {
    const result = await axios.post(registerUrl, JSON.stringify(data), options);

    if (result.data.profile?.length && result.data.profile[0].profile_info) {
      const profileInfo = result.data.profile[0].profile_info;
      Object.keys(profileInfo).length != 0 && setLocalConfig(profileInfo);
    }
    return result;
  } catch (error) {
    console.error('Error updating user preferences:', error);
    throw error;
  }
};

export { fetchDataFromAPI, fetchTermData, fetchViafouraData, registerUser, updateUserPreferences, getUserPreferences };
