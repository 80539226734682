import React, { useState, useEffect } from 'react';
import { trackableURL, createSocialSharingLinks } from '../../../helpers/utils';
import Typography from '@mui/material/Typography';
import '@fortawesome/fontawesome-free/css/all.css';
import './ShareButtons.scss';

const ShareButtons = ({ shareUrl, title }) => {
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => setIsDesktopView(window.innerWidth > 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  shareUrl = trackableURL(shareUrl, 'social_shares');

  const {
    facebookLink,
    messengerLink,
    twitterLink,
    linkedinLink,
    pinterestLink,
    emailLink,
    whatsappLink,
  } = createSocialSharingLinks(shareUrl, title);

  return (
    <div className="share-container">
      <Typography variant="h6" sx={{ mb: 4 }}>Share with others</Typography>
      <div className="share-buttons">
        {facebookLink && (
          <a href={facebookLink} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i>
            <span className="share-text">Share on Facebook</span>
            <p>Facebook</p>
          </a>
        )}
        {messengerLink && (
          <a href={messengerLink} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-messenger"></i>
            <span className="share-text">Share on Messenger</span>
            <p>Messenger</p>
          </a>
        )}
        {twitterLink && (
          <a href={twitterLink} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
            <span className="share-text">Share on Twitter</span>
            <p>Twitter</p>
          </a>
        )}
        {linkedinLink && (
          <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
            <span className="share-text">Share on LinkedIn</span>
            <p>LinkedIn</p>
          </a>
        )}
        {isDesktopView && pinterestLink && (
          <a href={pinterestLink} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-pinterest"></i>
            <span className="share-text">Pin it</span>
            <p>Pinterest</p>
          </a>
        )}
        {whatsappLink && (
          <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-whatsapp"></i>
            <span className="share-text">Share on WhatsApp</span>
            <p>WhatsApp</p>
          </a>
        )}
        {emailLink && (
          <a href={emailLink} target="_blank" rel="noopener noreferrer">
            <i className="far fa-envelope"></i>
            <span className="share-text">Email</span>
            <p>Email</p>
          </a>
        )}
      </div>
    </div>
  );
};

export default ShareButtons;
