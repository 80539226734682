import React from 'react';
import './teaser.scss';
import { useState, useEffect } from 'react';
import { fetchViafouraData } from '../../services/api';
import { getViafouraData } from '../../helpers/viafouraUtils';
import { useNavigate } from 'react-router-dom';
import { trackableURL, truncateString, getNameFromURL, getLogoFromURL } from '../../helpers/utils';
import commentBubble from '../../assets/Comment-Bubble.png';
import infoIcon from '../../assets/Info.png';
import ReactCardFlip from 'react-card-flip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import KebabMenu from '../KebabMenu/KebabMenu';
import ResponsiveImage from '../responsiveImage/responsiveImage';
import mnaPlaceholder from '../../assets/mna_placeholder.jpg';

function TeaserIframe({ index, item, updateExcludedDomains }) {
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);
  const [commentCount, setCommentCount] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);

  let navigate = useNavigate();

  const { url } = item;
  const { viafouraId = 0, viafouraPayload = '' } = url ? getViafouraData(url) : {};
  const formattedDate = item.lastModified.slice(0, 10).split('-').reverse().join('.');
  const imageThumbnail = item.imageThumbnail ? item.imageThumbnail : mnaPlaceholder;

  const handleIsFlipped = (event) => {
    event.preventDefault();
    setIsFlipped(!isFlipped);
  };

  const updateMedia = () => {
    setIsDesktopView(window.innerWidth > 768);
  };

  function handleClick(item) {
    navigate('/article', {
      replace: true,
      state: {
        url: url,
      },
    });
  }

  useEffect(() => {
    fetchViafouraData(viafouraId, viafouraPayload).then((response) => {
      const commentCount = response.commentCount;
      if (commentCount && commentCount > 0) {
        setCommentCount(commentCount);
      }
    });
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, [isDesktopView]);

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <div>
        <div className="image-container">
          <div
            data-testid="iframeDiv"
            onClick={() => {
              handleClick(item);
            }}
          >
            <ResponsiveImage
              imageThumbnail={imageThumbnail}
              defaultImage={mnaPlaceholder}
              altText={item.title}
            />
          </div>
        </div>
        <div className="content-container">
          <div className="content-header">{truncateString(item.title, 90)}</div>
          <div className="toolbar-container">
          <div className="toolbar-icon">
            <div className="icon">
              <a href={trackableURL(url, 'teaser_description') + '#comments-wrapper'} target="_blank" rel="noopener noreferrer">
                <img className="commentBubble" src={commentBubble} alt="Comments" />
                <p className="commentText">{commentCount}</p>
              </a>
            </div>
            <div className="icon">
              <img className="infoIcon" src={infoIcon} alt="More Information" onClick={handleIsFlipped} />
            </div>
          </div>
          <div className="brand-logo">
            <a href={trackableURL(url, 'teaser_image')} target="_blank" rel="noopener noreferrer">
              <img className="logo" src={require(`../../assets/brands/${getLogoFromURL(url)}`)} alt={getNameFromURL(url)} />
            </a>
            <KebabMenu title={item.title} url={url} updateExcludedDomains={updateExcludedDomains} />
          </div>
        </div>
        </div>
      </div>
      {/* back of the card */}
      <div className="listItemDesktop">
        <IconButton className="close-icon" onClick={handleIsFlipped}>
          <CloseIcon />
        </IconButton>
        <div className="flipped-header">
          <strong>Headline: </strong>
          {item.title}
        </div>
        <div className="flipped-header">
          <strong>Publish Date: </strong>
          {formattedDate}
        </div>
      </div>
    </ReactCardFlip>
  );
}

export default TeaserIframe;
