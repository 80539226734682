import React from 'react';
import { Link } from 'react-router-dom';
import './emptyHomepage.scss';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

function EmptyHomepage() {

  const theme = useTheme();

  return (
    <div className="empty--homepage">
      <div className="empty--container">
        <div>
          <Typography variant="h1bold" sx={{ color: theme.palette.primary.main }}>
            nothing to see here - yet
          </Typography>
        </div>
        <Typography variant='h6'>
          Customise your interests on{' '}
          <Link className="link-preferences" to="/settings">
            the settings page
          </Link>{' '}
          from a list of curated categories or dive into{' '}
          <Link className="link-preferences" to="/search">
            the search page
          </Link>{' '}
          to explore and discover news that matches your personal preferences.{' '}
        </Typography>
      </div>
    </div>
  );
}

export default EmptyHomepage;
