import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './navbar.scss';
import logoimage from '../../assets/mna_logo.png';
import logoicon from '../../assets/mna_icon.png';
import { AppBar, Toolbar, Menu, MenuItem } from '@mui/material';
import TabBar from './tabBar/TabBar';
import { registerUser } from '../../services/api';
import { isPersonalisedUser, isLoginRadiusUser, navigationRoute, avoid } from '../../helpers/utils';
import { removeLocalConfig } from '../../helpers/localConfigUtils';
import { dispatchLoginRadiusEvent } from '../../helpers/loginRadiusEvents';
import showSpeechBubble from '../speechBubble/speechBubble';
import { getFeatureValue } from '../../helpers/configUtilities';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { ThemeProvider } from '@mui/material/styles';
import SearchPopup from '../../pages/searchPage/SearchPopup';
import DrawerMenu from './drawerMenu';
import TrendingBar from './TrendingBar/TrendingBar';
import { navbarTheme } from './navbarTheme';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDesktopView, setIsDesktop] = useState(window.innerWidth >= 768);
  const [changeProfileCount, setChangeProfileCount] = useState(0);
  const [showSearchPopup, setShowSearchPopup] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const gtag = window.gtag || avoid;
  const siteRoot = getFeatureValue('siteRoot');

  let isCompleteProfileTriggered = false;

  const updateMedia = () => {
    setIsDesktop(window.innerWidth >= 768);
  };

  const updateProfileCount = (event) => {
    const data = event.data || event.detail || {};
    if (data.UserName && !isCompleteProfileTriggered) {
      isCompleteProfileTriggered = true;
      setChangeProfileCount(changeProfileCount + 1);
    }
  };
  const loginRadiusRegisterUser = () => {
    registerUser();
    localStorage.removeItem('mna-init-signup-or-signin');
    navigate('/');
  };

  useEffect(() => {
    window.addEventListener('auth-ui:registration-verified', loginRadiusRegisterUser);
    window.addEventListener('loginradius.user.profile', updateProfileCount);
    window.addEventListener('resize', updateMedia);

    return () => {
      window.removeEventListener('resize', updateMedia);
      window.removeEventListener('auth-ui:registration-verified', loginRadiusRegisterUser);
      window.removeEventListener('loginradius.user.profile', updateProfileCount);
    };
  }, [changeProfileCount]);

  const getUserName = () => {
    if (localStorage.getItem('auth-user')) {
      const authUser = JSON.parse(localStorage.getItem('auth-user'));
      const FirstName = authUser.FirstName;
      const LastName = authUser.LastName;
      const Email = authUser.Email[0].Value;
      if (FirstName && LastName) {
        return FirstName.charAt(0).toUpperCase() + LastName.charAt(0).toUpperCase();
      } else {
        return Email.charAt(0).toUpperCase() + Email.charAt(1).toUpperCase();
      }
    }
  };

  const handleClickLogo = () => {
    navigate('/');
    window.location.reload();
  };

  const handleMenuClick = (event) => {
    isDesktopView ? setAnchorEl(event.currentTarget) : setIsMenuOpen(true);
  };

  const handleCloseMenu = () => {
    isDesktopView ? setAnchorEl(null) : setIsMenuOpen(false);
  };

  const handleSetting = () => {
    handleCloseMenu();
    navigationRoute(navigate, '/settings');
  };

  const handleLogOut = () => {
    dispatchLoginRadiusEvent('auth-ui.logout');
    gtag('event', 'user_loggedOut', {
      event: 'clicked',
      source: 'navbar',
    });
    removeLocalConfig();
    navigate('/');
    handleCloseMenu();
  };

  const handleLogin = () => {
    localStorage.setItem('mna-init-signup-or-signin', true);
    handleCloseMenu();
    if (isPersonalisedUser()) {
      dispatchLoginRadiusEvent('auth-ui.authenticate');
    } else {
      navigate('/signup');
    }
  };

  if (!isPersonalisedUser() && !(location.pathname === '/signup' || location.pathname === '/error/404')) {
    showSpeechBubble(
      "Hi there! Are you enjoying using My News Assistant? We've noticed that you haven't yet personalised your news feed. Did you know that you can use our AI-powered platform to curate news stories that match your interests and preferences? It's easy to get started - just click 'Yes' and we'll guide you through the process.",
      `${siteRoot}/signup?utm_source=mynewsassistant.com&utm_medium=referral&utm_campaign=speech_bubble_popup`
    );
  }

  const handleOpenSearch = () => {
    setShowSearchPopup(true);
  };
  const handleCloseSearch = () => {
    setShowSearchPopup(false);
  };

  return (
    <>
      <ThemeProvider theme={navbarTheme}>
        <AppBar position={'fixed'} color="timberGreen">
          <Toolbar>
            {isDesktopView ? (
              <>
                <div className="left">
                  <img src={logoimage} alt="My News Assistant logo" onClick={handleClickLogo} className="logo" />
                </div>
                <div className="right">
                  <button className="rightText" onClick={handleSetting} aria-label="Let's make it personal: Personalize">
                    Let's make it personal
                  </button>
                  <IconButton
                    aria-label="search"
                    onClick={handleOpenSearch}
                    sx={{ mx: 1, '&:focus-visible': { backgroundColor: 'common.black' }, '&:hover': { backgroundColor: 'common.black' } }}
                  >
                    <SearchIcon color="lightGray" fontSize="large" />
                  </IconButton>
                  {showSearchPopup && <SearchPopup show={showSearchPopup} handleClose={handleCloseSearch} />}
                  {isLoginRadiusUser() ? (
                    <span onClick={handleMenuClick} className="profileName">
                      {getUserName()}
                    </span>
                  ) : (
                    <IconButton
                      aria-label="Account Menu"
                      id="account-menu"
                      aria-controls="account-menu"
                      aria-haspopup="true"
                      onClick={handleMenuClick}
                      sx={{
                        borderRadius: '50%',
                        padding: '0',
                        fontSize: '2.8rem',
                        backgroundColor: 'common.white',
                        '&:hover': { backgroundColor: 'common.black' },
                        '&:focus-visible': { backgroundColor: 'common.black' },
                      }}
                    >
                      <AccountCircleIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  )}
                  <Menu id="account-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                    {location.pathname !== '/settings' && <MenuItem onClick={handleSetting}>Settings</MenuItem>}
                    <MenuItem
                      data-testid={`navbar-${isLoginRadiusUser() ? 'logout' : 'login'}-button`}
                      onClick={isLoginRadiusUser() ? handleLogOut : handleLogin}
                    >
                      {isLoginRadiusUser() ? 'Logout' : 'Login'}
                    </MenuItem>
                  </Menu>
                </div>
              </>
            ) : (
              <>
                <div className="left">
                  <DrawerMenu />
                  <img src={logoicon} alt="My News Assistant logo" onClick={handleClickLogo} className="logo" />
                </div>
                <div className="right">
                  <IconButton
                    aria-label="search"
                    onClick={handleOpenSearch}
                    sx={{ '&:focus-visible': { backgroundColor: 'common.black' }, '&:hover': { backgroundColor: 'common.black' } }}
                  >
                    <SearchIcon color="lightGray" fontSize="medium" />
                  </IconButton>
                  {showSearchPopup && <SearchPopup show={showSearchPopup} handleClose={handleCloseSearch} />}
                  {isLoginRadiusUser() ? (
                    <span onClick={handleMenuClick} className="profileName">
                      {getUserName()}
                    </span>
                  ) : (
                    <IconButton
                      aria-label="open account menu"
                      onClick={handleMenuClick}
                      sx={{
                        borderRadius: '50%',
                        padding: '0',
                        fontSize: '2.2rem',
                        backgroundColor: 'common.white',
                        '&:hover': { backgroundColor: 'common.black' },
                        '&:focus-visible': { backgroundColor: 'common.black' },
                      }}
                    >
                      <AccountCircleIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  )}
                  <Drawer anchor="right" open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
                    <Box sx={{ width: '297px' }} role="presentation">
                      <List dense sx={{ px: 1, py: 2.5 }}>
                        <ListItem disableGutters>
                          <ListItemButton onClick={handleSetting}>
                            <ListItemText className={location.pathname === '/settings' ? 'highlighted' : 'list-item'}>Settings</ListItemText>
                          </ListItemButton>
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemButton onClick={isLoginRadiusUser() ? handleLogOut : handleLogin}>
                            <ListItemText className="list-item">{isLoginRadiusUser() ? 'Logout' : 'Login'}</ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Box>
                  </Drawer>
                </div>
              </>
            )}
          </Toolbar>
          {isDesktopView ? (
            <nav aria-label="categorised news" role="navigation">
              <TabBar />
            </nav>
          ) : (
            <nav aria-label="trending news" role="navigation">
              <TrendingBar />
            </nav>
          )}
        </AppBar>
      </ThemeProvider>
    </>
  );
};

export default Navbar;
