import './App.scss';
import * as React from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Outlet, Route, Routes, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider } from '@mui/material/styles';
import {globalTheme} from '../src/common/globalTheme'
import UserSetting from './pages/userSetting/userSetting';
import MyProfile from './pages/myProfile/myProfileLR';
import RouteHandler from './pages/routeHandler/routeHandler';
import Home from './pages/home/homePage';
import UserDefinedCategoriesPage from './pages/userDefinedCategories/userDefinedCategories';
import MainLayout from './pages/layouts/mainLayout';
import EmbedLayout from './pages/layouts/embedLayout';
import ArticlePage from './pages/article/articlePage';
import ProfilePage from './pages/myProfile/myProfile';
import TermsAndConditions from './pages/static/termsAndConditions';
import PrivacyNotice from './pages/static/privacy';
import CookieNotice from './pages/static/cookie';
import ContactUs from './pages/static/contactUs';
import AboutUs from './pages/static/aboutUs';
import Social from './pages/static/social';
import Signup from './pages/signup/signup';
import NotFound from './pages/error/pageNotFound/pageNotFound';
import SearchPage from './pages/searchPage/searchPage';
import EmbedSearchPage from './pages/searchPage/embedSearchPage';
import Error from './pages/error/error';
import { LoginRadiusTracking } from './components/tracking/GAtracking';
import { Helmet } from 'react-helmet-async';
import { getFeatureValue } from './helpers/configUtilities';

const siteRoot = getFeatureValue('siteRoot');


const App = () => {
  useEffect(() => {
    LoginRadiusTracking();
  }, []);

  return (
    <>
      <div id="auth-ui"></div>
      <Helmet>
        <title>My News Assistant | The Ultimate News Aggregator</title>
        <link rel="canonical" href={siteRoot} />
        <meta
          name="description"
          content="Get the latest news hassle-free with My News Assistant, the ultimate news aggregator. Our platform offers a wealth of articles from trusted sources, updated daily and tailored to your interests. With just a few clicks, create a custom news feed and stay informed on everything from sports news to science updates. Say goodbye to multiple news websites - My News Assistant makes it easy to stay up-to-date. Try it now and experience the ultimate news aggregator – My News Assistant!"
        />
        <meta property="og:title" content="Get the Latest News Hassle-Free with My News Assistant" />
        <meta
          property="og:description"
          content="My News Assistant is the ultimate news aggregator that offers a wealth of articles from trusted sources, updated daily and tailored to your interests. Create a custom news feed with just a few clicks and stay informed on everything from sports news to science updates. Say goodbye to multiple news websites and experience the ultimate news aggregator – My News Assistant!"
        />
        <meta property="og:image" content={`${siteRoot}/static/assets/images/mna_twitter_image.jpg`} />
        <meta property="og:url" content={siteRoot} />

        <meta name="twitter:title" content="Get the Latest News Hassle-Free with My News Assistant" />
        <meta
          name="twitter:description"
          content="My News Assistant is the ultimate news aggregator that offers a wealth of articles from trusted sources, updated daily and tailored to your interests. Create a custom news feed with just a few clicks and stay informed on everything from sports news to science updates. Say goodbye to multiple news websites and experience the ultimate news aggregator – My News Assistant!"
        />
        <meta name="twitter:image" content={`${siteRoot}/static/assets/images/mna_twitter_image.jpg`} />
        <meta name="twitter:card" content={`${siteRoot}/static/assets/images/mna_twitter_card.jpg`} />
      </Helmet>
      <ThemeProvider theme={globalTheme}>
        <div className="App">
          <ErrorBoundary FallbackComponent={<Navigate to={'/error/404'} />}>
            <Routes>
              <Route element={<MainLayout />}>
                <Route path="/" element={<Home />} />
                <Route path="/feed/">
                  <Route path="" element={<Navigate to={'/'} />} />
                  <Route path="/feed/:savedSearchSlug" element={<UserDefinedCategoriesPage />} />
                </Route>
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/my-profile" element={<MyProfile />} />
                <Route path="/settings" element={<UserSetting />} />
                <Route path="/:sectionSlug">
                  <Route path="" element={<RouteHandler />} />
                  <Route path=":subSectionSlug">
                    <Route path="" element={<RouteHandler />} />
                    <Route path=":categorySlug" element={<RouteHandler />} />
                  </Route>
                  <Route path=":subSectionSlug/:categorySlug" element={<RouteHandler />} />
                </Route>
                <Route path="article" element={<ArticlePage />} />
                <Route path="/legal/">
                  <Route path="" element={<Navigate to={'/'} />} />
                  <Route path="/legal/terms-and-conditions" element={<TermsAndConditions />} />
                  <Route path="/legal/privacy-notice" element={<PrivacyNotice />} />
                  <Route path="/legal/cookie-notice" element={<CookieNotice />} />
                </Route>
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/social" element={<Social />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/error" element={<Error />}>
                  <Route path="404" element={<NotFound />} />
                </Route>
                <Route path="/signup/*" element={<Signup />}>
                  <Route path=":path" element={<Outlet />} />
                </Route>
                <Route path="*" element={<Navigate to={'/error/404'} />} />
              </Route>
              <Route path="/embed/*" element={<EmbedLayout />}>
                <Route path="" element={<Navigate to={'/error/404'} />} />
                <Route path="search" element={<EmbedSearchPage />} />
              </Route>
            </Routes>
          </ErrorBoundary>
        </div>
      </ThemeProvider>
    </>
  );
};

export default App;
