import { locations } from './locations';

const getPublicationKeys = () => {
  return Object.keys(publicationData);
};

const getPublications = () => {
  return publicationData;
};

const getPublicationMap = () => {
  let publications = [];
  Object.keys(publicationData).map((key) => {
    publications.push({ [key]: publicationData[key]['mantisID'] });
  });
  return publications;
};

const getAllPublicationNames = () => {
  let publicationNames = [];
  Object.keys(publicationData).map((publication) => {
    publicationNames.push(publicationData[publication].Name);
  });
  return publicationNames;
};

const retrieveDomainName = (mantisId) => {
  const matchingNames = Object.entries(publicationData)
    .filter(([key, value]) => value.mantisID === mantisId)
    .map(([key, value]) => value.Name);
  return matchingNames;
};

const mapLocationToDomain = (region) => {
  const domainsArray = [];

  if (region.length) {
    region.map((regionKey) => {
      locations.map((location) => {
        if (Object.keys(location).toString() === regionKey) {
          domainsArray.push(String(Object.values(location).toString().split(',').join(',')));
        }
      });
    });
  }

  return domainsArray;
};

const filterDomainsByKeys = (domains, existingDomainsArray) => {
  if (domains.length) {
    domains.map((domainKey) => {
      for (const domain in publicationData) {
        if (publicationData[domain].Name === domainKey) {
          if (existingDomainsArray.includes(publicationData[domain].mantisID)) {
            return;
          } else {
            existingDomainsArray.push(publicationData[domain].mantisID);
          }
        }
      }
    });
  }

  return existingDomainsArray;
};

const mantisIDsToNames = (mantisIDs) => {
  const namesArray = [];
  if (!mantisIDs) {
    return namesArray;
  }

  if (typeof mantisIDs === 'string') {
    mantisIDs = mantisIDs.split(',');
  }

  mantisIDs.forEach((mantisID) => {
    for (const domain in publicationData) {
      if (publicationData[domain].mantisID === mantisID) {
        namesArray.push(publicationData[domain].Name);
      }
    }
  });

  return namesArray;
};

const publicationData = {
  'aberdeenlive.news': {
    logo: 'logo-aberdeenlive.svg',
    mantisID: 'aberdeenlive',
    Name: 'Aberdeen Live',
  },
  'bedfordshirelive.co.uk': {
    logo: 'logo-bedfordshirelive.svg',
    mantisID: 'bedfordshirelive',
    Name: 'Bedfordshire Live',
  },
  'belfastlive.co.uk': {
    logo: 'logo-belfastlive.svg',
    mantisID: 'belfastlive',
    Name: 'Belfast Live',
  },
  'birminghammail.co.uk': {
    logo: 'logo-birminghammail.svg',
    mantisID: 'birminghammail',
    Name: 'Birmingham Live',
  },
  'bristolpost.co.uk': {
    logo: 'logo-bristolpost.svg',
    mantisID: 'bristolpost',
    Name: 'Bristol Live',
  },
  'buckinghamshirelive.com': {
    logo: 'logo-buckinghamshirelive.svg',
    mantisID: 'buckinghamshirelive',
    Name: 'Buckinghamshire Live',
  },
  'business-live.co.uk': {
    logo: 'logo-birminghampost.svg',
    mantisID: 'business-live',
    Name: 'Business Live',
  },
  'buzz.ie': {
    logo: 'logo-buzz.svg',
    mantisID: 'buzz',
    Name: 'Buzz.ie',
  },
  'cambridge-news.co.uk': {
    logo: 'logo-cambridgenews.svg',
    mantisID: 'cambridgenews',
    Name: 'Cambridgeshire Live',
  },
  'cheshire-live.co.uk': {
    logo: 'logo-chesterchronicle.svg',
    mantisID: 'chesterchronicle',
    Name: 'Cheshire Live',
  },
  'chroniclelive.co.uk': {
    logo: 'logo-nechronicle.svg',
    mantisID: 'chroniclelive',
    Name: 'Chronicle Live',
  },
  'corkbeo.ie': {
    logo: 'logo-corkbeo.svg',
    mantisID: 'corkbeo',
    Name: 'CorkBeo',
  },
  'cornwalllive.com': {
    logo: 'logo-cornwalllive.svg',
    mantisID: 'cornwalllive',
    Name: 'Cornwall Live',
  },
  'coventrytelegraph.net': {
    logo: 'logo-coventrytelegraph.svg',
    mantisID: 'coventrytelegraph',
    Name: 'Coventry Live',
  },
  'curiously.co.uk': {
    logo: 'logo-curiously.svg',
    mantisID: 'curiously',
    Name: 'Curiously',
  },
  'dailypost.co.uk': {
    logo: 'logo-northwales.svg',
    mantisID: 'dailypost',
    Name: 'North Wales',
  },
  'dailyrecord.co.uk': {
    logo: 'logo-dailyrecord.svg',
    mantisID: 'dailyrecord',
    Name: 'Daily Record',
  },
  'dailystar.co.uk': {
    logo: 'logo-dailystar.svg',
    mantisID: 'dailystar',
    Name: 'Daily Star',
  },
  'derbytelegraph.co.uk': {
    logo: 'logo-derbytelegraph.svg',
    mantisID: 'derbytelegraph',
    Name: 'Derbyshire Live',
  },
  'devonlive.com': {
    logo: 'logo-devonlive.svg',
    mantisID: 'devonlive',
    Name: 'Devon Live',
  },
  'dorset.live': {
    logo: 'logo-dorsetlive.svg',
    mantisID: 'dorset',
    Name: 'Dorset Live',
  },
  'dublinlive.ie': {
    logo: 'logo-dublinlive.svg',
    mantisID: 'dublinlive',
    Name: 'Dublin Live',
  },
  'edinburghlive.co.uk': {
    logo: 'logo-edinburghlive.svg',
    mantisID: 'edinburghlive',
    Name: 'Edinburgh Live',
  },
  'essexlive.news': {
    logo: 'logo-essexlive.svg',
    mantisID: 'essexlive',
    Name: 'Essex Live',
  },
  'examinerlive.co.uk': {
    logo: 'logo-yorkshire-live.svg',
    mantisID: 'examinerlive',
    Name: 'Yorkshire Live',
  },
  'express.co.uk': {
    logo: 'logo-express.svg',
    mantisID: 'express',
    Name: 'Express',
  },
  'football.london': {
    logo: 'logo-footballlondon.svg',
    mantisID: 'football',
    Name: 'Football London',
  },
  'footballscotland.co.uk': {
    logo: 'logo-footballscotland.svg',
    mantisID: 'footballscotland',
    Name: 'Football Scotland',
  },
  'galwaybeo.ie': {
    logo: 'logo-galwaybeo.svg',
    mantisID: 'galwaybeo',
    Name: 'Galway Beo',
  },
  'gazettelive.co.uk': {
    logo: 'logo-gazettelive.svg',
    mantisID: 'gazettelive',
    Name: 'Teesside Live',
  },
  'getreading.co.uk': {
    logo: 'logo-getreading.svg',
    mantisID: 'getreading',
    Name: 'Berkshire Live',
  },
  'getsurrey.co.uk': {
    logo: 'logo-getsurrey.svg',
    mantisID: 'getsurrey',
    Name: 'Surrey Live',
  },
  'glasgowlive.co.uk': {
    logo: 'logo-glasgowlive.svg',
    mantisID: 'glasgowlive',
    Name: 'Glasgow Live',
  },
  'gloucestershirelive.co.uk': {
    logo: 'logo-gloucestershirelive.svg',
    mantisID: 'gloucestershirelive',
    Name: 'Gloucestershire Live',
  },
  'grimsbytelegraph.co.uk': {
    logo: 'logo-grimsbytelegraph.svg',
    mantisID: 'grimsbytelegraph',
    Name: 'Grimsby Live',
  },
  'hampshirelive.news': {
    logo: 'logo-hampshirelive.svg',
    mantisID: 'hampshirelive',
    Name: 'Hampshire Live',
  },
  'hertfordshiremercury.co.uk': {
    logo: 'logo-hertfordshiremercury.svg',
    mantisID: 'hertfordshiremercury',
    Name: 'Herts Live',
  },
  'hulldailymail.co.uk': {
    logo: 'logo-hulldailymail.svg',
    mantisID: 'hulldailymail',
    Name: 'Hull Live',
  },
  'insider.co.uk': {
    logo: 'logo-businessInsider.svg',
    mantisID: 'businessinsider',
    Name: 'Insider',
  },
  'inyourarea.co.uk': {
    logo: 'logo-inyourarea.svg',
    mantisID: 'inyourarea',
    Name: 'In Your Area',
  },
  'irishstar.com': {
    logo: 'logo-irishstar.svg',
    mantisID: 'irishstar',
    Name: 'Irish Star',
  },
  'irishmirror.ie': {
    logo: 'logo-irishmirror.svg',
    mantisID: 'irishmirror',
    Name: 'Irish Mirror',
  },
  'kentlive.news': {
    logo: 'logo-kentlive.svg',
    mantisID: 'kentlive',
    Name: 'Kent Live',
  },
  'lancs.live': {
    logo: 'logo-accrington.svg',
    mantisID: 'accrington',
    Name: 'Lancs Live',
  },
  'leeds-live.co.uk': {
    logo: 'logo-leedslive.svg',
    mantisID: 'leedslive',
    Name: 'Leeds Live',
  },
  'leicestermercury.co.uk': {
    logo: 'logo-leicestermercury.svg',
    mantisID: 'leicestermercury',
    Name: 'Leicestershire Live',
  },
  'lincolnshirelive.co.uk': {
    logo: 'logo-lincolnshirelive.svg',
    mantisID: 'lincolnshirelive',
    Name: 'Lincolnshire Live',
  },
  'liverpool.com': {
    logo: 'logo-liverpool.svg',
    mantisID: 'liverpool',
    Name: 'Liverpool.com',
  },
  'liverpoolecho.co.uk': {
    logo: 'logo-liverpoolecho.svg',
    mantisID: 'liverpoolecho',
    Name: 'Liverpool Echo',
  },
  'mirror.co.uk': {
    logo: 'logo-mirror.svg',
    mantisID: 'mirror',
    Name: 'Mirror',
  },
  'manchestereveningnews.co.uk': {
    logo: 'logo-men.svg',
    mantisID: 'manchestereveningnews',
    Name: 'Manchester Evening News',
  },
  'mylondon.news': {
    logo: 'logo-getwestlondon.svg',
    mantisID: 'mylondon',
    Name: 'My London News',
  },
  'norfolklive.co.uk': {
    logo: 'logo-norfolklive.svg',
    mantisID: 'norfolklive',
    Name: 'Norfolk Live',
  },
  'northantslive.news': {
    logo: 'logo-northantslive.svg',
    mantisID: 'northantslive',
    Name: 'Northants Live',
  },
  'nottinghampost.com': {
    logo: 'logo-nottinghampost.svg',
    mantisID: 'nottinghampost',
    Name: 'Nottinghamshire Live',
  },
  'ok.co.uk': {
    logo: 'logo-ok.svg',
    mantisID: 'ok',
    Name: 'OK!',
  },
  'oxfordshirelive.co.uk': {
    logo: 'logo-oxfordshirelive.svg',
    mantisID: 'oxfordshirelive',
    Name: 'Oxfordshire Live',
  },
  'plymouthherald.co.uk': {
    logo: 'logo-plymouthherald.svg',
    mantisID: 'plymouthherald',
    Name: 'Plymouth Live',
  },
  'rsvplive.ie': {
    logo: 'logo-rsvp.svg',
    mantisID: 'rsvplive',
    Name: 'RSVP Live',
  },
  'somersetlive.co.uk': {
    logo: 'logo-somersetlive.svg',
    mantisID: 'somersetlive',
    Name: 'Somerset Live',
  },
  'scottishdailyexpress.co.uk': {
    logo: 'logo-scottishdailyexpress.svg',
    mantisID: 'scottishdailyexpress',
    Name: 'Scottish Daily Express',
  },
  'suffolklive.com': {
    logo: 'logo-suffolklive.svg',
    mantisID: 'suffolklive',
    Name: 'Suffolk Live',
  },
  'sussexlive.co.uk': {
    logo: 'logo-sussexlive.svg',
    mantisID: 'sussexlive',
    Name: 'Sussex Live',
  },
  'staffordshire-live.co.uk': {
    logo: 'logo-staffordshirelive.svg',
    mantisID: 'staffordshirelive',
    Name: 'Staffordshire Live',
  },
  'stokesentinel.co.uk': {
    logo: 'logo-stokesentinel.svg',
    mantisID: 'stokesentinel',
    Name: 'Stoke on Trent Live',
  },
  'the-express.com': {
    logo: 'logo-the-express.svg',
    mantisID: 'the-express',
    Name: 'The Express US',
  },
  'themirror.com': {
    logo: 'logo-themirror.svg',
    mantisID: 'themirror',
    Name: 'The Mirror US',
  },
  'walesonline.co.uk': {
    logo: 'logo-walesonline.svg',
    mantisID: 'walesonline',
    Name: 'Wales Online',
  },
  'wiltshirelive.co.uk': {
    logo: 'logo-wiltshirelive.svg',
    mantisID: 'wiltshirelive',
    Name: 'Wiltshire Live',
  }
};

export {
  getPublicationKeys,
  getPublications,
  getPublicationMap,
  getAllPublicationNames,
  retrieveDomainName,
  mapLocationToDomain,
  filterDomainsByKeys,
  mantisIDsToNames,
};
