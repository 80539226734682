import React, { useEffect, useState } from 'react';
import TreeMenu from 'react-simple-tree-menu';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { getCategoryByType, getCategoryByName, getCategoryTreeMap, categoryTypes } from '../../config/categories';
import CustomPopup from '../../components/CustomPopup/CustomPopup';
import './interestTreeMenu.scss';

const treeData = categoryTypes.map((item) => {
  return {
    key: item.key,
    label: item.label,
    nodes: getCategoryTreeMap(getCategoryByType(item.key)),
  };
});

const ItemComponent = ({ style, level = 0, hasNodes, isOpen, label, selected, onClick, ...props }) => {
  return (
    <ListItemButton disableGutters dense component="li" selected={selected} onClick={onClick} className={hasNodes ? 'parent-node' : null}>
      <ListItemText primary={label} className={isOpen ? 'open-node' : null} />
      {hasNodes && (isOpen ? <ExpandLessRoundedIcon fontSize="large" color="osloGrey" /> : <ExpandMoreRoundedIcon fontSize="large" color="osloGrey" />)}
    </ListItemButton>
  );
};

const Chips = ({ chips, onDelete }) => {
  return (
    <>
      {chips.map((data) => {
        let configObject = getCategoryByName(data);
        return (
          <Chip
            key={data}
            label={configObject?.displayName}
            onDelete={onDelete(data)}
            data-testid="chip"
            deleteIcon={<HighlightOffIcon />}
            sx={{
              '& .MuiChip-deleteIcon': {
                color: 'common.black',
              },
            }}
          />
        );
      })}
    </>
  )
}

const InterestTreeMenu = ({ activeKeys, openNodes, selectedCategories, toggleItem, handleDelete }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [hiddenChipCount, setHiddenChipCount] = useState(0);
  const [showAllChips, setShowAllChips] = useState(false);

  const calculateHiddenChipCount = () => {
    const containerWidth = document.querySelector('.chip-container')?.clientWidth;
    const spacing = 5;

    const chipElements = document.querySelectorAll('.chips .MuiChip-root');
    let totalChipWidth = 0;
    let visibleChipCount = 0;

    chipElements.forEach((chipElement) => {
      const chipWidth = chipElement.clientWidth;
      totalChipWidth += chipWidth + spacing;
      if (totalChipWidth <= containerWidth) {
        visibleChipCount++;
      }
    });

    const hiddenChipCount = selectedCategories.length - visibleChipCount;

    return hiddenChipCount > 0 ? hiddenChipCount : 0;
  };

  useEffect(() => {
    const calculatedHiddenCount = calculateHiddenChipCount();
    setHiddenChipCount(calculatedHiddenCount);
  }, [selectedCategories, showAllChips]);

  return (
    <>
      <FormControl sx={{ width: '100%' }}>
        <TreeMenu cacheSearch data={treeData} onClickItem={toggleItem} debounceTime={125} hasSearch openNodes={openNodes} activeKey={activeKeys}>
          {({ search, items }) => (
            <>
              <div className={selectedCategories.length > 0 ? 'chip-container' : null}>
                <div className="chips" style={{ width: hiddenChipCount > 0 ? '85%' : '100%' }}>
                  <Chips chips={selectedCategories} onDelete={handleDelete} />
                </div>
                {hiddenChipCount > 0 && !showAllChips ? (
                  <Typography
                    variant="body1"
                    className="chips-span"
                    onClick={() => setShowAllChips(true)}
                    role="button"
                    aria-label={`Show ${hiddenChipCount} hidden chips`}
                  >
                    {' '}
                    + {hiddenChipCount} more
                  </Typography>
                ) : null}
              </div>
              <OutlinedInput
                fullWidth
                type="text"
                placeholder="Search"
                aria-label="category search"
                sx={{ mb: 1 }}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  search(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    {searchTerm.length > 0 ? (
                      <IconButton
                        edge="end"
                        aria-label="delete input"
                        onClick={() => {
                          setSearchTerm('');
                          search('');
                        }}
                      >
                        <HighlightOffIcon color="osloGrey" />
                      </IconButton>
                    ) : (
                      <SearchIcon color="osloGrey" />
                    )}
                  </InputAdornment>
                }
              />
              <List className="tree-item-group">
                {items.map(({ key, level, hasNodes, isOpen, label, onClick, ...props }) => (
                  <ItemComponent
                    key={key}
                    level={level}
                    hasNodes={hasNodes}
                    isOpen={isOpen}
                    label={label}
                    selected={activeKeys.includes(key)}
                    onClick={onClick}
                    {...props}
                  />
                ))}
              </List>
            </>
          )}
        </TreeMenu>
      </FormControl>

      {
        <CustomPopup
          isOpen={showAllChips}
          onClose={() => setShowAllChips(false)}
          actionButtons={[{ onClick: () => setShowAllChips(false), label: 'Close' }]}
        >
          <Typography variant="h1" id="max-width-dialog-title">
            Your selected interests
          </Typography>
          <Typography variant="body1">You can view all your selected interests here</Typography>
          <div className="chips-expanded">
            <Chips chips={selectedCategories} onDelete={handleDelete} />
          </div>
        </CustomPopup>
      }
    </>
  );
};

export default InterestTreeMenu;
