import { getFeatureFlag } from "../../helpers/configUtilities";
import { __log } from "../../helpers/utils";
import { getCookie } from "../../helpers/pageUtilities";
import {
  isConsented,
  purposes,
  publisherAndVendorPurposesAccepted,
  getConsentData
} from "./consent.js";

/**
 * Check all necessary consents and return mantisid cookie value
 * @function getMantisId
 * @returns {string} mantisid
 */
export async function getMantisIdIfAllowedForVendor(vendor) {
  const requiredPurposes = [
    purposes.STORE_AND_ACCESS_INFORMATION_ON_A_DEVICE,
    purposes.CREATE_A_PERSONALISED_ADS_PROFILE,
    purposes.SELECT_PERSONALISED_ADS
  ];
  const allowed = await isConsented(vendor, requiredPurposes);
  return allowed ? getCookie('mantisid') : undefined;
}

/**
 * Generate the UUID
 * @function generateUUID
 * @returns {string} the generated UUID in format xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
 */
function generateUUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
function getDomainName() {
  return window.location.hostname;
}
/**
 * set mantis id cookie
 * if cookie doesn't exist create cookie otherwise overwrite it with the same value and 1 year expiry
 * @function setMantisId
 */
function setMantisId() {
  const cookieValue = getCookie("mantisid");
  const mantisId = cookieValue === "" ? generateUUID() : cookieValue;
  const NEXT_YEAR_MS = 31536000000; // 1000 * 60 * 60 * 24 * 365
  const isSecure = window.location.protocol === "https:";
  const expires = new Date(Date.now() + NEXT_YEAR_MS).toUTCString();
  document.cookie = `mantisid=${mantisId};expires=${expires};path=/;domain=${getDomainName()};${isSecure && "samesite=none;secure;"}`;
  __log('Mantis ID', mantisId);
}
/**
 * check necessary consent
 * @function isAllowed for consent data
 */
async function isAllowed(consentData) {
  let cmpData = await getConsentData();
  consentData || cmpData[0];
  const requiredPurposes = [purposes.STORE_AND_ACCESS_INFORMATION_ON_A_DEVICE];
  return publisherAndVendorPurposesAccepted(requiredPurposes, consentData);
}

export async function init() {
  return new Promise((resolve) => {
    const isEnabled = getFeatureFlag("mantisIdEnabled");
    if (!isEnabled) {
      return;
    }
    const callback = async (tcData, success) => {
      if (success) {
        const allowed = await isAllowed(tcData);
        if (allowed) {
          setMantisId();
          window.__tcfapi(
            "removeEventListener",
            2,
            () => { },
            tcData.listenerId
          );
        }
      }
      resolve();
    };
    window.__tcfapi?.("addEventListener", 2, callback);
  });
} 
