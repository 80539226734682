import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ThemeProvider, createTheme } from '@mui/material/styles';

export const localTheme = (theme) =>
  createTheme({
    ...theme,
    components: {
      MuiTooltip: {
        styleOverrides: {
          popper: {
            '& .MuiTooltip-tooltip': {
              maxWidth: 432,
              backgroundColor: theme.palette.common.white,
              color: theme.palette.common.black,
              fontSize: theme.typography.size14,
              border: `1px solid ${theme.palette.gainsboro.main}`,
              borderRadius: '10px',
              padding: '35px 25px',
            },
            '& .MuiTooltip-arrow': {
              color: theme.palette.common.white,
              '&:before': {
                border: `1px solid ${theme.palette.gainsboro.main}`,
              },
            },
          },
        },
      },
    },
  });

function TooltipComponent({ placement, title, open, close, children }) {
  return (
    <ThemeProvider theme={localTheme}>
      <ClickAwayListener onClickAway={close}>
        <Tooltip arrow placement={placement} title={title} onClose={close} open={open} disableHoverListener disableTouchListener>
          {children}
        </Tooltip>
      </ClickAwayListener>
    </ThemeProvider>
  );
}

export default TooltipComponent;
