// React and hooks
import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './category.scss';

// Local Components
import ListItem from '../ad/ListItem';
import Overlay from '../shared/Overlay';
import CustomPopup from '../CustomPopup/CustomPopup';
import CategoryNotFound from './categoryNotFound';
import DesktopPlaceholder from './carouselPlaceholder';
import Signup from '../CustomPopup/Signup/Signup';
import Trending from '../breadcrumbsAndSocialSharing/Trending/Trending';
import PausedPersonalisationPopup from '../shared/PausedPersonalisationPopup';

// Material UI Components and Icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

// Helper Functions and Constants
import {
  __log,
  isPersonalisedUser,
  isPersonalisedExperienceEnabled,
  isLoginRadiusUser,
  removeExcludedDomains,
  retrieveExcludedDomains,
  constructPath,
  countCardsPerScreenWidth,
  ellipsisText
} from '../../helpers/utils';
import { getLocalConfig, setLocalConfig, getInterestProperty, setPinnedCategory, getMergedInterests } from '../../helpers/localConfigUtils';
import { fetchDataFromAPI, fetchTermData } from '../../services/api';
import { getSuggestedCategories } from '../../config/categories';

// Swiper (external library) and related styles
import { Swiper, SwiperSlide } from 'swiper/react'; //version ?
import SwiperCore, { Autoplay, Navigation, Pagination, Controller, Thumbs } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

// Config
import desktopSlots from '../../config/adConfig/homePageConfig/slotsDesktop';

let categoriesLoaded = 0;

SwiperCore.use([Autoplay, Navigation, Pagination, Controller, Thumbs]);

export default function List({
  categorySlug,
  categoryName,
  displayName,
  sectionSlug,
  subSectionSlug,
  domains,
  age,
  rowIndex,
  numCategories,
  desktopOrMobile,
  pageLimit,
  mantisCategories,
  tags,
  updatePinnedCategories,
  isPageNotFound,
  searchQuery,
  orSearchQuery,
  excludeQuery,
  searchCategory,
  searchDomains,
  exclude_tags,
  exclude_mantis_categories,
  concepts,
  entities,
  exclude_concepts,
  exclude_entities,
  value,
  author,
  pinnedCategories,
}) {
  const [lists, setLists] = useState({ mobile: [], desktop: [] });
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);
  const [isLoading, setIsLoading] = useState(true);
  const [updateDomains, setUpdateDomains] = useState(false);
  const [isEnhancedSignInPopupOpen, setIsEnhancedSignInPopupOpen] = useState(false);
  const [isPausedPopupOpen, setIsPausedPopupOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const handleLogin = () => {
    setIsEnhancedSignInPopupOpen(false);

    localStorage.setItem('mna-init-signup-or-signin', true);
    const event = new CustomEvent('auth-ui.authenticate', {
      detail: { origin: window.location.pathname },
    });
    window.dispatchEvent(event);
  };

  const handleClosePausedPopup = () => {
    setIsPausedPopupOpen(false);
  };

  const enhancedSignInPopupActions = [
    {
      onClick: () => handleLogin(),
      label: 'sign in',
    },
    {
      onClick: () => navigate('/signup/interests'),
      label: 'signup',
    },
  ];

  let name = displayName.toLowerCase();
  const pageStart = 0;

  const updateExcludedDomains = () => {
    setUpdateDomains(true);
    window.location.reload();
  };

  const handleNavigate = () => {
    setIsEnhancedSignInPopupOpen(true);
  };

  const injectAds = (data, rowIndex, isDesktopView) => {
    const dataCopy = [...data];
    if (!isDesktopView) {
      return dataCopy;
    }

    const adsPerRow = desktopSlots.filter((slot) => slot.carousel.row === rowIndex);

    adsPerRow.forEach((slot) => {
      dataCopy.splice(slot.carousel.col, 0, {
        id: slot.id,
        isAd: true,
      });
    });
    return dataCopy;
  };

  const updateMedia = () => {
    setIsDesktopView(window.innerWidth > 768);
  };

  const areAllCategoriesLoaded = (storedCategories) => {
    categoriesLoaded++;
    if (!storedCategories && categoriesLoaded === 5) {
      window.dispatchEvent(new CustomEvent('allCategoriesLoaded'));
      __log('allCategoriesLoaded event dispatched, all non-personalsied (default) categories loaded case', 1);
      categoriesLoaded = 0;
    } else if (storedCategories && categoriesLoaded === storedCategories.length) {
      window.dispatchEvent(new CustomEvent('allCategoriesLoaded'));
      __log('allCategoriesLoaded event dispatched, all personalsied categories loaded case', 2);
      categoriesLoaded = 0;
    }
  };

  const getRandomLists = () => {
    //retrieves merged categories from LocalStorage interests{} and savedSearches{}
    const storedCategories = getMergedInterests();
    const interestedDomains = removeExcludedDomains(domains);

    const storedExclusions = retrieveExcludedDomains();

    (searchQuery || orSearchQuery
      ? fetchTermData(
        searchQuery,
        orSearchQuery,
        excludeQuery,
        searchCategory ?? '',
        tags ?? '',
        searchDomains,
        pageStart,
        pageLimit ?? 20,
        value,
        storedExclusions,
        author ?? ''
      )
      : fetchDataFromAPI({
        age,
        concepts: concepts ?? '',
        domains: interestedDomains,
        entities: entities ?? '',
        exclude_concepts: exclude_concepts ?? '',
        exclude_entities: exclude_entities ?? '',
        exclude_mantis_categories: exclude_mantis_categories ?? '',
        exclude_tags: exclude_tags ?? '',
        excludedDomains: storedExclusions,
        mantisCategories,
        pageLimit,
        pageStart,
        tags: tags ?? '',
      })
    )
      .then((response) => {
        if (response.articleData && response.articleData.length > 0) {
          let data = response.articleData.sort((articleOne, articleTwo) => {
            return new Date(articleTwo.lastModified) - new Date(articleOne.lastModified); //sorting articles in decending order to display latest articles first
          });
          data.forEach((article) => {
            article.title = article.title.replace(/(<([^>]+)>)/gi, ''); //escape html tag
          });
          let articleJson = data.map(JSON.stringify);
          let uniqueArticle = new Set(articleJson);
          let uniqueArray = Array.from(uniqueArticle).map(JSON.parse);
          const dataWithAds = injectAds(uniqueArray, rowIndex, desktopOrMobile);
          setLists({ mobile: uniqueArray, desktop: dataWithAds });
        }
        setIsLoading(false);
        areAllCategoriesLoaded(storedCategories);
      })
      .catch((error) => {
        __log('fetchDataFromAPI error', error);
        areAllCategoriesLoaded(storedCategories);
      });
  };

  if (lists.length === 0) {
    return <div></div>;
  }

  const pinnedCategoryConfig = getInterestProperty('pinnedCategory', categoryName);
  const isFavourited = pinnedCategoryConfig ? pinnedCategoryConfig.enabled : false;
  const personalisedExperienceEnabled = isPersonalisedExperienceEnabled();

  const handleToggleFavourite = (event) => {
    if (!personalisedExperienceEnabled) {
      setIsPausedPopupOpen(true);
      return;
    }

    const isEnabled = isFavourited ? false : true;
    setIsLoading(true);
    setPinnedCategory(categoryName, isEnabled);
    updatePinnedCategories(event.currentTarget.value);
  };

  const suggestedCategories = getSuggestedCategories(sectionSlug, subSectionSlug, categoryName)

  useEffect(async () => {
    getRandomLists();
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, [
    age,
    mantisCategories,
    domains,
    rowIndex,
    numCategories,
    desktopOrMobile,
    pageStart,
    pageLimit,
    isLoading,
    updateDomains,
    pinnedCategories,
    isDesktopView,
  ]);

  const PinIcon = (
    <IconButton
      value={categoryName}
      onClick={isPersonalisedUser() ? handleToggleFavourite : handleNavigate}
      aria-label={isFavourited ? 'Pin to favorites' : 'Unpin from favorites'}
    >
      {isFavourited && personalisedExperienceEnabled ? <PushPinIcon color="primary" /> : <PushPinOutlinedIcon color="primary" />}
    </IconButton>
  );

  const Carousel = (
    <Swiper
      id={isDesktopView ? 'main' : 'thumbs'}
      thumbs={isDesktopView && { swiper: thumbsSwiper }}
      onSwiper={!isDesktopView && setThumbsSwiper}
      controller={{ control: controlledSwiper }}
      tag="section"
      wrapperTag="ul"
      navigation={lists.desktop.length > countCardsPerScreenWidth(window.innerWidth) ? true : false}
      spaceBetween={0}
      slidesPerView="auto"
      slidesOffsetAfter={isDesktopView && 50}
      watchOverflow={true}
      {...(rowIndex === 0 && {
        autoplay: {
          delay: 4500,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        },
      })}
    >
      <div className="container">
        {((isDesktopView && lists.desktop) || lists.mobile).map((item, i) => (
          <SwiperSlide key={i}>
            <ListItem key={i} index={i} item={item} updateExcludedDomains={updateExcludedDomains} />
          </SwiperSlide>
        ))}
      </div>
    </Swiper>
  );

  return isLoading && !isPageNotFound ? (
    <DesktopPlaceholder />
  ) : (
    <div className="list" data-testid="carousel">
      {rowIndex === 4 && !isPersonalisedUser() && !isLoginRadiusUser() && <Overlay />}
      {isDesktopView ? (
        <>
          <div className="horizontal-div">
            <div className="div-title">
              <Link to={`/${constructPath(sectionSlug, subSectionSlug, categorySlug)}`}>
                <Typography variant="h1bold" sx={{ color: theme.palette.primary.main }} data-testid="carousel-title">
                  {name}
                </Typography>
              </Link>
            </div>
            {!(location.pathname === '/signup' || location.pathname === '/error/404') && (
              <div className="div-icons">{location.pathname === '/' && PinIcon}</div>
            )}
          </div>

          {Object.keys(suggestedCategories).length !== 0 ? (
            <div className="trending-div" data-testid="trending-div">
              <Trending trendingKeywords={suggestedCategories} text />
            </div>
          ) : null}

          {lists.desktop.length > 0 ? Carousel : <CategoryNotFound categoryName={categoryName} />}
        </>
      ) : (
        <>
          <div className="horizontal-div">
            <div className="div-title">
              <Link to={`/${constructPath(sectionSlug, subSectionSlug, categorySlug)}`}>
                <Typography variant="h1bold" sx={{ color: theme.palette.primary.main }} data-testid="carousel-title">
                  {ellipsisText(name, 19)}
                </Typography>
              </Link>
            </div>
            {!(location.pathname === '/signup' || location.pathname === '/error/404') && <div className="div-icons">{PinIcon}</div>}
          </div>

          {Object.keys(suggestedCategories).length !== 0 ? (
            <div className="trending-div" data-testid="trending-div">
              <Trending trendingKeywords={suggestedCategories} />
            </div>
          ) : null}

          {lists.mobile.length > 0 ? Carousel : <CategoryNotFound categoryName={categoryName} />}
        </>
      )}
      {isEnhancedSignInPopupOpen && (
        <CustomPopup
          isOpen={isEnhancedSignInPopupOpen}
          onClose={() => setIsEnhancedSignInPopupOpen(false)}
          logo={require(`../../assets/mna_alt_logo.png`)}
          divider
          title="Sign in for an enhanced My News Assistant experience"
          actionButtons={enhancedSignInPopupActions}
        >
          <Signup />
        </CustomPopup>
      )}
      {isPausedPopupOpen && <PausedPersonalisationPopup show={isPausedPopupOpen} handleClosePausedPopup={handleClosePausedPopup} />}
    </div>
  );
}
