import { AdvertisingSlot } from 'react-advertising';
import Teaser from '../homepage/homepageTeaser';
import '../ad/ListItem.scss';

export default function ListItem({ index, item, updateExcludedDomains }) {
  return item.isAd ? (
    <AdvertisingSlot id={item.id} key={index} item={item} className="ad-placeholder-desktop"/>
  ) : (
    <Teaser key={index} index={index} item={item} updateExcludedDomains={updateExcludedDomains} />
  );
}
