import { getFeatureFlag } from '../../helpers/configUtilities';
import TeaserIframe from '../shared/TeaserIframe';
import Teaser from '../shared/Teaser';

export default function ListItem({item, updateExcludedDomains }) {

  return (
    <div>
      {!getFeatureFlag('iframeHome') ? (
        <Teaser item={item} updateExcludedDomains={updateExcludedDomains} />
      ) : (
        <TeaserIframe item={item} updateExcludedDomains={updateExcludedDomains} />
      )}
    </div>
  );
}
