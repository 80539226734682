import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLocalConfig, setLocalConfig } from '../../helpers/localConfigUtils';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CommunicationPreferencesForm from './communicationPreferencesForm';

function CommunicationPreferences() {
  const localConfig =  getLocalConfig();
  const [marketingPermission, setMarketingPermission] = useState(localConfig ? localConfig.consents.marketingPermission : false);
  const [pushNotificationPermission, setPushNotificationPermission] = useState(localConfig ? localConfig.notifications.push : false);
  const [newsletterPermission, setNewsletterPermission] = useState(localConfig ? localConfig.consents.newslettersPermission : false);
  const [newsletterFrequency, setNewsletterFrequency] = useState(localConfig ? localConfig.notifications.frequency : 'daily');
  const navigate = useNavigate();


  const handleSave = () => {
    if (localConfig) {
      localConfig.consents.marketingPermission = marketingPermission;
      localConfig.consents.newslettersPermission = newsletterPermission;
      localConfig.consents.pushNotificationsPermission = pushNotificationPermission;
      localConfig.notifications.email = newsletterPermission;
      localConfig.notifications.frequency = newsletterFrequency;
      localConfig.notifications.push = pushNotificationPermission;
      setLocalConfig(localConfig);
    }
    navigate('/');
  };

  return (
    <div>
      <CommunicationPreferencesForm
        marketingPermission={marketingPermission}
        setMarketingPermission={setMarketingPermission}
        pushNotificationPermission={pushNotificationPermission}
        setPushNotificationPermission={setPushNotificationPermission}
        newsletterPermission={newsletterPermission}
        setNewsletterPermission={setNewsletterPermission}
        newsletterFrequency={newsletterFrequency}
        setNewsletterFrequency={setNewsletterFrequency}
      />
      <Button
        onClick={() => handleSave()}
        className='btn'
        color='primary'
        variant='contained'
        sx={{ my: 4 }}
      >
        Save
      </Button>
    </div>
    );
  }

  export default CommunicationPreferences;
