import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { isLoginRadiusUser, avoid } from '../../helpers/utils';
import List from '../../components/homepage/category';
import { getCategorys } from '../../config/categories';

export default function Notice() {
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);
  const navigate = useNavigate();
  // GA4 tracking via gtag.js
  const gtag = window.gtag || avoid;
  const defaultCategoryName = 'celebrity';
  const config = getCategorys();
  const categoryConfig = config[defaultCategoryName];

  const handleLogin = () => {
    localStorage.setItem('mna-init-signup-or-signin', true);
    gtag('event', 'user_loggedIn', {
      event: 'clicked',
      source: 'navbar',
    });
    navigate('/signup/login');
  };

  const handleClickChoiceOpen = () => {
    localStorage.setItem('mna-init-signup-or-signin', true);
    navigate('/signup/interests');
    gtag('event', 'personalisation_notice', {
      event: 'show_notice',
      source: 'sign_up_flow',
    });
  };

  const updateMedia = () => {
    setIsDesktopView(window.innerWidth > 768);
  };

  useEffect(() => {
    document.title = 'Lets make it personal | My News Assistant';
    window.scrollTo(0, 0);
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <div className='bordered-container'>
      <div className='top-container'>
        <IconButton aria-label='go back' edge='start' onClick={() => navigate('/')}>
          <ArrowBackIcon color='timberGreen' />
        </IconButton>
        {!isLoginRadiusUser() && (
          <Typography variant='body1'>
            Already have an<br/>account,&nbsp;
            <Link
              component='button'
              onClick={handleLogin}
              className='sign-in-button'
              underline='none'
              color='primary'
            >
              sign in now
            </Link>
          </Typography>
        )}
      </div>
      <Typography variant='h1'>
        Let's make it<br/><span className='highlighted'>personal</span>
      </Typography>
      <Typography variant='label4' sx={{ display: 'block', mb: 2 }}>
        Looking for a hassle-free way to stay informed on the latest news and topics that matter to you? Look no further than My News Assistant!
      </Typography>
      <div className='content-text'>
        <Typography variant='body1'>
          Our platform offers a wealth of articles from trusted sources, updated daily and is tailored to your interests. With just a few clicks, you can create
          a custom news feed that keeps you up-to-date on everything from Manchester United transfer news to the latest in neuroscience.
        </Typography>
        <Typography variant='body1'>
          No more sifting through multiple news websites - My News Assistant makes it easy to stay informed on the topics you care about most. So why wait?
          Create your own custom news feed today and stay ahead of the game!
        </Typography>
        <Typography variant='body1'>
          Simply click ‘continue’ to create your unique custom news feed in two easy steps
        </Typography>
      </div>
      {isDesktopView && (
        <div className="carousel-div">
          <List
            key={defaultCategoryName}
            categorySlug={categoryConfig.categorySlug}
            categoryName={defaultCategoryName}
            displayName={categoryConfig.displayName}
            sectionSlug={categoryConfig.sectionSlug}
            subSectionSlug={categoryConfig.subSectionSlug}
            domains=""
            age={categoryConfig.age}
            rowIndex={1}
            pageStart={0}
            pageLimit={categoryConfig.limit}
            isPageNotFound={true}
            mantisCategories={categoryConfig.mantisCategories}
            tags={categoryConfig.tags}
            categoryType={categoryConfig.categoryType}
          />
        </div>
      )}
      <Container className='flex-btn'>
        <Button
          onClick={handleClickChoiceOpen}
          className='btn'
          color='primary'
          variant='contained'
        >
          Continue
        </Button>
      </Container>
    </div>
  );
}
