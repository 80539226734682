import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import logoimage from '../../assets/mna_logo.png';
import iconimage from '../../assets/mna_icon.png';
import { isConsented } from '../tracking/consent';
import { __log } from '../../helpers/utils';
import './footer.scss';

const footerLinks = [
  { label: 'Privacy Notice', href: 'https://www.reachplc.com/site-services/privacy-policy' },
  { label: 'Cookie Notice', href: 'https://www.reachplc.com/site-services/cookie-notice' },
  { label: 'Terms and Conditions', href: '/legal/terms-and-conditions' },
  { label: 'About Us', href: '/about-us' },
  { label: 'Contact Us', href: '/contact-us' },
];

export default function Footer() {
  const [isCCPA, setIsCCPA] = useState(undefined);
  const [isROW, setIsROW] = useState(undefined);
  const [isTabletView, setIsTabletView] = useState(window.innerWidth >= 768);
  const [isLaptopView, setIsLaptopView] = useState(window.innerWidth >= 1024);

  const location = useLocation();
  const basePath = location.pathname.split('/').filter(Boolean)[0];

  /**
   * Asks the CMP whether the user is in a CCPA jurisdiction
   * @function isInJurisdiction
   * @returns { Promise } a promise that resolves to a boolean indicatng whether the CMP thinks the CCPA applies to this user
   */
  function isInJurisdiction() {
    return new Promise((resolve) => {
      // handle case where CMP is not yet loaded
      if (typeof window.__uspapi !== 'function') {
        return;
      }

      window.__uspapi('uspPing', 1, (pingObj, pingStatus) => {
        if (!pingStatus) {
          resolve(false);
        }
        // mode is an array: ["GDPR", "USP"]
        // jurisdiction is ['CA'] or ['US']
        const { mode, jurisdiction, location } = pingObj;
        __log('mode from pingObj', mode);
        __log('jurisduction from pingObj', jurisdiction);
        __log('location from pingObj', location);
        if (location === 'non-US') {
          setIsROW(true);
        }
        const enabled = mode.includes('USP');
        const inJurisdiction = jurisdiction.map((j) => j.toUpperCase()).includes(location.toUpperCase());
        resolve(enabled && inJurisdiction);
      });
    });
  }

  /**
   * Triggers the CCPA 'Do Not Sell' link to be shown if needed.
   * @function showCCPA
   */
  function showCCPA() {
    return new Promise((resolve) => {
      isConsented()
        .then(isInJurisdiction)
        .then((isCCPA) => {
          if (isCCPA) {
            setIsCCPA(true);
            __log('state has changed to true', isCCPA);
          }
          resolve();
        })
        // if something goes wrong, don't show the link, assume they're in UK
        .catch(resolve);
    });
  }

  useEffect(() => {
    showCCPA();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsTabletView(window.innerWidth >= 768);
      setIsLaptopView(window.innerWidth >= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function handleConsentChoices(event) {
    event.preventDefault();
    window.__tcfapi('displayConsentUi', 2, function () {});
  }

  function handleCCPAChoice(event) {
    event.preventDefault();
    window.__uspapi('displayUspUi');
  }

  return (
    <footer className={!isTabletView && basePath === 'signup' ? 'uplifted-footer' : null}>
      <div className="footer">
        <div className="footer-links">
          {isCCPA && (
            <Link underline="none" variant="caption" id="consent-link" component="button" onClick={handleCCPAChoice} color="white">
              Do Not Sell My Data
            </Link>
          )}
          {!isCCPA && !isROW && (
            <Link underline="none" variant="caption" id="consent-link" component="button" onClick={handleConsentChoices} color="white">
              Privacy
            </Link>
          )}
          {footerLinks.map(({ label, href }) => (
            <Link key={label} underline="none" href={href} variant="caption">
              {label}
            </Link>
          ))}
          {isTabletView && (
            <p className="copyright-text">
              <small>&#9400; Reach plc {new Date().getFullYear()}</small>
            </p>
          )}
        </div>
        {isTabletView && (
          <div className="footer-logo">
            {isLaptopView ? (
              <>
                <img src={logoimage} alt="My News Assistant logo" className="mna-logo" />
                <Typography variant="caption" sx={{ mt: -0.5 }} color="white">
                  part of Reach group
                </Typography>
              </>
            ) : (
              <img src={iconimage} alt="My News Assistant logo" className="mna-icon" />
            )}
          </div>
        )}
      </div>
      {!isTabletView && (
        <div className="copyright">
          <p className="copyright-text">
            <small>&#9400; Reach plc {new Date().getFullYear()}</small>
          </p>
          <img src={iconimage} alt="My News Assistant logo" className="mna-icon" />
        </div>
      )}
    </footer>
  );
}
