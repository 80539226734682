import React from 'react';
import { Outlet, Routes, Route } from 'react-router-dom';
import Notice from '../../components/signup/notice';
import './signup.scss';
import Interest from '../../components/signup/interest';
import Location from '../../components/signup/location';
import Login from '../../components/signup/login';
import LoginSuccess from '../../components/signup/loginsuccess';
import Loader from '../../components/signup/loader';

export default function Signup() {
  return (
    <div className={'standard-layout signup-page'}>
      <main>
        <Routes>
          <Route path="/" element={<Notice />} />
          <Route path="loginsuccess" element={<LoginSuccess />} />
          <Route path="loader" element={<Loader />} />
          <Route path="notice" element={<Notice />} />
          <Route path="interests" element={<Interest />} />
          <Route path="location" element={<Location />} />
          <Route path="login" element={<Login />} />
        </Routes>
      </main>
    </div>
  );
}
