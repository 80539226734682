import React from 'react';
import './category.scss';
import Skeleton from 'react-loading-skeleton';
import TeaserPlaceholder from '../shared/teaserPlaceholder';
import 'react-loading-skeleton/dist/skeleton.css';

function categoryPlaceholderDesktop() {
  return (
    <div className="list">
      <div className="horizontal-div">
        <Skeleton containerClassName="div-title" style={{ width: '418px', height: '38px' }} />
        <Skeleton circle containerClassName="div-icons" style={{ height: '25px', width: '25px' }} />
      </div>
      <Skeleton className="trending-div" style={{ marginTop: '2px', marginBottom: '-15px', height: '30px' }} />
      <div className="carousel-desktop">
        <TeaserPlaceholder cards={10} />
      </div>
    </div>
  );
}

export default categoryPlaceholderDesktop;
