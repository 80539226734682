
module.exports = {
  prodEnvConfig: {
    AUTHUI_ENV: 'prod',
    APIKEY: '415307d0-d73a-49d3-9958-1c42c71165ee',
    LR_DOMAIN: 'https://auth.reachplc.com',
    HS_BASE_URL: 'https://d22khgmwbvruo6.cloudfront.net/mna-user',
    SEARCH_API: 'https://api.mantis-intelligence.com/reach/search',
    HOSTS: ['www.mynewsassistant.com', 'mynewsassistant.eu-gb.cf.appdomain.cloud','beta.mynewsassistant.com']
  },
  devEnvConfig: {
    AUTHUI_ENV: 'bertha',
    APIKEY: '170a7fc5-1449-4da6-931c-3e34e323dc3f',
    LR_DOMAIN: 'https://dev-auth.reachplc.com',
    HS_BASE_URL: 'https://d1dmo3revfurs1.cloudfront.net/mna-user',
    SEARCH_API: 'https://api-bertha.mantis-dev-awx.com/reach/search',
    HOSTS: ['dev.mynewsassistant.com', 'mantis-news.eu-gb.cf.appdomain.cloud','dev.mynewsassistant.com']
  },
};