import { avoid } from '../../helpers/utils';

export const LoginRadiusTracking = () => {
  // GA4 tracking via gtag.js
  const gtag = window.gtag || avoid;

  document.addEventListener('click', function (event) {
    if (event.target.className === 'button--solid undefined button check-email__button') {
      gtag('event', 'lr_registration_started', {
        event: 'email_address_supllied',
        source: ' step_1_LoginRadius_pop_up',
      });
    }
  });
};
