// React and hooks
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/Check';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LaunchIcon from '@mui/icons-material/Launch';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

// Local Components
import ShareButtonPopup from '../CustomPopup/ShareButtons/ShareButtonsPopup';

// Helper Functions and Constants
import { extractBaseDomain, getNameFromURL, getLogoFromURL, isPersonalisedUser, __log, trackableURL } from '../../helpers/utils';
import { getLocalConfig, setLocalConfig } from '../../helpers/localConfigUtils';
import { getPublicationMap } from '../../config/publications';

const MenuItemStyles= { minHeight: '38px', paddingLeft: '10px', paddingRight: '10px'}

const KebabMenu = ({ name, title, updateExcludedDomains, url, showHideOption = true, showGoToOption = true }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [isSocialSharingPopupOpen, setIsSocialSharingPopupOpen] = useState(false);

  const open = Boolean(anchorEl);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isEligible = isPersonalisedUser();

  name = name || getNameFromURL(url);

  // Function to handle opening the menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle closing the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to copy text to the clipboard
  const copyTextToClipboard = async () => {
    const trackableUrl = trackableURL(url, 'copy_link');

    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(trackableUrl);
    } else {
      return document.execCommand('copy', true, trackableUrl);
    }
  };

  // Function to handle the click of the copy option
  const handleCopyClick = () => {
    copyTextToClipboard()
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 4500);
      })
      .catch(__log);

    handleClose();
  };

  // Function to handle exclusion of domains
  const handleExclusion = () => {
    const excludedDomain = url.match(/^(?:https?:\/\/)?(?:www\.)?([^:\/\n]+)/)[1];
    const localConfig = getLocalConfig();
    const domains = getPublicationMap();

    domains.forEach((domain) => {
      if (domain[excludedDomain] && localConfig && !localConfig?.exclusions.includes(domain[excludedDomain])) {
        localConfig.exclusions.push(domain[excludedDomain]);
        updateExcludedDomains(domain[excludedDomain]);
      }
    });

    setLocalConfig(localConfig);
    handleClose();
  };

  // Function to handle navigation to the signup page
  const handleNavigate = () => {
    navigate('/signup');
    handleClose();
  };

  // Function to handle the click of the share option
  const handleShareClick = () => {
    const trackableUrl = trackableURL(url, 'share_link');

    if (navigator.share) {
      navigator
        .share({
          title: title,
          text: 'Stay connected and informed with My News Assistant, your ultimate news companion! 📲💡',
          url: trackableUrl,
        })
        .then(() => __log('Successful share'))
        .catch((error) => __log('Error sharing:', error));
    } else {
      setIsSocialSharingPopupOpen(true);
    }

    handleClose();
  };

  // Function to handle the click of the go to option
  const handleGoToClick = (url) => {
    const baseUrl = extractBaseDomain(url);
    const trackableUrl = trackableURL(baseUrl, 'goto_publisher');

    window.open(trackableUrl, '_blank');
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          '&:focus-visible': { backgroundColor: 'unset' },
          '&:hover': { backgroundColor: 'unset' },
        }}
      >
        <MoreVertIcon color="osloGrey" />
        <Typography variant="label1" sx={{ color: theme.palette.osloGrey.main, fontFamily: theme.typography.fontFamily }}>
          Options
        </Typography>
      </IconButton>
      <Menu
        disableAutoFocusItem
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ sx: { borderRadius: '5px', border: '1px solid #DADCE0', paddingTop: '0px', paddingBottom: '0px' } }}
      >
        <MenuItem divider={true} onClick={handleShareClick} sx={MenuItemStyles}>
          <ShareIcon color="osloGrey" fontSize="medium" />
          <Typography variant="body1">&nbsp; Share</Typography>
        </MenuItem>
        <MenuItem divider={true} onClick={handleCopyClick} sx={MenuItemStyles}>
          {isCopied ? <CheckIcon color="osloGrey" fontSize="medium" /> : <FileCopyIcon color="osloGrey" fontSize="medium" />}
          <Typography variant="body1">&nbsp; Copy</Typography>
        </MenuItem>
        {showHideOption && !(location.pathname === '/signup' || location.pathname === '/error/404' || location.pathname === '/search' || location.pathname === '/embed/search') && (
          <MenuItem divider={true} sx={MenuItemStyles} onClick={isEligible ? handleExclusion : handleNavigate}>
            <VisibilityOffIcon color="osloGrey" fontSize="medium" />
            <Typography variant="body1"> &nbsp; Hide all stories from {name}</Typography>
          </MenuItem>
        )}
        {showGoToOption && (
          <MenuItem divider={true} onClick={() => handleGoToClick(url)} sx={MenuItemStyles}>
            <LaunchIcon color="osloGrey" fontSize="medium" />
            <Typography variant="body1"> &nbsp; Go to {name}</Typography>
          </MenuItem>
        )}
      </Menu>
      {!navigator.share && isSocialSharingPopupOpen && (
        <ShareButtonPopup
          isOpen={isSocialSharingPopupOpen}
          onClose={() => setIsSocialSharingPopupOpen(false)}
          logo={require(`../../assets/brands/sm/${getLogoFromURL(url)}`)}
          title={title}
          url={url}
          handleCopy={copyTextToClipboard}
        />
      )}
    </div>
  );
};

export default KebabMenu;
