import React from 'react';
import CustomPopup from '../CustomPopup/CustomPopup';
import { useNavigate } from 'react-router-dom';
import { getLocalConfig, setLocalConfig } from '../../helpers/localConfigUtils';

function PausedPersonalisationPopup({ show, handleClosePausedPopup }) {
  const navigate = useNavigate();

  const handlePause = () => {
    let localConfig = getLocalConfig();
    localConfig.consents.personalisedExperience = true;
    setLocalConfig(localConfig);
    handleClosePausedPopup(true)
    navigate('/');
  };

  const personalisationPopupActions = [
    {
      onClick: handleClosePausedPopup,
      label: 'Cancel',
    },
    {
      onClick: handlePause,
      label: 'Unpause',
    },
  ];

  return (
    <CustomPopup
      isOpen={show}
      onClose={handleClosePausedPopup}
      logo={require(`../../assets/mna_alt_logo.png`)}
      divider
      title="Personalisation is paused"
      actionButtons={personalisationPopupActions}
    >
      {
        <p>
          The follow functionality is currently unavailable as personalisation has been paused. To enable this feature, simply click the button below to unpause
          personalisation.
        </p>
      }
    </CustomPopup>
  );
}

export default PausedPersonalisationPopup;
