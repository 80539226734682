import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Breadcrumbs as BreadcrumbsMui } from '@mui/material';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getFeatureFlag, getFeatureValue } from '../../../helpers/configUtilities';
import '../breadcrumbsAndSocialSharing.scss';

function Breadcrumbs({ sectionSlug, subSectionSlug, categorySlug }) {
  const breadcrumbsEnabled = getFeatureFlag('breadcrumbsEnabled');
  const shouldRenderBreadcrumbs = !sectionSlug && !subSectionSlug && !categorySlug;

  if (!breadcrumbsEnabled || shouldRenderBreadcrumbs) {
    return <nav aria-label="breadcrumb" className="breadcrumb-list" role="navigation"></nav>;
  }

  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth >= 768);
  const siteRoot = getFeatureValue('siteRoot');
  const HOME = 'My News Assistant';

  useEffect(() => {
    const handleResize = () => setIsDesktopView(window.innerWidth >= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const capitalizeFirstLetter = (str) => {
    if (!str) return '';
    return str.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const replaceDashWithSpace = (str) => {
    if (!str) return '';
    return str.replaceAll('-', ' ');
  };

  const breadcrumbItems = [
    { name: HOME, url: '/' },
    {
      name: capitalizeFirstLetter(replaceDashWithSpace(sectionSlug)),
      url: sectionSlug ? `/${sectionSlug.toLowerCase()}` : null,
    },
    {
      name: capitalizeFirstLetter(replaceDashWithSpace(subSectionSlug)),
      url: subSectionSlug ? `/${sectionSlug.toLowerCase()}/${subSectionSlug.toLowerCase()}` : null,
    },
    {
      name: capitalizeFirstLetter(replaceDashWithSpace(categorySlug)),
      url: categorySlug ? `/${sectionSlug.toLowerCase()}/${subSectionSlug.toLowerCase()}/${categorySlug.toLowerCase()}` : null,
    },
  ].filter((item) => item.name);

  const breadcrumbList = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      ...breadcrumbItems.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': `${siteRoot}${item.url}`,
          name: item.name,
        },
      })),
    ],
  };

  const breadcrumbsToShow = isDesktopView ? breadcrumbItems : breadcrumbItems.slice(-2);
  const mobileBreadcrumbsText = breadcrumbsToShow.length > 1 ? breadcrumbsToShow[0].name : HOME;
  const mobileBreadcrumbsUrl = breadcrumbsToShow.length > 1 ? breadcrumbsToShow[0].url : '/';

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(breadcrumbList)}</script>
      </Helmet>
      {isDesktopView ? (
        <BreadcrumbsMui aria-label="breadcrumbs" separator={<ChevronRightIcon fontSize="small" />}>
          {breadcrumbItems.map((item, index) =>
            item.url ? (
              <Link
                className={breadcrumbItems.length - 1 === index ? 'breadcrumb-active' : 'breadcrumb'}
                underline="hover"
                variant="caption"
                key={index}
                href={item.url}
              >
                {item.name}
              </Link>
            ) : (
              <Typography
                className={breadcrumbItems.length - 1 === index ? 'breadcrumb-active' : 'breadcrumb'}
                variant="caption"
                key={index}
              >
                {item.name}
              </Typography>
            )
          )}
        </BreadcrumbsMui>
      ) : (
        <BreadcrumbsMui
          aria-label="breadcrumbs"
          separator={''}
          sx={{
            '& .MuiBreadcrumbs-li': {
              height: '20px',
            },
          }}
        >
          <ChevronLeftIcon fontSize="small" />
          <Link className="breadcrumb" underline="hover" variant="caption" href={mobileBreadcrumbsUrl}>
            {mobileBreadcrumbsText}
          </Link>
        </BreadcrumbsMui>
      )}
    </>
  );
}

export default Breadcrumbs;
