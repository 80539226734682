import React, { useState, useMemo, useEffect, forwardRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { locations, locationsKey } from '../../config/locations';
import { avoid } from '../../helpers/utils';
import { getLocalConfig, getUniqueLocations,  createAndStoreLocalConfig } from '../../helpers/localConfigUtils';
import { getFeatureFlag } from '../../helpers/configUtilities';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import CommunicationPreferencesForm from '../myAccount/communicationPreferencesForm';

const Location = forwardRef((props, ref) => {
  const location = useLocation();
  const categoriesArray = location?.state?.categories || [];
  const communicationPreferencesEnabled = getFeatureFlag('communicationPreferencesEnabled');
  const [isClearDisabled, setClearDisabled] = useState(true);
  const [isMinim, setIsMinim] = useState(false);
  const [isTerms, setIsTerms] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState(['UK']);
  const [searchLocation, setSearchLocation] = useState([]);
  const [isLabelDisabled, setLabelDisabled] = useState(true);
  const [includeUKPublications, setIncludeUKPublications] = useState(false);
  const [marketingPermission, setMarketingPermission] = useState(false);
  const [pushNotificationPermission, setPushNotificationPermission] = useState(false);
  const [newsletterPermission, setNewsletterPermission] = useState(false);
  const [newsletterFrequency, setNewsletterFrequency] = useState('daily');
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false);
  const exclusions = [
    'International',
    'US',
    'UK',
    'Ireland',
    'Northern Ireland',
    'East',
    'East Midlands',
    'Humber & Lincolnshire',
    'North East',
    'North West',
    'Scotland',
    'South',
    'South West',
    'Wales',
    'West Midlands',
    'Yorkshire',
  ];

  let navigate = useNavigate();
  // GA4 tracking via gtag.js
  const gtag = window.gtag || avoid;

  const containsText = (text, searchCategory) => text.toLowerCase().indexOf(searchCategory) > -1;
  const displayedLocations = useMemo(() => {
    return locationsKey.filter((option) => containsText(option, searchLocation));
  }, [searchLocation]);

  const handleLocationChange = (event) => {
    setLabelDisabled(false);
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelectedLocations(selectedLocations.length === locationsKey.length ? [] : displayedLocations);
      return;
    }
    setSelectedLocations(value);
  };

  const handleInfoClose = () => {
    setIsTerms(false);
    setIsMinim(false);
  };

  const handleCheckboxChange = (event) => {
    setIncludeUKPublications(event.target.checked);
  }

  const handleSave = (locations) => {
  
    if (!termsAndConditionsAccepted) {
      setIsTerms(true);
      return;
    }

    if (selectedLocations.length <= 0) {
      setIsMinim(true);
      return;
    }

    const locationsArray = selectedLocations.reduce((acc, locationkey) => {
      locations.forEach(location => {
        if (location[locationkey]) {
          const locationSelection = locationkey.startsWith('- ') ? locationkey.slice(2) : locationkey;
  
          if (locationSelection) {
            acc.push(locationSelection);
          }
        }
      });
  
      return acc;
    }, []);

    createAndStoreLocalConfig(locationsArray, categoriesArray, termsAndConditionsAccepted, newsletterPermission, newsletterFrequency, marketingPermission, pushNotificationPermission);
  
    gtag('event', 'personalisation_location', {
      'event': 'clicked_location',
      'source': 'sign_up_flow',
    });
  
    localStorage.getItem('auth-user')
      ? (localStorage.setItem('mna-init-signup-or-signin', true), navigate('/signup/loader'))
      : navigate('/signup/login');
  };
  

  const handlePersonalizedFields = () => {
    const localConfig = getLocalConfig();
    const storedLocation = getUniqueLocations(localConfig);
    let locationsArray = [];

    if (storedLocation) {
      setClearDisabled(false);
      setLabelDisabled(false);
    }
    if (storedLocation.length > 0) {
      storedLocation.map((locationValue) => {
        locations.map((location) => {
          if (Object.keys(location)[0] === locationValue) {
            locationsArray.push(locationValue);
          } else if (Object.keys(location)[0] === '- ' + locationValue) {
            locationsArray.push('- ' + locationValue);
          }
        });
      });
      setSelectedLocations(locationsArray);
    }
  };

  useEffect(() => {
    document.title = 'My Location | My News Assistant';
    window.scrollTo(0, 0);
    handlePersonalizedFields();
  }, []);

  return (
    <div>
      {
        <div className='bordered-container'>
          <div className='top-container'>
            <IconButton aria-label='go back' edge='start' onClick={() => navigate('/signup/interests')}>
              <ArrowBackIcon color='timberGreen' />
            </IconButton>
            <Typography variant='body1'>
              <b>Customise</b> | step 2 of 2
            </Typography>
          </div>
          <Typography variant='h1'>
            My <span className='highlighted'>Location</span>
          </Typography>
          <Typography variant='label4' sx={{ display: 'block', mb: 2 }}>
            To create your personalised news feed, simply select your Location and well do the rest.
          </Typography>
          <div className='form-container'>
            <Box sx={{  width: '100%' }}>
              <DropdownMenu
                inputLabel='Location'
                selectedItems={selectedLocations}
                handleSelectionChange={handleLocationChange}
                handleSearchChange={setSearchLocation}
                displayedItems={displayedLocations}
              />
            </Box>
          </div>
          {/* <div className="content-text">
              <label>
                <input id="include-uk-pubs" type="checkbox" checked={includeUKPublications} onChange={handleCheckboxChange} /> Include UK national publications
              </label>
            </div> */}
          <div className="content-text">
            {communicationPreferencesEnabled && (
              <CommunicationPreferencesForm
                marketingPermission={marketingPermission}
                setMarketingPermission={setMarketingPermission}
                pushNotificationPermission={pushNotificationPermission}
                setPushNotificationPermission={setPushNotificationPermission}
                newsletterPermission={newsletterPermission}
                setNewsletterPermission={setNewsletterPermission}
                newsletterFrequency={newsletterFrequency}
                setNewsletterFrequency={setNewsletterFrequency}
              />
            )}
            <div>
              <label>
                <Typography variant='body1' color='common.black'>
                  <strong>Terms And Conditions</strong>
                </Typography>
                <Typography variant='body1'>
                  We Make My News Assistant better for you by tailoring your experience, and personalising your feed, to provide you with captivating
                  content, which includes some advertising.
                </Typography>
                <Typography variant='body1'>
                  This experience is enabled through our understanding of your answers, your preferences and location, and your browsing and use of My News
                  Assistant. You can control whether or not you see personalised and tailored content from within your account settings page.
                </Typography>
                <Typography variant='body1'>
                  Please see our{' '}
                  <Link
                    className='link'
                    target='_blank'
                    href='/legal/privacy-notice'
                  >
                    Privacy notice
                  </Link>
                  {' '}for information on how we handle your personal data.
                </Typography>
              </label>
            </div>
            <div>
              <Checkbox
                edge='start'
                color='primary'
                checked={termsAndConditionsAccepted}
                id='termsAndConditions'
                onChange={(e) => setTermsAndConditionsAccepted(e.target.checked)}
              />
              <label htmlFor='termsAndConditions'>
                <Typography variant='body1' component='span' >
                  I have read and agree to the{' '}
                  <Link
                    className='link'
                    href='/legal/terms-and-conditions'
                    target='_blank'
                  >
                    terms and conditions*
                  </Link>
                </Typography>
              </label>
            </div>
          </div>
          <Container className='flex-btn'>
            <Button
              onClick={() => handleSave(locations)}
              className='btn'
              color='primary'
              variant='contained'
            >
              Continue
            </Button>
          </Container>
        </div>
      }
      {
        <Dialog open={isMinim} aria-labelledby="responsive-dialog-title">
          <DialogContent>
            <Typography id='responsive-dialog-title' variant='h3' sx={{ mx: 2, mt: 4, mb: 2 }}>Select a Location</Typography>
            <Typography variant='h6' sx={{ mx: 2 }}>Please select a Location</Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              autoFocus
              onClick={handleInfoClose}
              color='primary'
              variant='contained'
              sx={{ width: '100%', mx: 4, mb: 4 }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      }
      {
        <Dialog open={isTerms} aria-labelledby="responsive-dialog-title">
          <DialogContent>
            <Typography id='responsive-dialog-title' variant='h3' sx={{ mx: 2, mt: 4, mb: 2 }}>{'Info'}</Typography>
            <Typography variant='h6' sx={{ mx: 2 }}>Please agree to the terms and conditions.</Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              autoFocus
              onClick={handleInfoClose}
              color='primary'
              variant='contained'
              sx={{ width: '100%', mx: 4, mb: 4 }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
});

Location.displayName = 'Location';
export default Location;
