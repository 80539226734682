const getRouteByName = (routeName) => {
	const routeConfig = routeData[routeName];
	if (routeConfig) {
		return routeConfig;
	}
	for (const routeConfig of Object.values(routeData)) {
		if (routeConfig.sectionSlug === routeName) {
			return routeConfig;
		}
	}
	return undefined;
}

const routeData = {
	'news': {
		"displayName": "News",
		"isEnabled": true,
		"key": "news",
		"metaDescription": "Stay up-to-date with the latest news headlines and breaking stories from around the world",
		"metaImage": "",
		"metaTitle": "Latest News Headlines and Breaking Stories",
		"sectionSlug": "news",
		"subSectionSlug": "",
		"sectionDescription": "My News Assistant is your go-to source for the latest news and breaking stories from around the world.",
		"sectionImage": ""
	},
	'entertainment': {
		"displayName": "Entertainment",
		"isEnabled": true,
		"key": "entertainment",
		"metaDescription": "Get the latest entertainment news, celebrity gossip and reviews from My News Assistant",
		"metaImage": "",
		"metaTitle": "Entertainment News, Celebrity Gossip and Reviews",
		"sectionSlug": "entertainment",
		"subSectionSlug": "",
		"sectionDescription": "My News Assistant is your one-stop shop for the latest entertainment news, celebrity gossip, and reviews.",
		"sectionImage": ""
	},
	'red-carpet': {
		"displayName": "Red Carpet",
		"isEnabled": true,
		"key": "red-carpet",
		"metaDescription": "My News Assistantc is your source for the latest red carpet photos, fashion, and gossip",
		"metaImage": "",
		"metaTitle": "Red Carpet Photos, Fashion and Gossip",
		"sectionSlug": "entertainment",
		"subSectionSlug": "red-carpet",
		"sectionDescription": "My News Assistant brings you the latest red carpet photos, fashion, and gossip from around the world.",
		"sectionImage": ""
	},
	'movies': {
		"displayName": "Movies",
		"isEnabled": true,
		"key": "movies",
		"metaDescription": "My News Assistant is your source for the latest movie reviews, trailers, and box office updates",
		"metaImage": "",
		"metaTitle": "Latest Movie Reviews, Trailers, and Box Office",
		"sectionSlug": "entertainment",
		"subSectionSlug": "movies",
		"sectionDescription": "My News Assistant brings you the latest movie reviews, trailers, and box office updates.",
		"sectionImage": ""
	},
	'tv-shows': {
		"displayName": "TV Shows",
		"isEnabled": true,
		"key": "tv-shows",
		"metaDescription": "My News Assistant is your source for the latest TV show news, reviews, and recaps",
		"metaImage": "",
		"metaTitle": "Latest TV Show News, Reviews, and Recaps",
		"sectionSlug": "entertainment",
		"subSectionSlug": "tv-shows",
		"sectionDescription": "My News Assistant brings you the latest TV show news, reviews, and recaps.",
		"sectionImage": ""
	},
	'music': {
		"displayName": "Music",
		"isEnabled": true,
		"key": "music",
		"metaDescription": "My News Assistant is your source for the latest music news, reviews, and releases",
		"metaImage": "",
		"metaTitle": "Latest Music News, Reviews, and Releases",
		"sectionSlug": "entertainment",
		"subSectionSlug": "music",
		"sectionDescription": "My News Assistant brings you the latest music news, reviews, and releases.",
		"sectionImage": ""
	},
	'awards-and-events': {
		"displayName": "Awards and Events",
		"isEnabled": true,
		"key": "awards-and-events",
		"metaDescription": "Stay updated on the latest awards shows and entertainment events. Get celebrity gossip, reviews, and more from My News Assistant.",
		"metaImage": "",
		"metaTitle": "Latest Awards and Events Coverage",
		"sectionSlug": "entertainment",
		"subSectionSlug": "awards-and-events",
		"sectionDescription": "My News Assistant is your go-to source for the latest updates on awards shows, entertainment events, celebrity gossip, and reviews.",
		"sectionImage": ""
	},
	'business-and-finance': {
		"displayName": "Business and Finance",
		"isEnabled": true,
		"key": "business-and-finance",
		"metaDescription": "My News Assistant is your source for the latest business and finance news and analysis",
		"metaImage": "",
		"metaTitle": "Latest Business and Finance News and Analysis",
		"sectionSlug": "business-and-finance",
		"subSectionSlug": "",
		"sectionDescription": "My News Assistant brings you the latest business and finance news and analysis.",
		"sectionImage": ""
	},
	'business': {
		"displayName": "Business",
		"isEnabled": true,
		"key": "business",
		"metaDescription": "My News Assistant is your source for the latest business news and analysis",
		"metaImage": "",
		"metaTitle": "Latest Business News and Analysis",
		"sectionSlug": "business-and-finance",
		"subSectionSlug": "business",
		"sectionDescription": "My News Assistant brings you the latest business news and analysis.",
		"sectionImage": ""
	},
	'finance': {
		"displayName": "Finance",
		"isEnabled": true,
		"key": "finance",
		"metaDescription": "My News Assistant is your source for the latest finance news and analysis",
		"metaImage": "",
		"metaTitle": "Latest Finance News and Analysis",
		"sectionSlug": "business-and-finance",
		"subSectionSlug": "finance",
		"sectionDescription": "My News Assistant brings you the latest finance news and analysis.",
		"sectionImage": ""
	},
	'life-and-style': {
		"displayName": "Life and Style",
		"isEnabled": true,
		"key": "life-and-style",
		"metaDescription": "My News Assistant is your source for the latest lifestyle news and trends",
		"metaImage": "",
		"metaTitle": "Latest Lifestyle News and Trends",
		"sectionSlug": "life-and-style",
		"subSectionSlug": "",
		"sectionDescription": "My News Assistant brings you the latest lifestyle news and trends.",
		"sectionImage": ""
	},
	'fashion': {
		"displayName": "Fashion",
		"isEnabled": true,
		"key": "fashion",
		"metaDescription": "My News Assistant is your source for the latest fashion news and trends",
		"metaImage": "",
		"metaTitle": "Latest Fashion News and Trends",
		"sectionSlug": "life-and-style",
		"subSectionSlug": "fashion",
		"sectionDescription": "My News Assistant brings you the latest fashion news and trends.",
		"sectionImage": ""
	},
	'food-and-drink': {
		"displayName": "Food and Drink",
		"isEnabled": true,
		"key": "food-and-drink",
		"metaDescription": "My News Assistant is your source for the latest food and drink news and reviews",
		"metaImage": "",
		"metaTitle": "Latest Food and Drink News and Reviews",
		"sectionSlug": "life-and-style",
		"subSectionSlug": "food-and-drink",
		"sectionDescription": "My News Assistant brings you the latest food and drink news and reviews.",
		"sectionImage": ""
	},
	'health-fitness-nutrition': {
		"displayName": "Health and Fitness and Nutrition",
		"isEnabled": true,
		"key": "health-fitness-nutrition",
		"metaDescription": "My News Assistant is your source for the latest health, fitness, and nutrition news and advice",
		"metaImage": "",
		"metaTitle": "Latest Health, Fitness, and Nutrition News and Advice",
		"sectionSlug": "life-and-style",
		"subSectionSlug": "health-fitness-nutrition",
		"sectionDescription": "My News Assistant brings you the latest health, fitness, and nutrition news and advice.",
		"sectionImage": ""
	},
	'relationships': {
		"displayName": "Relationships",
		"isEnabled": true,
		"key": "relationships",
		"metaDescription": "My News Assistant is your source for the latest relationship advice and news",
		"metaImage": "",
		"metaTitle": "Latest Relationship Advice and News",
		"sectionSlug": "life-and-style",
		"subSectionSlug": "relationships",
		"sectionDescription": "My News Assistant brings you the latest relationship advice and news.",
		"sectionImage": ""
	},
	'politics-and-society': {
		"displayName": "Politics and Society",
		"isEnabled": true,
		"key": "politics-and-society",
		"metaDescription": "My News Assistant is your source for the latest politics and society news and analysis",
		"metaImage": "",
		"metaTitle": "Latest Politics and Society News and Analysis",
		"sectionSlug": "politics-and-society",
		"subSectionSlug": "",
		"sectionDescription": "My News Assistant brings you the latest politics and society news and analysis.",
		"sectionImage": ""
	},
	'politics': {
		"displayName": "Politics",
		"isEnabled": true,
		"key": "politics",
		"metaDescription": "My News Assistant is your source for the latest political news and analysis",
		"metaImage": "",
		"metaTitle": "Latest Political News and Analysis",
		"sectionSlug": "politics-and-society",
		"subSectionSlug": "politics",
		"sectionDescription": "My News Assistant brings you the latest political news and analysis.",
		"sectionImage": ""
	},
	'society-and-culture': {
		"displayName": "Society and Culture",
		"isEnabled": true,
		"key": "society-and-culture",
		"metaDescription": "My News Assistant is your source for the latest society and culture news and analysis",
		"metaImage": "",
		"metaTitle": "Latest Society and Culture News and Analysis",
		"sectionSlug": "politics-and-society",
		"subSectionSlug": "society-and-culture",
		"sectionDescription": "My News Assistant brings you the latest society and culture news and analysis.",
		"sectionImage": ""
	},
	'science-and-education': {
		"displayName": "Science and Education",
		"isEnabled": true,
		"key": "science-and-education",
		"metaDescription": "My News Assistant is your source for the latest science and education news and analysis",
		"metaImage": "",
		"metaTitle": "Latest Science and Education News and Analysis",
		"sectionSlug": "science-and-education",
		"subSectionSlug": "",
		"sectionDescription": "My News Assistant brings you the latest science and education news and analysis.",
		"sectionImage": ""
	},
	'science': {
		"displayName": "Science",
		"isEnabled": true,
		"key": "science",
		"metaDescription": "My News Assistant is your source for the latest science news and analysis",
		"metaImage": "",
		"metaTitle": "Latest Science and Analysis",
		"sectionSlug": "science-and-education",
		"subSectionSlug": "science",
		"sectionDescription": "My News Assistant brings you the latest science news and analysis.",
		"sectionImage": ""
	},
	'education': {
		"displayName": "Education",
		"isEnabled": true,
		"key": "education",
		"metaDescription": "My News Assistant is your source for the latest education news and analysis",
		"metaImage": "",
		"metaTitle": "Latest Education News and Analysis",
		"sectionSlug": "science-and-education",
		"subSectionSlug": "education",
		"sectionDescription": "My News Assistant brings you the latest education news and analysis.",
		"sectionImage": ""
	},
	'sports': {
		"displayName": "Sports",
		"isEnabled": true,
		"key": "sports",
		"metaDescription": "My News Assistant is your source for the latest sports news and analysis",
		"metaImage": "",
		"metaTitle": "Latest Sports News and Analysis",
		"sectionSlug": "sports",
		"subSectionSlug": "",
		"sectionDescription": "My News Assistant brings you the latest sports news and analysis.",
		"sectionImage": ""
	},
	'football': {
		"displayName": "Football",
		"isEnabled": true,
		"key": "football",
		"metaDescription": "My News Assistant is your source for the latest football news and analysis",
		"metaImage": "",
		"metaTitle": "Latest Football News and Analysis",
		"sectionSlug": "sports",
		"subSectionSlug": "football",
		"sectionDescription": "My News Assistant brings you the latest football news and analysis.",
		"sectionImage": ""
	},
	'other-sports': {
		"displayName": "Other Sports",
		"isEnabled": true,
		"key": "other-sports",
		"metaDescription": "My News Assistant is your source for the latest other sports news and analysis",
		"metaImage": "",
		"metaTitle": "Latest Other Sports News and Analysis",
		"sectionSlug": "sports",
		"subSectionSlug": "other-sports",
		"sectionDescription": "My News Assistant brings you the latest other sports news and analysis.",
		"sectionImage": ""
	},
	'technology': {
		"displayName": "Technology",
		"isEnabled": true,
		"key": "technology",
		"metaDescription": "My News Assistant is your source for the latest technology news and reviews",
		"metaImage": "",
		"metaTitle": "Latest Technology News and Reviews",
		"sectionSlug": "technology",
		"subSectionSlug": "",
		"sectionDescription": "My News Assistant brings you the latest technology news and reviews.",
		"sectionImage": ""
	},
	'tech': {
		"displayName": "Tech",
		"isEnabled": true,
		"key": "tech",
		"metaDescription": "My News Assistant is your source for the latest tech news and reviews",
		"metaImage": "",
		"metaTitle": "Latest Tech News and Reviews",
		"sectionSlug": "technology",
		"subSectionSlug": "tech",
		"sectionDescription": "My News Assistant brings you the latest tech news and reviews.",
		"sectionImage": ""
	},
	'travel-and-events': {
		"displayName": "Travel and Events",
		"isEnabled": true,
		"key": "travel-and-events",
		"metaDescription": "My News Assistant is your source for the latest travel and events news and guides",
		"metaImage": "",
		"metaTitle": "Latest Travel and Events News and Guides",
		"sectionSlug": "travel-and-events",
		"subSectionSlug": "",
		"sectionDescription": "My News Assistant brings you the latest travel and events news and guides.",
		"sectionImage": ""
	},
	'travel': {
		"displayName": "Travel",
		"isEnabled": true,
		"key": "travel",
		"metaDescription": "My News Assistant is your source for the latest travel news and guides",
		"metaImage": "",
		"metaTitle": "Latest Travel News and Guides",
		"sectionSlug": "travel-and-events",
		"subSectionSlug": "travel",
		"sectionDescription": "My News Assistant brings you the latest travel news and guides.",
		"sectionImage": ""
	},
	'events': {
		"displayName": "Events",
		"isEnabled": true,
		"key": "events",
		"metaDescription": "My News Assistant is your source for the latest events news and highlights",
		"metaImage": "",
		"metaTitle": "Latest Events News and Highlights",
		"sectionSlug": "travel-and-events",
		"subSectionSlug": "events",
		"sectionDescription": "My News Assistant brings you the latest events news and highlights.",
		"sectionImage": ""
	},
	'discover': {
		"displayName": "Discover",
		"isEnabled": true,
		"key": "discover",
		"metaDescription": "Explore the latest and greatest news, reviews, and stories across a wide range of topics. From the latest technology trends to cutting-edge scientific discoveries, we've got it all covered.",
		"metaImage": "",
		"metaTitle": "Discover the Latest and Greatest Across a Range of Topics",
		"sectionSlug": "discover",
		"subSectionSlug": "",
		"sectionDescription": "My News Assistant is your one-stop-shop for exploring the latest and greatest across a range of topics. Discover new and exciting stories, trends, and developments across a wide range of subjects, including technology, science, entertainment, lifestyle, and more.",
		"sectionImage": ""
	},
	'animals': {
		"displayName": "Animals",
		"isEnabled": true,
		"key": "animals",
		"metaDescription": "My News Assistant is your source for the latest animal news and stories",
		"metaImage": "",
		"metaTitle": "Latest Animal News and Stories",
		"sectionSlug": "discover",
		"subSectionSlug": "animals",
		"sectionDescription": "My News Assistant brings you the latest animal news and stories.",
		"sectionImage": ""
	},
	'motoring': {
		"displayName": "Motoring",
		"isEnabled": true,
		"key": "motoring",
		"metaDescription": "My News Assistant is your source for the latest motoring news and reviews",
		"metaImage": "",
		"metaTitle": "Latest Motoring News and Reviews",
		"sectionSlug": "discover",
		"subSectionSlug": "motoring",
		"sectionDescription": "My News Assistant brings you the latest motoring news and reviews.",
		"sectionImage": ""
	},
	'charity': {
		"displayName": "Charity",
		"isEnabled": true,
		"key": "charity",
		"metaDescription": "Immerse yourself in a world of compassion and impact through charitable stories. We are your source for meaningful narratives and positive change. Join us in fostering a brighter future through impactful charitable initiatives. Stay connected, make a difference, and be part of a community dedicated to creating positive impact.",
		"metaImage": "",
		"metaTitle": "Latest Charity News and Stories",
		"sectionSlug": "discover",
		"subSectionSlug": "charity",
		"sectionDescription": "My News Assistant brings you the latest charitable news stories.",
		"sectionImage": ""
	},
	'topic': {
		"displayName": "Topic",
		"isEnabled": true,
		"key": "topic",
		"metaDescription": "My News Assistant covers a diverse range of topics to keep you informed and entertained. From breaking news and insightful analysis to lifestyle trends and fascinating stories, we have you covered.",
		"metaImage": "",
		"metaTitle": "Stay Informed and Entertained with Our Diverse Range of Topics",
		"sectionSlug": "topic",
		"subSectionSlug": "",
		"sectionDescription": "My News Assistant offers a diverse range of coverage across various topics to keep you informed and entertained. From breaking news and insightful analysis on politics and society to the latest technology trends and scientific discoveries, we have you covered.",
		"sectionImage": ""
	},
	'art-and-design': {
		"displayName": "Art and Design",
		"isEnabled": true,
		"key": "art-and-design",
		"metaDescription": "My News Assistant is your source for the latest art and design news and trends",
		"metaImage": "",
		"metaTitle": "Latest Art and Design News and Trends",
		"sectionSlug": "art-and-design",
		"subSectionSlug": "",
		"sectionDescription": "My News Assistant brings you the latest art and design news and trends.",
		"sectionImage": ""
	},
};

export {
	getRouteByName
};