import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 350,
    },
  },
  getcontentanchorel: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

const DropdownMenu = ({
  inputLabel,
  selectedItems,
  handleSelectionChange,
  handleSearchChange,
  displayedItems
}) => {
  const kebabCaseId = (text) => text.split(' ').map(word => word.toLowerCase()).join('_');

  return (
    <FormControl variant='standard' sx={{ width: '100%', my: 1 }}>
      <InputLabel id={kebabCaseId(inputLabel)}>
        {inputLabel}
      </InputLabel>
      <Select
        data-testid={kebabCaseId(inputLabel)}
        labelId={kebabCaseId(inputLabel)}
        multiple
        sx={{ fontSize: '1rem' }}
        value={selectedItems}
        onChange={handleSelectionChange}
        renderValue={(selectedItems) => selectedItems.join(', ')}
        MenuProps={MenuProps}
        IconComponent={() => <KeyboardArrowDownIcon fontSize='large' color='osloGrey' />}
      >
        <ListSubheader sx={{ pt: 1 }}>
          <OutlinedInput
            fullWidth
            type='text'
            placeholder='Type to search'
            aria-label={'search ' + inputLabel.toLowerCase()}
            onChange={e => handleSearchChange(e.target.value.toLowerCase())}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon color='osloGrey' />
              </InputAdornment>
            }
          />
        </ListSubheader>
        {displayedItems.map((option) => (
          <MenuItem key={option} value={option} dense >
            <ListItemIcon sx={{ minWidth: 'unset' }}>
              <Checkbox
                color='primary'
                checked={selectedItems.indexOf(option) > -1}
                inputProps={{ "aria-labelledby": option}}
              />
            </ListItemIcon>
            <ListItemText id={option} primary={option}/>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default DropdownMenu;