const locations = [
  {'International': 'dailyrecord,dailystar,dublinlive,express,football,footballscotland,glasgowlive,inyourarea,irishmirror,liverpool,mirror,ok,rsvplive,irishstar,the-express,themirror'},
  {'US': 'irishstar,the-express,themirror,liverpool'},
  {'- New York': 'irishstar,the-express'},
  {'UK': 'business-live,dailyrecord,dailystar,express,glasgowlive,insider,inyourarea,mirror,ok,rsvplive'},
  {'Ireland': 'buzz,corkbeo,dublinlive,galwaybeo,irishmirror'},
  {'Northern Ireland': 'belfastlive'},
  {'East': 'bedfordshirelive,cambridgenews,hertfordshiremercury,northantslive'},
  {'- Bedfordshire': 'bedfordshirelive'},
  {'- Cambridge': 'cambridgenews'},
  {'- Hertfordshire': 'hertfordshiremercury'},
  {'- Northamptonshire': 'northantslive'},
  {'East Midlands': 'derbytelegraph,leicestermercury,nottinghampost,burtonmail'},
  {'- Derbyshire': 'derbytelegraph'},
  {'- Leicestershire': 'leicestermercury'},
  {'- Nottinghamshire': 'nottinghampost'},
  {'Humber & Lincolnshire': 'grimsbytelegraph,hulldailymail,lincolnshirelive'},
  {'- Grimsby': 'grimsbytelegraph'},
  {'- Hull': 'hulldailymail'},
  {'- Lincolnshire': 'lincolnshirelive'},
  {'North East': 'chroniclelive,gazettelive'},
  {'- Teesside':'chroniclelive,gazettelive'},
  {'North West':'chesterchronicle,liverpool,liverpoolecho,manchestereveningnews,accrington'},
  {'- Cheshire':'chesterchronicle'},
  {'- Liverpool': 'liverpool,liverpoolecho'},
  {'- Manchester':'manchestereveningnews'},
  {'- Sefton & Lancashire': 'accrington'},
  {'Scotland': 'dailyrecord,edinburghlive,footballscotland,insider,dailyrecord,footballscotland,glasgowlive,insider'},
  {'- Edinburgh': 'dailyrecord,edinburghlive,footballscotland,insider'},
  {'- Glasgow': 'dailyrecord,footballscotland,glasgowlive,insider'},
  {'South': 'buckinghamshirelive,essexlive,kentlive,football,mylondon,oxfordshirelive,getsurrey,sussexlive,hampshirelive'},
  {'- Buckinghamshire': 'buckinghamshirelive'},
  {'- Essex': 'essexlive'},
  {'- Hampshire': 'hampshirelive'},
  {'- Kent': 'kentlive'},
  {'- London': 'football,mylondon'},
  {'- Oxfordshire': 'oxfordshirelive'},
  {'- Surrey & Hampshire': 'getsurrey'},
  {'- Sussex': 'sussexlive'},
  {'South West': 'getreading,bristolpost,cornwalllive,devonlive,plymouthherald,dorset,gloucestershirelive,somersetlive'},
  {'- Berkshire': 'getreading'},
  {'- Bristol': 'bristolpost'},
  {'- Cornwall': 'cornwalllive'},
  {'- Devon': 'devonlive,plymouthherald'},
  {'- Dorset': 'dorset'},
  {'- Gloucestershire': 'gloucestershirelive'},
  {'- Somerset': 'somersetlive'},
  {'Wales': 'dailypost,walesonline'},
  {'- North Wales': 'dailypost,walesonline'},
  {'- South & West Wales': 'walesonline'},
  {'West Midlands': 'coventrytelegraph,birminghammail,stokesentinel'},
  {'- Coventry & Warwickshire': 'coventrytelegraph'},
  {'- Greater Birmingham': 'birminghammail'},
  {'- Staffordshire': 'stokesentinel'},
  {'Yorkshire': 'leedslive,examinerlive'},
  {'- Leeds':'leedslive'},
  {'- West Yorkshire': 'examinerlive'}
]

const locationsKey = [
  'International',
  'US',
  '- New York',
  'UK',
  'Ireland',
  'Northern Ireland',
  'East',
  '- Bedfordshire',
  '- Cambridge',
  '- Hertfordshire',
  '- Northamptonshire',
  'East Midlands',
  '- Derbyshire',
  '- Leicestershire',
  '- Nottinghamshire',
  'Humber & Lincolnshire',
  '- Grimsby',
  '- Hull',
  '- Lincolnshire',
  'North East',
  '- Teesside',
  'North West',
  '- Cheshire',
  '- Liverpool',
  '- Manchester',
  '- Sefton & Lancashire',
  'Scotland',
  '- Edinburgh',
  '- Glasgow',
  'South',
  '- Buckinghamshire',
  '- Essex',
  '- Kent',
  '- London',
  '- Oxfordshire',
  '- Surrey & Hampshire',
  '- Sussex',
  'South West',
  '- Berkshire',
  '- Bristol',
  '- Cornwall',
  '- Devon',
  '- Dorset',
  '- Gloucestershire',
  '- Somerset',
  'Wales',
  '- North Wales',
  '- South & West Wales',
  'West Midlands',
  '- Coventry & Warwickshire',
  '- Greater Birmingham',
'- Staffordshire',
  'Yorkshire',
  '- Leeds',
'- West Yorkshire'
]

export { locations, locationsKey };
