import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import { isLoginRadiusUser, getEnvConfig } from '../../helpers/utils';
import { removeLocalConfig } from '../../helpers/localConfigUtils';
import { dispatchLoginRadiusEvent } from '../../helpers/loginRadiusEvents';
import CustomPopup from '../../components/CustomPopup/CustomPopup';
import TooltipComponent from '../../components/tooltip/Tooltip';
import PasswordStrength from './passwordStrength';
import logoimage from '../../assets/mna_alt_lr_logo.png';
import './myProfile.scss';

export default function ProfilePage() {
  const [isDelete, setDelete] = useState(false);
  const [isAlert, setAlert] = useState(false);
  const [isPasswordPopUp, setPasswordPopUp] = useState(false);
  const [CurrentPassword, setCurrentPassword] = useState('');
  const [NewPassword, setNewPassword] = useState('');
  const [isPasswordInCorrectAlert, setIsPasswordInCorrectAlert] = useState(false);
  const [isPasswordnotMatchAlert, setIsPasswordnotMatchAlert] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isSuccessDeleteAlert, setSuccessDeleteAlert] = useState(false);
  const [ChangeProfileCount, setChangeProfileCount] = useState(0);
  const [isDesktopView, setIsDesktopView] = React.useState(window.innerWidth > 768);
  const authUser = JSON.parse(localStorage.getItem('auth-user'));
  const firstName = authUser.FirstName;
  const lastName = authUser.LastName;
  const userName = authUser.UserName;
  const email = authUser.Email[0].Value;
  const formFields = [
    {
      label: 'Email',
      value: email,
    },
    {
      label: 'First Name',
      value: firstName,
    },
    {
      label: 'Last Name',
      value: lastName,
    },
    {
      label: 'Nick Name',
      value: userName,
    },
  ];
  let isCompleteProfileTriggered = false;

  let navigate = useNavigate();
  const envConfig = getEnvConfig();

  const handleClickShowCurrentPassword = () => setShowCurrentPassword((show) => !show);

  const handleMouseDownCurrentPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  const handleDeleteAccount = async () => {
    const deleteUserURL = 'https://5yep17ytxf.execute-api.eu-west-1.amazonaws.com/prod/delete';
    try {
      let response = await axios.get(`${deleteUserURL}?token=${localStorage.loginRadiusToken}`);
      if (response.status != 200) {
        setAlert(true);
      } else {
        setDelete(false);
        setSuccessDeleteAlert(true);
      }
      return response;
    } catch (error) {
      setDelete(false);
      setAlert(true);
      return [];
    }
  };

  const handleDelete = () => {
    setDelete(true);
  };

  const handleDeleteClose = () => {
    setDelete(false);
  };

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
    setTooltipOpen(true);
  };

  const handleNewPasswordOnClick = (e) => {
    setNewPassword(e.target.value);
    setTooltipOpen(true);
  };

  const handleClickAway = () => {
    setTooltipOpen(false);
  };

  const handleClickChangePassword = async () => {
    const changePasswordURL = `https://api.loginradius.com/identity/v2/auth/password/change?apikey=${envConfig.APIKEY}`;
    const requestPayload = {
      newpassword: NewPassword,
      oldpassword: CurrentPassword,
    };
    try {
      let response = await axios.put(`${changePasswordURL}`, requestPayload, { headers: { Authorization: `Bearer ${localStorage.loginRadiusToken}` } });
      if (response.status != 200) {
        setAlert(true);
      } else {
        setPasswordPopUp(false);
      }
      return response;
    } catch (error) {
      setPasswordPopUp(false);
      if (error.response) {
        if (error.response.data.Message == 'Current password is invalid') {
          setIsPasswordInCorrectAlert(true);
        } else if (error.response.data.Message == 'Validation failed for one or more fields') {
          setIsPasswordnotMatchAlert(true);
        } else {
          setAlert(true);
        }
      } else {
        setAlert(true);
      }
    }
  };
  const handleSignOut = () => {
    dispatchLoginRadiusEvent('auth-ui.logout');
    removeLocalConfig();
  };

  const handlePassword = () => {
    setPasswordPopUp(true);
  };

  const handleEditProfile = () => {
    dispatchLoginRadiusEvent('auth-ui.complete-profile');
  };

  useEffect(() => {
    window.addEventListener('loginradius.user.profile', (event) => {
      const data = event.data || event.detail || {};
      if (data.UserName && !isCompleteProfileTriggered) {
        isCompleteProfileTriggered = true;
        setChangeProfileCount(ChangeProfileCount + 1);
      }
    });
    window.scrollTo(0, 0);
    if (!window.AuthUI) {
      navigate(0);
    }
  }, [ChangeProfileCount]);

  useEffect(() => {
    const updateMedia = () => setIsDesktopView(window.innerWidth > 768);
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div className="profile-page">
      {isLoginRadiusUser() && (
        <>
          <Typography variant="h2">
            Welcome <span className="highlighted">Back {authUser ? firstName : ''}</span>
          </Typography>
          <form>
            {formFields.map(({ label, value }) => {
              return (
                <TextField
                  type="text"
                  fullWidth
                  margin="dense"
                  variant="standard"
                  label={label}
                  key={label}
                  value={value}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              );
            })}
          </form>
          <div className="change-profile">
            <Link component="button" onClick={handleEditProfile} className="link-button" underline="none" color="primary" data-testid="change-profile-button">
              Change profile
            </Link>
            <Link component="button" onClick={handlePassword} className="link-button" underline="none" color="primary">
              Change password
            </Link>
          </div>
          {isDesktopView && (
            <Button onClick={handleSignOut} className="btn" color="primary" variant="contained" data-testid="myprofile-logout-button" sx={{ mt: 3, mb: 4 }}>
              Sign out
            </Button>
          )}
          <div className="delete-profile">
            <Typography variant="body1">
              Please note that deleting your account is permanent and means that you will no longer have access across any of our sites. All comments associated
              with your account will be permanently removed.
            </Typography>
            <Button onClick={handleDelete} className="btn-alert" color="error" variant="contained" size="large">
              Delete Account
            </Button>
          </div>

          {/* DELETE ACCOUNT POPUP */}
          <CustomPopup
            isOpen={isDelete}
            onClose={handleDeleteClose}
            logo={logoimage}
            actionButtons={[{ onClick: () => handleDeleteAccount(), label: 'Delete account' }]}
            logoStyles={{ width: '112px' }}
          >
            <Typography variant="body1" id="max-width-dialog-title">
              Are you sure you want to delete your account?
            </Typography>
          </CustomPopup>

          {/* CHANGE PASSWORD POPUP */}
          <CustomPopup
            isOpen={isPasswordPopUp}
            onClose={() => {
              setPasswordPopUp(false);
              setShowCurrentPassword(false);
              setShowNewPassword(false);
            }}
            logo={logoimage}
            actionButtons={[{ onClick: () => handleClickChangePassword(), label: 'Change Password' }]}
            logoStyles={{ width: '112px' }}
          >
            <Typography variant="h1" id="max-width-dialog-title">
              Change <span className="highlighted">Password</span>
            </Typography>
            <FormControl sx={{ mt: 2, width: '100%' }} variant="standard">
              <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel>
              <Input
                id="outlined-adornment-current-password"
                type={showCurrentPassword ? 'text' : 'password'}
                onChange={handleCurrentPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowCurrentPassword} onMouseDown={handleMouseDownCurrentPassword}>
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <ClickAwayListener onClickAway={handleClickAway}>
              <FormControl sx={{ mt: 1, width: '100%' }} variant="standard">
                <InputLabel htmlFor="outlined-adornment-new-password">New Password</InputLabel>
                <TooltipComponent placement={'top'} title={<PasswordStrength password={NewPassword} />} open={tooltipOpen}>
                  <Input
                    id="outlined-adornment-password"
                    type={showNewPassword ? 'text' : 'password'}
                    onChange={handleNewPassword}
                    onClick={handleNewPasswordOnClick}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowNewPassword} onMouseDown={handleMouseDownNewPassword}>
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </TooltipComponent>
              </FormControl>
            </ClickAwayListener>
          </CustomPopup>

          {/* ALERT POPUP */}
          <CustomPopup
            isOpen={isAlert}
            onClose={() => setAlert(false)}
            logo={logoimage}
            actionButtons={[{ onClick: () => setAlert(false), label: 'OK' }]}
            logoStyles={{ width: '112px' }}
          >
            <Typography variant="body1" id="max-width-dialog-title">
              Something went wrong. Please try again
            </Typography>
          </CustomPopup>

          {/* Password incorrect dialog */}
          <CustomPopup
            isOpen={isPasswordInCorrectAlert}
            onClose={() => setIsPasswordInCorrectAlert(false)}
            logo={logoimage}
            actionButtons={[{ onClick: () => setIsPasswordInCorrectAlert(false), label: 'OK' }]}
            logoStyles={{ width: '112px' }}
          >
            <Typography variant="body1" id="max-width-dialog-title">
              Your current password is invalid, please use the correct password
            </Typography>
          </CustomPopup>

          {/* Password haven't match with requirements */}
          <CustomPopup
            isOpen={isPasswordnotMatchAlert}
            onClose={() => setIsPasswordnotMatchAlert(false)}
            logo={logoimage}
            actionButtons={[{ onClick: () => setIsPasswordnotMatchAlert(false), label: 'OK' }]}
            logoStyles={{ width: '112px' }}
          >
            <Typography variant="body1" id="max-width-dialog-title">
              New password does not meet the complexity requirements
            </Typography>
          </CustomPopup>

          {/* SUCCESSFUL DELETE POPUP */}
          <CustomPopup
            isOpen={isSuccessDeleteAlert}
            onClose={() => setSuccessDeleteAlert(false)}
            logo={logoimage}
            actionButtons={[{ onClick: () => handleSignOut(), label: 'Close' }]}
            logoStyles={{ width: '112px' }}
          >
            <Typography variant="body1" id="max-width-dialog-title">
              Your account has been successfully deleted
            </Typography>
          </CustomPopup>
        </>
      )}
    </div>
  );
}
