import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import ShareButtons from '../../CustomPopup/ShareButtons/ShareButtons';
import './ShareButtonsPopup.scss';

const ShareButtonsPopup = ({ isOpen, onClose, logo, title, url, handleCopy }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="responsive-dialog-title" className="share-button-dialog">
      <IconButton onClick={onClose} size="small" sx={{ position: 'absolute', top: '5px', right: '5px' }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography id="responsive-dialog-title" variant="h3" sx={{ my: 2 }}>
          Share
        </Typography>
        <div className="copy-link">
          <div className="inline-logo">
            <img src={logo} alt="Logo" />
          </div>
          <Typography variant="h6" className="copy-title">
            {title}
          </Typography>
          <Button startIcon={<LinkIcon />} onClick={handleCopy} className="copy-link-button">
            Copy Link
          </Button>
        </div>
        <hr className="divider" />
        <ShareButtons shareUrl={url} title={title} />
      </DialogContent>
    </Dialog>
  );
};

export default ShareButtonsPopup;
