import { Outlet } from 'react-router-dom';
import './embedLayout.scss'

export default function EmbedLayout() {
  return (
    <div className="embed-page-container">
      <Outlet />
    </div>
  );
}
