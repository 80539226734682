import React from 'react';
import { __log } from '../../helpers/utils';

const ResponsiveImage = ({ imageThumbnail, defaultImage, altText }) => {
  const isDefaultImage = imageThumbnail === defaultImage;
  const imageComponent = getImageComponent(imageThumbnail || defaultImage, defaultImage, isDefaultImage, altText);

  return (
    <div>
      {imageComponent}
    </div>
  );
};

const getImageComponent = (imageSrc, defaultImage, isDefaultImage, altText) => {
  try {
    const url = new URL(imageSrc);
    const hostname = url?.hostname;

    let modifiedImageSrc = imageSrc;
    let srcset = ''; // Declare srcset variable here
    let sizes = ''; // Declare sizes variable here

    if (hostname === 'cdn.images.express.co.uk' || isDefaultImage) {
      modifiedImageSrc = imageSrc.replace('.jpg', '.webp');
    } else if (hostname === 'd4oy8w59p2l43.cloudfront.net') {
      modifiedImageSrc = imageSrc;
    } else {
      const replaceImageSrc = (modifier) => imageSrc.replace('s1200', modifier).replace('.jpg', '.webp');
      srcset = `${replaceImageSrc('r250')} 250w, ${replaceImageSrc('r500')} 500w`; // Assign srcset value here
      sizes = '(max-width: 1913px) 300px, 100vw'; // Assign sizes value here
      modifiedImageSrc = replaceImageSrc('r500');
    }

    return (
      <img
        className="teaser-img search--img-container i-amphtml-fill-content i-amphtml-replaced-content"
        decoding="async"
        alt={altText || ''}
        src={modifiedImageSrc}
        srcSet={!isDefaultImage ? srcset : null} // Generate srcset only if it's not the default image
        sizes={sizes}
      />
    );
  } catch (error) {
    // Handle the error here, for example by returning a default image or an error message
    __log(error);
    return (
      <img
        className="teaser-img search--img-container i-amphtml-fill-content i-amphtml-replaced-content"
        decoding="async"
        alt={altText || ''}
        src={defaultImage}
      />
    );
  }
};

export default ResponsiveImage;
