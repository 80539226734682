import { getLocalConfig, setLocalConfig, getUniqueLocations } from './localConfigUtils';
import { createTimestamp } from './utils';
import { getUserPreferences, updateUserPreferences } from '../services/api';

const getSavedSearchConfigOrDefault = (key, localConfig) => {
  localConfig = getLocalConfig(localConfig);

  const defaultSearchConfig = {
    author: '',
    categorySlug: '',
    categoryQuery: '',
    createdDate: '',
    displayName: '',
    domains: '',
    excludeQuery: '',
    name: '',
    options: {
      expandedSearch: false,
      locations: [],
      pinnedCategory: {
        enabled: false,
        order: '',
      },
    },
    orSearchQuery: '',
    searchQuery: '',
    sectionSlug: 'feed',
    sortOrder: '',
    subSectionSlug: '',
  };

  return localConfig?.savedSearches?.[key] ?? defaultSearchConfig;
};

const getSavedSearch = (searchName, localConfig) => {
  localConfig = getLocalConfig(localConfig);
  return localConfig?.savedSearches?.[searchName] || null;
};

const getSavedSearches = (localConfig) => {
  localConfig = getLocalConfig(localConfig);
  return localConfig?.savedSearches ? localConfig.savedSearches : false;
};

const getSavedSearchKeys = (localConfig) => {
  localConfig = getLocalConfig(localConfig);
  return localConfig?.savedSearches ? Object.keys(localConfig.savedSearches) : false;
};

const getSavedSearchProperty = (interestProperty, searchName, localConfig) => {
  localConfig = getLocalConfig(localConfig);
  if (!localConfig) return false;

  if (
    Object.prototype.hasOwnProperty.call(localConfig.savedSearches, searchName) &&
    Object.prototype.hasOwnProperty.call(localConfig.savedSearches[searchName].options, interestProperty)
  ) {
    return localConfig.savedSearches[searchName].options[interestProperty];
  } else {
    return localConfig[interestProperty];
  }
};

const generateSearchQuery = (configOrExactQuery, orQuery) => {
  let searchQueryStr = '';
  if (typeof configOrExactQuery === 'object') {
    const config = configOrExactQuery;
    searchQueryStr = `${config.searchQuery ?? ''}${config.searchQuery && config.orSearchQuery ? '-' : ''}${config.orSearchQuery ?? ''}`;
  } else {
    const exactQuery = configOrExactQuery;
    searchQueryStr = `${exactQuery ?? ''}${exactQuery && orQuery ? '-' : ''}${orQuery ?? ''}`;
  }

  const key = searchQueryStr.toLowerCase().replace(/\s+/g, '-');
  return {
    searchQuery: searchQueryStr,
    key,
  };
};

const updateSavedSearchCategory = (savedSearch, config, locations = []) => {
  let localConfig = getLocalConfig();
  if (!localConfig) return;

  if (locations.length === 0) {
    locations = getUniqueLocations(localConfig);
  }
  const key = savedSearch.toLowerCase().replace(/\s+/g, '-');

  const savedSearchExists = Object.prototype.hasOwnProperty.call(localConfig.savedSearches, key);

  if (localConfig.savedSearches[key]) {
    delete localConfig.savedSearches[key];
  } else {
    const newCategory = {
      ...config,
      options: {
        expandedSearch: false,
        locations: Array.from(locations),
        pinnedCategory: {
          enabled: false,
          order: '',
        },
      },
    };
    localConfig.savedSearches[key] = newCategory;
  }
  setLocalConfig(localConfig);
  return !savedSearchExists;
};

const createUserDefinedCategory = async (localConfig, exactSearchQuery, orSearchQuery, category, domains, excludeQuery, selectValue, author) => {
  const userPreferences = localConfig ?? (await getUserPreferences())?.profile_info;
  if(!userPreferences) return null;

  const { searchQuery, key } = generateSearchQuery(exactSearchQuery, orSearchQuery);
  const displayName = `${exactSearchQuery ?? ''}${exactSearchQuery && orSearchQuery ? ' ' : ''}${orSearchQuery ?? ''}`.replace(/\b\w/g, (char) =>
    char.toUpperCase()
  );

  const savedSearches = userPreferences.savedSearches || {};

  if (savedSearches[key]) {
    delete savedSearches[key];
  } else {
    const defaultSearchConfig = getSavedSearchConfigOrDefault(key, userPreferences);

    savedSearches[key] = {
      ...defaultSearchConfig,
      author: author,
      categorySlug: key,
      categoryQuery: category,
      createdDate: createTimestamp(),
      displayName: displayName,
      domains: domains,
      excludeQuery: excludeQuery,
      name: key,
      orSearchQuery: orSearchQuery,
      searchQuery: exactSearchQuery,
      sortOrder: selectValue,
    };
  }

  const updatedLocalConfig = { ...userPreferences, savedSearches };
  setLocalConfig(updatedLocalConfig);

  if (localStorage.getItem('auth-user')) {
    updateUserPreferences();
  }
};

export {
  getSavedSearchConfigOrDefault,
  getSavedSearch,
  getSavedSearches,
  getSavedSearchKeys,
  getSavedSearchProperty,
  updateSavedSearchCategory,
  generateSearchQuery,
  createUserDefinedCategory,
};
