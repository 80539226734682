const recurringEvents = {
  'bafta-film-awards': {
    categorySlug: 'bafta-film-awards',
    displayName: 'BAFTA Film Awards',
    sectionSlug: 'entertainment',
    subSectionSlug: 'awards-and-events',
    startDate: '2024-01-28', // Start displaying 3 weeks before the BAFTA Film Awards
    endDate: '2024-02-21' // Stop displaying a few days after the BAFTA Film Awards
  },      
  'academy-awards': {
    categorySlug: 'academy-awards',
    displayName: 'Academy Awards',
    sectionSlug: 'entertainment',
    subSectionSlug: 'awards-and-events',
    startDate: '2024-02-17', // Start displaying 3 weeks before the Academy Awards
    endDate: '2024-03-13' // Stop displaying a few days after the Academy Awards
  },
  'brit-awards': {
    categorySlug: 'brit-awards',
    displayName: 'BRIT Awards',
    sectionSlug: 'entertainment',
    subSectionSlug: 'awards-and-events',
    startDate: '2024-02-10', // Start displaying 3 weeks before the BRIT Awards
    endDate: '2024-03-05' // Extend a few days after the BRIT Awards for post-event coverage
  },
  'uefa-champions-league': {
    categorySlug: 'uefa-champions-league',
    displayName: 'UEFA Champions League',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    startDate: '2023-06-13', // Start displaying from the beginning of the tournament
    endDate: '2024-06-05' // Extend a few days after the final match for post-tournament coverage
  },
  'premier-league': {
    categorySlug: 'premier-league',
    displayName: 'Premier League',
    sectionSlug: 'sports',
    subSectionSlug: 'football',
    startDate: '2024-08-17', // Start displaying from the beginning of the 2024/25 Premier League season
    endDate: '2025-05-31' // Extend a few days after the season ends for post-season coverage
  },   
  'glastonbury-festival': {
    categorySlug: 'glastonbury-festival',
    displayName: 'Glastonbury Festival',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'festivals',
    startDate: '2024-06-01', // Start displaying early June to ramp up excitement and provide festival information
    endDate: '2024-07-02' // Extend a few days after the festival ends for wrap-up coverage and reviews
  },
  'coachella-music-arts-festival': {
    categorySlug: 'coachella-music-arts-festival',
    displayName: 'Coachella Festival',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'festivals',
    startDate: '2024-03-22', // Start displaying 3 weeks before the Coachella Festival
    endDate: '2024-04-24' // Extend a few days after the Coachella Festival ends for post-event coverage
  },
  'black-friday': {
    categorySlug: 'black-friday',
    displayName: 'Black Friday',
    sectionSlug: 'discover',
    subSectionSlug: '',
    startDate: '2024-11-22', // A week before Black Friday
    endDate: '2024-12-02', // A few days after for extended deals
  },
  'cyber-monday': {
    categorySlug: 'cyber-monday',
    displayName: 'Cyber Monday',
    sectionSlug: 'discover',
    subSectionSlug: '',
    startDate: '2024-11-29', // A few days before Cyber Monday
    endDate: '2024-12-03', // A day after Cyber Monday
  },
  christmas: {
    categorySlug: 'christmas',
    displayName: 'Christmas',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    startDate: '2024-12-01', // Start of December
    endDate: '2024-12-26', // Day after Christmas
  },
  'diwali': {
    categorySlug: 'diwali',
    displayName: 'Diwali',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    startDate: '2024-10-17',
    endDate: '2024-11-7',
  },
  'easter': {
    categorySlug: 'easter',
    displayName: 'Easter',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    startDate: '2024-03-25',
    endDate: '2024-04-12',
  },
  'eid-al-fitr': {
    categorySlug: 'eid-al-fitr',
    displayName: 'Eid al-Fitr',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    startDate: '2024-04-7',
    endDate: '2024-04-12',
  },
  'fathers-day': {
    categorySlug: 'fathers-day',
    displayName: 'Fathers Day',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    startDate: '2024-06-09', // A week before Father's Day
    endDate: '2024-06-17', // A day after Father's Day
  },
  'halloween': {
    categorySlug: 'halloween',
    displayName: 'Halloween',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    startDate: '2024-10-24', // A week before Halloween
    endDate: '2024-11-01', // A few days after Halloween
  },
  'mothers-day': {
    categorySlug: 'mothers-day',
    displayName: 'Mothers Day',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    startDate: '2024-05-05', // A week before Mother's Day
    endDate: '2024-05-13', // A day after Mother's Day
  },
  'ramadan': {
    categorySlug: 'ramadan',
    displayName: 'Ramadan',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    startDate: '2024-03-7',
    endDate: '2024-04-10',
  },
  'st-patricks-day': {
    categorySlug: 'st-patricks-day',
    displayName: 'St Patricks Day',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    startDate: '2024-03-10', // A week before St. Patrick's Day
    endDate: '2024-03-18', // A day after St. Patrick's Day
  },
  'valentines-day': {
    categorySlug: 'valentines-day',
    displayName: 'Valentines Day',
    sectionSlug: 'travel-and-events',
    subSectionSlug: 'events',
    startDate: '2024-01-24', // Start displaying 3 weeks before Valentine's Day
    endDate: '2024-02-15', // A day after Valentine's Day for any follow-up content
  }
}

export default {
  breadcrumbsEnabled: true,
  communicationPreferencesEnabled: false,
  domainExclusionsEnabled: false,
  features: {
    domainExclusions: '',
    siteRoot: 'https://www.mynewsassistant.com',
    trendingKeywords: {
      'deals': {
        categorySlug: 'deals-and-reviews',
        displayName: 'Deals And Reviews',
        sectionSlug: 'life-and-style',
        subSectionSlug: '',
      }, 
      'king-charles': {
        categorySlug: 'king-charles',
        categoryQuery: '',
        displayName: 'King Charles',
        domains: '',
        excludeQuery: '',
        name: 'king-charles',
        orSearchQuery: '',
        searchQuery: 'king charles',
        sectionSlug: 'feed',
        sortOrder: 'date',
        subSectionSlug: '',
      },
      'rishi-sunak': {
        categorySlug: 'rishi-sunak',
        categoryQuery: '',
        displayName: 'Rishi Sunak',
        domains: '',
        excludeQuery: '',
        name: 'rishi-sunak',
        orSearchQuery: '',
        searchQuery: 'Rishi Sunak',
        sectionSlug: 'feed',
        sortOrder: 'date',
        subSectionSlug: '',
      },
      inflation: {
        categorySlug: 'inflation',
        categoryQuery: '',
        displayName: 'Inflation',
        domains: '',
        excludeQuery: '',
        name: 'inflation',
        orSearchQuery: '',
        searchQuery: 'Inflation',
        sectionSlug: 'feed',
        sortOrder: 'date',
        subSectionSlug: '',
      },
      'royal-family': {
        categorySlug: 'royal-family',
        categoryQuery: '',
        displayName: 'Royal Family',
        domains: '',
        excludeQuery: '',
        name: 'royal-family',
        orSearchQuery: '',
        searchQuery: 'Royal Family',
        sectionSlug: 'feed',
        sortOrder: 'date',
        subSectionSlug: '',
      },
      ...recurringEvents
    },
    CustomSuggestedCategories: {
      'royal-family': {
        'king-charles': {
          categorySlug: 'king-charles',
          categoryQuery: '',
          displayName: 'King Charles',
          domains: '',
          excludeQuery: '',
          name: 'king-charles',
          orSearchQuery: '',
          searchQuery: 'king charles',
          sectionSlug: 'feed',
          sortOrder: 'date',
          subSectionSlug: '',
        },
        'queen-camilla': {
          categorySlug: 'queen-camilla',
          categoryQuery: '',
          displayName: 'Queen Camilla',
          domains: '',
          excludeQuery: '',
          name: 'queen-camilla',
          orSearchQuery: '',
          searchQuery: 'queen camilla',
          sectionSlug: 'feed',
          sortOrder: 'date',
          subSectionSlug: '',
        },
        'prince-william': {
          categorySlug: 'prince-william',
          categoryQuery: '',
          displayName: 'Prince of Wales',
          domains: '',
          excludeQuery: '',
          name: 'prince-william',
          orSearchQuery: '',
          searchQuery: 'prince william',
          sectionSlug: 'feed',
          sortOrder: 'date',
          subSectionSlug: '',
        },
        'princess-of-wales': {
          categorySlug: 'princess-of-wales',
          categoryQuery: '',
          displayName: 'Princess of Wales',
          domains: '',
          excludeQuery: '',
          name: 'princess-of-wales',
          orSearchQuery: '',
          searchQuery: 'princess of wales',
          sectionSlug: 'feed',
          sortOrder: 'date',
          subSectionSlug: '',
        },
        'prince-harry': {
          categorySlug: 'prince-harry',
          categoryQuery: '',
          displayName: 'Prince Harry',
          domains: '',
          excludeQuery: '',
          name: 'prince-harry',
          orSearchQuery: '',
          searchQuery: 'prince harry',
          sectionSlug: 'feed',
          sortOrder: 'date',
          subSectionSlug: '',
        },
        'duchess-of-sussex': {
          categorySlug: 'duchess-of-sussex',
          categoryQuery: '',
          displayName: 'Duchess of Sussex',
          domains: '',
          excludeQuery: '',
          name: 'duchess-of-sussex',
          orSearchQuery: '',
          searchQuery: 'duchess of sussex',
          sectionSlug: 'feed',
          sortOrder: 'date',
          subSectionSlug: '',
        },
      },
    },
  },
  iframeCategory: false,
  iframeHome: false,
  mantisIdEnabled: true,
  ppidEnabled: true,
  SpeechBubbleEnabled: false,
  TrendingComponentEnabled: true,
  UserDefinedCategoriesEnabled: true,
};
