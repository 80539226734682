import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { setUserIdFromLocalStorage } from '../../helpers/localConfigUtils';
import { dispatchLoginRadiusEvent } from '../../helpers/loginRadiusEvents';

export default function Login(props) {
  const navigate = useNavigate();
  let isLoginSuccessfull = false;

  dispatchLoginRadiusEvent('auth-ui.authenticate');

  const handleLogin = () => {
    localStorage.setItem("mna-init-signup-or-signin", true);
    dispatchLoginRadiusEvent('auth-ui.authenticate');
  };

  const navigateHome = () => {
    localStorage.setItem("mna-init-signup-or-signin", true);
    navigate('/');
  };

  useEffect(() => {

    window.addEventListener('auth-ui.authentication', (event) => {
      const data = event.data || event.detail || {};
      if (data.isAuthenticated && !isLoginSuccessfull) {
        isLoginSuccessfull = true;
        setUserIdFromLocalStorage();
        if (localStorage.getItem('mna-init-signup-or-signin')) {
          navigate('/signup/loader');
        }
      }
    });

    document.title = 'Log in or Register | My News Assistant';
    window.scrollTo(0, 0);

    const observer = new MutationObserver((mutations, obs) => {
      let content = document.querySelector('div.modal__content');
      let closeButton = document.querySelector('div.regwall-box__close-btn');
      let box = document.querySelector('div.regwall-box');
      let consentLink = document.querySelector('.consent-fields__link');
      // let submitButton = document.querySelectorAll('form > button'))

      if (content) {
        content.style.height = 'fit-content !important';
        box.style.backgroundColor = 'white';
        consentLink.style.color = 'black';
        // submitButton.addEventListener('click', handleClick)

        if (closeButton) {
          closeButton.addEventListener('click', navigateHome);
        }
        obs.disconnect();
        return;
      }
    });

    observer.observe(document, {
      childList: true,
      subtree: true,
    });

  }, []);

  return (
    <div id='container' className='bordered-container'>
      <div className='top-container'>
        <IconButton data-testid='go-back' aria-label='go back' edge='start' onClick={navigateHome}>
          <ArrowBackIcon color='timberGreen' />
        </IconButton>
      </div>
      <Typography variant='h1'>
        Log in or create an account
      </Typography>
      <div className='content-text'>
        <Typography variant='body1'>
          Sign in or register to save your personalised news feed.
        </Typography>
      </div>
      <Container className='flex-btn'>
        <Button
          onClick={handleLogin}
          className='btn'
          color='primary'
          variant='contained'
          data-testid='sign-in-or-register-button'
        >
          Sign in or Register
        </Button>
      </Container>
    </div>
  );
}
