import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import './static.scss';

const CookieNotice = () => {
  useEffect(() => {
    document.title = 'Cookie Notice | My News Assistant';
    window.scrollTo(0, 0);
  });

  return (
    <div className="standard-layout static-page">
      <main>
        <section className="static-content">
          <Typography variant="h1bold" color="primary">
            Cookie Notice
          </Typography>
          <section>
            <Typography variant="h4bold" color="primary">
              1. Who we are and how to contact us
            </Typography>
            <p>
              We are the Reach group, which is made up of Reach plc and its subsidiaries. We, via our various subsidiary companies and brands, publish via
              newspapers, magazines and digital platforms; national websites and regional websites (our ‘Sites’), and mobile applications (our ‘Apps’), enjoyed
              by millions every month. You can see the list of websites and brands <a href="https://local.reachsolutions.co.uk/brands/">here</a>.
            </p>
            <address>
              Our address is:<br />
              One Canada Square, Canary Wharf, London, E14 5AP.
            </address>
            <p>
              For any queries you have relating to this Notice, you can contact our Data Protection Team via the forms provided within our{' '}
              <a href="/legal/privacy-notice" target="_self" rel="Follow">Privacy Notice</a>, or by writing to ‘The Data Protection Team’ at the above address.{' '}
            </p>
          </section>
          <section>
            <Typography variant="h4bold" color="primary">
              2. About this Cookie Notice
            </Typography>
            <p>
              This notice explains how we use cookies and similar technologies (‘cookies’) on our Sites and Apps and how you can manage the behaviour of these
              cookies.
            </p>
            <p>
              With that in mind, we want you to feel in control of the information you generate when you use our Sites and Apps; this Notice is organised based
              on what you might want to know about how we use cookies, including how you can manage the behaviour of these cookies.
            </p>
          </section>
          <section>
            <Typography variant="h4bold" color="primary">
              3. What is a cookie?
            </Typography>
            <p>
              A cookie is a small data file sent from a website to your web browser. It is stored in your web browser’s cache and allows a website, or a third
              party, to recognise your browser or mobile device, and to obtain data about your browsing activity.
            </p>
            <p>
              Mobile devices and browsers can be shared by a number of people, so cookies do not always relate to particular individuals. For a detailed
              description of how we use the personal data we collect, please see our <a href="/legal/privacy-notice" target="_self" rel="Follow">Privacy Notice</a>.
            </p>
          </section>
          <section>
            <Typography variant="h4bold" color="primary">
              4. What types of cookies do we use?
            </Typography>
            <p>We use five main types of cookies: essential, functional, analytic, personalisation, and advertising.</p>
            <section>
              <Typography variant="h5bold" color="primary">
                4.1 Essential Cookies
              </Typography>
              <p>
                These cookies are essential to provide you with the services that you have requested from us; because of their essential nature, they cannot be
                switched off.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                4.2 Functional Cookies
              </Typography>
              <p>
                These cookies enable us to remember some important information, such as your computer or mobile device when you visit a website, your
                registration and log in details, and your settings and preferences.
              </p>
              <p>
                They are used by us to ensure that you don’t have to log in every single time you want to comment on our articles, and to ensure our interactive
                services work effectively.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                4.3 Analytic Cookies
              </Typography>
              <p>
                Analytic cookies retain information as you view different pages on our Sites, and they help us to paint a picture of how our readers use our
                Sites.
              </p>
              <p>
                Additionally, these cookies help us to make the website work as efficiently as possible, and provide better content and services in the future.
              </p>
              <p>We may also use analytics cookies to measure the effectiveness of advertising on the Sites and the Apps and elsewhere on the internet.</p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                4.4 Personalisation Cookies
              </Typography>
              <p>These cookies enable us to recommend content we think you’ll be interested in, based on what you’ve looked at before.</p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                4.5 Advertising Cookies
              </Typography>
              <p>
                These cookies are placed by us, and our advertising partners, to deliver more relevant advertising. A complete list of our advertising partners
                is provided within our Consent Management Platform (‘CMP’), which is displayed to all new Site and App visitors.
              </p>
              <p>
                We undertake this activity on the basis of your consent, which we request on their behalf through the ‘cookie banner’ which appears when you
                first visit our Site. The ‘cookie banner’ message box will reappear from time to time in case you change your mind, or when something changes
                (typically every 30 days). Please see the ‘cog’ icon or link at the bottom of every webpage to manage your consents. When using our Apps, if you
                want to change the settings, go to the ‘My Account’ Link at the bottom of the screen which includes ‘My Privacy’ and takes you to the CMP.
              </p>
              <p>
                Most of our third-party advertising partners operate within an industry framework and code of practice issued by the Internet Advertising Bureau
                (IAB) called the “Transparency and Consent Framework”, which sets out the technical means for your choices to be passed through the ad networks.
                It also requires that members adhere to IAB policies and standards of behaviour.
              </p>
              <p>
                Advertising partners who do not work within the IAB framework are listed separately in the platform which can be viewed in our Consent
                Management Platform (CMP). In the Consent Management Platform popup go to the ‘Partners’ page and scroll down - IAB partners are listed first,
                then you'll see Non-IAB vendors and finally Google partners.
              </p>
              <p>
                These cookies may gather information about your browsing activity on our sites and for third parties, across different Sites and across
                different visits. They allow us and advertisers to show more relevant ads, while also allowing advertisers to manage how many times a device
                sees an advert. They might also enable an advertiser to tailor advertising to you when you visit our Sites.
              </p>
              <p>
                When you have accepted the placement of advertising cookies via our CMP and, subsequent to this consent, provide us with your email address
                (when you create an account, sign up for a newsletter, enter a competition/survey etc.) we will share your email address in a hashed form to
                trusted 3rd parties. Such information will be used to enable targeted advertising, profiling, and interest-based content.{' '}
              </p>
              <p>
                Please see the <a href="/legal/privacy-notice/" target="_self" rel="Follow">Privacy Notice</a> for further information about how we use your 
                personal data.
              </p>
            </section>
          </section>
          <section>
            <Typography variant="h4bold" color="primary">
              5. Why do we use cookies?
            </Typography>
            <p>
              The information generated from your browsing helps us to understand how people use our websites, how we can improve them, and also to make sure
              that adverts are more relevant to you.
            </p>
            <p>Examples of what we use cookies for include:</p>
            <ul>
              <li>
                If we can see that more users from London prefer football content on a Friday evening than Sunday morning, then we can change when and how much
                content we produce for London football teams on a weekday as opposed to a weekend;
              </li>
              <li>
                When we run a poll on a particularly contentious local issue, we can use cookies to prevent a device or browser voting hundreds of times to
                influence the poll results;
              </li>
              <li>
                If a browser or device is recognised to enjoy lots of content about celebrities, we and/or our partners may show adverts that relate to relevant
                lifestyle, tech and consumer goods.
              </li>
            </ul>
            <p>
              Further information on the purposes of placing cookies can be found within our CMP, displayed to all new Site visitors. It can also be found via
              the Privacy cog icon or link at the bottom of each webpage for our Sites and for Apps via the ‘My Account’ Link at the bottom of the screen which
              includes ‘My Privacy’ and takes you to the CMP.
            </p>
          </section>
          <section>
            <Typography variant="h4bold" color="primary">
              6. How can you manage your cookie preferences?
            </Typography>
            <section>
              <Typography variant="h5bold" color="primary">
                6.1 On Site
              </Typography>
              <p>
                We have put a Consent Management Platform (CMP) in place on our Sites. This platform helps you to manage what non-Essential cookies are placed
                on your device; you can decide whether to accept or reject a range of cookies with defined purposes. These preferences can be changed at any
                time, by clicking on the Privacy cog icon or link on the bottom of every webpage.
              </p>
              <p>
                Additionally, most web browsers will allow you to stop accepting cookies. This is normally controlled through the Settings or Help page of a
                browser; further information can be found using the below links:
              </p>
              <ul>
                <li>
                  <a href="http://windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies#ie=ie-10">
                    Microsoft Edge cookie settings
                  </a>
                </li>
                <li>
                  <a href="http://support.mozilla.com/en-US/kb/Cookies">
                    Firefox cookie settings
                  </a>
                </li>
                <li>
                  <a href="https://support.google.com/chrome/answer/95647?hl=en&amp;ref_topic=14666">
                    Chrome cookie settings
                  </a>
                </li>
                <li>
                  <a href="https://support.apple.com/kb/PH17191?locale=en_US">
                    Safari cookie settings
                  </a>
                </li>
              </ul>
              <p>
                For interest based advertising, you can also opt-out by visiting:{' '}
                <a href="http://youronlinechoices.eu/">http://youronlinechoices.eu</a>.
              </p>
              <p>
                There is an AdChoice logo at the corner of every advertisement that carries behavioural targeting cookies; you can click on the link within this
                logo to control the settings that Google uses to display adverts (<a href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a>).
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                6.2 On App
              </Typography>
              <p>
                We also have a CMP in place on our Apps, allowing you to control what cookies are placed on your mobile device. These preferences can be changed
                at any time, by navigating to the ‘My Account’ Link at the bottom of the screen which includes ‘My Privacy’ and takes you to the CMP.
              </p>
              <p>
                Additionally, when using a mobile device, you can opt-out of receiving interest based advertising by selecting ‘Privacy’ and then ‘Advertising’
                in the Settings of your Apple iPhone or iPad, or the “Opt out of Ads Personalisation” in the Google Settings on your Android device.
              </p>
              <p>
                When managing Site and App cookies, it is important to be aware that switching off cookies may affect the way one of our Sites operates, and it
                may adversely affect the quality of your experience on a Site.
              </p>
              <p>
                Finally, please be aware that if you opt out of Advertising cookies, you are not opting out of seeing advertising. You will still see adverts,
                they just may not be as relevant to you and your interests.
              </p>
            </section>
            <section>
              <Typography variant="h5bold" color="primary">
                6.3 Residents of California
              </Typography>
              <p>
                If you are a resident of California, you may opt out of the “sale” of your personal information through the use of our website cookies by
                selecting the “Do Not Sell My Personal Information” link located on the footer, header or a pop-up on each webpage.
              </p>
            </section>
          </section>
          <section>
            <Typography variant="h4bold" color="primary">
              7. How long do cookies last?
            </Typography>
            <p>
              Cookies are stored on your device until you delete them or until they expire, as a general rule we will not retain any data that has been captured
              through their use for any longer than necessary.
            </p>
            <p>Detailed information regarding the duration of cookies set by our partners, can be found within the relevant section of our CMP.</p>
          </section>
          <section>
            <Typography variant="h4bold" color="primary">
              8. Are there any third parties involved?
            </Typography>
            <p>
              When you provide us with permission, we will share data collected through the use of cookies and similar technologies on our Sites and Apps with
              selected third party partners.
            </p>
            <p>
              We provide detailed information on the identity of partners, including the data that they collect and what they use it for, within the relevant
              sections of our CMP.
            </p>
          </section>
          <section>
            <Typography variant="h4bold" color="primary">
              9. Ad Blocker Detection
            </Typography>
            <p>
              We, or our advertisers, may use code on our Sites to detect if you are using an ad blocker. If we detect that you are using an ad blocker, we may
              display a warning message describing how this may affect your experience using the Sites and ask that you disable it.
            </p>
          </section>
          <section>
            <Typography variant="h4bold" color="primary">
              10. Social Media Technologies
            </Typography>
            <p>
              When you use social media platforms with the Sites and the Apps, each platform has its own technologies and policies to be aware of. For
              information on limiting the use of widgets and sharing functions with social networks, see below:
            </p>
            <p>
              For Twitter widgets, you can find more information <a href="https://dev.twitter.com/web/overview/privacy">here</a>.
              <br />
              For Facebook widgets, you can find more information <a href="https://www.facebook.com/help/443483272359009/">here</a>.
              <br />
              For Pinterest widgets, you can find more information <a href="https://help.pinterest.com/en/articles/personalization-and-data">here</a>.
            </p>
          </section>
          <section>
            <Typography variant="h4bold" color="primary">
              11. Cookie Notice last updated
            </Typography>
            <p>This Notice was updated on 20/12/2022.</p>
          </section>
        </section>
      </main>
    </div>
  );
};

export default CookieNotice;
