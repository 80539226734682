import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './categoryWise.scss';
import TeaserPlaceholder from '../shared/teaserPlaceholder';

function gridMobilePlaceholder() {
  return (
    <div className="mobile-grid-placeholder">
      <div className="category-header">
        <div className="divTitle">
          <Skeleton containerClassName="listTitle" style={{ width: '175px', height: '30px' }} />
        </div>
        <Skeleton style={{ width: '105px', borderRadius: '30px', height: '30px' }} />
      </div>
      <div className="grid-container">
        <TeaserPlaceholder height="100%" cards={8} />
      </div>
    </div>
  );
}

export default gridMobilePlaceholder;
