const slots = [
  {
    carousel: {
      row: 0,
      col: 3,
    },
    id: 'div-gpt-ad-carousel-mpu-0-1',
    sizes: [[300, 250]],
    targeting: {
      index: 6001,
      hivis: 'n',
    },
  },
  {
    carousel: {
      row: 1,
      col: 4,
    },
    id: 'div-gpt-ad-carousel-mpu-1-1',
    sizes: [[300, 250]],
    targeting: {
      index: 6002,
      hivis: 'n',
    },
  },
  {
    carousel: {
      row: 2,
      col: 5,
    },
    id: 'div-gpt-ad-carousel-mpu-2-1',
    sizes: [[300, 250]],
    targeting: {
      index: 6003,
      hivis: 'n',
    },
  },
  {
    carousel: {
      row: 3,
      col: 6,
    },
    id: 'div-gpt-ad-carousel-mpu-3-1',
    sizes: [[300, 250]],
    targeting: {
      index: 6004,
      hivis: 'n',
    },
  },
  {
    carousel: {
      row: 4,
      col: 7,
    },
    id: 'div-gpt-ad-carousel-mpu-4-1',
    sizes: [[300, 250]],
    targeting: {
      index: 6005,
      hivis: 'n',
    },
  },
];

export default slots;
