import { __log } from '../../helpers/utils';

export const vendor = {
  //id
  google: 755,
};

export const purposes = {
  STORE_AND_ACCESS_INFORMATION_ON_A_DEVICE: '1',
  SELECT_BASIC_ADS: '2',
  CREATE_A_PERSONALISED_ADS_PROFILE: '3',
  SELECT_PERSONALISED_ADS: '4',
  CREATE_A_PERSONALISED_CONTENT_PROFILE: '5',
  SELECT_PERSONALISED_CONTENT: '6',
  MEASURE_AD_PERFORMANCE: '7',
  MEASURE_CONTENT_PERFORMANCE: '8',
  APPLY_MARKET_RESEARCH_TO_GENERATE_AUDIENCE_INSIGHTS: '9',
  DEVELOP_AND_IMPROVE_PRODUCTS: '10',
  USE_PRECISE_GEOLOCATION_DATA: '11',
  ACTIVELY_SCAN_DEVICE_CHARACTERISTICS_FOR_IDENTIFICATION: '12',
};

let isGDPRSubscribed = false;
let isCCPADataReady = false;
let consentData = {
  gdprData: null,
  ccpaData: null,
};

/**
 * Function  for CMP Api
 */
export function subscribeGDPRData() {
  return new Promise((resolve, reject) => {
    if (!isGDPRSubscribed) {
      __tcfapi('addEventListener', 2, (tcData, success) => {
        if (success) {
          consentData.gdprData = tcData;
          isGDPRSubscribed = true;
          __log('tcData', tcData);
          resolve(tcData);
        } else {
          reject(new Error('Error retrieving tcData from Consent Management Platform'));
        }
      });
    }
  });
}

export function getCCPAData() {
  return new Promise((resolve, reject) => {
    __uspapi('getUSPData', 1, (uspData, success) => {
      if (success) {
        consentData.ccpaData = uspData;
        isCCPADataReady = true;
        __log('uspData', uspData);
        resolve(uspData);
      } else {
        reject(new Error('Error retrieving uspData from Consent Management Platform'));
      }
    });
  });
}

// get CMP data:  array of {}
export const getConsentData = async () => {
  let isGDPRDataPresent = consentData.gdprData !== null;

  if (isGDPRDataPresent && isCCPADataReady) {
    const gdprData = consentData.gdprData;
    const ccpaData = consentData.ccpaData;
    return [gdprData, ccpaData];
  }

  if (isGDPRDataPresent && !isCCPADataReady) {
    return await Promise.all([consentData.gdprData, getCCPAData()]);
  }

  if (!isGDPRDataPresent && isCCPADataReady) {
    return await Promise.all([subscribeGDPRData(), consentData.ccpaData]);
  }

  // If not ready, fetch the data asynchronously
  const [gdprData, ccpaData] = await Promise.all([subscribeGDPRData(), getCCPAData()]);
  return [gdprData, ccpaData];
};

/**
 * Function to check vendor consent
 */
export async function isVendorConsented(vendor, consentData) {
  let cmpData = await getConsentData();
  consentData = consentData || cmpData[0];
  if (!gdprApplies(consentData)) {
    return true;
  }
  const allowedVendors = consentData?.vendor?.consents;
  return !!allowedVendors?.[vendor];
}

// if gdprApplies is null here (runForceReconsent is just loaded),
// we don't know if gdpr applies yet, we have to get it from CMP
export function gdprApplies(consentData) {
  return consentData?.gdprApplies !== false; // return true for EU and UK
}

/**
 * Function to check vendor and purposes
 */
export async function isConsented(vendor, purposes) {
  let cmpData = await getConsentData();

  //GDPR is false and the US user hasn't opted-out
  if (!gdprApplies(cmpData[0]) && cmpData[1].uspString.charAt(2) === 'N') {
    return true;
  }

  if (cmpData[0].gdprApplies !== false) {
    __log('case GDPR applies', true);
    return isVendorConsented(vendor, cmpData[0]) && vendorPurposesAccepted(purposes, cmpData[0]);
  } else if (cmpData[0].gdprApplies === false && cmpData[1].uspString.indexOf('-') === -1) {
    __uspapi('uspPing', 1, function (uspData, success) {
      console.log('cmp responded:', uspData, success);
    });
    __log('case CCPA applies', true);
  } else {
    __log('neither case applies, ROW', true);
    return true;
  }
}

function checkPurposes(requiredPurposes, consents) {
  const consentedPurposes = Object.keys(consents);
  return requiredPurposes.every((purpose) => consentedPurposes.includes(purpose));
}
/**
 * function that checks the vendor purposes
 */
export async function vendorPurposesAccepted(requiredPurposes, consentData) {
  let cmpData = await getConsentData();
  consentData = consentData || cmpData[0];
  if (!gdprApplies(consentData)) {
    return true;
  }
  const { consents } = consentData?.purpose || {};
  return checkPurposes(requiredPurposes, consents);
}
/**
 * Function to check all reqired publisher purposes accepted
 */
export async function publisherPurposesAccepted(requiredPurposes, consentData) {
  let cmpData = await getConsentData();
  consentData = consentData || cmpData[0];
  if (!gdprApplies(consentData)) {
    return true;
  }

  const { consents } = consentData?.publisher || {};
  return checkPurposes(requiredPurposes, consents);
}
/**
 * function that checks the publisher purposes and the vendor purposes
 */

export async function publisherAndVendorPurposesAccepted(requiredPurposes, consentData) {
  let cmpData = await getConsentData();
  consentData = consentData || cmpData[0];
  if (!gdprApplies(consentData)) {
    return true;
  }
  return vendorPurposesAccepted(requiredPurposes, consentData) && publisherPurposesAccepted(requiredPurposes, consentData);
}
