import React, { useState } from 'react';
import './searchPage.scss';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function SearchPlaceholder({ cards }) {
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);

  return Array(cards)
    .fill(0)
    .map((item, i) => (
      <div className="search--card" key={i}>
        <div className="left-col">
          <Skeleton style={{ borderRadius: '10px', height: '100%' }} />
        </div>
        <div className="right-col">
          <Skeleton count={6} style={{ marginBottom: '3px' }} />
          <Skeleton style={{ width: isDesktopView ? '228px' : '100%', height: '35px', marginTop: '27px' }} />
        </div>
      </div>
    ));
}

export default SearchPlaceholder;
