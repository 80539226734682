import React from 'react';
import { Outlet } from 'react-router-dom';

export default function Signup() {
  const [isDesktopView, setIsDesktopView] = React.useState(window.innerWidth > 768);

  return (
    <div className={'standard-layout error-page'}>
      <main className={isDesktopView ? 'desktop' : 'mobile'}>
        <Outlet />
      </main>
    </div>
  );
}
