import './teaser.scss';
import { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import { fetchViafouraData } from '../../services/api';
import { getTimeSinceLastModified, trackableURL, truncateString, getNameFromURL, getLogoFromURL } from '../../helpers/utils';
import { getDomain } from '../../helpers/pageUtilities';
import { getViafouraData } from '../../helpers/viafouraUtils';

import ReactCardFlip from 'react-card-flip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

import CommentsCount from '../CommentsCount/CommentsCount';
import KebabMenu from '../KebabMenu/KebabMenu';
import ResponsiveImage from '../responsiveImage/responsiveImage';
import mnaPlaceholder from '../../assets/mna_placeholder.jpg';

function Teaser({ index, item, updateExcludedDomains }) {
  const [isDesktopView, setDesktop] = useState(window.innerWidth >= 768);
  const [isFlipped, setIsFlipped] = useState(false);

  const theme = useTheme();
  const { url } = item;
  const imageThumbnail = item.imageThumbnail ? item.imageThumbnail : mnaPlaceholder;

  const handleIsFlipped = (event) => {
    event.preventDefault();
    setIsFlipped(!isFlipped);
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, [isDesktopView]);

  return (
    <div className="card-container">
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <div className="teaser-front" data-testid="cardFlipDesktop">
          <div className="image-container">
            <a href={trackableURL(url, 'teaser_image')} target="_blank" rel="noopener noreferrer">
              <ResponsiveImage imageThumbnail={imageThumbnail} defaultImage={mnaPlaceholder} altText={item.title} />
            </a>
          </div>
          <div className="details-container">
            <a href={trackableURL(url, 'teaser_brandlogo')} target="_blank" rel="noopener noreferrer">
              <img className="brand-logo" src={require(`../../assets/brands/sm/${getLogoFromURL(url)}`)} alt={getNameFromURL(url)} />
            </a>
            <Typography variant="label1" className="details">
              {getDomain(url).replace(/^www\./, '')} &#8226; {getTimeSinceLastModified(item.lastModified)}{' '}
            </Typography>
          </div>
          <div className="content-container">
            <a href={trackableURL(url, 'teaser_title')} target="_blank" rel="noopener noreferrer">
              <Typography variant={isDesktopView ? 'h6bold' : 'h4bold'} className="content-title">
                {truncateString(item.title, 120)}
              </Typography>
            </a>
          </div>
          <div className="toolbar-container">
            <CommentsCount item={item} />
            <IconButton
              onClick={handleIsFlipped}
              sx={{
                '&:focus-visible': { backgroundColor: 'unset' },
                '&:hover': { backgroundColor: 'unset' },
              }}
            >
              <ErrorOutlineIcon color="osloGrey" />
              <Typography variant="label1" sx={{ color: theme.palette.osloGrey.main, fontFamily: theme.typography.fontFamily }}>
                {' '}
                &nbsp; More Info
              </Typography>
            </IconButton>
            <KebabMenu title={item.title} url={url} updateExcludedDomains={updateExcludedDomains} />
          </div>
        </div>
        {/* back of the card */}
        <div className="teaser-back">
          <div className="toolbar-container">
            <IconButton size="small" onClick={handleIsFlipped} aria-label="Close" sx={{ position: 'absolute', top: '5px', right: '5px' }}>
              <CloseIcon color="osloGrey" />
            </IconButton>
          </div>
          <div className="details-container">
            <a href={trackableURL(url, 'teaser_brandlogo')} target="_blank" rel="noopener noreferrer">
              <img className="brand-logo" src={require(`../../assets/brands/sm/${getLogoFromURL(url)}`)} alt={getNameFromURL(url)} />
            </a>
            <Typography variant="label1" className="details">
              {getDomain(url).replace(/^www\./, '')} &#8226; {getTimeSinceLastModified(item.lastModified)}{' '}
            </Typography>
          </div>
          <div className="content-container">
            <a href={trackableURL(url, 'teaser_title')} target="_blank" rel="noopener noreferrer">
              <Typography variant={isDesktopView ? 'h6bold' : 'h4bold'} className="content-title">
                {item.leadText ? truncateString(item.leadText, 350) : item.title}
              </Typography>
            </a>
          </div>
          <div className="toolbar-container">
            <CommentsCount item={item} />
            <IconButton
              component={Link}
              sx={{
                padding: '0px',
                color: theme.palette.osloGrey.main,
                '&:focus-visible': { backgroundColor: 'unset' },
                '&:hover': { backgroundColor: 'unset' },
              }}
              href={trackableURL(url, 'teaser_readmore')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ReadMoreIcon color="osloGrey" />
              <Typography variant="label1" sx={{ color: theme.palette.osloGrey.main }}>
                &nbsp; Read More
              </Typography>
            </IconButton>
          </div>
        </div>
      </ReactCardFlip>
    </div>
  );
}

export default Teaser;
