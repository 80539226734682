import { getEnvConfig } from '../helpers/utils';
import logoimage from '../assets/mna_alt_lr_logo.png';

const envConfig = getEnvConfig();

const authUiConfig = {
        rootContainerId: 'auth-ui',
        initialRoute: '/',
        cssVariables: {
            'primary-color': '#00ADEC',
            'secondary-color': '#bd0000',
            'highlighted-color': '#e90e0e',
            'regwall-logo': 'url("'+ logoimage + '?qc-size=103,90")',
            'logo': 'url("'+ logoimage + '?qc-size=103,90")',
        },
        publicationName: 'My News Assistant',
        publicationID: 'mynewsassistant',
        origin: "MyNewsAssistant",
        authUiEnv:envConfig.AUTHUI_ENV,
        apiKey: envConfig.APIKEY,
        lrDomain: envConfig.LR_DOMAIN,
        reCaptchaKey: '6LcEdbMaAAAAAHWi9vyOYDy03KFzfWdNhsw89zD0',
        enableMagicLink: true,
        googleOneTap: true,
        track: () => {
        }
};

export { authUiConfig };