/**
 * Gets the value of a specified query parameter from a URL
 *
 * e.g. getQueryParameter('token', 'http://www.mynewsassistant.com/?token=abc')
 * will return the string 'abc'
 *
 * @function getQueryParameter
 * @param queryParam {string} - the query parameter to get
 * @returns {string} - the value of the query parameter, or null if not present in url
 */
function getQueryParameter(queryParam) {
  const queryString = window.location.search;
  const paramValue = new URLSearchParams(queryString).get(queryParam);
  return paramValue;
}
  
/**
 * Gets a cookie by name.
 * @function getCookie 
 * @param cookieName {string} - the cookie name to get
 */
function getCookie(cookieName) {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].replace(/^ +/, "");
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

/**
 * Creates a first party cookie.
 * @function createCookie 
 * @param cookieName {string} - the cookie name to set
 * @param cookieValue {string} - the cookie value to set
 * @param daysToExpire {string} - the number of days until the cookie expires
 */
function createCookie(cookieName, cookieValue, daysToExpire) {
  var date = new Date();
  date.setTime(date.getTime()+(daysToExpire*24*60*60*1000));
  document.cookie = cookieName + "=" + cookieValue + "; expires=" + date.toGMTString() + "; path=/";
}

function getDomain(url) {
  if (url.includes('http://')) {
    url = url.replace(/(^\w+:|^)\/\//, '')
  }
  return url.split('/')[0];
}

export { getQueryParameter, getCookie, createCookie, getDomain };