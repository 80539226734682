import React from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { __log, getTimeSinceLastModified, trackableURL, truncateString, getLogoFromURL, isEmbedPagePresent } from '../../helpers/utils';

import { getDomain } from '../../helpers/pageUtilities';
import SearchPlaceholder from './searchPlaceholder';
import ResponsiveImage from '../../components/responsiveImage/responsiveImage';
import mnaPlaceholder from '../../assets/mna_placeholder.jpg';
import CommentsCount from '../../components/CommentsCount/CommentsCount';
import KebabMenu from '../../components/KebabMenu/KebabMenu';

function SearchResults({ isLoading, isDataAvailable, searchData, exactSearchQuery, orSearchQuery }) {
  const theme = useTheme();

  return (
    <div className="results--wrapper">
      {isLoading ? (
        <SearchPlaceholder cards={10} />
      ) : isDataAvailable ? (
        searchData.map((item, i) => (
          <>
            <div key={item} className="search--card">
              <div className="search--img-container">
                <a href={trackableURL(item.url, isEmbedPagePresent() ? 'embedded_search_item' : 'search_item')} target="_blank" rel="noopener noreferrer">
                  <ResponsiveImage
                    imageThumbnail={item.imageThumbnail ? item.imageThumbnail : mnaPlaceholder}
                    defaultImage={mnaPlaceholder}
                    altText={item.title}
                  />
                </a>
              </div>
              <div className="search--details-container">
                <div className="publication--details">
                  <a href={trackableURL(item.url, 'teaser_brandlogo')} target="_blank" rel="noopener noreferrer">
                    <img className="search--logo" src={require(`../../assets/brands/sm/${getLogoFromURL(item.url)}`)} alt="logo" />
                  </a>
                  <Typography variant="label1" sx={{ color: theme.palette.osloGrey.main }}>
                    {getDomain(item.url).replace(/^www\./, '')} &#8226; {getTimeSinceLastModified(item.lastModified)}{' '}
                  </Typography>
                </div>
                <div className="search--content">
                  <a href={trackableURL(item.url, isEmbedPagePresent() ? 'embedded_search_item' : 'search_item')} target="_blank" rel="noopener noreferrer">
                    <Typography sx={{ color: theme.palette.common.black, paddingBottom: '10px' }} variant="h6bold">
                      {item.title}
                    </Typography>
                  </a>
                  <Typography variant="body1" sx={{ color: theme.palette.secondary.main }}>
                    {truncateString(item.leadText, 240)}
                  </Typography>
                </div>
                <div className="search--toolbar">
                  <CommentsCount item={item} />
                  <KebabMenu title={item.title} url={item.url} />
                </div>
              </div>
            </div>
            <Divider sx={{ color: theme.palette.gainsboro.main, borderBottomWidth: 'inherit' }} />
          </>
        ))
      ) : (
        <div className="missing--search">
          <div className="loupe--container">
            {' '}
            <img className="loupe" src={require(`../../assets/Search-Icon.png`)} alt="loupe" />
            <Typography variant="h2">Your search - {`${exactSearchQuery} ${orSearchQuery}`} - did not match any articles.</Typography>
          </div>
          <div className="text--container">
            <Typography variant="h6bold">Suggestions:</Typography>
            <Typography variant="body1">&#8226; Check your spelling for any typos or errors in your search query.</Typography>
            <Typography variant="body1">&#8226; Try different keywords to see if you get more results.</Typography>
            <Typography variant="body1">
              &#8226; Use advanced search options, such as filters or parameters, to narrow down your search and find more relevant results.
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchResults;
