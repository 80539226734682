// React and hooks
import React from 'react';

// Local Components
import List from '../../components/homepage/category';

// Material UI Components and Icons
import { AdvertisingSlot } from 'react-advertising';

// Helper Functions and Constants
import { getCategoryByName, getCategoryByType } from '../../config/categories.js';
import { __log, retrieveDomainsFromLocation } from '../../helpers/utils';
import { getInterestProperty, getLocalConfig, getDisplayName } from '../../helpers/localConfigUtils';
import { getSavedSearch } from '../../helpers/savedSearchUtils';

// Other Imports
import mobileSlots from '../../config/adConfig/homePageConfig/slotsMobile';

/**
 * Render a category and its corresponding ad slot.
 * @param {number} index - The index of the category.
 * @param {string} categoryName - The name of the category.
 * @param {Array} storedCategories - The list of stored categories from localStorage.
 * @param {Array} pinnedCategories - The pinned categories state.
 * @param {Function} setPinnedCategories - The function to update the pinned categories state.
 * @param {Function} updatePinnedCategories - The function to update the pinned categories.
 * @param {boolean} isDesktopView - The flag indicating if it's a desktop view.
 * @returns {ReactElement|null} The rendered category and ad slot, or null if the category is not enabled.
 */
function renderCategory(index, categoryName, storedCategories, pinnedCategories, setPinnedCategories, updatePinnedCategories, isDesktopView) {
  const categoryConfig = getCategoryByName(categoryName) || getSavedSearch(categoryName);
  const displayName = getDisplayName(categoryName) ?? categoryConfig.displayName;
  const numCategories = Object.keys(getCategoryByType()).length;

  // Skip rendering if the category is not enabled
  if (!categoryConfig || categoryConfig.isEnabled === false) {
    return null;
  }

  const mobileAdId = !isDesktopView && mobileSlots.find((slot) => slot.position === index)?.id;
  const personalExperience = !!(storedCategories && getLocalConfig()?.consents.personalisedExperience);

  // Determine if expanded search is enabled
  const expandedSearchEnabled = getInterestProperty('expandedSearch', categoryName);
  const interestedDomains = personalExperience && !expandedSearchEnabled ? retrieveDomainsFromLocation(categoryName) : '';

  return (
    <div key={index}>
      <List
        key={categoryConfig.mantisCategories}
        categorySlug={categoryConfig.categorySlug}
        categoryName={categoryName}
        displayName={displayName}
        sectionSlug={categoryConfig.sectionSlug}
        subSectionSlug={categoryConfig.subSectionSlug}
        domains={interestedDomains}
        age={categoryConfig.age}
        rowIndex={index}
        numCategories={numCategories}
        desktopOrMobile={isDesktopView}
        pageLimit={categoryConfig.limit}
        mantisCategories={categoryConfig.mantisCategories}
        tags={categoryConfig.tags}
        updatePinnedCategories={updatePinnedCategories}
        searchQuery={categoryConfig.searchQuery} 
        orSearchQuery={categoryConfig.orSearchQuery}
        excludeQuery={categoryConfig.excludeQuery}
        searchCategory={categoryConfig.categoryQuery}
        searchDomains={categoryConfig.domains}
        exclude_tags={categoryConfig?.excludeTags}
        exclude_mantis_categories={categoryConfig?.excludeMantisCategories}
        concepts={categoryConfig?.concepts}
        entities={categoryConfig?.entities}
        exclude_concepts={categoryConfig?.exclude_concepts}
        exclude_entities={categoryConfig?.exclude_entities}
        value={categoryConfig.sortOrder ? categoryConfig.sortOrder : 'relevance'}
        author={categoryConfig.author}
        pinnedCategories={pinnedCategories} // Pass the pinnedCategories state
        setPinnedCategories={setPinnedCategories} // Pass the setPinnedCategories function
      />
      {mobileAdId && <AdvertisingSlot id={mobileAdId} key={mobileAdId} className="ad-placeholder" />}
    </div>
  );
}

export default renderCategory;
