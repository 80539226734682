import React from 'react';
import { Link } from 'react-router-dom';
import { locations } from '../config/locations';
import { prodEnvConfig, devEnvConfig } from '../config/envConfig';
import { getQueryParameter, getCookie } from './pageUtilities';
import { getFeatureFlag, getFeatureValue } from './configUtilities';
import { getPublications } from '../config/publications';
import { getLocalConfig } from './localConfigUtils';
import { getInterestProperty } from './localConfigUtils';
import { getCategoryByName } from '../config/categories';

const __log = (message, debugdata, background, colour) => {
  background = background || '#24ADE4';
  colour = colour || '#fff';
  if (!getQueryParameter('debug') && getCookie('mna-debug') !== 'true') return;
  return console.log('%c%s', 'color: ' + colour + '; background: ' + background + ';', '[INFO] My News Assistant:', message, debugdata);
};

const isPersonalisedUser = () => !!getLocalConfig();

const isPersonalisedExperienceEnabled = () => {
  const localConfig = getLocalConfig();
  return !!localConfig?.consents.personalisedExperience;
};

const isLoginRadiusUser = () => {
  const lrAuthUser = localStorage.getItem('auth-user');
  const lrLoggedInUser = localStorage.getItem('lrLogged');
  return !!(lrAuthUser && lrLoggedInUser);
};

const navigationRoute = (navigate, src) => {
  const isPersonalised = isPersonalisedUser();
  const isAuth = isLoginRadiusUser();

  if (isAuth || isPersonalised) {
    navigate(src);
  } else {
    navigate('/signup');
  }
};

const removeExcludedDomains = (domains) => {
  let storedExclusions = getInterestProperty('exclusions');

  if (!storedExclusions || domains.length <= 0) {
    return domains;
  } else {
    const newArray = domains.split(',').filter((value) => {
      return !storedExclusions.includes(value);
    });
    return newArray.toString();
  }
};

const retrieveExcludedDomains = () => {
  const defaultExcludeDomains = getFeatureFlag('domainExclusionsEnabled') ? getFeatureValue('domainExclusions') : '';
  let storedExclusions = getInterestProperty('exclusions');

  if (!Array.isArray(storedExclusions)) {
    storedExclusions = [];
  }

  defaultExcludeDomains ? storedExclusions.push(defaultExcludeDomains) : storedExclusions;

  return `${storedExclusions.join(',')}`;
};

const retrieveDomainsFromLocation = (category) => {
  let domainsArray = [];
  let storedLocation = getInterestProperty('locations', category);
  if (storedLocation) {
    storedLocation.map((locationValue) => {
      locations.map((location) => {
        let locationSelection = location[locationValue] || location['- ' + locationValue];
        if (locationSelection) {
          domainsArray.push(locationSelection);
        }
      });
    });
  }
  return domainsArray.length >= 1
    ? domainsArray
        .join(',')
        .split(',')
        .filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        })
        .join(',')
    : '';
};

// envConfig structure change to get hostnames as an array
const getEnvConfig = () => {
  const hostName = window.location.hostname;
  let isProd = false;
  if (prodEnvConfig.HOSTS.includes(hostName)) {
    isProd = true;
  }
  return {
    AUTHUI_ENV: isProd ? prodEnvConfig.AUTHUI_ENV : devEnvConfig.AUTHUI_ENV,
    APIKEY: isProd ? prodEnvConfig.APIKEY : devEnvConfig.APIKEY,
    LR_DOMAIN: isProd ? prodEnvConfig.LR_DOMAIN : devEnvConfig.LR_DOMAIN,
    HS_BASE_URL: isProd ? prodEnvConfig.HS_BASE_URL : devEnvConfig.HS_BASE_URL,
    SEARCH_API: isProd ? prodEnvConfig.SEARCH_API : devEnvConfig.SEARCH_API,
  };
};

const getTimeSinceLastModified = (modifiedDate) => {
  const articleDate = Date.parse(modifiedDate);
  const currentDate = Date.now();
  const differenceInMs = currentDate - articleDate;
  const differenceInMinutes = Math.round(differenceInMs / (1000 * 60));
  const differenceInHours = Math.floor(differenceInMinutes / 60);
  const differenceInDays = Math.floor(differenceInHours / 24);
  if (differenceInDays > 7) {
    return `${modifiedDate.slice(0, 10).split('-').reverse().join('/')}`;
  } else if (differenceInDays >= 1) {
    return `${differenceInDays} day${differenceInDays > 1 ? 's' : ''} ago`;
  } else if (differenceInHours >= 1) {
    return `${differenceInHours} hour${differenceInHours > 1 ? 's' : ''} ago`;
  } else {
    return `${differenceInMinutes} minute${differenceInMinutes > 1 ? 's' : ''} ago`;
  }
};

const trackableURL = (url = '', campaign = 'default', ampArticle = false) => {
  const isDesktopView = window.innerWidth > 768;
  let newUrl = url;

  if (!isDesktopView && ampArticle && !newUrl.includes('/amp') && !newUrl.includes('.amp')) {
    newUrl = newUrl.includes('express.co.uk') ? `${newUrl}/amp` : `${newUrl}.amp`;
  }

  const hasHttp = newUrl.startsWith('http://') || newUrl.startsWith('https://');
  const protocol = hasHttp ? '' : 'https://';

  const campaignParams = `utm_source=mynewsassistant.com&utm_medium=referral&utm_campaign=${campaign}_${isDesktopView ? 'desktop' : 'mobile'}`;
  const finalUrl = `${protocol}${newUrl}?${campaignParams}`;

  return url ? finalUrl : '#';
};

const truncateString = (str, num) => {
  return str?.length > num ? `${str.slice(0, num)}...` : str;
};

const getConfigFromURL = (url) => {
  const publications = getPublications();
  const domain = url?.match(/^(?:https?:\/\/)?(?:www\.)?([^:\/\n]+)/)?.[1];
  const publication = publications[domain];
  return publication || {};
};

const getNameFromURL = (url) => {
  const publication = getConfigFromURL(url);
  return publication?.Name || '';
};

const getLogoFromURL = (url) => {
  const publication = getConfigFromURL(url);
  return publication?.logo || 'logo-reach.svg';
};

const createTimestamp = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const timezone = date.getTimezoneOffset() / -60; // convert to hours and reverse sign

  const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  const formattedTimezone = `UTC${timezone > 0 ? '+' : ''}${timezone.toString().padStart(2, '0')}:00`;

  return `${formattedDate} ${formattedTime} ${formattedTimezone}`;
};

// no-op instruction
const avoid = () => {};

const removeDuplicates = (array) => {
  let flattenedArray = array.map((string) => string.split(',')).flat();
  return [...new Set(flattenedArray)];
};

const isEmbedPagePresent = () => {
  return document.querySelector('.embed-page-container') !== null;
};

const getScrollPercent = () => {
  var h = document.documentElement,
    b = document.body,
    st = 'scrollTop',
    sh = 'scrollHeight';
  return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
};

const constructPath = (...parts) => {
  return parts.filter(Boolean).join('/');
};

const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

const createSocialSharingLinks = (shareUrl, title) => {
  const baseFacebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
  const facebookLink = isMobile() ? `fb://facewebmodal/f?href=${encodeURIComponent(shareUrl)}` : baseFacebookLink;
  const messengerLink = isMobile() ? `fb-messenger://share/?link=${encodeURIComponent(shareUrl)}&app_id=123456789` : null;
  const baseTwitterLink = `https://twitter.com/intent/tweet?shareUrl=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(title)}`;
  const twitterLink = isMobile() ? `twitter://post?message=${encodeURIComponent(title + ' ' + shareUrl)}` : baseTwitterLink;
  const baseLinkedinLink = `https://www.linkedin.com/shareArticle?shareUrl=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(title)}`;
  const linkedinLink = isMobile()
    ? `linkedin://shareArticle?mini=true&shareUrl=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(title)}`
    : baseLinkedinLink;
  const pinterestLink = `https://www.pinterest.com/pin/create/button/?shareUrl=${encodeURIComponent(shareUrl)}&media=&description=${encodeURIComponent(title)}`;
  const emailLink = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(shareUrl)}`;
  const baseWhatsappLink = `https://wa.me/?text=${encodeURIComponent(`${title} ${shareUrl}`)}`;
  const whatsappLink = isMobile() ? `whatsapp://send?text=${encodeURIComponent(title + ' ' + shareUrl)}` : baseWhatsappLink;
  const nextdoorLink = `https://nextdoor.com/share?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(title)}`;

  return { facebookLink, messengerLink, twitterLink, linkedinLink, pinterestLink, emailLink, whatsappLink, nextdoorLink };
};

const extractBaseDomain = (url) => {
  const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^:/\n?]+)/g;
  const matches = url.match(domainRegex);
  if (matches && matches.length > 0) {
    return matches[0];
  }
  return '';
};

const disableScrollBar = (searchParams) => {
  const style = document.createElement('style');
  style.innerHTML = `
    html::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
    html {
        scrollbar-width: none;
    }
    `;
  document.head.appendChild(style);
};

const sortAlphabetically = (array) => {
  return array.sort((a, b) => a.localeCompare(b));
};

const countCardsPerScreenWidth = (screenWidth) => {
  return Math.floor((screenWidth - 128) / 320);
};

const ellipsisText = (text, maxLength) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

export {
  __log,
  isPersonalisedUser,
  isPersonalisedExperienceEnabled,
  isLoginRadiusUser,
  navigationRoute,
  removeExcludedDomains,
  retrieveExcludedDomains,
  retrieveDomainsFromLocation,
  getEnvConfig,
  getTimeSinceLastModified,
  trackableURL,
  truncateString,
  getConfigFromURL,
  getNameFromURL,
  getLogoFromURL,
  createTimestamp,
  avoid,
  removeDuplicates,
  isEmbedPagePresent,
  getScrollPercent,
  constructPath,
  isMobile,
  createSocialSharingLinks,
  extractBaseDomain,
  disableScrollBar,
  sortAlphabetically,
  countCardsPerScreenWidth,
  ellipsisText,
};
