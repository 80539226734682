// React and hooks
import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

// Material UI Components and Icons
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';

// Helper Functions and Constants
import { trackableURL } from '../../../helpers/utils';
import { getCategoryByName } from '../../../config/categories';
import { getRouteByName } from '../../../config/routeConfig';
import { getFeatureValue } from '../../../helpers/configUtilities';
import { constructPath } from '../../../helpers/utils';

// Local Components
import ShareButtonsPopup from '../../CustomPopup/ShareButtons/ShareButtonsPopup';

const ShareButton = () => {
  // Define state variables
  const [isSocialSharingPopupOpen, setIsSocialSharingPopupOpen] = useState(false);

  const { sectionSlug, subSectionSlug, categorySlug } = useParams();
  const location = useLocation();
  const siteRoot = getFeatureValue('siteRoot');

  // Retrieve values from location if they are undefined
  const updatedSectionSlug = sectionSlug || location.pathname.split('/')[1];
  const updatedSubSectionSlug = subSectionSlug || location.pathname.split('/')[2];
  const updatedCategorySlug = categorySlug || location.pathname.split('/')[3];

  const lastParam = updatedCategorySlug || updatedSubSectionSlug || updatedSectionSlug;

  // Retrieve category and route configuration
  const categoryConfig = getCategoryByName(lastParam);
  const routeConfig = getRouteByName(lastParam);

  // Construct the expected and actual URL paths
  const expectedURLPath = constructPath(categoryConfig?.sectionSlug, categoryConfig?.subSectionSlug, categoryConfig?.categorySlug);
  const actualURLPath = constructPath(updatedSectionSlug, updatedSubSectionSlug, updatedCategorySlug);

  const isExpectedCategoryMatch = expectedURLPath === actualURLPath;

  // Choose config based on whether the category matches
  const config = isExpectedCategoryMatch ? categoryConfig : routeConfig;

  const canonicalURL = actualURLPath ? `${siteRoot}/${actualURLPath}` : window.location.href;

  const pageTitle = config?.metaTitle ? config.metaTitle + ' | My News Assistant' : '';

  // Generate trackable URL
  const trackableUrl = trackableURL(canonicalURL, 'share_link');

  // Function to handle click of share button
  const handleShareClick = () => {
    // If navigator.share is supported
    if (navigator.share) {
      // Use native share dialog
      navigator
        .share({
          title: pageTitle ? pageTitle : document.title,
          text: 'Stay connected and informed with My News Assistant, your ultimate news companion! 📲💡',
          url: trackableUrl,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing:', error));
    } else {
      // Otherwise, open custom share popup
      setIsSocialSharingPopupOpen(true);
    }
  };

  const handleCopy = async () => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(trackableUrl);
    } else {
      return document.execCommand('copy', true, trackableUrl);
    }
  };

  return (
    <>
      <IconButton aria-label="share" sx={{ p: 0 }} onClick={handleShareClick}>
        <ShareIcon sx={{ color: 'common.black' }} fontSize="small" />
      </IconButton>
      {!navigator.share && isSocialSharingPopupOpen && (
        <ShareButtonsPopup
          isOpen={isSocialSharingPopupOpen}
          onClose={() => setIsSocialSharingPopupOpen(false)}
          logo={require('../../../assets/mna_icon_black.jpg')}
          title={pageTitle ? pageTitle : document.title}
          url={canonicalURL}
          handleCopy={handleCopy}
        />
      )}
    </>
  );
};

export default ShareButton;
