import React from 'react';
import './teaser.scss';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function teaserPlaceholder({ cards }) {
  return Array(cards)
    .fill(0)
    .map((item, i) => (
      <div className="card-container" key={i}>
        <Skeleton height="350px" style={{lineHeight: 'unset', borderRadius: '10px'}} />
      </div>
    ));
}

export default teaserPlaceholder;
