import React from 'react';
import { useState, useRef } from 'react';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import './editableCategoryName.scss'
import { ellipsisText } from '../../helpers/utils';


const EditableCategoryName = ({ displayName, isEditable, handleCategoryNameChange }) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [inputText, setInputText] = useState(displayName);
    const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768)

    const inputRef = useRef(null);
    const theme = useTheme()

    const handleStartEdit = () => {
        setIsEditMode(true);
        inputRef.current.focus();
    };

    const handleSaveChanges = (e) => {
        if (inputText.length !== 0) {
            setIsEditMode(false);
            handleCategoryNameChange(e);
        }
    };

    const iconButton = isEditMode ? (
        <IconButton sx={{ ml: 1 }} aria-label='save changes' onClick={handleSaveChanges}>
            <CheckCircleOutlineIcon fontSize='small' />
        </IconButton>
    ) : ( isDesktopView && (
        <IconButton
            className='editIcon'
            sx={{ ml: 1, display: 'none' }}
            aria-label='edit category name'
            onClick={handleStartEdit}
        >
            <EditIcon fontSize='small' />
        </IconButton>
        )
    );

    return (
      <div className="divTitle">
        {isEditMode ? (
          <TextField
            type="text"
            inputRef={inputRef}
            autoFocus={isEditMode}
            defaultValue={displayName}
            onBlur={handleSaveChanges}
            onFocus={() => setIsEditMode(true)}
            onKeyDown={(e) => e.key === 'Enter' && inputRef.current.blur()}
            onChange={(e) => setInputText(e.target.value.trim())}
            error={inputText.length === 0}
            variant="standard"
            sx={{
              '& .MuiInputBase-root': {
                color: theme.palette.primary.main,
                fontSize: theme.typography.size24,
                [theme.breakpoints.up('tablet')]: {
                  fontSize: theme.typography.size38,
                },
              },
              '& .MuiInputBase-input': {
                padding: 0,
                letterSpacing: 'normal',
              },
            }}
          />
        ) : (
          <Typography
            variant="h1bold"
            color={theme.palette.primary.main}
            ref={inputRef}
            onClick={handleStartEdit}
            role="button"
            tabIndex={0}
            aria-label={`The name of the news category is ${displayName}. Press to edit`}
            onKeyDown={(e) => e.key === 'Enter' || (e.key === ' ' && handleStartEdit())}
          >
            {isDesktopView ? displayName : ellipsisText(displayName, 15)}
          </Typography>
        )}
        {isEditable && iconButton}
      </div>
    );
};

export default EditableCategoryName;