import React from 'react';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';

const CommunicationPreferencesForm = ({
  marketingPermission,
  setMarketingPermission,
  pushNotificationPermission,
  setPushNotificationPermission,
  newsletterPermission,
  setNewsletterPermission,
  newsletterFrequency,
  setNewsletterFrequency,
}) => {
  return (
    <div>
      <Typography variant="body1" color="common.black">
        <strong>Communication Preferences</strong>
      </Typography>
      <Typography variant="body1" sx={{ mb: 1.5 }}>
        Stay up-to-date on the latest news and topics that matter to you with My News Assistant's newsletters and real-time push notifications. Choose how
        often you'd like to receive them:
      </Typography>
      <FormGroup sx={{ mb: 1 }}>
        <FormControlLabel
          control={<Checkbox checked={marketingPermission} onChange={(e) => setMarketingPermission(e.target.checked)} sx={{ p: 0.5 }} />}
          label="Yes, I want to receive exclusive offers."
        />
        <FormControlLabel
          control={<Checkbox checked={pushNotificationPermission} onChange={(e) => setPushNotificationPermission(e.target.checked)} sx={{ p: 0.5 }} />}
          label="Yes, send me push notifications for the latest news and topics."
        />
        <FormControlLabel
          control={<Checkbox checked={newsletterPermission} onChange={(e) => setNewsletterPermission(e.target.checked)} sx={{ p: 0.5 }} />}
          label="Yes, I want to receive My News Assistant newsletters."
        />
      </FormGroup>
      {newsletterPermission && (
        <>
          <FormControl>
            <RadioGroup
              aria-label='choose newsletter frequency'
              name='newsletter-frequency'
              value={newsletterFrequency}
              onChange={(e) => setNewsletterFrequency(e.target.value)}
            >
              <FormControlLabel value='daily' control={<Radio sx={{ p: 0.5 }} />} label='Daily' sx={{ ml: 2.5 }} />
              <FormControlLabel value='weekly' control={<Radio sx={{ p: 0.5 }} />} label='Weekly' sx={{ ml: 2.5 }} />
              <FormControlLabel value='monthly' control={<Radio sx={{ p: 0.5 }} />} label='Monthly' sx={{ ml: 2.5 }} />
            </RadioGroup>
          </FormControl>
          <Typography variant='body1' sx={{ pt: 1.5 }}>
            We value your privacy and will never share your information with third parties. You can unsubscribe from our newsletters or push notifications at
            any time.
          </Typography>
        </>
      )}
    </div>
  );
};

export default CommunicationPreferencesForm;
