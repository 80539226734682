import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './categoryWise.scss';
import TeaserPlaceholder from '../shared/teaserPlaceholder';

function gridDesktopPlaceholder() {
  return (
    <div className="category-wrapper">
      <div className="category-header">
        <div className="title-container">
          <Skeleton containerClassName="listTitle" style={{ width: '560px', height: '35px' }} />
          <Skeleton style={{ width: '105px', height: '30px', borderRadius: '30px' }} />
        </div>
      </div>
      <div className="grid-placeholder">
        <TeaserPlaceholder cards={12} />
      </div>
    </div>
  );
}

export default gridDesktopPlaceholder;
