import React, { useEffect, useState } from 'react';
import DoneIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Cancel';

export default function PasswordStrength(props) {
  const [hasMinimumCharacters, setHasMinimumCharacters] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);

  useEffect(() => {
    if (props.password) {
      setHasMinimumCharacters(props.password.length >= 8);
      setHasUppercase(props.password.toLowerCase() !== props.password);
      setHasLowercase(props.password.toUpperCase() !== props.password);
      setHasSymbol(/[^a-zA-Z0-9]/.test(props.password));
      setHasNumber(/\d/.test(props.password));
    }
  }, [props.password]);

  return (
    <div className="password-strength">
      <div className="password-strength__checkbox-group">
        <input name="criteria-characters" type="checkbox" checked={hasMinimumCharacters} disabled />
        <div className="password-strength__checkbox-group__labelIcon">
          {hasMinimumCharacters ? <DoneIcon color='success' /> : <CloseIcon />}
        </div>
        <label htmlFor="criteria-characters">Must be at least 8 characters</label>
      </div>

      <div className="password-strength__checkbox-group">
        <input name="criteria-uppercase" type="checkbox" checked={hasUppercase && hasLowercase} disabled />
        <div className="password-strength__checkbox-group__labelIcon">
          {hasUppercase && hasLowercase ? <DoneIcon color='success' /> : <CloseIcon />}
        </div>
        <label htmlFor="criteria-uppercase">Use a mix of uppercase and lowercase</label>
      </div>

      <div className="password-strength__checkbox-group">
        <input name="criteria-number" type="checkbox" checked={hasNumber} disabled />
        <div className="password-strength__checkbox-group__labelIcon">{hasNumber ? <DoneIcon color='success' /> : <CloseIcon />}</div>
        <label htmlFor="criteria-number">Include one number</label>
      </div>

      <div className="password-strength__checkbox-group">
        <input name="criteria-symbol" type="checkbox" checked={hasSymbol} disabled />
        <div className="password-strength__checkbox-group__labelIcon">{hasSymbol ? <DoneIcon color='success' /> : <CloseIcon />}</div>
        <label htmlFor="criteria-symbol">Include a special character</label>
      </div>
    </div>
  );
}
