import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import './static.scss';

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = 'Terms & Conditions | My News Assistant';
    window.scrollTo(0, 0);
  });

  return (
    <div className="standard-layout static-page">
      <main>
        <section className="static-content">
          <Typography variant="h1bold" color="primary">
            Terms & Conditions
          </Typography>
          <section>
            <Typography variant="h5bold" color="primary">
              USER TERMS & CONDITIONS ("agreement").
            </Typography>
            <p>
              Please read this agreement carefully. It sets out the terms on which you submit content to us and sets out the terms under which we agree to make
              available any of our websites and apps, including e-editions (“the Site”), however you access it, to you.
            </p>
            <p>
              By using the Site, you confirm that you accept the terms of this agreement and that you agree to comply with them. If you do not agree to this
              agreement, you must not use the Site.
            </p>
            <p>
              The publisher of the Site is a subsidiary of Reach plc (company number 82548) which asserts copyright in the Site. Reach Plc, and all of its
              subsidiaries’, registered address is One Canada Square, Canary Wharf, London, E14 5AP. Reach Plc, and all of its subsidiaries’, VAT number is 440
              3567 67.
            </p>
            <p>“We” or “us” in this agreement means Reach Plc and all of its subsidiaries.</p>
            <p>
              To contact us, please see the{' '}
              <a className="anchor" href="/contact-us">
                contact us
              </a>{' '}
              page on this site.
            </p>
            <p>
              We offer access to many features of the Site free of charge but due to the nature of the Internet, we do not promise full and error free operation
              of the Site at all times.
            </p>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              THERE ARE OTHER DOCUMENTS THAT ARE RELEVANT TO YOU
            </Typography>
            <p>This agreement refers to the following additional terms, which also apply to your use of the Site:</p>
            <ul>
              <li>
                <a href="/legal/privacy-notice">Our Privacy Notice</a>
              </li>
              <li>
                <a href="/legal/cookie-notice">Our Cookie Notice</a>
              </li>
            </ul>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              WE MAY MAKE CHANGES TO THESE TERMS
            </Typography>
            <p>We amend these terms from time to time. These terms were most recently updated on 18th April 2019.</p>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              YOUR USE OF THE SITE
            </Typography>
            <p>
              The Site is for personal and non-commercial use only. You may download and print portions of the Site for your personal, non-commercial use only.
            </p>
            <p>
              Any other use of materials on the Site, including any user generated content (including without limitation reproduction for a purpose (other than
              that noted above) and any modification, distribution or republication) without our prior written permission is strictly prohibited.
            </p>
            <p>You agree not to frame the Site for any purpose, unless specifically authorised by us to do so.</p>
            <p>
              You agree that you will not use the Site to submit, or knowingly or recklessly receive, any material including without limitation, comments and
              reviews that:
            </p>
            <ul>
              <li>
                are in any way, obscene, threatening, abusive, offensive, defamatory, invasive of another’s privacy, in breach of confidence, embarrassing to
                any person, likely to deceive, inaccurate, misleading, hateful, blasphemous, pornographic, or racially, ethnically or otherwise objectionable;
              </li>
              <li>are in breach of any third party’s intellectual property rights (including copyright) or other rights;</li>
              <li>
                constitute or encourage conduct that would be considered a criminal offence or otherwise contrary to any law, regulation or code of practice or
                give rise to civil liability or infringe the rights of any third party anywhere in the world;
              </li>
              <li>are, or could be, technically harmful;</li>
              <li>are, or may be deemed to be, advertising or promotional materials including “spam” email;</li>
              <li>makes excessive demands for bandwidth; or</li>
              <li>
                contains any virus or other malicious program or which may otherwise impair or harm our computer systems or any third party computer system.
              </li>
            </ul>
            <p>
              You agree not to impersonate any person or entity or misrepresent any affiliation with any person or entity when creating an account on the Site
              or submitting any content to the Site.
            </p>
            <p>
              You also agree not to attack the Site or access or attempt to access the accounts of other users or gain a greater level of access to the Site
              than authorised.
            </p>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              OUR MONITORING OF THE SITE
            </Typography>
            <p>
              You acknowledge that we have no obligation to monitor any user generated content on the Site but in our sole discretion, we have the right to
              delete any such content and we may remove any such material at any time. Notwithstanding this, you are solely responsible for and bear the legal
              risk associated anywhere in the world in relation to any such content you may post.
            </p>
            <p>
              In the event that you are in breach of the terms of this agreement, we will have the right to terminate any account that you have with the Site
              and you may not open another account on it or otherwise continue to use the Site.
            </p>
            <p>
              We will co-operate with law enforcement or regulatory authorities or any court order requesting or requiring it to disclose the identity or
              location of or any other information in relation to anyone who has submitted content to the Site in breach or allegedly in breach of this
              agreement or the law. In appropriate circumstances, we may also disclose such information to the police, your Internet service provider and any
              third party to whom we may make a disclosure in good faith.
            </p>
            <p>
              You acknowledge that submitting content to the Site does not guarantee that it will appear on the Site. You cannot edit or remove content once you
              have submitted it to the Site. However, if you would like make a complaint about specific user generated content on the Site, please see the
              contact us page on this site.
            </p>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              RIGHTS YOU ARE GIVING US IN CONTENT YOU EITHER SEND TO US FOR PUBLICATION OR UPLOAD ON THE SITE OR POST ON OUR SOCIAL MEDIA ACCOUNTS
            </Typography>
            <p>
              If you send us, upload or post content , you grant us a perpetual, royalty free, irrevocable, non-exclusive right and licence to use, reproduce,
              publish, communicate to the public, translate, create derivative works from and distribute such content into any form, medium or technology now
              known or hereafter developed. In addition, you waive any and all moral rights in such content.
            </p>
            <p>
              By sending us content for publication you confirm that you either own the copyright in the content, or are legally entitled to provide it to us,
              and that you have the consent of all of the people in the content. If the content features a child, or children, you confirm that you are the the
              parent or guardian of the child, or children, and have the legal right to grant consent (or the parent or guardian of the child, or children, has
              granted such consent).
            </p>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              COMPETITIONS AND PRIZE DRAWS
            </Typography>
            <p>By registering an account on the Site, you will have access to enter competitions and/or prize draws on the Site.</p>
            <p>
              Competition and prize draws may be operated by third parties and where they are, this will be made clear to you and additional terms and
              conditions between that third party and you will apply.
            </p>
            <p>
              Whether we or a third party operate the completion or prize draw, you will likely be dealing with other third parties directly as a result of it
              and as such any contracts and/or arrangements will be formed between you and them, without us as a party and we cannot be held liable for your use
              of or any liability arising out of the same (save to the extent required by law).
            </p>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              DO NOT RELY ON INFORMATION ON THE SITE
            </Typography>
            <p>
              The content on the Site is provided “as-is” and for general information only. It is not intended to amount to advice on which you should rely. You
              must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on the Site.
            </p>
            <p>
              We make no representations, warranties or guarantees, whether express or implied, that the content on the Site is accurate, complete or up to date
              and we do not accept any responsibility for any of such content.
            </p>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              LINKS TO OTHER WEBSITES
            </Typography>
            <p>
              The Site may include links to the content, web sites and/or services owned and/or operated by third parties. These are provided for your
              convenience only and we are not responsible for and do not give any warranties or make any representations regarding any such content, web sites
              and/or services.
            </p>
            <p>
              The onus for ensuring that content presented on the Internet is legal rests with the original content provider, and we will not be under any
              obligations to limit access to material unless notified that it is illegal or otherwise in breach of the terms of this agreement. Notwithstanding,
              we shall have the right to remove any items we believe may be illegal or otherwise in breach of the terms of this agreement.
            </p>
            <p>Sometimes we include links to other websites; if you click on one and make a purchase of a product or service, we may receive a commission.</p>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              OUR INTELLECTUAL PROPERTY AND TRADE MARKS
            </Typography>
            <p>
              The intellectual property in all design, text, graphics and other material (other than user generated content) and the selection or arrangement of
              such material on the Site is owned by us and/or our respective licensors.
            </p>
            <p>We are the owner of:</p>
            <ul>
              <li>those trade mark(s) indicated as such throughout the Site from time to time; and</li>
              <li>all other trade marks used in the Site which are not licensed to us by any third party.</li>
            </ul>
            <p>All other trade marks, product names and company names or logos cited therein are the property of their respective owners.</p>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              YOUR ACCOUNT
            </Typography>
            <p>Should you create a user account on the Site, the personal details that you provide must be true, accurate and complete.</p>
            <p>
              You must keep your password(s) relevant to the Site confidential and not reveal them to anyone else. You are responsible for all activities that
              are carried out under your username and password(s). We do not have the means to check the identities of people using the Site and will not be
              liable where your username and/or password(s) are used by someone else. You agree to notify us immediately by email of any unauthorised use of
              your account of which you become aware. Please see the <a href="/contact-us">contact us</a> page on this site contact details.
            </p>
            <p>
              You will not create additional accounts for the purpose of abusing the functionality of the Site or other users or for any other reason in breach
              of the terms of this agreement.
            </p>
            <p>
              You can cancel your account at any time by emailing us. Please see the <a href="/contact-us">contact us</a> page on this site.
            </p>
            <p>
              We shall use reasonable endeavours to ensure that the Site and any information it holds are kept secure. However, due to the nature of the
              Internet, we cannot accept any liability for those who intentionally attempt to and/ or gain unauthorised access to the same by means such as,
              without limitation, computer hacking (save to the extent that we are unable to exclude such liability by law) and we do not warrant that the Site
              or any of its contents are free of viruses or malware
            </p>
            <p>If you log-in to our sites using social media, for example, Facebook, your profile picture will associated with your account.</p>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              HOW WE MAY USE YOUR PERSONAL INFORMATION AND COOKIES
            </Typography>
            <p>
              We will use your personal information as set out in our <a href="/legal/privacy-notice">Privacy Notice</a>.
            </p>
            <p>
              We will use cookies as set out in our <a href="/legal/cookie-notice">Cookie Notice</a>.
            </p>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              CLAIMS MADE AGAINST US BY THIRD PARTIES
            </Typography>
            <p>
              Where a claim is brought against us by a third party in relation to your use of the Site you agree to fully reimburse us and all companies within
              our group for all losses, costs, actions, claims, damages, expenses (including legal costs and expenses) or liabilities, whatsoever suffered or
              incurred by us or the companies within our group in or as a consequence of your breach of this agreement and your use of the Site which is
              included on the Site.
            </p>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              WE ARE NOT LIABLE FOR THINGS BEYOND OUR CONTROL
            </Typography>
            <p>We are not liable for things beyond our control, such as power failure and problems on the Internet.</p>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU
            </Typography>
            <ul>
              <li>
                We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal
                injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.
              </li>
              <li>
                We exclude all implied conditions, warranties, representations or other terms that may apply to the Site or any content on it, to the extent
                that it is lawful to do so
              </li>
            </ul>
            <p>
              We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise,
              even if foreseeable, arising under or in connection with:
            </p>
            <ul>
              <li>use of, or inability to use, the Site; or</li>
              <li>use of or reliance on any content displayed on the Site.</li>
            </ul>
            <p>We will not be liable for:</p>
            <ul>
              <li>loss of profits, sales, business, or revenue;</li>
              <li>business interruption;</li>
              <li>loss of anticipated savings;</li>
              <li>loss of business opportunity, goodwill or reputation; or</li>
              <li>any indirect or consequential loss or damage.</li>
            </ul>
            <p>
              If defective digital content that we have supplied damages a device or digital content belonging to you and this is caused by our failure to use
              reasonable care and skill. Our only liability will be either to repair the damage or pay you equivalent compensation.
            </p>
            <p>
              If any provision (or part of a provision) of this agreement is found by any court or administrative body of competent jurisdiction to be invalid,
              unenforceable or illegal, the other provisions shall remain in force.
            </p>
            <p>
              If any invalid, unenforceable or illegal provision would be valid, enforceable or legal if some part of it were deleted, the provision shall apply
              with whatever modification is necessary to give effect to the commercial intention of the parties.
            </p>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              THIS AGREEMENT IS PERSONAL TO YOU
            </Typography>
            <p>
              This agreement shall be personal to you and you may not assign, transfer, sublet, lease or delegate all or any of your rights and obligations,
              without our prior written consent.
            </p>
            <p>
              We reserve the right to assign or transfer all or any of its rights and obligations under this agreement to any companies in the same group as
              Reach plc or another third party. In the event of assignment or transfer, notification will either be given to you by e-mail or posted on the
              Site.
            </p>
          </section>
          <section>
            <Typography variant="h5bold" color="primary">
              WHICH COUNTRY'S LAWS APPLY TO ANY DISPUTES?
            </Typography>
            <p>
              Please note that these terms of use, their subject matter and their formation, are governed by English law. You and we both agree that the courts
              of England and Wales will have exclusive jurisdiction except that if you are a resident of Northern Ireland you may also bring proceedings in
              Northern Ireland, if you are resident of Scotland, you may also bring proceedings in Scotland and if you are a resident of the Republic of Ireland
              you may also bring proceedings in the Republic of Ireland.
            </p>
            <p>
              If you are a consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident. Nothing in these terms
              and conditions, including, without limitation, this section dealing with governing law and jurisdiction, affects your rights as a consumer to rely
              on such mandatory provisions of local law.
            </p>
          </section>
        </section>
      </main>
    </div>
  );
};

export default TermsAndConditions;
