import { Outlet } from 'react-router-dom';
import Navbar from '../../components/navigation/navbar';
import Footer from '../../components/footer/footer';
import './mainLayout.scss';
import './loginRadius.scss';



export default function MainLayout() {
  return (
      <div className="page-container">
        <Navbar />
        <Outlet />
        <Footer />
      </div>
  );
}
