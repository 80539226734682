import './categoryPage.scss';
import React, { useState, useEffect } from 'react';
import CategoryArticles from '../../components/categorypage/CategoryWiseArticle';
import { AdvertisingProvider } from 'react-advertising';
import CircularProgress from '@mui/material/CircularProgress';
import { setPublisherProvidedId } from '../../components/tracking/gam';
import { init as mantisIdInit } from '../../components/tracking/mantisId';
import { getAdConfig } from '../../config/adConfig/getAdConfig'
import { __log } from '../../helpers/utils';


const mantisIdReady = new Promise((resolve) => {
  mantisIdInit().then(setPublisherProvidedId).then(resolve);
  if (location.hostname === 'localhost') {
    resolve()
  }
});

export default function CategoryPage({ sectionSlug, subSectionSlug, categorySlug, config }) {
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);
  const [adConfiguration, setAdConfiguration] = useState(undefined);
  const [isCircularLoading, setCircularLoading] = useState(false);

  // first render does not have any config for the AdvertisingProvider wrapper
  // in order to avoid blocking the rendering of the whole page
  // config are loaded by setAdConfiguration state function during the second render
  const initAds = async () => {
    // we need to wait for the async mantisid / ppid process before we can load ads
    await mantisIdReady;
    // pass the adConfig to the AdvertisingProvider component, triggering a render
    setAdConfiguration(getAdConfig('category'));
    __log('Ads initiated', 'for category page');
  };

  const updateCircularLoading = async (isTrue) => {
    setCircularLoading(isTrue);
  }

  const updateMedia = () => {
    initAds()
    setIsDesktopView(window.innerWidth > 768);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('singleCategoryLoaded', initAds);
    window.addEventListener('resize', updateMedia);
    return () => {
      window.removeEventListener('resize', updateMedia);
      window.removeEventListener('singleCategoryLoaded', initAds);
    }
  }, []);

  return (
    <div className="standard-layout category-page categoryArticles">
      <main className={isDesktopView ? 'desktop' : 'mobile'}>
        <AdvertisingProvider config={adConfiguration}>
          <CategoryArticles
            sectionSlug={sectionSlug}
            subSectionSlug={subSectionSlug}
            categorySlug={categorySlug}
            config={config}
            circularLoading={updateCircularLoading}
          />
          {isCircularLoading && (
            <div className='circularLoading'>
              <CircularProgress color='primary' thickness={5} />
            </div>
          )
          }
        </AdvertisingProvider>
      </main>
    </div>
  );
}