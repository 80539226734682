import React, { useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

import { getDisplayName } from '../../helpers/localConfigUtils.js';
import { constructPath } from '../../helpers/utils.js';
import useSavedCategories from './useSavedCategories.js';

const DrawerMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const { savedCategories, savedSearches } = useSavedCategories();

  const updatedCategories = savedCategories.map((category) => ({
    ...category,
    type: 'category',
    displayName: getDisplayName(category.key) ?? category.displayName,
  }));
  const updatedSavedSearches = Object.values(savedSearches).map((item) => ({ ...item, type: 'saved-search' }));
  const staticLinks = [{ type: 'static', displayName: 'My Feed', pathname: '/' }];
  const menuItems = [...staticLinks, ...updatedCategories, ...updatedSavedSearches];

  const isActiveLink = (category) => {
    if (category.type === 'static') return location.pathname === category.pathname;
    const pathParts = location.pathname.split('/').filter((part) => part !== '');
    const lastPathPart = pathParts[pathParts.length - 1];
    return lastPathPart === category.categorySlug;
  };

  const handleCategoryClick = (category) => {
    if (category.type === 'static') {
      navigate(category.pathname);
    } else {
      navigate(constructPath(category.sectionSlug, category.subSectionSlug, category.categorySlug));
    }
    setIsMenuOpen(false);
  };

  const filteredMenuItems = useMemo(() => {
    return menuItems.filter((category) => category.displayName.toLowerCase().indexOf(searchTerm) > -1);
  }, [searchTerm, menuItems]);

  return (
    <>
      <IconButton onClick={() => setIsMenuOpen(true)}>
        <MenuIcon color="lightGray" />
      </IconButton>
      <Drawer anchor="left" open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        <Box sx={{ width: '297px' }} role="presentation">
          <List dense sx={{ pt: 0, pb: 3, px: 1 }}>
            <ListSubheader sx={{ pt: 4, pb: 3 }}>
              <OutlinedInput
                fullWidth
                type="text"
                placeholder="Search"
                aria-label={'search categories'}
                onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon color="osloGrey" />
                  </InputAdornment>
                }
              />
            </ListSubheader>
            {filteredMenuItems &&
              filteredMenuItems.map((category, index) => (
                <ListItem key={index} disableGutters>
                  <ListItemButton onClick={() => handleCategoryClick(category)}>
                    <ListItemText className={isActiveLink(category) ? 'highlighted' : 'list-item'}>{category.displayName}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default DrawerMenu;
