import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import CommentIcon from '@mui/icons-material/Comment';

import { getViafouraData } from '../../helpers/viafouraUtils';
import { fetchViafouraData } from '../../services/api';
import { trackableURL } from '../../helpers/utils';

function CommentsCount({ item }) {
  const [commentCount, setCommentCount] = useState(0);


  const theme = useTheme();
  const { url } = item;
  const { viafouraId = 0, viafouraPayload = '' } = url ? getViafouraData(url) : {};

  useEffect(() => {
    let isMounted = true;

    fetchViafouraData(viafouraId, viafouraPayload).then((response) => {
      isMounted && response ? setCommentCount(response.commentCount) : setCommentCount(0);
    });

    return () => {
      isMounted = false;
    };

    
  }, []);

  return (
    <IconButton
      component={Link}
      sx={{
        padding: '0px',
        color: theme.palette.osloGrey.main,
        '&:focus-visible': { backgroundColor: 'unset' },
        '&:hover': { backgroundColor: 'unset' },
      }}
      //only teaser comments ?
      href={trackableURL(item.url, 'teaser_comments') + '#comments-wrapper'}
      target="_blank"
      rel="noopener noreferrer"
      disableRipple={true}
    >
      <CommentIcon color="osloGrey" data-testid="ModeCommentIcon" />
      <Typography variant="label1" sx={{ color: theme.palette.osloGrey.main }}>
        &nbsp; {commentCount} comments
      </Typography>
    </IconButton>
  );
}

export default CommentsCount;
