import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//component for my-profile page (login-radius)
const MyProfile = () => {
  let navigate = useNavigate();

  useEffect(() => {
    navigate('/settings');
  }, [])

  return null;
};

export default MyProfile;