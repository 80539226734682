// React and hooks
import React, { useState, useEffect } from 'react';

// Local Components
import EmptyHomepage from '../../components/homepage/emptyHomepage';
import renderCategory from '../../components/homepage/RenderComponent';
import BreadcrumbsAndSocialSharingContainer from '../../components/breadcrumbsAndSocialSharing/breadcrumbsAndSocialSharing';

// Material UI Components and Icons
import { useNavigate } from 'react-router-dom';
import { AdvertisingProvider } from 'react-advertising';

// Helper Functions and Constants
import { setPublisherProvidedId } from '../../components/tracking/gam';
import { init as mantisIdInit } from '../../components/tracking/mantisId';
import { getAdConfig } from '../../config/adConfig/getAdConfig';
import { getCategoryByName, getCategoryByType } from '../../config/categories.js';
import { __log } from '../../helpers/utils';
import { getQueryParameter, createCookie } from '../../helpers/pageUtilities';
import { authUiConfig } from '../../config/authUIConfig';
import {
  setUserIdFromLocalStorage,
  sortInterestsByPinnedCategoryOrder,
  isPersonalisedExperience,
} from '../../helpers/localConfigUtils';


if (getQueryParameter('debug')) {
  createCookie('mna-debug', true, 9999);
}

// Initialize mantisId and set publisher provided ID, then resolve promise
const mantisIdReady = new Promise((resolve) => {
  mantisIdInit().then(setPublisherProvidedId).then(resolve);
  if (location.hostname === 'localhost') {
    __log('localhost env detected', 'skipping cmp load');
    resolve();
  }
});

// Initialize authUI when the DOM is loaded
document.addEventListener('DOMContentLoaded', () => {
  if (window.AuthUI) {
    window.AuthUI.init(authUiConfig);
  }
});

/**
 * The Homepage component.
 * @returns {ReactElement} The Homepage component.
 */
export default function Homepage() {
  const [adConfiguration, setAdConfiguration] = useState(undefined);
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);
  const navigate = useNavigate();
  const [pinnedCategories, setPinnedCategories] = useState([]);
  
  const config = getCategoryByType();
  const categoriesMap = new Map(Object.entries(config));
  const personalisedExperience = isPersonalisedExperience();
  const storedCategories = sortInterestsByPinnedCategoryOrder();
  /**
   * Update the pinned categories state by toggling the given category key.
   * @param {string} categoryKey - The key of the category to toggle.
   */
  const updatePinnedCategories = (categoryKey) => {
    setPinnedCategories((prevState) => {
      if (prevState.includes(categoryKey)) {
        return prevState.filter((activeCategory) => activeCategory !== categoryKey);
      }
      return [...prevState, categoryKey];
    });
    __log('state changing in the section page with the clicked categ', categoryKey);
  };

  /**
   * Update the media state (desktop or mobile) and reinitialize ads.
   */
  const updateMedia = () => {
    initAds();
    setIsDesktopView(window.innerWidth > 768);
  };

  /**
   * Initialize ads and set adConfiguration.
   * @returns {Promise<void>} Resolves once ads have been initialized.
   */
  const initAds = async () => {
    await mantisIdReady;
    setAdConfiguration(getAdConfig('homepage'));
    __log('Ads initiated', 'for home page');
  };

  /**
   * Update the user authentication status.
   * @param {Object} event - The event containing the user authentication status.
   */
  const updateUserAuthStatus = (event) => {
    const data = event.data || event.detail || {};
    if (data.isAuthenticated) {
      setUserIdFromLocalStorage();
      if (localStorage.getItem('mna-init-signup-or-signin')) {
        navigate('/signup/loader');
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('auth-ui.authentication', updateUserAuthStatus);
    window.addEventListener('allCategoriesLoaded', initAds);
    window.addEventListener('resize', updateMedia);

    return () => {
      window.removeEventListener('auth-ui.authentication', updateUserAuthStatus);
      window.removeEventListener('allCategoriesLoaded', initAds);
      window.removeEventListener('resize', updateMedia);
    };
  }, [pinnedCategories]);

  return (
    <>
      <BreadcrumbsAndSocialSharingContainer />
      <div className="standard-layout home-page" data-testid="container">
        <main className={window.innerWidth > 768 ? 'desktop' : 'mobile'}>
          <AdvertisingProvider config={adConfiguration}>
            {/* Home page rendering the core categories as there are no preferences set by the user */}
            {(!personalisedExperience || !storedCategories) &&
              Array.from(categoriesMap.keys()).map((categoryName, index) => {
                const categoryConfig = getCategoryByName(categoryName);
                return (
                  categoryConfig.categoryType === 'primary' &&
                  renderCategory(index, categoryName, storedCategories, pinnedCategories, setPinnedCategories, updatePinnedCategories, isDesktopView)
                );
              })}
            {/* Home page rendering the preferred categories by checking the localStorage */}
            {(storedCategories.length > 0 &&
              personalisedExperience) ? 
              storedCategories.map((categoryName, index) =>
                renderCategory(index, categoryName, storedCategories, pinnedCategories, setPinnedCategories, updatePinnedCategories, isDesktopView)
              ) : personalisedExperience && <EmptyHomepage  />}
          </AdvertisingProvider>
        </main>
      </div>
    </>
  );
}
