import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const PopupContainer = styled('div')({
  padding: '20px 24px 20px 24px',
});
let logoContainerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
};
let defaultLogoStyles = {
  maxWidth: '100%',
  height: '25px',
};
export const popupLocalTheme = (theme) =>
  createTheme({
    ...theme,
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '10px',
            boxShadow: ' 20px 0px 44px 0px rgba(0, 0, 0, 0.25)',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.size20,
            fontWeight: '700',
            padding: '0',
            marginBottom: theme.typography.size16,
            [theme.breakpoints.down('tablet')]: {
              fontSize: theme.typography.size16,
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.size16,
            fontWeight: '500',
            letterSpacing: '0.1px',
            padding: '0',
            lineHeight: theme.typography.size22,
            overflow: 'hidden',
            [theme.breakpoints.down('tablet')]: {
              fontSize: theme.typography.size12,
            },
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            display: 'flex',
            padding: 'unset',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px  0px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.size16,
            fontWeight: '400',
            lineHeight: 'normal',
            alignItems: 'center',
            appearance: 'none',
            backgroundColor: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '5px',
            color: theme.palette.primary.contrastText,
            cursor: 'pointer',
            display: 'inline-flex',
            justifyContent: 'center',
            padding: '10px 30px',
            height: '45px',
            textDecoration: 'none',
            textTransform: 'none',
            textRendering: 'optimizeLegibility',
            transition: 'background-color .2s, box-shadow .2s, color .2s, transform .2s',
            '&:hover': {
              textDecoration: 'none',
              backgroundColor: theme.palette.common.black,
              border: `1px solid ${theme.palette.timberGreen.main}`,
            },
          },
        },
      },
    },
  });

const CustomPopup = ({ isOpen, onClose, logo, divider, title, children, actionButtons, logoStyles }) => {
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleEscapeKeyPress = (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener('keydown', handleEscapeKeyPress);
    } else {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [isOpen, onClose]);

  return (
    <>
      <ThemeProvider theme={popupLocalTheme}>
        <Dialog fullWidth maxWidth={isDesktopView ? 'sm' : '100%'} open={isOpen} onClose={onClose} aria-labelledby="max-width-dialog-title" aria-label={title}>
          <PopupContainer>
            <div style={logoContainerStyles}>
              {logo && <img src={logo} alt="Logo" style={logoStyles ?? defaultLogoStyles} />}
              <IconButton onClick={onClose} size="small" sx={{ position: 'absolute', top: '5px', right: '5px' }}>
                <CloseIcon />
              </IconButton>
            </div>
            {title && <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>}
            {divider && <hr style={{ height: '1px', border: 'none', backgroundColor: '#ccc', margin: '0 0 20px 0', padding: '0' }} />}
            <DialogContent>
              <div>{children}</div>
            </DialogContent>
            {actionButtons && actionButtons.length > 0 && (
              <DialogActions>
                {actionButtons.map((button, index) => (
                  <Button key={index} onClick={button.onClick} fullWidth={true}>
                    {button.label}
                  </Button>
                ))}
              </DialogActions>
            )}
          </PopupContainer>
        </Dialog>
      </ThemeProvider>
    </>
  );
};
CustomPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  logo: PropTypes.string,
  divider: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

export default CustomPopup;
