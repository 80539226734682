export function dispatchLoginRadiusEvent(eventName) {
    let detailObject = { origin: "MyNewsAssistant" };

    if(eventName === 'auth-ui.complete-profile') {
        detailObject.fields = 'username';
    }

    window.dispatchEvent(new CustomEvent(eventName, {
        detail: detailObject
    }));
}